import React, { useEffect } from "react";
import AllCheckbox from "@src/components/Inputs/AllCheckbox";
import Checkbox from "@src/components/Inputs/Checkbox";
import { StyledOverflowContainer } from "@src/styles/commonStyles";
import styled, { css, keyframes } from "styled-components";
import { ColorTypeConst } from "@constructs/common";
import { useTranslation } from "react-i18next";
import DataLoadingText from "../Loadings/DataLoadingText";

export type TableHead = { value: string; label: string };

interface CommonTableProps {
  colorType: ColorType;
  head: TableHead[];
  minWidth?: number;
  maxHeight?: number;
  body: JSX.Element[] | null;
  isLoading?: boolean;
  customGridColumnsMinMaxArray?: { min: string; max: string }[];
  customTextOverflowLine?: number; // default 1line
  isCustomPaging?: boolean;
  page?: number;
  size?: number;
}

type CheckableConditionalProps =
  | ({
      isCheckableRow: true;
      isCheckedRow: boolean[];
      handleCheckedRow: (index: number, checked?: boolean) => void;
      checkboxName: string;
    } & AllCheckableConditionalProps)
  | {
      isCheckableRow?: never;
      isCheckedRow?: never;
      handleCheckedRow?: never;
      isAllCheckableRow?: false;
      handleCheckedAll?: never;
      checkboxName?: never;
    };

type AllCheckableConditionalProps =
  | {
      handleCheckedAll: (allChecked: boolean) => void;
    }
  | {
      handleCheckedAll?: never;
    };

type ClickableConditionalProps =
  | {
      isClickableRow: true;
      isClickedRow: boolean[];
      handleClickedRow: (index: number, checked?: boolean) => void;
    }
  | {
      isClickableRow?: false;
      isClickedRow?: never;
      handleClickedRow?: never;
    };

type TableProps = CommonTableProps &
  CheckableConditionalProps &
  ClickableConditionalProps;

const innerTableShow = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const StyledNoDataWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  min-height: 400px;
  font-size: ${({ theme }) => theme.size.common.fontSize.md};
  color: ${({ theme }) => theme.color.table.tbody.font};
  background-color: ${({ theme }) => theme.color.table.tbody.backgroundOdd};
  border-radius: 4px;

  & > span {
    position: relative;
  }
`;

const StyledTableContainer = styled.div<{
  minWidth?: number;
  colorType: ColorType;
  isClickableRow?: boolean;
  isCheckableRow?: boolean;
  headLength: number;
  customGridColumnsMinMaxArray?: { min: string; max: string }[];
  customTextOverflowLine?: number;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  & + * {
    margin-top: 10px;
  }
  width: 100%;
  min-width: ${({ minWidth }) => minWidth ?? 500}px;

  table {
    display: table;
    width: 100%;
    border-collapse: collapse;

    tr > * {
      text-align: left;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    thead {
      position: sticky;
      top: 0;

      &::after {
        content: "";
        display: block;
        background-color: ${({ theme }) => theme.color.card.background};
        width: 100%;
        height: 8px;
      }

      tr {
        display: grid;
        grid-template-columns: ${({
          isCheckableRow,
          headLength,
          customGridColumnsMinMaxArray,
        }) =>
          isCheckableRow && customGridColumnsMinMaxArray
            ? `minmax(52px, 52px) ${customGridColumnsMinMaxArray
                .map((item) => `minmax(${item.min}, ${item.max})`)
                .join(" ")}`
            : isCheckableRow
            ? `minmax(52px, 52px) repeat(${headLength}, 1fr)`
            : customGridColumnsMinMaxArray
            ? customGridColumnsMinMaxArray
                .map((item) => `minmax(${item.min}, ${item.max})`)
                .join(" ")
            : `repeat(${headLength}, 1fr)`};

        font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
        border-radius: 4px;

        &,
        th:first-child {
          background-color: ${({ theme, colorType }) =>
            colorType === ColorTypeConst.Primary
              ? theme.color.table.thead.backgroundPrimary
              : theme.color.table.thead.backgroundSecondary};
        }

        th {
          display: inline-flex;
          align-items: center;
          color: ${({ theme, colorType }) =>
            colorType === ColorTypeConst.Primary
              ? theme.color.table.thead.fontPrimary
              : theme.color.table.thead.fontSecondary};
        }
      }

      tr > * {
        padding: 8px 16px;
        min-height: 34px;
      }
    }
  }

  tbody {
    & > tr {
      display: grid;
      align-items: center;
      grid-template-columns: ${({
        isCheckableRow,
        headLength,
        customGridColumnsMinMaxArray,
      }) =>
        isCheckableRow && customGridColumnsMinMaxArray
          ? `minmax(52px, 52px) ${customGridColumnsMinMaxArray
              .map((item) => `minmax(${item.min}, ${item.max})`)
              .join(" ")}`
          : isCheckableRow
          ? `minmax(52px, 52px) repeat(${headLength}, 1fr)`
          : customGridColumnsMinMaxArray
          ? customGridColumnsMinMaxArray
              .map((item) => `minmax(${item.min}, ${item.max})`)
              .join(" ")
          : `repeat(${headLength}, 1fr)`};

      font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
      background-color: ${({ theme }) => theme.color.table.tbody.backgroundOdd};
      border-radius: 4px;

      td {
        color: ${({ theme }) => theme.color.table.tbody.font};
        line-height: ${({ theme }) => theme.size.common.fontSize.lg};

        /* 
         * 말줄임표 설정
         * defulat: 1줄, customTextOverflowLine에 따라 줄 변경
         */
        word-break: keep-all;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: ${({ theme, customTextOverflowLine }) =>
          customTextOverflowLine &&
          `calc(${theme.size.common.fontSize.lg} * ${customTextOverflowLine})`};
        display: ${({ customTextOverflowLine }) =>
          customTextOverflowLine && "-webkit-box"};
        -webkit-line-clamp: ${({ customTextOverflowLine }) =>
          customTextOverflowLine};
        -webkit-box-orient: ${({ customTextOverflowLine }) =>
          customTextOverflowLine && "vertical"};
        white-space: ${({ customTextOverflowLine }) =>
          !customTextOverflowLine && "nowrap"};

        &:first-child {
          background-color: ${({ theme }) =>
            theme.color.table.tbody.backgroundOdd};
        }
      }

      &:nth-child(2n) {
        &,
        td:first-child {
          background-color: ${({ theme }) =>
            theme.color.table.tbody.backgroundEven};
        }
      }

      ${({ isClickableRow, colorType }) =>
        isClickableRow &&
        css`
          &:not(.trForInnerTable):hover {
            & > td {
              background-color: ${({ theme }) =>
                colorType === ColorTypeConst.Primary
                  ? `${theme.color.table.tbody.backgroundHoverPrimary} !important`
                  : `${theme.color.table.tbody.backgroundHoverSecondary} !important`};
            }
            cursor: pointer;
          }
        `}

      &.innerTableTr {
        opacity: 0;
        transform: translateY(-20px);
        animation: ${innerTableShow} 0.34s ease-out forwards;
      }
    }

    tr > * {
      padding: 0;
      /* white-space: nowrap; */

      &:not(.noPd) {
        padding: 9px 16px;
      }
    }
  }
`;

function TableComponent({
  colorType,
  head,
  isClickableRow,
  isCheckableRow,
  isCheckedRow,
  minWidth,
  maxHeight,
  body,
  isLoading,
  handleCheckedRow,
  handleCheckedAll,
  handleClickedRow,
  checkboxName,
  customGridColumnsMinMaxArray,
  customTextOverflowLine,
  isCustomPaging,
  page,
  size,
}: TableProps) {
  const { t } = useTranslation();
  const handleClickRow = isClickableRow
    ? (
        e: React.MouseEvent<HTMLTableRowElement>,
        index: number,
        checked?: boolean
      ) => {
        const target = e.target as HTMLElement;
        if (
          target.tagName.toUpperCase() !== "TD" &&
          target.tagName.toUpperCase() !== "TR"
        )
          return;
        handleClickedRow(index, checked);
      }
    : () => {};

  const newBody =
    body && isCustomPaging && size && page
      ? body.map((item, index) => {
          const start = size * (page - 1);
          const end = start + (size - 1);
          if (index >= start && index <= end) {
            return item;
          }
          return null;
        })
      : null;

  // 데이터 로딩 중일 때 체크박스 전체 선택이 됐으면 선택 해제
  useEffect(() => {
    if (
      handleCheckedAll !== undefined &&
      isLoading &&
      isCheckedRow?.every((item) => item === true)
    ) {
      handleCheckedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <StyledOverflowContainer
      isDataNull={!body || body.length === 0}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxHeight: maxHeight ? `${maxHeight}px` : undefined,
      }}
    >
      <StyledTableContainer
        minWidth={minWidth}
        colorType={colorType}
        isClickableRow={isClickableRow}
        isCheckableRow={isCheckableRow}
        headLength={head.length}
        customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
        customTextOverflowLine={customTextOverflowLine}
      >
        <table>
          <thead>
            <tr>
              {isCheckableRow ? (
                <th
                  key={0}
                  style={{
                    position: "sticky",
                    left: "0",
                  }}
                >
                  <AllCheckbox
                    name={`${checkboxName}-all`}
                    colorType="table"
                    isCheckedArray={isCheckedRow}
                    handler={
                      handleCheckedAll !== undefined
                        ? handleCheckedAll
                        : () => {}
                    }
                    isDisabled={handleCheckedAll === undefined ? true : false}
                  />
                </th>
              ) : null}
              {head.map((th: TableHead, thIndex: number) => (
                <th key={isCheckableRow ? thIndex + 1 : thIndex}>
                  {t(th.label)}
                </th>
              ))}
            </tr>
          </thead>
          {!isLoading && body && body.length > 0 ? (
            <tbody>
              {/* isCheckable이 true일 때 checkbox를 그리기 위한 데이터 가공 */}
              {newBody !== null
                ? newBody.map(
                    (
                      bodyItem: JSX.Element | JSX.Element[] | null,
                      bodyItemIndex: number
                    ) => {
                      // bodyItem이 null이면 무시하기 custom paging 처리
                      if (!bodyItem) {
                        return null;
                      }

                      /*  body의 N번째 Child인 bodyItem은
                      tbody의 자식으로 그려진 body이기 때문에 <tr> 태그가 일반적이다.

                      현재 웹표준에 따라 그려질 수 있는 돔은 3종류이다.
                      [1] <>
                            <tr></tr>
                            <tr or F></tr or F>
                          </>

                      [2] <tr></tr>

                      [3] <tr></tr>
                          <tr or F></tr or F>
                    */

                      // bodyItem이 배열인지 아닌지 확인하는 용도
                      const arrayCheck = Array.isArray(bodyItem);

                      if (arrayCheck) {
                        // 1. Array 형태라면 [3]의 형태를 이루고 있다고 판단한다.
                      } else {
                        // 2. Array가 아니기 때문에 [1]/[2] 둘중 하나, bodyItem의 type이 tr인지 확인한다.
                        if (bodyItem.type === "tr") {
                          // tr이라면 isCheckable 확인 후 그려준다.
                          // isClickableRow가 true일 경우 ClickEvent 추가한다.
                          return (
                            <tr
                              {...bodyItem.props}
                              key={bodyItemIndex}
                              onClick={(e) => handleClickRow(e, bodyItemIndex)}
                            >
                              {/* 체크가 가능한 테이블 일 경우 Checkbox를 추가한다. */}
                              {isCheckableRow && (
                                <td
                                  style={{
                                    position: "sticky",
                                    left: "0",
                                  }}
                                >
                                  <Checkbox
                                    name={checkboxName}
                                    colorType="table"
                                    isChecked={isCheckedRow[bodyItemIndex]}
                                    index={
                                      bodyItemIndex
                                    } /* index는 1줄의 Index를 받아온다.? */
                                    handler={handleCheckedRow}
                                  />
                                </td>
                              )}
                              {bodyItem.props.children}
                            </tr>
                          );
                        } else {
                          // tr이 아니면 fragment로 판단한다.

                          const arrayCheckSecond = Array.isArray(
                            bodyItem.props.children
                          );

                          // bodyItem의 children이 array인지 확인한다.
                          if (arrayCheckSecond) {
                            return bodyItem.props.children.map(
                              (tr: JSX.Element) => {
                                // tr의 type이 tr인지 Fragment(or undefined)인지 확인
                                // key에 innerTable을 포함하지 않는지 확인
                                if (
                                  tr.type === "tr" &&
                                  !tr.key?.toString().includes("innerTable")
                                ) {
                                  return (
                                    <tr
                                      {...tr.props}
                                      key={bodyItemIndex}
                                      onClick={(e) =>
                                        handleClickRow(e, bodyItemIndex)
                                      }
                                    >
                                      {/* 체크가 가능한 테이블 일 경우 Checkbox를 추가한다. */}
                                      {isCheckableRow && (
                                        <td
                                          style={{
                                            position: "sticky",
                                            left: "0",
                                          }}
                                        >
                                          <Checkbox
                                            name={checkboxName}
                                            colorType="table"
                                            isChecked={
                                              isCheckedRow[bodyItemIndex]
                                            }
                                            index={
                                              bodyItemIndex
                                            } /* index는 1줄의 Index를 받아온다.? */
                                            handler={handleCheckedRow}
                                          />
                                        </td>
                                      )}
                                      {tr.props.children}
                                    </tr>
                                  );
                                } else {
                                  return tr;
                                }
                              }
                            );
                          }
                        }
                      }
                      return <></>;
                    }
                  )
                : body
                ? body.map(
                    (
                      bodyItem: JSX.Element | JSX.Element[],
                      bodyItemIndex: number
                    ) => {
                      /*  body의 N번째 Child인 bodyItem은
                      tbody의 자식으로 그려진 body이기 때문에 <tr> 태그가 일반적이다.

                      현재 웹표준에 따라 그려질 수 있는 돔은 3종류이다.
                      [1] <>
                            <tr></tr>
                            <tr or F></tr or F>
                          </>

                      [2] <tr></tr>

                      [3] <tr></tr>
                          <tr or F></tr or F>
                    */

                      // bodyItem이 배열인지 아닌지 확인하는 용도
                      const arrayCheck = Array.isArray(bodyItem);

                      if (arrayCheck) {
                        // 1. Array 형태라면 [3]의 형태를 이루고 있다고 판단한다.
                      } else {
                        // 2. Array가 아니기 때문에 [1]/[2] 둘중 하나, bodyItem의 type이 tr인지 확인한다.

                        if (bodyItem.type === "tr") {
                          // tr이라면 isCheckable 확인 후 그려준다.
                          // isClickableRow가 true일 경우 ClickEvent 추가한다.
                          return (
                            <tr
                              {...bodyItem.props}
                              key={bodyItemIndex}
                              onClick={(e) => handleClickRow(e, bodyItemIndex)}
                            >
                              {/* 체크가 가능한 테이블 일 경우 Checkbox를 추가한다. */}
                              {isCheckableRow && (
                                <td
                                  style={{
                                    position: "sticky",
                                    left: "0",
                                  }}
                                >
                                  <Checkbox
                                    name={checkboxName}
                                    colorType="table"
                                    isChecked={isCheckedRow[bodyItemIndex]}
                                    index={
                                      bodyItemIndex
                                    } /* index는 1줄의 Index를 받아온다.? */
                                    handler={handleCheckedRow}
                                  />
                                </td>
                              )}
                              {bodyItem.props.children}
                            </tr>
                          );
                        } else {
                          // tr이 아니면 fragment로 판단한다.

                          const arrayCheckSecond = Array.isArray(
                            bodyItem.props.children
                          );

                          // bodyItem의 children이 array인지 확인한다.
                          if (arrayCheckSecond) {
                            return bodyItem.props.children.map(
                              (tr: JSX.Element) => {
                                // tr의 type이 tr인지 Fragment(or undefined)인지 확인
                                // key에 innerTable을 포함하지 않는지 확인
                                if (
                                  tr.type === "tr" &&
                                  !tr.key?.toString().includes("innerTable")
                                ) {
                                  return (
                                    <tr
                                      {...tr.props}
                                      key={bodyItemIndex}
                                      onClick={(e) =>
                                        handleClickRow(e, bodyItemIndex)
                                      }
                                    >
                                      {/* 체크가 가능한 테이블 일 경우 Checkbox를 추가한다. */}
                                      {isCheckableRow && (
                                        <td>
                                          <Checkbox
                                            name={checkboxName}
                                            colorType="table"
                                            isChecked={
                                              isCheckedRow[bodyItemIndex]
                                            }
                                            index={
                                              bodyItemIndex
                                            } /* index는 1줄의 Index를 받아온다.? */
                                            handler={handleCheckedRow}
                                          />
                                        </td>
                                      )}
                                      {tr.props.children}
                                    </tr>
                                  );
                                } else {
                                  return tr;
                                }
                              }
                            );
                          }
                        }
                      }
                      return <></>;
                    }
                  )
                : null}
            </tbody>
          ) : null}
        </table>
        {isLoading || !body || body.length === 0 ? (
          <StyledNoDataWrap>
            {isLoading ? (
              <DataLoadingText text={t("loading_data")} showIcon size="sm" />
            ) : (
              <DataLoadingText text={t("no_data")} />
            )}
          </StyledNoDataWrap>
        ) : null}
      </StyledTableContainer>
    </StyledOverflowContainer>
  );
}

const Table = React.memo(
  TableComponent,
  (prevProps, nextProps) =>
    prevProps.head === nextProps.head &&
    prevProps.body === nextProps.body &&
    prevProps.isCheckableRow === nextProps.isCheckableRow &&
    prevProps.isClickableRow === nextProps.isClickableRow &&
    prevProps.isCheckedRow === nextProps.isCheckedRow &&
    prevProps.isClickedRow === nextProps.isClickedRow &&
    prevProps.handleCheckedRow === nextProps.handleCheckedRow &&
    prevProps.handleClickedRow === nextProps.handleClickedRow &&
    prevProps.handleCheckedAll === nextProps.handleCheckedAll &&
    prevProps.page === nextProps.page &&
    prevProps.size === nextProps.size &&
    prevProps.isLoading === nextProps.isLoading
);

export default Table;

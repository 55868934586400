import Icon from "@src/components/Icon";
import { CustomDateYearSelector } from "@src/components/Inputs/sizeTheme";
import _ from "lodash";
import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Select from "../Inputs/Selects/Select";
import { getMonth, getYear } from "date-fns";

interface CustomHeaderProps {
  date: Date;
  monthDate: Date;
  maxDate?: Date;
  minDate?: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    svg {
      fill: #aaa;
    }
  }

  &:disabled {
    svg {
      fill: ${({ theme }) => theme.color.calendar.fontDisabled};
    }
    cursor: auto;
  }
`;

const StyledYearMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  .select__control {
    height: auto;
  }

  & > *,
  & > .react-datepicker__current-month {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.color.font.default};
    font-size: ${({ theme }) => theme.size.common.fontSize.lg};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
  }

  span {
    font-size: ${({ theme }) => theme.size.common.fontSize.lg};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
  }

  & > div:last-child {
    &::after {
      content: attr(data-date);
    }
  }
`;

function CustomHeaderComponent({
  date,
  monthDate,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  maxDate,
  minDate,
}: CustomHeaderProps) {
  // Year Selectbox의 최소/최대값 기준
  const yearRange = 2;

  // for infinity selector year
  const [startYear, setStartYear] = React.useState<number | null>(null);
  const [endYear, setEndYear] = React.useState<number | null>(null);

  // 연도 선택 select box에 보여질 데이터 : range(시작 연도, 끝 연도, 연도 간격)
  const [years, setYears] = React.useState<number[]>([]);

  // 월 선택 select box에 보여질 데이터
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  // Year Selectbox 무한 옵션

  // 1. startDate, endDate 구하기
  useEffect(() => {
    if (
      maxDate &&
      minDate &&
      getYear(maxDate) - getYear(minDate) < yearRange * 2
    ) {
      // 최소날짜, 최대날짜 둘다 있고 둘의 차이가 yearRange * 2 보다 작을 경우 고정
      setStartYear(getYear(minDate));
      setEndYear(getYear(maxDate));
    } else if (maxDate && getYear(date) + 2 >= getYear(maxDate)) {
      // date + 2 년도가 maxDate의 년도 보다 크거나 같을 경우
      // endYear는 maxDate의 년도로 고정 / startYear는 (maxDate - yearRange * 2)
      setStartYear(getYear(maxDate) - yearRange * 2);
      setEndYear(getYear(maxDate));
    } else if (minDate && getYear(date) - 2 <= getYear(minDate)) {
      // date - 2 년도가 minDate의 년도 보다 작거나 같을 경우
      // startYear는 minDate의 년도로 고정 / endYear는 (minDate + yearRange * 2)
      setStartYear(getYear(minDate));
      setEndYear(getYear(minDate) + yearRange * 2);
    } else if (!minDate && !maxDate) {
      setStartYear(getYear(date) - yearRange);
      setEndYear(getYear(date) + yearRange);
    }
  }, [date, maxDate, minDate]);

  // 2. startDate, endDate 한번 더 구하거나 setYears
  useEffect(() => {
    if (startYear && endYear) {
      if (
        (!maxDate || (maxDate && getYear(date) !== getYear(maxDate))) &&
        (getYear(date) <= startYear || getYear(date) >= endYear)
      ) {
        setStartYear(getYear(date) - yearRange);
        setEndYear(getYear(date) + yearRange);
      } else {
        const yearOptions = _.range(startYear, endYear + 1, 1);
        setYears(yearOptions);
        setStartYear(null);
        setEndYear(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startYear, endYear]);

  return (
    <div className="custom-react-datepicker__select-wrapper">
      {/* 이전 월로 이동하는 버튼 */}
      <StyledButton
        className="prev"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <Icon
          iconName="arrowLeft"
          iconWidth={16}
          iconHeight={16}
          iconColor="#ccc"
        />
      </StyledButton>
      {years.length > 0 ? (
        <StyledYearMonth>
          <div className="custom-react-datepicker__current-month">
            <div className="custom-react-datepicker__select-item">
              {/* 연도 선택 select box */}
              {
                <Select
                  value={{
                    value: getYear(date).toString(),
                    label: getYear(date).toString(),
                  }}
                  sizeTheme={CustomDateYearSelector}
                  name="year-select"
                  options={years.map((year: number) => {
                    return { value: year.toString(), label: year.toString() };
                  })}
                  event={(value: SelectOption) =>
                    changeYear(Number(value.value))
                  }
                  showOnlyValue
                  fixWidth={60}
                  // isValueNotAlteredByOption
                />
              }
            </div>
            <span>.</span>
            <div className="custom-react-datepicker__select-item">
              {/* 월 선택 select box */}
              <Select
                value={{
                  value: months[getMonth(date)],
                  label: months[getMonth(date)],
                }}
                sizeTheme={CustomDateYearSelector}
                name="month-select"
                options={months.map((month: string) => ({
                  value: month,
                  label: month,
                }))}
                event={(value: SelectOption) => {
                  changeMonth(months.indexOf(value.value));
                }}
                showOnlyValue
                fixWidth={36}
              />
            </div>
          </div>

          <div
            className="react-datepicker__current-month"
            data-date={`${
              months[getMonth(date)] === "12"
                ? getYear(date) + 1
                : getYear(date)
            }. ${
              months[getMonth(date)] === "12"
                ? months[0]
                : months[getMonth(date) + 1]
            }`}
          />
        </StyledYearMonth>
      ) : null}

      {/* 다음 월로 이동하는 버튼 */}
      <StyledButton
        className="next"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <Icon
          iconName="arrowRight"
          iconWidth={16}
          iconHeight={16}
          iconColor="#ccc"
        />
      </StyledButton>
    </div>
  );
}

const CustomHeaderMemo = React.memo(CustomHeaderComponent, (prev, next) => {
  return (
    getMonth(prev.date) === getMonth(next.date) &&
    getYear(prev.date) === getYear(next.date)
  );
});

export default CustomHeaderMemo;

const size = {
  common: {
    fontSize: {
      xs: "0.55rem",
      sm: "0.6rem",
      xbase: "0.65rem",
      base: "0.7rem",
      xmd: "0.75rem",
      md: "0.8rem",
      mdlg: "0.9rem",
      lg: "1rem",
      xlg: "1.1rem",
    },
    fontWeight: {
      xs: 200,
      sm: 300,
      base: 400,
      md: 500,
      lg: 600,
      xlg: 800,
    },
  },
  normalInput: {
    padding: 10,
    height: 40,
    borderRadius: 2,
    addonWidth: 20,
    addonHeight: 20,
    optionHeight: 36,
    rangeDateWidth: 218,
    singleDateWidth: 150,
  },
  smallSizeInput: {
    padding: 8,
    height: 30,
    borderRadius: 2,
    addonWidth: 16,
    addonHeight: 16,
    optionHeight: 30,
    rangeDateWidth: 218,
    singleDateWidth: 150,
  },
  bigSizeInput: {
    padding: 10,
    height: 50,
    borderRadius: 2,
    addonWidth: 24,
    addonHeight: 24,
    optionHeight: 36,
    rangeDateWidth: 250,
    singleDateWidth: 160,
  },
};

export default size;

import React, { ReactNode, useEffect } from "react";
import AllCheckbox from "@src/components/Inputs/AllCheckbox";
import Checkbox from "@src/components/Inputs/Checkbox";
import {
  StyledFlexWrap,
  StyledOverflowContainer,
} from "@src/styles/commonStyles";
import styled, { css, keyframes } from "styled-components";
import {
  ColorTypeConst,
  FlexAlignTypeConst,
  ThemeConst,
} from "@constructs/common";
import { theme } from "@src/styles/theme";
import { XXSmallNoSpaceIconButtonStyles } from "../Buttons/sizeTheme";
import IconButton from "../Buttons/IconButton";
import Input from "../Inputs/Input";
import { useForm } from "react-hook-form";
import { NormalInputStyle, SmallInputStyle } from "../Inputs/sizeTheme";
import useTableHooks from "@src/hooks/useTableHooks";
import { getTextFromElement } from "@src/hooks/useCommonHooks";
import { useThemeStore } from "@src/stores/useCommonStore";
import { useTranslation } from "react-i18next";
import DataLoadingText from "../Loadings/DataLoadingText";

export type TableHead = { value: string; label: string };
export interface TableBody {
  trOptions: {
    dataRowIndex: number;
  };
  tdOptions: {
    dataKey: string;
    dataValue: string | boolean | number | null;
    tdValue: string | JSX.Element;
  }[];
}

interface CommonTableProps {
  colorType: ColorType;
  head: TableHead[];
  minWidth?: number;
  maxHeight?: number;
  body: TableBody[] | null;
  isLoading?: boolean;
  customGridColumnsMinMaxArray?: { min: string; max: string }[];
  customTextOverflowLine?: number; // default 1line
  isCustomPaging?: boolean;
  getCustomPagingCount?: (value: number) => void;
  page?: number;
  size?: number;
  isSearchable?: boolean;
  searchInputLocation?: "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT";
  isSortable?: boolean;
  elementSize?: "small" | "normal";
  customButtons?: ReactNode;
}

type CheckableConditionalProps =
  | ({
      isCheckableRow: true;
      isCheckedRow: boolean[];
      handleCheckedRow: (index: number, checked?: boolean) => void;
      checkboxName: string;
    } & AllCheckableConditionalProps)
  | {
      isCheckableRow?: never;
      isCheckedRow?: never;
      handleCheckedRow?: never;
      isAllCheckableRow?: false;
      handleCheckedAll?: never;
      checkboxName?: never;
    };

type AllCheckableConditionalProps =
  | {
      handleCheckedAll: (
        allChecked: boolean,
        visibleSelected?: number[]
      ) => void;
    }
  | {
      handleCheckedAll?: never;
    };

type ClickableConditionalProps =
  | {
      isClickableRow: true;
      isClickedRow: boolean[];
      handleClickedRow: (index: number, checked?: boolean) => void;
      isColumnClickToSearch?: never;
    }
  | {
      isClickableRow?: never;
      isClickedRow?: never;
      handleClickedRow?: never;
      isColumnClickToSearch?: never;
    }
  | {
      isClickableRow?: never;
      isClickedRow?: never;
      handleClickedRow?: never;
      isColumnClickToSearch: true;
    };

type TableProps = CommonTableProps &
  CheckableConditionalProps &
  ClickableConditionalProps;

const innerTableShow = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const StyledNoDataWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  /* margin-top: 8px; */

  font-size: ${({ theme }) => theme.size.common.fontSize.md};
  color: ${({ theme }) => theme.color.table.tbody.font};

  width: 100%;
  min-height: 200px;

  background-color: ${({ theme }) => theme.color.table.tbody.backgroundOdd};
  border-radius: 4px;

  & > span {
    position: relative;
  }
`;

const StyledTableContainer = styled.div<{
  minWidth?: number;
  colorType: ColorType;
  isClickableRow?: boolean;
  isCheckableRow?: boolean;
  headLength: number;
  customGridColumnsMinMaxArray?: { min: string; max: string }[];
  customTextOverflowLine?: number;
  isColumnClickToSearch?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  & + * {
    margin-top: 10px;
  }
  width: 100%;
  min-width: ${({ minWidth }) => minWidth ?? 500}px;

  table {
    display: table;
    width: 100%;
    border-collapse: collapse;

    tr > * {
      text-align: left;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      &::after {
        content: "";
        display: block;
        background-color: ${({ theme }) => theme.color.card.background};
        width: 100%;
        height: 8px;
      }

      tr {
        display: grid;
        grid-template-columns: ${({
          isCheckableRow,
          headLength,
          customGridColumnsMinMaxArray,
        }) =>
          isCheckableRow && customGridColumnsMinMaxArray
            ? `minmax(52px, 52px) ${customGridColumnsMinMaxArray
                .map((item) => `minmax(${item.min}, ${item.max})`)
                .join(" ")}`
            : isCheckableRow
            ? `minmax(52px, 52px) repeat(${headLength}, 1fr)`
            : customGridColumnsMinMaxArray
            ? customGridColumnsMinMaxArray
                .map((item) => `minmax(${item.min}, ${item.max})`)
                .join(" ")
            : `repeat(${headLength}, 1fr)`};

        font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
        border-radius: 4px;

        &,
        th:first-child {
          background-color: ${({ theme, colorType }) =>
            colorType === ColorTypeConst.Primary
              ? theme.color.table.thead.backgroundPrimary
              : theme.color.table.thead.backgroundSecondary};
        }

        th {
          display: inline-flex;
          align-items: center;
          color: ${({ theme, colorType }) =>
            colorType === ColorTypeConst.Primary
              ? theme.color.table.thead.fontPrimary
              : theme.color.table.thead.fontSecondary};
        }
      }

      tr > * {
        padding: 6px 16px;
        min-height: 38px;
      }
    }
  }

  tbody {
    & > tr {
      display: grid;
      align-items: center;
      grid-template-columns: ${({
        isCheckableRow,
        headLength,
        customGridColumnsMinMaxArray,
      }) =>
        isCheckableRow && customGridColumnsMinMaxArray
          ? `minmax(52px, 52px) ${customGridColumnsMinMaxArray
              .map((item) => `minmax(${item.min}, ${item.max})`)
              .join(" ")}`
          : isCheckableRow
          ? `minmax(52px, 52px) repeat(${headLength}, 1fr)`
          : customGridColumnsMinMaxArray
          ? customGridColumnsMinMaxArray
              .map((item) => `minmax(${item.min}, ${item.max})`)
              .join(" ")
          : `repeat(${headLength}, 1fr)`};

      font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
      background-color: ${({ theme }) => theme.color.table.tbody.backgroundOdd};
      border-radius: 4px;

      td {
        color: ${({ theme }) => theme.color.table.tbody.font};
        line-height: ${({ theme }) => theme.size.common.fontSize.lg};

        /* 
         * 말줄임표 설정
         * defulat: 1줄, customTextOverflowLine에 따라 줄 변경
         */
        word-break: keep-all;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: ${({ theme, customTextOverflowLine }) =>
          customTextOverflowLine &&
          `calc(${theme.size.common.fontSize.lg} * ${customTextOverflowLine})`};
        display: ${({ customTextOverflowLine }) =>
          customTextOverflowLine && "-webkit-box"};
        -webkit-line-clamp: ${({ customTextOverflowLine }) =>
          customTextOverflowLine};
        -webkit-box-orient: ${({ customTextOverflowLine }) =>
          customTextOverflowLine && "vertical"};
        white-space: ${({ customTextOverflowLine }) =>
          !customTextOverflowLine && "nowrap"};

        &:first-child {
          background-color: ${({ theme }) =>
            theme.color.table.tbody.backgroundOdd};
        }

        &:hover {
          cursor: ${({ isColumnClickToSearch }) =>
            isColumnClickToSearch ? "porint" : "unset"};
          color: ${({ isColumnClickToSearch, theme }) =>
            isColumnClickToSearch ? theme.color.primary : undefined};
        }
      }

      &:nth-child(2n) {
        &,
        td:first-child {
          background-color: ${({ theme }) =>
            theme.color.table.tbody.backgroundEven};
        }
      }

      ${({ isClickableRow, colorType }) =>
        isClickableRow &&
        css`
          &:not(.trForInnerTable):hover {
            & > td {
              background-color: ${({ theme }) =>
                colorType === ColorTypeConst.Primary
                  ? `${theme.color.table.tbody.backgroundHoverPrimary} !important`
                  : `${theme.color.table.tbody.backgroundHoverSecondary} !important`};
            }
            cursor: pointer;
          }
        `}

      &.innerTableTr {
        opacity: 0;
        transform: translateY(-20px);
        animation: ${innerTableShow} 0.34s ease-out forwards;
      }
    }

    tr > * {
      padding: 0;
      /* white-space: nowrap; */

      &:not(.noPd) {
        padding: 9px 16px;
      }
    }
  }
`;

/**
 *
 * @param
 * - colorType: ColorType("primary" | "secondary")
 * - head: TableHead[]
 * - body: TableBody[] | null
 * - minWidth?: number
 * - maxHeight?: number
 * - customGridColumnsMinMaxArray:
 * - customTextOverflowLine:
 * - isSearchable: 테이블 내 컬럼의 시작 문자열을 검색해서 일치하는 줄만 반환하는 기능을 사용하는 boolean 파라미터
 * - isSortable: 테이블 내 컬럼 별로 오름차순/내림차순 정렬하는 기능을 사용하는 boolean 파라미터
 * - isClickableRow:
 * - isCheckableRow:
 * - isCheckedRow:
 * - isLoading:
 * - handleCheckedRow:
 * - handleCheckedAll:
 * - handleClickedRow:
 * - checkboxName: 'name' that attribute of checkbox tag
 * - isCustomPaging: body는 모든 데이터를 반환하고 페이징 기능을 테이블에서 처리하는 boolean 파라미터
 * - getCustomPagingCount: isCustomPaging 사용할 때 검색 등으로 카운트 개수 바뀐 경우 카운트 값 리턴해서 부모 컴포넌트에서 사용
 * - page: page of paging
 * - size: size of paging
 * - customButtons
 * @returns
 */
function TableComponent({
  colorType,
  head,
  body,
  minWidth,
  maxHeight,
  customGridColumnsMinMaxArray,
  customTextOverflowLine,
  isSearchable,
  searchInputLocation = "TOP_LEFT",
  isSortable,
  isClickableRow,
  isCheckableRow,
  isCheckedRow,
  isLoading,
  handleCheckedRow,
  handleCheckedAll,
  handleClickedRow,
  checkboxName,
  isCustomPaging,
  getCustomPagingCount,
  page,
  size,
  isColumnClickToSearch,
  elementSize,
  customButtons,
}: TableProps) {
  // * Hooks
  const { t } = useTranslation();
  const { register, setFocus, watch, setValue, getValues } = useForm();
  const { compareValues } = useTableHooks();
  const themeType = useThemeStore();

  // * State
  const [sortIndex, setSortIndex] = React.useState<number>(-1);
  const [sortType, setSortType] = React.useState<string>("");
  const [sortedTableBody, setSortedBodyData] =
    React.useState<TableBody[] | null>(null);
  const [customPagingCount, setCustomPagingCount] = React.useState<number>(0);

  const handleCustomPagingTable = React.useCallback(
    (page: number, size: number, body: TableBody[]) => {
      // 페이지당 데이터의 시작 인덱스와 끝 인덱스 계산
      const startIndex = (page - 1) * size;
      const endIndex = startIndex + size;

      setCustomPagingCount(body.length);

      // 데이터를 페이지에 맞게 잘라 반환
      const newData = [...body].slice(startIndex, endIndex);
      return newData;
    },
    []
  );

  // * Memo
  // 필터데이터 메모 생성
  // 이때 가공을 완료한 후에 isCustomPaging을 설정해준다.
  const filteredTableBody = React.useMemo(
    () => {
      if (sortedTableBody) {
        if (isSearchable) {
          const newData = sortedTableBody.filter((tr) => {
            if (watch("search")) {
              const search = tr.tdOptions.findIndex((td) => {
                if (
                  td.dataValue &&
                  String(td.dataValue)
                    .toLowerCase()
                    .includes(watch("search").toLowerCase())
                ) {
                  return true;
                } else if (
                  typeof td.tdValue === "object" &&
                  getTextFromElement(td.tdValue)
                    .toLowerCase()
                    .includes(watch("search").toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
              return search !== -1 ? true : false;
            } else {
              return true;
            }
          });

          if (isCustomPaging && page && size) {
            return handleCustomPagingTable(page, size, newData);
          } else {
            return newData;
          }
        } else if (isCustomPaging && page && size) {
          return handleCustomPagingTable(page, size, sortedTableBody);
        } else {
          return sortedTableBody;
        }
      }

      return null;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedTableBody, isSearchable, watch("search"), isCustomPaging, size, page]
  );

  // * Functions
  // 체크박스 핸들러 콜백
  const handleCheckbox = React.useCallback((index: number) => {
    if (handleCheckedRow) handleCheckedRow(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // table thead의 오름차순/내림차순 정렬 버튼 핸들러
  const handleSortButton = React.useCallback(
    (index: number, keyValue: string, type: "up" | "down") => {
      if (index === sortIndex && type === sortType) {
        // 이미 이 컬럼으로 솔트된 상태이므로 초기화한다.
        getSortedTableBody("initialize");
        setSortIndex(-1);
        setSortType("");
        return;
      }

      if (type === "up") {
        getSortedTableBody("up", keyValue);
        setSortIndex(index);
        setSortType("up");
      } else if (type === "down") {
        getSortedTableBody("down", keyValue);
        setSortIndex(index);
        setSortType("down");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortIndex, sortType, body]
  );
  const getSortedTableBody = React.useCallback(
    (type: "up" | "down" | "initialize", keyValue?: string) => {
      if (body) {
        if (type === "initialize") {
          // 초기화
          setSortedBodyData(body);
          return;
        }

        if (keyValue) {
          setSortedBodyData(() => {
            const newData = [...body];
            return newData.sort((a, b) => {
              const valueA =
                a.tdOptions.find((item) => item.dataKey === keyValue)
                  ?.dataValue ?? null;
              const valueB =
                b.tdOptions.find((item) => item.dataKey === keyValue)
                  ?.dataValue ?? null;

              return compareValues(
                valueA,
                valueB,
                type === "up" ? "asc" : "desc",
                keyValue === "uptime" ? keyValue : undefined
              );
            });
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [body]
  );

  const handleClickCell = isColumnClickToSearch
    ? (
        e: React.MouseEvent<HTMLTableCellElement>,
        dataKey: string,
        dataValue: string | number | boolean | null,
        tdValue: string | JSX.Element
      ) => {
        if (dataValue === null || dataValue === "") return;

        if (typeof dataValue === "string" || typeof dataValue === "number") {
          setValue("search", String(dataValue));
        } else if (typeof tdValue === "object") {
          setValue("search", getTextFromElement(tdValue));
        }
      }
    : () => {};
  const handleClickRow = isClickableRow
    ? (
        e: React.MouseEvent<HTMLTableRowElement>,
        index: number,
        checked?: boolean
      ) => {
        const target = e.target as HTMLElement;
        if (
          target.tagName.toUpperCase() !== "TD" &&
          target.tagName.toUpperCase() !== "TR"
        ) {
          return;
        }

        handleClickedRow(index, checked);
      }
    : () => {};

  // * Effects
  // 데이터 로딩 중일 때 체크박스 전체 선택이 됐으면 선택 해제
  useEffect(() => {
    if (
      handleCheckedAll !== undefined &&
      isLoading &&
      isCheckedRow?.every((item) => item === true)
    ) {
      handleCheckedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // body가 null이 아니면 sortedTableBody를 업데이트한다.
  useEffect(() => {
    if (body) {
      setSortedBodyData(body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  useEffect(() => {
    if (handleCheckedAll) handleCheckedAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("search")]);

  // 커스텀 페이징 카운트 업데이트
  useEffect(() => {
    if (getCustomPagingCount) {
      getCustomPagingCount(customPagingCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPagingCount]);

  return (
    <>
      {(isSearchable && searchInputLocation === "TOP_LEFT") || customButtons ? (
        <StyledFlexWrap
          align={FlexAlignTypeConst.SpaceBetween}
          vertical={FlexAlignTypeConst.FlexEnd}
          style={{
            width: "100%",
            flexWrap: "wrap",
            marginBottom: 10,
          }}
          gap={12}
        >
          {isSearchable ? (
            <Input
              sizeTheme={
                elementSize === "small" ? SmallInputStyle : NormalInputStyle
              }
              placeholder="Table search"
              inputName="table-search"
              eventType="UseForm"
              register={{ ...register("search") }}
              setFocus={() => setFocus("search")}
              autoComplete="false"
              addonShow
              addonName="search"
              addonDirection="left"
              isClearable
              getValue={getValues("search")}
              clearValue={() => {
                setValue("search", "");
              }}
              fixWidth={200}
            />
          ) : null}
          {customButtons ? (
            <StyledFlexWrap
              align={FlexAlignTypeConst.FlexEnd}
              style={{
                flex: 1,
                minWidth: "max-content",
              }}
            >
              {customButtons}
            </StyledFlexWrap>
          ) : null}
        </StyledFlexWrap>
      ) : null}
      <StyledOverflowContainer
        isDataNull={!body || body.length === 0}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          maxHeight: maxHeight ? `${maxHeight}px` : undefined,
        }}
      >
        <StyledTableContainer
          minWidth={minWidth}
          colorType={colorType}
          isClickableRow={isClickableRow}
          isCheckableRow={isCheckableRow}
          headLength={head.length}
          customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
          customTextOverflowLine={customTextOverflowLine}
          isColumnClickToSearch={isColumnClickToSearch}
        >
          {isLoading ||
          !body ||
          body.length === 0 ||
          !filteredTableBody ||
          filteredTableBody.length === 0 ? (
            <>
              <table>
                <thead>
                  <tr>
                    {isCheckableRow ? (
                      <th key={0}>
                        <AllCheckbox
                          name={`${checkboxName}-all`}
                          colorType="table"
                          isCheckedArray={isCheckedRow}
                          handler={
                            handleCheckedAll !== undefined
                              ? handleCheckedAll
                              : () => {}
                          }
                          isDisabled={
                            handleCheckedAll === undefined ? true : false
                          }
                        />
                      </th>
                    ) : null}
                    {head.map((th: TableHead, thIndex: number) => (
                      <th key={isCheckableRow ? thIndex + 1 : thIndex}>
                        <span>{t(th.label)}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
              <StyledNoDataWrap>
                {isLoading ? (
                  <DataLoadingText
                    text={t("loading_data")}
                    showIcon
                    size="sm"
                    boxHeight="auto"
                  />
                ) : (
                  <DataLoadingText text={t("no_data")} boxHeight="auto" />
                )}
              </StyledNoDataWrap>
            </>
          ) : (
            <table>
              <thead>
                <tr>
                  {isCheckableRow ? (
                    <th
                      key={0}
                      style={{
                        position: "sticky",
                        left: "0",
                      }}
                    >
                      <AllCheckbox
                        name={`${checkboxName}-all`}
                        colorType="table"
                        isCheckedArray={isCheckedRow}
                        handler={
                          handleCheckedAll !== undefined
                            ? handleCheckedAll
                            : () => {}
                        }
                        isDisabled={
                          handleCheckedAll === undefined ? true : false
                        }
                        visibleIndexes={filteredTableBody?.map(
                          (item) => item.trOptions.dataRowIndex
                        )}
                      />
                    </th>
                  ) : null}
                  {head.map((th: TableHead, thIndex: number) => (
                    <th key={isCheckableRow ? thIndex + 1 : thIndex}>
                      <span>{t(th.label)}</span>
                      {isSortable ? (
                        <StyledFlexWrap
                          flexDirection="column"
                          align={FlexAlignTypeConst.Center}
                          vertical={FlexAlignTypeConst.Center}
                          gap={2}
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <IconButton
                            iconName="arrowTop"
                            onClick={() =>
                              handleSortButton(thIndex, th.value, "up")
                            }
                            sizeTheme={XXSmallNoSpaceIconButtonStyles}
                            colorType="custom"
                            iconColor={"#a6b2f6"}
                            iconHoverColor={
                              themeType === ThemeConst.Light
                                ? theme.color.primary
                                : "#fff"
                            }
                            isActiveWithScale={
                              thIndex === sortIndex && sortType === "up"
                            }
                          />
                          <IconButton
                            iconName="arrowBottom"
                            onClick={() =>
                              handleSortButton(thIndex, th.value, "down")
                            }
                            sizeTheme={XXSmallNoSpaceIconButtonStyles}
                            colorType="custom"
                            iconColor="#a6b2f6"
                            iconHoverColor={
                              themeType === ThemeConst.Light
                                ? theme.color.primary
                                : "#fff"
                            }
                            isActiveWithScale={
                              thIndex === sortIndex && sortType === "down"
                            }
                          />
                        </StyledFlexWrap>
                      ) : null}
                    </th>
                  ))}
                </tr>
              </thead>
              {!isLoading &&
              filteredTableBody &&
              filteredTableBody.length > 0 ? (
                <tbody>
                  {filteredTableBody.map((tr: TableBody) => {
                    return (
                      <tr
                        key={tr.trOptions.dataRowIndex}
                        onClick={
                          isClickableRow
                            ? (e) =>
                                handleClickRow(e, tr.trOptions.dataRowIndex)
                            : undefined
                        }
                      >
                        <>
                          {isCheckableRow ? (
                            <td
                              style={{
                                position: "sticky",
                                left: "0",
                              }}
                              key={`${tr.trOptions.dataRowIndex}-0`}
                            >
                              <Checkbox
                                name={checkboxName}
                                colorType="table"
                                isChecked={
                                  isCheckedRow[tr.trOptions.dataRowIndex]
                                }
                                index={tr.trOptions.dataRowIndex}
                                handler={handleCheckbox}
                              />
                            </td>
                          ) : null}
                          {tr.tdOptions.map((td, index) => (
                            <td
                              key={`${tr.trOptions.dataRowIndex}-${index + 1}`}
                              style={{
                                cursor: isColumnClickToSearch
                                  ? "pointer"
                                  : "unset",
                              }}
                              onClick={
                                isColumnClickToSearch
                                  ? (e) =>
                                      handleClickCell(
                                        e,
                                        td.dataKey,
                                        td.dataValue,
                                        td.tdValue
                                      )
                                  : undefined
                              }
                            >
                              {td.tdValue}
                            </td>
                          ))}
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              ) : null}
            </table>
          )}
        </StyledTableContainer>
      </StyledOverflowContainer>
      {isSearchable && searchInputLocation.includes("BOTTOM") ? (
        <StyledFlexWrap
          align={
            searchInputLocation.includes("RIGHT")
              ? FlexAlignTypeConst.FlexEnd
              : FlexAlignTypeConst.FlexStart
          }
          vertical={FlexAlignTypeConst.FlexEnd}
          style={{
            width: "100%",
            flexWrap: "wrap",
            marginBottom: 10,
          }}
          gap={12}
        >
          <Input
            sizeTheme={
              elementSize === "small" ? SmallInputStyle : NormalInputStyle
            }
            placeholder="Table search"
            inputName="table-search"
            eventType="UseForm"
            register={{ ...register("search") }}
            setFocus={() => setFocus("search")}
            autoComplete="false"
            addonShow
            addonName="search"
            addonDirection="left"
            isClearable
            getValue={getValues("search")}
            clearValue={() => {
              setValue("search", "");
            }}
            fixWidth={200}
          />
        </StyledFlexWrap>
      ) : null}
    </>
  );
}

const Table = React.memo(
  TableComponent,
  (prevProps, nextProps) =>
    prevProps.head === nextProps.head &&
    prevProps.body === nextProps.body &&
    prevProps.isCheckableRow === nextProps.isCheckableRow &&
    prevProps.isClickableRow === nextProps.isClickableRow &&
    prevProps.isCheckedRow === nextProps.isCheckedRow &&
    prevProps.isClickedRow === nextProps.isClickedRow &&
    prevProps.handleCheckedRow === nextProps.handleCheckedRow &&
    prevProps.handleClickedRow === nextProps.handleClickedRow &&
    prevProps.handleCheckedAll === nextProps.handleCheckedAll &&
    prevProps.page === nextProps.page &&
    prevProps.size === nextProps.size &&
    prevProps.isLoading === nextProps.isLoading
);

export default Table;

import Loading from "@src/components/Loadings/Loading";
import AuthLayout from "@layout/AuthLayout";
import Input from "@components/Inputs/Input";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@src/components/Buttons/Button";
import { BigButtonStyles } from "@src/components/Buttons/sizeTheme";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "@src/firebase";
import { useNavigate } from "react-router-dom";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import { StyledButtonWrap, StyledTitle } from "../styles";
import { StyledTextUnderlineButton } from "@src/styles/commonStyles";
import React from "react";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { handleSendEmailVerification } = useFirebaseHooks();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("not_valid_email_format")
      .required("email_is_required"),
    password: yup
      .string()
      .required("password_is_required")
      .min(12, "enter_at_least_12")
      .max(40, "enter_up_to_40")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{12,}[^\s]*$/,
        "password_guide_line"
      ),
    passwordConfirm: yup
      .string()
      .required("password_is_required")
      .oneOf([yup.ref("password"), null], "password_does_not_match")
      .min(12, "enter_at_least_12")
      .max(40, "enter_up_to_40"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // 초기값 설정
      email: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const singupOnSubmit = (data: {
    email: string;
    password: string;
    passwordConfirm: string;
  }) => {
    setIsLoading(true);

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // 이메일 인증 안되어있으면 강제로 로그아웃 시키고 이메일 인증 안내 팝업창 띄우기
        if (!user.emailVerified) {
          signOut(auth);
          handleSendEmailVerification(user);
        }
      })
      .catch((error) => {
        switch (error) {
          case "auth/email-already-in-use":
            handleAlertDialogOpen(
              t("email_already_in_use_title"),
              t("email_already_in_use_message")
            );
            break;
          case "auth/invalid-email":
            handleAlertDialogOpen(t("notification"), t("email_not_valid"));
            break;
          // case "auth/operation-not-allowed":
          //   // 이메일/비밀번호 계정이 활성화되지 않은 경우 throw됩니다. Firebase Console의 Auth 탭에서 이메일/비밀번호 계정을 활성화합니다
          //   handleAlertDialogOpen(t("""), t(""));
          //   break;
        }
        const errorCode = error.code;
        if (errorCode.toLowerCase().includes("email-already-in-use")) {
          handleAlertDialogOpen(
            t("email_already_in_use_title"),
            t("email_already_in_use_message")
          );
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <AuthLayout>
        <div>
          <StyledTitle>Sign up!</StyledTitle>
          <form onSubmit={handleSubmit(singupOnSubmit)}>
            <div>
              <Input
                inputName="sign-up-email"
                placeholder={t("please_enter_email")}
                type="text"
                eventType="UseForm"
                sizeTheme={NormalInputStyle}
                register={{
                  ...register("email"),
                }}
                setFocus={() => setFocus("email")}
                isInvalid={errors.email !== undefined}
                errorMessages={[errors.email?.message as string]}
                addonShow={true}
                addonDirection="left"
                addonName="user"
                isShowLabel
                labelDirection="TOP"
                labelText={t("email")}
                isFixBottomMargin
                autoComplete="off"
                labelType="AUTH"
              />
              <Input
                inputName="sign-up-password"
                placeholder={t("please_enter_password")}
                type="password"
                sizeTheme={NormalInputStyle}
                eventType="UseForm"
                register={{
                  ...register("password"),
                }}
                setFocus={() => setFocus("password")}
                isInvalid={errors.password !== undefined}
                errorMessages={[errors.password?.message as string]}
                addonShow={true}
                addonDirection="left"
                addonName="lock"
                isShowLabel
                labelDirection="TOP"
                labelText={t("password")}
                isFixBottomMargin
                autoComplete="new-password"
                labelType="AUTH"
              />
              <Input
                inputName="sign-up-password-confirm"
                placeholder={t("please_enter_password_again")}
                type="password"
                sizeTheme={NormalInputStyle}
                eventType="UseForm"
                register={{
                  ...register("passwordConfirm"),
                }}
                setFocus={() => setFocus("passwordConfirm")}
                isInvalid={errors.passwordConfirm !== undefined}
                errorMessages={[errors.passwordConfirm?.message as string]}
                addonShow={true}
                addonDirection="left"
                addonName="lockCheck"
                isShowLabel
                labelDirection="TOP"
                labelText={t("password_verification")}
                isFixBottomMargin
                labelType="AUTH"
              />
            </div>
            <Button
              type="submit"
              colorType="primary"
              sizeTheme={BigButtonStyles}
              text={t("sign_up")}
              fixWidth="100%"
            />
          </form>
          <StyledButtonWrap>
            <StyledTextUnderlineButton
              type="button"
              onClick={() => navigate("/auth/login")}
            >
              {t("go_to_login")}
            </StyledTextUnderlineButton>
          </StyledButtonWrap>
        </div>
      </AuthLayout>
    </>
  );
}

import React from "react";
import { createPortal } from "react-dom";
import {
  StlyedDialogFooter,
  StlyedDialogWrap,
  StyledContentWrap,
  StyledMessageWrap,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDimContainer,
  StyledTitleBox,
} from "@src/components/Dialogs/styles";
import { SmallDialogStyles } from "../sizeTheme";
import Icon from "@src/components/Icon";
import {
  StyledFlexWrap,
  StyledIconButton,
  StyledIconFloatWrap,
} from "@src/styles/commonStyles";
import Button from "@src/components/Buttons/Button";
import { DialogButtonStyles } from "@src/components/Buttons/sizeTheme";
import { FlexAlignTypeConst, ELEMENT_Z_INDEX } from "@constructs/common";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";

/**
 * z-index - 1400
 * @returns Alert Dialog
 */
export default function AlertDialog() {
  const dialogRoot = document.getElementById("dialog") as HTMLElement;

  // hook
  const { isAlertDialogOpen, alertTitle, alertContent, cancelAlertDialogOpen } =
    useAlertDialog();

  const handleCancelClick = React.useCallback(() => {
    cancelAlertDialogOpen();
  }, [cancelAlertDialogOpen]);

  return createPortal(
    <AlertDialogMemo
      title={alertTitle}
      open={isAlertDialogOpen}
      content={alertContent}
      handleCancelClick={handleCancelClick}
    />,
    dialogRoot
  );
}

interface DialogComponentProps {
  title: string;
  content: React.ReactNode;
  open: boolean;
  handleCancelClick: () => void;
  customMaxWidth?: number;
}

const DialogComponent = ({
  title,
  open,
  content,
  handleCancelClick,
  customMaxWidth,
}: DialogComponentProps) => {
  const { t } = useTranslation();

  return (
    <StyledDimContainer
      open={open}
      customZIndex={ELEMENT_Z_INDEX.ALERTDIALOG}
      sizeTheme={SmallDialogStyles}
      padding={80}
      customMaxWidth={customMaxWidth}
    >
      <StlyedDialogWrap>
        <StyledDialogHeader>
          <StyledTitleBox>
            <h4>{title}</h4>
          </StyledTitleBox>
          <StyledIconFloatWrap floatTop={10} floatRight={10}>
            <StyledIconButton type="button" onClick={handleCancelClick}>
              <Icon
                iconName="close"
                iconWidth={SmallDialogStyles.titleIconWidth}
                iconHeight={SmallDialogStyles.titleIconHeight}
              />
            </StyledIconButton>
          </StyledIconFloatWrap>
        </StyledDialogHeader>
        <StyledDialogBody>
          <StyledContentWrap>
            <StyledMessageWrap
              style={{
                whiteSpace:
                  typeof content === "string" ? "pre-line" : undefined,
                lineHeight: typeof content === "string" ? "1.1rem" : undefined,
              }}
            >
              {Array.isArray(content) &&
              content.every((item) => typeof item === "string")
                ? content.map((item, index) => <span key={index}>{item}</span>)
                : content}
            </StyledMessageWrap>
          </StyledContentWrap>
        </StyledDialogBody>
        <StlyedDialogFooter>
          <StyledFlexWrap
            align={FlexAlignTypeConst.FlexEnd}
            gap={12}
            rowGap={10}
            columnGap={10}
          >
            <Button
              className="dialog-confirm-btn"
              type="button"
              colorType="primary"
              sizeTheme={DialogButtonStyles}
              text={t("confirm")}
              onClick={handleCancelClick}
            />
          </StyledFlexWrap>
        </StlyedDialogFooter>
      </StlyedDialogWrap>
    </StyledDimContainer>
  );
};

const AlertDialogMemo = React.memo(
  DialogComponent,
  (prevProps, nextProps) =>
    prevProps.open === nextProps.open &&
    prevProps.title === nextProps.title &&
    prevProps.content === nextProps.content
);

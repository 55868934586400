export default function useTableHooks() {
  // 데이터 비교 함수
  const compareValues = (
    valA: null | number | string | boolean,
    valB: null | number | string | boolean,
    sortOption: "asc" | "desc",
    keyValue?: string
  ) => {
    // 빈값일 때 바로 솔트
    if ((valA === null || valA === "") && (valB === null || valB === ""))
      return 0;
    if (valA === null || valA === "") return sortOption === "asc" ? -1 : 1;
    if (valB === null || valB === "") return sortOption === "asc" ? 1 : -1;

    // uptime 예외처리
    if (
      keyValue === "uptime" &&
      typeof valA === "string" &&
      typeof valB === "string"
    ) {
      const [daysA, timeA] = valA.split("days, ");
      const [daysB, timeB] = valB.split("days, ");

      const daysAgoA = parseInt(daysA, 10);
      const daysAgoB = parseInt(daysB, 10);

      if (daysAgoA === daysAgoB) {
        return sortOption === "asc"
          ? timeA.localeCompare(timeB)
          : timeB.localeCompare(timeA);
      }
      return sortOption === "asc" ? daysAgoA - daysAgoB : daysAgoB - daysAgoA;
    }

    if (
      (typeof valA === "boolean" && typeof valB === "boolean") ||
      (typeof valA === "number" && typeof valB === "number")
    ) {
      return sortOption === "asc"
        ? Number(valA) - Number(valB)
        : Number(valB) - Number(valA);
    } else {
      return sortOption === "asc"
        ? String(valA).localeCompare(String(valB))
        : String(valB).localeCompare(String(valA));
    }
  };

  return {
    compareValues,
  };
}

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./responses/en.json";
import koTranslation from "./responses/ko.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ko: {
    translation: koTranslation,
  },
  en: {
    translation: enTranslation,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem("i18nextLng") || "ko", // 기본 한국어를 영어로 설정 (설정하지 않은 경우)
    resources,
    supportedLngs: ["ko", "en"],
    // keySeparator: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator", "htmlTag", "path", "subdomain"], // 언어 감지 우선 순위 설정
      caches: ["localStorage"], // 언어 감지 캐시 설정
    },
  });

export default i18n;

import { ELEMENT_Z_INDEX, ThemeConst } from "@src/constructs/common";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { mediaQuery } from "./theme";

interface StyledFlexWrapProps {
  align?: FlexAlignType;
  vertical?: FlexAlignType;
  gap?: number;
  columnGap?: number;
  rowGap?: number;
  flexDirection?: "column" | "row";
}
interface StyledGridWrapProps {
  templateColumns: string;
  templateRows?: string;
  gap?: number;
  columnGap?: number;
  rowGap?: number;
}

export const Div = styled.div<{
  layoutGridColumn?: string;
  layoutGridParent?: boolean;
  layoutGridInherit?: boolean;
}>`
  grid-column: ${({ layoutGridColumn }) =>
    layoutGridColumn ? layoutGridColumn : undefined};

  ${({ layoutGridParent }) =>
    layoutGridParent ? CSSLayoutGridParent : undefined}
  ${({ layoutGridInherit }) =>
    layoutGridInherit ? CSSLayoutGridInherit : undefined}
`;

export const CSSLayoutGridParent = css`
  & {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr);
    gap: 36px;

    ${mediaQuery.tablet} {
      gap: 20px;
    }

    ${mediaQuery.mobile} {
      gap: 10px;
    }
  }
`;

export const CSSLayoutGridInherit = css`
  ${CSSLayoutGridParent}
  grid-column: 1 / 13;
`;

export const StyledErrorText = styled.span`
  color: ${({ theme }) => theme.color.invalid};
`;

export const StyledWanningText = styled.span`
  color: ${({ theme }) => theme.color.invalid};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
`;
export const StyledAttentionText = styled.span`
  color: ${({ theme }) => theme.color.attention};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
`;

export const StyledDefaultText = styled.span<{
  size?: "lg" | "mdlg" | "xmd" | "md";
  weight?: number;
  align?: "center" | "left";
}>`
  color: ${({ theme }) => theme.color.font.default};
  font-size: ${({ theme, size }) =>
    size ? theme.size.common.fontSize[size] : theme.size.common.fontSize.base};
  line-height: ${({ theme, size }) =>
    `${
      Number(
        size
          ? theme.size.common.fontSize[size].replace("rem", "")
          : theme.size.common.fontSize.base.replace("rem", "")
      ) + 0.2
    }rem`};
  font-weight: ${({ theme, weight }) =>
    weight ?? theme.size.common.fontWeight.lg};
  text-align: ${({ align }) => align ?? "left"};
`;

export const StyledPrimaryText = styled(StyledDefaultText)<{
  size?: string;
  weight?: number;
  align?: "center" | "left";
}>`
  color: ${({ theme }) => theme.color.primary};
  white-space: pre-wrap;
`;

export const StyledSecondaryText = styled(StyledDefaultText)<{
  size: string;
  weight?: number;
  align?: "center" | "left";
}>`
  color: ${({ theme }) => theme.color.secondary};
`;

export const StyledPrimaryLabel = styled.span<{
  labelLineHeight?: number;
  labelFixWidth?: number;
}>`
  display: inline-flex;
  flex: 0 0 auto;

  color: ${({ theme }) => theme.color.primary};
  width: ${({ labelFixWidth }) =>
    labelFixWidth ? `${labelFixWidth}px` : undefined};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
  line-height: ${({ labelLineHeight }) =>
    labelLineHeight ? `${labelLineHeight}px` : undefined};
`;

export const StyledFlexWrap = styled(Div)<StyledFlexWrapProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? undefined};
  justify-content: ${({ align }) => align ?? "center"};
  align-items: ${({ vertical }) => vertical ?? "flex-start"};
  gap: ${({ gap }) => gap ?? 8}px;
  column-gap: ${({ gap, columnGap }) => columnGap ?? gap ?? 8}px;
  row-gap: ${({ gap, rowGap }) => rowGap ?? gap ?? 8}px;
`;

export const StyledGridWrap = styled(Div)<StyledGridWrapProps>`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  grid-template-rows: ${({ templateRows }) => templateRows};
  gap: ${({ gap }) => gap ?? 8}px;
  column-gap: ${({ gap, columnGap }) => gap ?? columnGap ?? 8}px;
  row-gap: ${({ gap, rowGap }) => gap ?? rowGap ?? 8}px;
  width: 100%;
  height: auto;

  ${mediaQuery.largeLaptop} {
    height: 100%;
  }
`;

export const StyledOverflowContainer = styled(Div)<{ isDataNull: boolean }>`
  width: auto;
  max-width: 100%;
  /* height: 100%; */
  overflow: ${({ isDataNull }) => (isDataNull ? "hidden" : "auto")};
`;

export const StyledOverflowXContainer = styled(StyledOverflowContainer)`
  overflow-y: hidden;
`;
export const StyledOverflowYContainer = styled(StyledOverflowContainer)`
  overflow-x: hidden;
`;

export const StyledDimFullScreen = styled(Div)<{
  bgColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100svh;
  gap: 1vw;
  background-color: ${({ bgColor }) => bgColor ?? `rgba(0, 0, 0, 0.4)`};
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  user-select: none;
  z-index: ${ELEMENT_Z_INDEX.DIMFULLSCREEN};
`;

export const StyledMotionSpan = styled(motion.span)`
  display: inline-flex;
  align-items: center;
`;

export const StyledFloatBox = styled(Div)<{ isInvalid?: boolean }>`
  background: ${({ theme }) => theme.color.input.background};
  border: ${({ theme }) =>
    theme.type === ThemeConst.Dark ? "1px solid #0D0D0D" : undefined};
  border-radius: 4px;
  box-shadow: 0 0 6px
    ${({ theme, isInvalid }) =>
      isInvalid
        ? theme.color.invalidShadow
        : theme.color.primaryShadow} !important;
`;

export const StyledCommonTabFocusing = css`
  display: inline-flex;
  align-items: center;
  position: relative;
  outline: none;

  &::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    outline: 2px solid #c5ceff;
    box-shadow: 0 0 8px -4px ${({ theme }) => theme.color.primary};
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
`;

export const StyledIconButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

interface FloatWrapStyles {
  floatTop?: number;
  floatLeft?: number;
  floatRight?: number;
  floatBottom?: number;
}

export const StyledIconFloatWrap = styled(Div)<FloatWrapStyles>`
  position: absolute;
  top: ${({ floatTop }) => (floatTop ? `${floatTop}px` : undefined)};
  right: ${({ floatRight }) => (floatRight ? `${floatRight}px` : undefined)};
  bottom: ${({ floatBottom }) =>
    floatBottom ? `${floatBottom}px` : undefined};
  left: ${({ floatLeft }) => (floatLeft ? `${floatLeft}px` : undefined)};

  svg {
    fill: ${({ theme }) => theme.color.font.default};
  }
`;

export const StyledMoreBadge = styled(motion.span)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px 8px;
  width: auto;
  min-width: 40px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.secondary};

  color: white;
  font-size: 14px;
  font-weight: 500;

  border-radius: 12px;
`;

export const StyledTextUnderlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.color.input.label};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export const StyledDeviceIdText = styled.span<{ boxSize?: string }>`
  display: inline-flex;
  padding: ${({ boxSize }) => (boxSize === "SMALL" ? "2px 6px" : "6px 12px")};
  color: white;
  font-weight: ${({ theme, boxSize }) =>
    boxSize === "SMALL"
      ? theme.size.common.fontWeight.md
      : theme.size.common.fontWeight.lg};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 16px;

  ${mediaQuery.mobile} {
    font-size: ${({ theme }) => theme.size.common.fontSize.base};
    padding: ${({ boxSize }) => (boxSize === "SMALL" ? "2px 6px" : "4px 8px")};
  }
`;

export const StyledRelativeFullWrap = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  /* z-index: 1000; */
`;

export const StyledContentWrap = styled(Div)<{ isChildScroll?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledTablePaginationWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
  }

  ${mediaQuery.largeLaptop} {
    max-height: unset;
  }
`;

export const StyledI18nSpan = styled.span`
  padding: 0;
  letter-spacing: -0.7px;
  &::after {
    content: attr(data-str);
  }
`;

export const StyledTabButtonWrap = styled(Div)<{ marginBotttom?: number }>`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  width: 100%;

  margin-bottom: ${({ marginBotttom }) =>
    marginBotttom ? `${marginBotttom}px` : "20px"};

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > *:first-child {
      width: 100%;
    }

    & > *:last-child {
      align-self: flex-end;
    }
  }
`;

export const StyledExtendImageWrap = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.card.dimBackground};
  border: 0;

  position: fixed;
  inset: 0;
  z-index: 10000;
  transition: background-color 0.24s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledFloatButonWrap = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  position: absolute;
  left: 0;
`;

import styled, { css } from "styled-components";
import { InputStyleProps } from "./sizeTheme";
import { StyledCommonTabFocusing } from "@src/styles/commonStyles";

export interface StyledInputBoxProps {
  sizeTheme: InputStyleProps;
  addonDirection?: string;
  isInvalid?: boolean;
  isDate?: boolean;
  isFixBottomMargin?: boolean;
  fixWidth?: number;
}

interface StyledSelectProps {
  sizeTheme: InputStyleProps;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isInvalid?: boolean;
  allowIconHide?: boolean;
  showOnlyValue?: boolean;
  fixWidth?: number;
  maxWidth?: number;
  minWidth?: number;
  menuIsOpen?: boolean;
  optionAlign?: "start" | "center" | "end";
  showAddon?: boolean;
}

interface AllCheckboxStyleProps {
  colorType: "primary" | "secondary" | "table";
  isCheckedArray?: boolean;
  isChecked?: boolean;
  isSomeChecked?: boolean;
  isDisabled?: boolean;
  checkboxWidth: number;
  checkboxHeight: number;
}

// * for Input Component

export const StyledInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const StyledInputTabBox = styled.div`
  &.tab-focused {
    ${StyledCommonTabFocusing}
  }
`;
export const StyledAddonWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: ${({ theme }) => theme.color.font.placeHolder};
    opacity: 0.6;
    cursor: pointer;
  }
`;
export const StyledInputBox = styled.div<StyledInputBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 ${({ sizeTheme }) => sizeTheme.padding}px;
  width: ${({ fixWidth }) => (fixWidth ? `${fixWidth}px` : "100%")};
  height: ${({ sizeTheme }) => sizeTheme.height}px;
  color: ${({ theme }) => theme.color.font.default};
  background-color: ${({ theme }) => theme.color.input.background};
  border-radius: ${({ sizeTheme }) => sizeTheme.borderRadius}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.color.invalid : theme.color.input.border};

  &:hover:not([aria-disabled="true"]):not([aria-readonly="true"]) {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.invalid : theme.color.primary};

    ${StyledAddonWrap} {
      svg {
        fill: ${({ theme, isInvalid }) =>
          isInvalid ? theme.color.invalid : theme.color.primary};
        opacity: 1;
      }
    }
  }

  // Date
  &:not([aria-disabled="true"]):not([aria-readonly="true"]) {
    cursor: ${({ isDate }) => (isDate ? "pointer" : "auto")};

    input {
      cursor: ${({ isDate }) => (isDate ? "pointer" : "auto")};
    }
    svg {
      cursor: pointer;
    }
  }

  // Disabled
  &[aria-disabled="true"] {
    background-color: ${({ theme }) => theme.color.input.disabled};

    input {
      color: ${({ theme }) => theme.color.font.disabled};
    }

    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.invalid : theme.color.font.disabled};

    svg {
      fill: ${({ theme, isInvalid }) =>
        isInvalid ? theme.color.invalid : theme.color.font.disabled};
      opacity: 1;
    }
  }

  &[aria-disabled="true"] {
    cursor: auto;
    input,
    svg {
      cursor: auto;
    }
    svg {
      cursor: auto;
    }
  }

  &[aria-readonly="true"] {
    cursor: inherit;
    input,
    svg {
      cursor: inherit;
    }
  }

  /* Tab Focusing */
  ${StyledInputTabBox}.tab-focusing &,
  &:focus-within,
  &.focus {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.invalid : theme.color.primary};
    box-shadow: 0 0 0 2px
      ${({ theme, isInvalid }) =>
        isInvalid ? theme.color.invalidShadow : theme.color.primaryShadow};

    ${StyledAddonWrap} {
      svg {
        fill: ${({ theme, isInvalid }) =>
          isInvalid ? theme.color.invalid : theme.color.primary};
        opacity: 1;
      }
    }
    position: relative;
    z-index: 1;
  }

  ${StyledAddonWrap} {
    order: ${({ addonDirection }) => (addonDirection === "left" ? 1 : 2)};
  }

  input {
    order: ${({ addonDirection }) => (addonDirection === "left" ? 2 : 1)};
    width: 100%;
    height: 100%;
    font-size: ${({ sizeTheme }) => sizeTheme.fontSize};
    font-weight: ${({ sizeTheme }) => sizeTheme.fontWeight};
    color: ${({ theme }) => theme.color.font.default};
    background-color: transparent;

    &::placeholder {
      color: ${({ theme }) => theme.color.font.placeHolder};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${({ isInvalid, theme }) =>
        isInvalid ? theme.color.invalid : theme.color.font.default};
      /* font-size: 1.1rem; */
    }
  }
`;

export const StyledTextWrap = styled.div<StyledInputBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  min-height: ${(isFixBottomMargin) =>
    isFixBottomMargin ? "14px" : undefined};
  line-height: 1.2;

  & > * {
    font-size: ${({ sizeTheme }) => sizeTheme.noticeFontSize};
  }
`;

export const StyledLabelInputWrap = styled.div<{
  labelDirection?: "TOP" | "LEFT";
}>`
  display: flex;
  flex-direction: ${({ labelDirection }) =>
    labelDirection === "TOP" ? "column" : "row"};
  column-gap: 12px;
  row-gap: 8px;
`;

export const StyledLabel = styled.label<{ labelFixWidth?: number }>`
  display: inline-flex;
  align-items: center;
  width: ${({ labelFixWidth }) =>
    labelFixWidth ? `${labelFixWidth}px` : undefined};
  color: ${({ theme }) => theme.color.input.label};
  font-size: ${({ theme }) => theme.size.common.fontSize.sm};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
  }
`;

export const StyledDeviceId = styled.span`
  display: inline-flex;
  padding: 4px 8px;
  color: white;
  font-size: ${({ theme }) => theme.size.common.fontSize.sm};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;
`;

// Selectbox for tab
export const StyledSelectBox = styled.div`
  &.tab-focused {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

export const StyledSelect = styled.div<StyledSelectProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  * {
    transition: 0s !important;
  }

  .select__indicators {
    display: ${({ showOnlyValue }) => (showOnlyValue ? "none" : "block")};

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      padding-right: 6px;
      height: 100%;
    }

    .select__clear-indicator ~ .select__dropdown-indicator {
      display: none;
    }
  }
  .select__control {
    width: 100%;
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : undefined)};
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
    min-height: unset !important;
    height: ${({ sizeTheme }) => sizeTheme.height}px;
    font-size: ${({ sizeTheme }) => sizeTheme.fontSize};
    font-weight: ${({ sizeTheme }) => sizeTheme.fontWeight};
    background-color: ${({ theme }) => theme.color.input.background};
    border-color: ${({ theme, isInvalid, showOnlyValue }) =>
      showOnlyValue
        ? "transparent"
        : isInvalid
        ? theme.color.invalid
        : theme.color.input.border};
    border-radius: ${({ sizeTheme }) => sizeTheme.borderRadius}px;
    cursor: pointer;

    &:hover {
      border-color: ${({ theme, isInvalid, showOnlyValue }) =>
        showOnlyValue
          ? "transparent"
          : isInvalid
          ? theme.color.invalid
          : theme.color.primary};

      .select__dropdown-indicator svg {
        fill: ${({ theme }) => theme.color.primary};
      }
    }

    & > * {
      height: 100%;
    }

    .select__placeholder {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      letter-spacing: -0.2px;
    }

    .select__value-container {
      padding: ${({ sizeTheme }) => `0 ${Number(sizeTheme.padding)}px`};

      & > * {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .select__single-value {
        margin: 0;
        color: ${({ theme }) => theme.color.font.default};
      }

      .select__input-container {
        color: ${({ theme }) => theme.color.font.default};
      }
    }

    .select__dropdown-indicator {
      display: ${({ allowIconHide }) => (allowIconHide ? "none" : "flex")};
      padding: 0px;
      padding-right: 6px;
      align-items: center;
      justify-content: center;
      height: 100%;

      svg {
        transition: transform 0.14s;
        fill: ${({ theme, isDisabled, isInvalid }) =>
          isDisabled
            ? theme.color.font.disabled
            : isInvalid
            ? theme.color.invalid
            : theme.color.font.placeHolder};
      }
    }

    // Disabled
    &.select__control--is-disabled {
      background-color: ${({ theme }) => theme.color.input.disabled};
      border-color: ${({ theme }) => theme.color.font.disabled};

      .select__single-value--is-disabled {
        color: ${({ theme }) => theme.color.font.disabled};
      }
    }

    // Focused
    &.select__control--is-focused {
      box-shadow: unset;
    }

    // Hover & Focus transprent
    .select__device {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 100%;
    }
    .select__device_id {
      display: inline-flex;
      padding: 4px 8px;
      color: white;
      font-size: ${({ theme }) => theme.size.common.fontSize.sm};
      font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
      background-color: ${({ theme }) => theme.color.primary};
      border-radius: 10px;
    }
    .select__device_alias {
      display: block;
      color: ${({ theme }) => theme.color.font.default};

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select__indicator-separator {
      display: none;
    }
  }

  /* 애드온 추가 */
  .select-addon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;

    position: absolute;
    left: 8px;
    z-index: 1;
  }

  /* select__control focusing */
  ${StyledSelectBox} {
    display: flex;
    align-items: center;
    position: relative;
    width: ${({ fixWidth }) => (fixWidth ? `${fixWidth}px` : "auto")};

    & > *:not(.select-addon) {
      width: 100%;
    }

    &:has(.select-addon) {
      .select__single-value,
      .select__placeholder {
        transform: translateX(18px);
      }
    }

    &.tab-focused {
      .select__control {
        border-color: ${({ theme, isInvalid, showOnlyValue }) =>
          showOnlyValue
            ? "transparent"
            : isInvalid
            ? theme.color.invalid
            : theme.color.primary};
        box-shadow: 0 0 0 1px
          ${({ theme, isInvalid, showOnlyValue }) =>
            showOnlyValue
              ? "transparent"
              : isInvalid
              ? theme.color.invalidShadow
              : theme.color.primaryShadow};

        .select__indicator {
          svg {
            * {
              fill: ${({ theme, isInvalid, showOnlyValue }) =>
                showOnlyValue
                  ? "transparent"
                  : isInvalid
                  ? theme.color.invalid
                  : theme.color.primary};
            }
          }
        }

        .select__dropdown-indicator {
          svg {
            transform: ${({ menuIsOpen }) =>
              menuIsOpen ? `rotateZ(180deg)` : undefined};
          }
        }
      }
    }
  }
`;

//  For Selectbox Float
export const StyledSelectFloat = styled.div<StyledSelectProps>`
  .select__menu-portal {
    .select__menu {
      width: 100%;

      font-size: ${({ sizeTheme }) => sizeTheme.optionFontSize};
      font-weight: ${({ sizeTheme }) => sizeTheme.optionFontWeight};
    }
  }

  .select__menu {
    .select__menu-list {
      display: grid;
      max-height: 224px;

      background-color: ${({ theme }) => theme.color.input.background};
      border-radius: 2px;
      box-shadow: 0 0 6px
        ${({ theme, isInvalid }) =>
          isInvalid
            ? theme.color.floatBox.invalidBoxShadow
            : theme.color.floatBox.boxShadow};
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.scrollbar};
      }

      // one option
      .select__option {
        display: flex;
        align-items: center;
        justify-content: ${({ optionAlign }) => optionAlign ?? "flex-start"};
        gap: 10px;
        padding: ${({ sizeTheme }) => `0 ${sizeTheme.padding}px`};
        height: ${({ sizeTheme }) => sizeTheme.optionHeight}px;
        color: ${({ theme }) => theme.color.font.default};
        background-color: ${({ theme }) => theme.color.input.background};
        transition: all 0s;
        overflow: hidden;
        min-height: unset;
        min-height: ${({ sizeTheme }) => sizeTheme.optionHeight}px;
        height: auto;
        padding: ${({ showAddon }) =>
          showAddon ? `4px 10px 4px 16px` : "4px 10px"};

        & > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        * {
          transition: all 0s;
        }

        &:hover,
        &.select__option--is-focused {
          background-color: ${({ theme }) => theme.color.input.selectHover};
          cursor: pointer;
        }

        &.select__option--is-selected {
          color: white;
          background-color: ${({ theme }) => theme.color.primary};

          .select__device_id {
            color: ${({ theme }) => theme.color.primary};
            background-color: white;
          }
        }

        .select__device {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .select__device_id {
          display: inline-flex;
          padding: 4px 8px;
          color: white;
          font-size: ${({ theme }) => theme.size.common.fontSize.sm};
          font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
          background-color: ${({ theme }) => theme.color.primary};
          border-radius: 10px;
        }
        .select__device_alias {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const CssCheckboxPrimary = css<AllCheckboxStyleProps>`
  & {
    border-color: ${({ theme }) => theme.color.primary};

    svg {
      fill: ${({ theme }) => theme.color.primary};
      stroke: ${({ theme }) => theme.color.primary};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.color.primaryHover};

    svg {
      fill: ${({ theme }) => theme.color.primaryHover};
      stroke: ${({ theme }) => theme.color.primaryHover};
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primaryShadow};
  }

  ${({ isSomeChecked, isCheckedArray, isChecked }) =>
    (isSomeChecked === false && isCheckedArray === false) ||
    (isChecked === false && {})}
`;

export const CssCheckboxSecondary = css`
  & {
    border-color: ${({ theme }) => theme.color.secondary};

    svg {
      fill: ${({ theme }) => theme.color.secondary};
      stroke: ${({ theme }) => theme.color.secondary};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.color.secondaryHover};

    svg {
      fill: ${({ theme }) => theme.color.secondaryHover};
      stroke: ${({ theme }) => theme.color.secondaryHover};
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.secondaryShadow};
  }
`;

export const CssCheckboxTable = css`
  & {
    border-color: ${({ theme }) => theme.color.table.checkbox};

    svg {
      fill: ${({ theme }) => theme.color.table.checkbox};
      stroke: ${({ theme }) => theme.color.table.checkbox};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.color.table.checkboxHover};

    svg {
      fill: ${({ theme }) => theme.color.table.checkboxHover};
      stroke: ${({ theme }) => theme.color.table.checkboxHover};
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.table.checkboxShadow};
  }
`;

export const CssCheckboxDisable = css`
  &,
  &:hover {
    background-color: ${({ theme }) => theme.color.disabled};
    border-color: ${({ theme }) => theme.color.font.disabled};

    svg {
      fill: ${({ theme }) => theme.color.font.disabled};
      stroke: ${({ theme }) => theme.color.font.disabled};
    }
  }

  &:focus-within {
    box-shadow: unset;
  }
`;

export const StyledCheckboxWrap = styled.div<{
  textSize: string;
  textWeight: number;
  fixWidth?: number;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ fixWidth }) => (fixWidth ? `${fixWidth}px` : undefined)};
  cursor: pointer;

  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: ${({ theme }) => theme.color.font};
    font-size: ${({ textSize }) => textSize};
    font-weight: ${({ textWeight }) => textWeight};
  }
`;

export const StyledCheckbox = styled.div<AllCheckboxStyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ checkboxWidth }) => checkboxWidth}px;
  height: ${({ checkboxHeight }) => checkboxHeight}px;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.color.input.disabled : undefined};
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, colorType, isDisabled }) =>
    isDisabled
      ? theme.color.font.disabled
      : colorType === "primary"
      ? theme.color.primary
      : colorType === "secondary"
      ? theme.color.secondary
      : theme.color.table.checkbox};

  svg {
    fill: ${({
      theme,
      colorType,
      isSomeChecked,
      isCheckedArray,
      isChecked,
      isDisabled,
    }) =>
      ((isChecked !== undefined && isChecked === false) ||
        isCheckedArray === false) &&
      !isSomeChecked
        ? "transparent"
        : isDisabled
        ? theme.color.font.disabled
        : colorType === "primary"
        ? theme.color.primary
        : colorType === "secondary"
        ? theme.color.secondary
        : theme.color.table.checkbox};
  }

  /* ${({ isDisabled }) => isDisabled && CssCheckboxDisable} */
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};

  & > input[type="checkbox"] {
    width: 100%;
    height: 100%;
    cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  }
`;

export const StyledSelectWithLabelWrap = styled.div<{
  labelDirection?: "TOP" | "LEFT";
}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ labelDirection }) =>
    labelDirection === "TOP" ? "column" : "row"};
  gap: 8px 12px;
`;

import RenderChart from "./Chart";
import React, { ReactNode, useEffect } from "react";
import { S3PresignedUrlRequestParams } from "@src/fetch/fetchChart";
import {
  StyledChartWrap,
  StyledImageContentWrap,
  StyledTableContentContainer,
  StyledTableWrap,
} from "./styles";
import Card from "@components/Card";
import { NormalCardStyle } from "@components/Card/sizeTheme";
import Button from "@components/Buttons/Button";
import {
  NormalButtonStyles,
  NormalIconButtonStyles,
  XSmallNoSpaceIconButtonStyles,
} from "@components/Buttons/sizeTheme";
import useCommonHooks, {
  convertFileKeyToDateStr,
  getSensorLabel,
} from "@src/hooks/useCommonHooks";
import Loading from "@src/components/Loadings/Loading";
import {
  StyledTablePaginationWrap,
  StyledFlexWrap,
  StyledGridWrap,
} from "@src/styles/commonStyles";
import { ColorTypeConst, FlexAlignTypeConst } from "@src/constructs/common";
import IconButton from "@components/Buttons/IconButton";
import useQueryHooks from "@src/hooks/useQueryHooks";
import useFilesDownloadHooks from "@src/hooks/useFilesDownloadHooks";
import Pagination from "@components/Pagination";
import Table from "@components/Table";
import { useLanguageStore } from "@src/stores/useTranslationStore";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const rawDataTableHead = [
  { value: "group", label: "group" },
  { value: "device_id", label: "device" },
  { value: "structure_name", label: "install_location" },
  { value: "sensor", label: "sensor" },
  { value: "file_name", label: "file_name" },
  { value: "date", label: "date" },
  { value: "view", label: "view" },
  { value: "save", label: "save" },
];
const rawDataTableHeadShort = [
  { value: "file_name", label: "file_name" },
  { value: "view", label: "view" },
  { value: "save", label: "save" },
];

interface ContentTableProps {
  searchSensor?: string;
  tableMaxHeight?: number;
  data: FileListResponse | null;
  tableTitle: NonNullable<ReactNode>;
  fileUrlForContent: string;
  setFileUrlForContent: React.Dispatch<React.SetStateAction<string>>;
  dataType: S3BucketType;
  paging: PagingType;
  setPaging: (paging: PagingType) => void;
  updatePagination?: (data: PagingType) => void;
  isLoading?: boolean;
}

function ContentTable({
  data,
  tableTitle,
  tableMaxHeight,
  fileUrlForContent,
  setFileUrlForContent,
  dataType,
  paging,
  setPaging,
  updatePagination,
  isLoading,
}: ContentTableProps) {
  const language = useLanguageStore();
  const customGridColumnsMinMaxArray = [
    {
      min: "95px",
      max: "140px",
    },
    {
      min: "85px",
      max: "85px",
    },
    {
      min: language === "en" ? "250px" : "95px",
      max: language === "en" ? "250px" : "120px",
    },
    {
      min: "125px",
      max: "125px",
    },
    {
      min: "130px",
      max: "1fr",
    },
    {
      min: "150px",
      max: "240px",
    },
    {
      min: "60px",
      max: "100px",
    },
    {
      min: "60px",
      max: "100px",
    },
  ];
  const customGridColumnsMinMaxArrayShort = [
    {
      min: "318px",
      max: "1fr",
    },
    {
      min: "57px",
      max: "100px",
    },
    {
      min: "57px",
      max: "100px",
    },
  ];

  // * State
  const [isCheckedRow, setIsCheckedRow] = React.useState<boolean[]>([]);
  const [imageDataParams, setImageDataParams] =
    React.useState<S3PresignedUrlRequestParams>({
      type: "",
      fileKey: "",
    });
  const [newImageUrl, setNewImageUrl] = React.useState<string>("");
  const [contentIndex, setContentIndex] = React.useState<{
    index: number;
    called: number;
  }>({
    index: -1,
    called: 0,
  });

  // * Hooks
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleCheckbox } = useCommonHooks();
  const { useS3PresignedUrlQuery } = useQueryHooks();
  const { isGetS3FilesMutateLoading, handleFilesDownloadButton } =
    useFilesDownloadHooks(dataType, data, isCheckedRow);
  const { data: imageData, isFetching: isFetchingImage } =
    useS3PresignedUrlQuery(imageDataParams); // imageData는 fileUrlforContent에 .jpg를 포함하고 있을 때만 불러온다.

  // * Functions
  const handleShowContent = (index: number) => {
    setContentIndex((prev) => {
      const newData = {
        ...prev,
      };
      newData.index = index;
      newData.called = newData.called + 1;
      return newData;
    });
  };

  // * Effects

  // contentIndex.called의 값이 바뀌면 contentIndex.index를 확인한다.
  // contentIndex.index가 -1이 아닐 경우 fileUrlForContent를 data.fileList[contentIndex.index].fileKey로 업데이트한다.
  useEffect(() => {
    if (contentIndex.index !== -1) {
      setFileUrlForContent(data?.fileList[contentIndex.index].fileKey ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIndex.called]);

  useEffect(() => {
    if (imageData) {
      setNewImageUrl(() => imageData.presignedUrl);
    }
  }, [imageData]);

  // data가 변경되고 있으면 isChcekdRow를 data의 filterList 길이만큼 false로 채운다.
  useEffect(() => {
    if (data) {
      setIsCheckedRow(new Array(data.fileList.length).fill(false));
    }
  }, [data]);

  // 컨텐트 카드에 노출할 로우/이미지 파일 파라미터 설정 / file url이 업데이트되면 url 파라미터의 contentUrl도 업데이트한다.
  useEffect(() => {
    if (fileUrlForContent) {
      if (fileUrlForContent.includes(".jpg")) {
        setImageDataParams({
          type: "image",
          fileKey: fileUrlForContent,
        });
      }
    }

    const newSearchParams = new URLSearchParams(searchParams);
    const contentUrl = searchParams.get("contentUrl");

    if (fileUrlForContent && fileUrlForContent !== contentUrl) {
      newSearchParams.set("contentUrl", fileUrlForContent);
      return setSearchParams(newSearchParams.toString());
    } else if (!fileUrlForContent) {
      newSearchParams.delete("contentUrl");
      return setSearchParams(newSearchParams.toString());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrlForContent]);

  // 데이터 변경 시 URL 파라미터에 contentUrl이 있을 경우 contentUrl의 데이터가 현재 데이터 리스트 중 1개라도 디바이스/센서가 일치하는지 확인
  // 불일치 시 contentUrl 삭제
  useEffect(() => {
    const contentUrl = searchParams.get("contentUrl");
    if (contentUrl) {
      switch (dataType) {
        case "scoreReport":
        case "scoreReportRaw":
          // deviceId
          const hasDeviceFilUrlInFileList = data?.fileList.findIndex(
            (item) => item.deviceId === contentUrl.split("/")[0]
          );
          if (hasDeviceFilUrlInFileList === -1) {
            setFileUrlForContent("");
          }
          break;
        default:
          // sensorId
          const hasSensorFilUrlInFileList = data?.fileList.findIndex(
            (item) =>
              item.sensorId ===
              `ae.${contentUrl.split("/")[0]}-${contentUrl.split("/")[1]}`
          );

          if (hasSensorFilUrlInFileList === -1) {
            setFileUrlForContent("");
          }
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // * 테이블 바디
  const rawDataTableBody = React.useMemo(() => {
    return data
      ? data.fileList.map((item: IFileListData, index: number) => {
          return fileUrlForContent ? (
            <tr key={index}>
              <td>{item.fileKey}</td>
              <td>
                <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                  <IconButton
                    sizeTheme={XSmallNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName={dataType === "image" ? "image" : "chart"}
                    onClick={() => handleShowContent(index)}
                    isActive={fileUrlForContent === item.fileKey}
                  />
                </StyledFlexWrap>
              </td>
              <td>
                <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                  <IconButton
                    sizeTheme={XSmallNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName="save"
                    onClick={() => handleFilesDownloadButton(item.fileKey)}
                    // isDisabled={isGetS3FilesMutateLoading}
                  />
                </StyledFlexWrap>
              </td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.deviceGroupName}</td>
              <td>{item.deviceId}</td>
              <td>{item.structureName}</td>
              <td>{getSensorLabel(item.sensorId)}</td>
              <td>{item.fileKey}</td>
              <td>{convertFileKeyToDateStr(item.fileKey)}</td>
              <td>
                <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                  <IconButton
                    sizeTheme={XSmallNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName={dataType === "image" ? "image" : "chart"}
                    onClick={() => handleShowContent(index)}
                    isActive={fileUrlForContent === item.fileKey}
                  />
                </StyledFlexWrap>
              </td>
              <td>
                <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                  <IconButton
                    sizeTheme={XSmallNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName="save"
                    onClick={() => handleFilesDownloadButton(item.fileKey)}
                    // isDisabled={isGetS3FilesMutateLoading}
                  />
                </StyledFlexWrap>
              </td>
            </tr>
          );
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fileUrlForContent, dataType, handleShowContent]);

  return (
    <>
      {isGetS3FilesMutateLoading ? <Loading /> : null}
      <StyledTableContentContainer isChildScroll>
        <StyledTableWrap fileUrl={fileUrlForContent}>
          <Card
            sizeTheme={NormalCardStyle}
            title={tableTitle}
            headerButtons={
              <IconButton
                sizeTheme={NormalIconButtonStyles}
                colorType="primary"
                text={t("save_selected_file")}
                iconName="saveAll"
                onClick={() => handleFilesDownloadButton()}
                isDisabled={
                  isGetS3FilesMutateLoading ||
                  isCheckedRow.every((item) => !item)
                }
              />
            }
          >
            <StyledTablePaginationWrap>
              <Table
                colorType={ColorTypeConst.Primary}
                head={
                  fileUrlForContent ? rawDataTableHeadShort : rawDataTableHead
                }
                isCheckableRow
                checkboxName="content"
                isCheckedRow={isCheckedRow}
                minWidth={434}
                maxHeight={tableMaxHeight}
                body={rawDataTableBody}
                handleCheckedAll={(bool: boolean) =>
                  handleCheckbox(bool, setIsCheckedRow)
                }
                handleCheckedRow={(index: number) =>
                  handleCheckbox(index, setIsCheckedRow)
                }
                customGridColumnsMinMaxArray={
                  fileUrlForContent
                    ? customGridColumnsMinMaxArrayShort
                    : customGridColumnsMinMaxArray
                }
                isLoading={isLoading}
              />
              {data && data.count > 0 ? (
                <Pagination
                  page={paging.page}
                  size={paging.size}
                  count={data.count}
                  groupLength={5}
                  showSizeSelect
                  elementSize="normal"
                  setPaging={setPaging}
                  refetch={updatePagination}
                />
              ) : null}
            </StyledTablePaginationWrap>
          </Card>
        </StyledTableWrap>
        {fileUrlForContent && fileUrlForContent.includes(".jpg") ? (
          <StyledImageContentWrap>
            <Card
              sizeTheme={NormalCardStyle}
              title={`촬영일시: ${convertFileKeyToDateStr(fileUrlForContent)}`}
              showCloseBtn
              handleClose={() => setFileUrlForContent("")}
              isLoading={isFetchingImage}
            >
              <StyledGridWrap
                templateColumns="auto"
                templateRows="minmax(50px, 1fr) max-content"
                gap={15}
                style={{
                  overflow: "hidden",
                  justifyContent: "center",
                }}
              >
                <img
                  src={newImageUrl}
                  alt="이미지"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    aspectRatio: "16/9",
                    justifySelf: "center",
                  }}
                />
                <div
                  style={{
                    justifySelf: "flex-end",
                  }}
                >
                  <Button
                    sizeTheme={NormalButtonStyles}
                    colorType="primary"
                    type="button"
                    text="JPG 저장"
                    onClick={() => handleFilesDownloadButton(fileUrlForContent)}
                    isDisabled={isGetS3FilesMutateLoading}
                  />
                </div>
              </StyledGridWrap>
            </Card>
          </StyledImageContentWrap>
        ) : fileUrlForContent ? (
          <StyledChartWrap>
            <RenderChart
              dataType={dataType}
              fileUrl={fileUrlForContent}
              setFileUrl={setFileUrlForContent}
            />
          </StyledChartWrap>
        ) : null}
      </StyledTableContentContainer>
    </>
  );
}

const ContentTableMemo = React.memo(
  ContentTable,
  (prevProps, nextProps) =>
    prevProps.tableTitle === nextProps.tableTitle &&
    prevProps.data === nextProps.data &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.paging.size === nextProps.paging.size &&
    prevProps.paging.page === nextProps.paging.page &&
    prevProps.fileUrlForContent === nextProps.fileUrlForContent
);
export default ContentTableMemo;

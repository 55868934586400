import { FlexAlignTypeConst } from "@src/constructs/common";
import { StyledFlexWrap, StyledPrimaryText } from "@src/styles/commonStyles";
import { theme } from "@src/styles/theme";
import Icon from "../Icon";

interface DataLoadingTextProps {
  boxHeight?: string;
  text: string;
  size?: "md" | "sm";
  showIcon?: boolean;
  zIndex?: number;
}

export default function DataLoadingText({
  boxHeight,
  text,
  size = "md",
  showIcon,
  zIndex,
}: DataLoadingTextProps) {
  return (
    <StyledFlexWrap
      align={FlexAlignTypeConst.Center}
      vertical={FlexAlignTypeConst.Center}
      flexDirection="column"
      rowGap={16}
      style={{
        flex: boxHeight ? "0 0 auto" : 1,
        width: "100%",
        height: boxHeight ?? "100%",
        minHeight: 200,
        zIndex: zIndex ?? "unset",
      }}
    >
      {showIcon && (
        <Icon
          iconName={size === "sm" ? "loadingSmall" : "loading"}
          iconColor={theme.color.primary}
        />
      )}
      <StyledPrimaryText size="md" weight={400} align="center">
        {text}
      </StyledPrimaryText>
    </StyledFlexWrap>
  );
}

import styled from "styled-components";
import Icon from "../Icon";
import { Div } from "@src/styles/commonStyles";

const StyledColorBoxWrap = styled(Div)<{ bgColor: string }>`
  display: flex;
  flex: 1;
  justify-content: space-between;

  padding: 15px;
  width: 100%;
  height: 100px;
  background: ${({ bgColor }) => bgColor};

  position: relative;
  overflow: hidden;

  border-radius: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.14);
`;

const StyledBgIconWrap = styled(Div)`
  position: absolute;
  left: 10%;
  bottom: -20px;
`;

const StyledTextSpan = styled.span<{
  textSize: string;
  textWeight: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 6px;

  color: white;
  font-size: ${({ textSize }) => textSize};
  font-weight: ${({ textWeight }) => textWeight};

  &:nth-child(1) {
    align-self: flex-start;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &:nth-child(2) {
    align-self: flex-end;

    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

interface ColorBoxProps {
  bgColor: string;
  bgIconName: string;
  bgIconWidth: number;
  keyTextSize: string;
  keyTextWeight: number;
  valueTextSize: string;
  valueTextWeight: number;
  keyText: string | string[];
  valueText: string;
}

export default function ColorBox({
  bgColor,
  bgIconName,
  bgIconWidth,
  keyTextSize,
  keyTextWeight,
  valueTextSize,
  valueTextWeight,
  keyText,
  valueText,
}: ColorBoxProps) {
  return (
    <StyledColorBoxWrap bgColor={bgColor}>
      <StyledTextSpan textSize={keyTextSize} textWeight={keyTextWeight}>
        {Array.isArray(keyText)
          ? keyText.map((item, index) => <span key={index}>{item}</span>)
          : keyText}
      </StyledTextSpan>
      <StyledTextSpan textSize={valueTextSize} textWeight={valueTextWeight}>
        {valueText}
      </StyledTextSpan>
      <StyledBgIconWrap>
        <Icon
          iconName={bgIconName}
          iconWidth={bgIconWidth}
          iconColor="rgba(255,255,255,0.1)"
        />
      </StyledBgIconWrap>
    </StyledColorBoxWrap>
  );
}

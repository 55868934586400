export interface CardSizeProps {
  paddingTB: number;
  paddingLR: number;
}

export const NormalCardStyle = {
  paddingTB: 36,
  paddingLR: 24,
};

export const SmallCardStyle = {
  paddingTB: 24,
  paddingLR: 16,
};

import { initialPaging } from "@constructs/common";
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import { create } from "zustand";

interface QueryStoreType {
  showCustomLoading: boolean;
  rawDataPaging: PagingType;
  eventRawDataPaging: PagingType;
  cameraDataPaging: PagingType;
  smartCameraDataPaging: PagingType;
  psdDataPaging: PagingType;
  deviceSpecialInfoDataPaging: PagingType;
  eventDataselectedSearchType: SelectOption;
  eventDatasearchValue: string;
  actions: {
    setShowCustomLoading: (showCustomLoading: boolean) => void;
    setRawDataPaging: (rawDataPaging: PagingType) => void;
    setEventRawDataPaging: (eventRawDataPaging: PagingType) => void;
    setCameraDataPaging: (cameraDataPaging: PagingType) => void;
    setSmartCameraDataPaging: (cameraDataPaging: PagingType) => void;
    setPSDDataPaging: (psdDataPaging: PagingType) => void;
    setDeviceSpecialInfoDataPaging: (
      deviceSpecialInfoDataPaging: PagingType
    ) => void;
    setEventDataselectedSearchType: (
      eventDataselectedSearchType: SelectOption
    ) => void;
    setEventDatasearchValue: (eventDatasearchValue: string) => void;
  };
}

const useQueryStore = create<QueryStoreType>((set) => ({
  showCustomLoading: false,
  rawDataPaging: initialPaging,
  eventRawDataPaging: initialPaging,
  cameraDataPaging: initialPaging,
  smartCameraDataPaging: initialPaging,
  psdDataPaging: initialPaging,
  deviceSpecialInfoDataPaging: initialPaging,
  eventDataselectedSearchType: initialSelectOption,
  eventDatasearchValue: "",
  actions: {
    setShowCustomLoading: (showCustomLoading: boolean) => {
      set((prev) => ({ ...prev, showCustomLoading }));
    },
    setRawDataPaging: (rawDataPaging: PagingType) => {
      set((prev) => ({ ...prev, rawDataPaging }));
    },
    setEventRawDataPaging: (eventRawDataPaging: PagingType) => {
      set((prev) => ({ ...prev, eventRawDataPaging }));
    },
    setCameraDataPaging: (cameraDataPaging: PagingType) => {
      set((prev) => ({ ...prev, cameraDataPaging }));
    },
    setSmartCameraDataPaging: (smartCameraDataPaging: PagingType) => {
      set((prev) => ({ ...prev, smartCameraDataPaging }));
    },
    setPSDDataPaging: (psdDataPaging: PagingType) => {
      set((prev) => ({ ...prev, psdDataPaging }));
    },
    setDeviceSpecialInfoDataPaging: (
      deviceSpecialInfoDataPaging: PagingType
    ) => {
      set((prev) => ({ ...prev, deviceSpecialInfoDataPaging }));
    },
    setEventDataselectedSearchType: (
      eventDataselectedSearchType: SelectOption
    ) => {
      set((prev) => ({ ...prev, eventDataselectedSearchType }));
    },
    setEventDatasearchValue: (eventDatasearchValue: string) => {
      set((prev) => ({ ...prev, eventDatasearchValue }));
    },
  },
}));

export const useShowCustomLoading = () =>
  useQueryStore((state) => state.showCustomLoading);
export const useRawDataPaging = () =>
  useQueryStore((state) => state.rawDataPaging);
export const useEventRawDataPaging = () =>
  useQueryStore((state) => state.eventRawDataPaging);
export const useCameraDataPaging = () =>
  useQueryStore((state) => state.cameraDataPaging);
export const useSmartCameraDataPaging = () =>
  useQueryStore((state) => state.smartCameraDataPaging);
export const usePSDDataPaging = () =>
  useQueryStore((state) => state.psdDataPaging);
export const useDeviceSpecialInfoDataPaging = () =>
  useQueryStore((state) => state.deviceSpecialInfoDataPaging);
export const useEventDataSelectedSearchType = () =>
  useQueryStore((state) => state.eventDataselectedSearchType);
export const useEventDataSearchValue = () =>
  useQueryStore((state) => state.eventDatasearchValue);

export const useQueryActions = () => useQueryStore((state) => state.actions);

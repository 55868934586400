import useQueryHooks from "@src/hooks/useQueryHooks";
import {
  useCommonStoreActions,
  useEventDatasetStore,
  useInterestSensorListStore,
  useIsSummarySidebarStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import { useEffect } from "react";
import {
  StyledHeightFixWrap,
  StyledLeftWrap,
  StyledMapButtonWrap,
  StyledMapWrap,
  StyledRightDmeasureChartSlideWrap,
  StyledRightWrap,
} from "./styles";
import CountBox, { ICounts } from "./CountBox";
import DmeasureChartSlide from "./DmeasureChartSlide";
import EventTable from "./EventTable";
import DeviceStatusTable from "./DeviceStateTable";
// import ManagementStandardTable from "./ManagementStandardTable";
import React from "react";
import { auth } from "@src/firebase";
import { User, onAuthStateChanged } from "firebase/auth";
import {
  RangeSensorIdOrSensorListRequestParams,
  RangeOptionalDeviceIdSensorIdRequestParams,
} from "@src/fetch/fetchChart";
import { format } from "date-fns";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import NaverMap from "@src/components/Maps/NaverMap";
import Select from "@src/components/Inputs/Selects/Select";
import DeviceSearchSelect from "@src/components/Inputs/Selects/DeviceSearchSelect";
import { SmallInputStyle } from "@src/components/Inputs/sizeTheme";
import useFetchData, {
  DeviceLatLngResponse,
  RangeOptionalSearchPagingRequestParams,
} from "@src/fetch/fetchCommon";
import { useNavermaps } from "react-naver-maps";
import { useMutation } from "@tanstack/react-query";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import Dialog from "@src/components/Dialogs/Dialog";
import { SmallDialogStyles } from "@src/components/Dialogs/sizeTheme";
import {
  StyledDeviceIdText,
  StyledFlexWrap,
  StyledPrimaryText,
} from "@src/styles/commonStyles";
import { FlexAlignTypeConst } from "@src/constructs/common";
import Button from "@src/components/Buttons/Button";
import {
  NormalButtonStyles,
  SmallButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import Loading from "@src/components/Loadings/Loading";
import { SmallCardStyle } from "@src/components/Card/sizeTheme";
import { useLanguageStore } from "@src/stores/useTranslationStore";
import GoogleMap from "@src/components/Maps/GoogleMap";
import GroupButton from "@src/components/Buttons/GroupButton";
import { useTranslation } from "react-i18next";
import useUserInfoHooks from "@src/hooks/useUserInfoHooks";

export default function HomePage() {
  // hooks
  const { t, i18n } = useTranslation();
  const language = useLanguageStore();
  const navermaps = useNavermaps();
  const userInfo = useUserInfoStore();
  const isSummarySidebar = useIsSummarySidebarStore();
  const interestSensorList = useInterestSensorListStore();
  const { setInterestSensorList } = useCommonStoreActions();
  const {
    useDeviceLatLngQuery,
    useDmeasureDataQuery,
    useDtriggerFileListQuery,
    useDeviceStateShortQuery,
  } = useQueryHooks();
  const { getUserInterestSensor, setUserInterestSensor } = useFirebaseHooks();
  const eventDataset = useEventDatasetStore();
  const {
    selectedDeviceGroup,
    selectedDevice,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    handleSelectedDeviceGroup,
    handleSelectedDevice,
  } = useUserInfoHooks();

  // * State
  const [init, setInit] = React.useState<boolean>(false);
  const [fbUser, setFbUser] = React.useState<User | null>(null);
  const [counts, setCounts] = React.useState<ICounts | null>(null);
  const [dataParams, setDataParams] =
    React.useState<RangeSensorIdOrSensorListRequestParams | null>(null);
  const [center, setCenter] =
    React.useState<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >(undefined);
  const [isMarkerDialogOpen, setIsMarkerDialogOpen] =
    React.useState<boolean>(false);
  const clickedMarkerRef =
    React.useRef<{
      deviceId: string;
      deviceName: string;
    } | null>(null);
  const beforeEventDatasetLengthRef = React.useRef<number>(0);

  // * QUERY
  const { isFetching: isFetchingDmeasure, data: dmeasureData } =
    useDmeasureDataQuery(
      dataParams as RangeOptionalDeviceIdSensorIdRequestParams,
      "gradient"
    );

  const {
    isFetching: isFetchingDeviceLatLng,
    data: deviceLatLngData,
    refetch: deviceLatLngRefetch,
  } = useDeviceLatLngQuery();

  const {
    isFetching: isFetchingDtrigger,
    data: dtriggerData,
    refetch: dtriggerDataRefetch,
  } = useDtriggerFileListQuery({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    alignType: "date_desc",
  } as RangeOptionalSearchPagingRequestParams);

  const { isFetching: isFetchingDeviceState, data: deviceStateData } =
    useDeviceStateShortQuery();

  // * Functions
  const updateClickedMarker = React.useCallback((id: string, name: string) => {
    clickedMarkerRef.current = {
      deviceId: id,
      deviceName: name,
    };
    setIsMarkerDialogOpen(true);
  }, []);

  // * Effect
  // userInfo 전역 변수 업데이트 되면 카운트 박스에 사용할 카운트 가공
  useEffect(() => {
    if (userInfo.userGroup) {
      if (!init) {
        setInit(true);
      }
      setCounts(() => {
        const newData = {
          deviceGroups: userInfo.deviceGroupList.length,
          structures: userInfo.structureList.length,
          devices: userInfo.deviceList.length,
          sensors: userInfo.sensorList.length,
        };
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // Firebase auth 스테이트 상태 확인
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setFbUser(user);
      } else {
        setFbUser(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // firestore의 interestSensorList가 있으면 관심 센서 통계 데이터 호출하는 데이터 파라미터 업데이트
  useEffect(() => {
    if (interestSensorList) {
      setDataParams(() => ({
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        sensorId: interestSensorList.join(","),
      }));
    }
  }, [interestSensorList]);

  // 처음 접속 시 firestore에서 불러오기
  useEffect(() => {
    const getSensorList = async () => {
      const data = await getUserInterestSensor(fbUser?.uid ?? "");

      if (userInfo.sensorList && data) {
        const sensorList = userInfo.sensorList.flatMap((i) => i.sensor_id);
        const newInterestSensorList = data.sensorList.filter((i) =>
          sensorList.includes(i)
        );

        if (
          JSON.stringify(data.sensorList) !==
          JSON.stringify(newInterestSensorList)
        ) {
          // 두 배열이 일치하지 않을 경우 newInterestSensorList로 firestore db를 업데이트 한다.
          setUserInterestSensor(newInterestSensorList);
        }

        setInterestSensorList(newInterestSensorList);
      }
    };
    getSensorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // deviceLatLngData, selectedDevice가 변경되면 현재 selectedDevice.value를 찾아서 맵 센터를 해당 디바이스의 위/경도로 이동
  useEffect(() => {
    if (selectedDevice?.value && deviceLatLngData) {
      // * 지도에 센터를 선택된 디바이스 위치로 변경
      const device = deviceLatLngData.find(
        (item) => item.deviceId === selectedDevice.value
      ) as DeviceLatLngResponse;

      setCenter({
        lat:
          device.latitude !== null
            ? Number(device.latitude) + 0.001
            : 37.5434128 + 0.001,
        lng:
          device.longitude !== null
            ? Number(device.longitude) - 0.0012
            : 126.9528962 - 0.0012,
      });
    }
  }, [deviceLatLngData, selectedDevice, navermaps.LatLng]);

  // 이벤트 발생하면 디바이스 위/경도 api 리패치
  // 처음 페이지 접속 시에는 실행하지 않는다.
  // 이전 이벤트데이터셋 개수보다 작아질 경우 실행하지 않는다.
  useEffect(() => {
    if (
      init &&
      eventDataset &&
      eventDataset.length > 0 &&
      eventDataset.length > beforeEventDatasetLengthRef.current
    ) {
      deviceLatLngRefetch();
      dtriggerDataRefetch();
    }

    beforeEventDatasetLengthRef.current = eventDataset?.length ?? 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDataset, init, deviceLatLngRefetch, dtriggerDataRefetch]);

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { FetchResetDeviceDtrigger } = useFetchData();
  const { handleAlertDialogOpen } = useAlertDialog();

  const {
    mutate: resetDeviceDtriggerMutate,
    isLoading: isResetDeviceDtriggerLoading,
  } = useMutation(FetchResetDeviceDtrigger);

  // 마커 이벤트 삭제 버튼 핸들러
  const handleResetDeviceDtriggerButton = React.useCallback(() => {
    resetDeviceDtriggerMutate(
      {
        deviceId: clickedMarkerRef.current?.deviceId ?? "",
      },
      {
        onSuccess: (data) => {
          if (data) {
            clickedMarkerRef.current = null;
            setIsMarkerDialogOpen(false);

            handleAlertDialogOpen(
              t("notification"),
              t("turn_off_device_event_notifications")
            );

            deviceLatLngRefetch();
          }
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDeviceDtriggerMutate, deviceLatLngRefetch, handleAlertDialogOpen]);

  // const { useCustomLoading } = useCommonHooks();
  // useCustomLoading();

  // 구글 맵
  const [toggleMap, setToggleMap] = React.useState<"N" | "G">(
    language === "ko"
      ? "N"
      : language === "en"
      ? "G"
      : i18n.language === "ko"
      ? "N"
      : i18n.language === "en"
      ? "G"
      : "N"
  );

  useEffect(() => {
    if (language === "ko") {
      setToggleMap("N");
    } else if (language === "en") {
      setToggleMap("G");
    }
  }, [language]);

  useEffect(() => {
    if (i18n.language === "ko") {
      setToggleMap("N");
    } else if (i18n.language === "en") {
      setToggleMap("G");
    }
  }, [i18n.language]);

  const ToggleMapButtonWrapRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      {isResetDeviceDtriggerLoading ? <Loading /> : null}
      <StyledHeightFixWrap>
        <StyledLeftWrap isSummarySidebar={isSummarySidebar}>
          <CountBox counts={counts} />
          <StyledMapWrap>
            <StyledMapButtonWrap align="LEFT_TOP">
              <Select
                sizeTheme={SmallInputStyle}
                name="deviceGroupSelect"
                placeholder={t("group")}
                options={memorizedDeviceGroupOptions}
                value={selectedDeviceGroup}
                event={(value) =>
                  handleSelectedDeviceGroup(Number(value.value))
                }
                fixWidth={language === "en" ? 150 : 110}
              />
              <DeviceSearchSelect
                sizeTheme={SmallInputStyle}
                name="deviceSearchSelect"
                placeholder={t("device")}
                options={memorizedDeviceOptions}
                value={selectedDevice}
                event={(value) => handleSelectedDevice(value.value as string)}
                fixWidth={language === "en" ? 220 : 180}
              />
            </StyledMapButtonWrap>
            <StyledMapButtonWrap
              align="LEFT_BOTTOM"
              onMouseEnter={() => {
                ToggleMapButtonWrapRef.current?.classList.add("enter");
              }}
              onMouseLeave={() => {
                ToggleMapButtonWrapRef.current?.classList.remove("enter");
              }}
            >
              <div className="toggleMapWrap" ref={ToggleMapButtonWrapRef}>
                <GroupButton
                  sizeTheme={SmallButtonStyles}
                  colorType="primary"
                  type="button"
                  contents={[
                    {
                      text: "N",
                      onClick: () => {
                        setToggleMap("N");
                      },
                      maxWidth: 40,
                    },
                    {
                      text: "G",
                      onClick: () => {
                        setToggleMap("G");
                      },
                      maxWidth: 40,
                    },
                  ]}
                  activeToggle={toggleMap === "G" ? 1 : 0}
                ></GroupButton>
              </div>
            </StyledMapButtonWrap>
            {language === "en" || toggleMap === "G" ? (
              <GoogleMap
                center={center}
                markerData={deviceLatLngData}
                showMarker
                markerType="cluster"
                updateClickedMarker={updateClickedMarker}
              />
            ) : (
              <NaverMap
                center={center}
                markerData={deviceLatLngData}
                showMarker
                markerType="cluster"
                updateClickedMarker={updateClickedMarker}
                isLoading={isFetchingDeviceLatLng}
              />
            )}

            {/* 이벤트 알림 해제 다이얼로그  */}
            <Dialog
              title={
                <StyledFlexWrap gap={10} vertical={FlexAlignTypeConst.Center}>
                  <StyledDeviceIdText>
                    {clickedMarkerRef.current?.deviceId}
                  </StyledDeviceIdText>
                  <span>{clickedMarkerRef.current?.deviceName}</span>
                </StyledFlexWrap>
              }
              sizeTheme={SmallDialogStyles}
              isOpen={isMarkerDialogOpen}
              setIsOpen={setIsMarkerDialogOpen}
            >
              <StyledFlexWrap
                flexDirection="column"
                vertical={FlexAlignTypeConst.Center}
                gap={15}
              >
                {deviceLatLngData?.find(
                  (item) => item.deviceId === clickedMarkerRef.current?.deviceId
                )?.dtrigger === "Y" ? (
                  <StyledPrimaryText size="md">
                    {t("the_device_which_event_occurred")}
                  </StyledPrimaryText>
                ) : (
                  <StyledPrimaryText size="xmd">
                    {t("the_device_which_no_event_occurred")}
                  </StyledPrimaryText>
                )}
                <Button
                  colorType="primary"
                  sizeTheme={NormalButtonStyles}
                  text={t("turn_off_event_notifications")}
                  onClick={handleResetDeviceDtriggerButton}
                  isDisabled={
                    deviceLatLngData?.find(
                      (item) =>
                        item.deviceId === clickedMarkerRef.current?.deviceId
                    )?.dtrigger === "N"
                  }
                />
              </StyledFlexWrap>
            </Dialog>
          </StyledMapWrap>

          {!isFetchingDmeasure && dtriggerData && dtriggerData.count > 0 ? (
            <DmeasureChartSlide
              isLoading={isFetchingDmeasure}
              dmeasureData={dmeasureData}
            />
          ) : null}
        </StyledLeftWrap>
        <StyledRightWrap>
          {/* <ManagementStandardTable /> */}
          {!isFetchingDtrigger && dtriggerData && dtriggerData.count > 0 ? (
            <>
              <DeviceStatusTable
                cardSizeTheme={SmallCardStyle}
                isLoading={isFetchingDeviceState}
                data={deviceStateData}
                showPaging
                isCustomPaging
                elementSize="small"
              />
              <EventTable isFetching={isFetchingDtrigger} data={dtriggerData} />
            </>
          ) : (
            <>
              <StyledRightDmeasureChartSlideWrap>
                <DmeasureChartSlide
                  isLoading={isFetchingDmeasure}
                  dmeasureData={dmeasureData}
                />
              </StyledRightDmeasureChartSlideWrap>
              <div style={{ flex: "0 0 auto" }}>
                <DeviceStatusTable
                  cardSizeTheme={SmallCardStyle}
                  isLoading={isFetchingDeviceState}
                  data={deviceStateData}
                  showPaging
                  isCustomPaging
                  elementSize="small"
                />
              </div>
            </>
          )}
        </StyledRightWrap>
      </StyledHeightFixWrap>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function useI18nHooks() {
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = React.useState<SelectOption>({
    value: i18n.language === "ko" ? "ko" : i18n.language === "en" ? "en" : "ko",
    label:
      i18n.language === "ko"
        ? t("ko")
        : i18n.language === "en"
        ? t("en")
        : t("ko"),
  });

  const handleChangeLanguage = React.useCallback(
    (lang: string) => {
      i18n
        .changeLanguage(lang)
        .then(() => {
          console.log(`언어가 ${lang}로 변경되었습니다.`);
          // 언어 변경 후 필요한 작업 수행
          onLanguageChanged(lang);
        })
        .catch((error) => {
          console.error("언어 변경에 실패했습니다.", error);
          // 실패 시 처리할 내용
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n]
  );

  // 구글 번역이랑 같이 적용할 때 제대로 안바뀌는 이슈가 있어서 setTimeout 적용
  const handleSelectUpdate = () => {
    setTimeout(() => {
      if (i18n.language === "ko") {
        setSelectedLanguage({ value: "ko", label: t("ko") });
      } else if (i18n.language === "en") {
        setSelectedLanguage({ value: "en", label: t("en") });
      }
    }, 200);
  };

  const onLanguageChanged = (lang: string) => {
    handleSelectUpdate();
    localStorage.setItem("i18nextLng", lang);
  };

  const useI18nLanguageChangedEffect = React.useEffect(() => {
    // languageChanged 이벤트에 핸들러 등록
    i18n.on("languageChanged", (lang) => onLanguageChanged(lang));

    // 컴포넌트가 언마운트될 때 이벤트 해제
    return () => {
      i18n.off("languageChanged", (lang) => onLanguageChanged(lang));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  return {
    selectedLanguage,
    setSelectedLanguage,
    handleChangeLanguage,
    useI18nLanguageChangedEffect,
  };
}

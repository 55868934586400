import { Div } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledChartGridWrap = styled(Div)<{
  viewLarger: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ viewLarger }) =>
    viewLarger ? "1fr !important" : "repeat(3, 1fr)"};
  flex-wrap: wrap;
  flex: 0 0 auto;
  gap: 2.4vw;
  width: 100%;

  & > div {
    flex: 0 0 auto;
    min-width: 200px;
    min-height: 200px;
  }

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const StyledStateChartGridWrap = styled(Div)<{
  viewLarger: boolean;
  dataType?: string;
}>`
  display: grid;
  grid-template-columns: ${({ viewLarger }) =>
    viewLarger ? "1fr !important" : "repeat(5, 1fr)"};
  flex-wrap: wrap;
  flex: 0 0 auto;
  gap: 30px;
  width: 100%;

  & > div {
    flex: 0 0 auto;
    min-width: 200px;
    min-height: 200px;
  }

  min-width: ${({ viewLarger }) => (viewLarger ? undefined : "1450px")};
`;

export const StyledDeviceIdText = styled.span<{ boxSize?: string }>`
  display: inline-flex;
  padding: ${({ boxSize }) => (boxSize === "SMALL" ? "2px 6px" : "6px 12px")};
  color: white;
  font-weight: ${({ theme, boxSize }) =>
    boxSize === "SMALL"
      ? theme.size.common.fontWeight.md
      : theme.size.common.fontWeight.lg};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 16px;

  ${mediaQuery.mobile} {
    font-size: ${({ theme }) => theme.size.common.fontSize.base};
    padding: ${({ boxSize }) => (boxSize === "SMALL" ? "2px 6px" : "4px 8px")};
  }
`;

export const StyledDateText = styled.span`
  font-size: ${({ theme }) => theme.size.common.fontSize.base};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.sm};
`;

export const StyledTableWrap = styled(Div)<{ fileUrl: string }>`
  width: ${({ fileUrl }) => (fileUrl !== "" ? "534px" : "100%")};
  transition: width 0.24s ease-out;

  ${mediaQuery.largeLaptop} {
    order: 2;
  }
  ${mediaQuery.tablet} {
    min-width: auto;
  }
`;

export const StyledChartWrap = styled(Div)`
  flex: 1;

  ${mediaQuery.largeLaptop} {
    order: 1;
  }
`;

export const StyledCameraDimContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.card.dimBackground};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
`;

export const StyledCameraMotionWrap = styled(motion.div)`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 1100px;
  max-height: 100%;

  z-index: 1100;
  position: relative;
`;

import { useEffect } from "react";
import AuthLayout from "@layout/AuthLayout";
import {
  applyActionCode,
  confirmPasswordReset,
  signOut,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth } from "@src/firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyledButtonWrap, StyledTitle } from "../styles";
import {
  StyledFlexWrap,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledTextUnderlineButton,
} from "@src/styles/commonStyles";
import React from "react";
import {
  useCommonStoreActions,
  useLoggedInStore,
} from "@src/stores/useCommonStore";
import Input from "@components/Inputs/Input";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@src/components/Buttons/Button";
import { BigButtonStyles } from "@src/components/Buttons/sizeTheme";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";

export default function AdditionalWork() {
  const { t } = useTranslation();
  const [accountEmail, setAccountEmail] =
    React.useState<string>("loading_email");
  const [actionCode, setActionCode] = React.useState<string>("");
  const [searchParams] = useSearchParams();
  const { handleAlertDialogOpen, cancelAlertDialogOpen } = useAlertDialog();
  const loggedIn = useLoggedInStore();
  const { setLoggedIn } = useCommonStoreActions();
  const [where, setWhere] = React.useState<string>("");
  const navigate = useNavigate();

  const memorizedAccountEmail = React.useMemo(() => {
    if (accountEmail) {
      return t(accountEmail);
    } else {
      return "";
    }
  }, [t, accountEmail]);

  // * useForm
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("new_password_is_required")
      .min(12, "enter_at_least_12")
      .max(40, "enter_up_to_40")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{12,}[^\s]*$/,
        "password_guide_line"
      ),
    newPasswordConfirm: yup
      .string()
      .required("new_password_verification_is_required")
      .oneOf([yup.ref("newPassword"), null], "password_does_not_match")
      .min(12, "enter_at_least_12")
      .max(40, "enter_up_to_40"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // 초기값 설정
      newPassword: "",
      newPasswordConfirm: "",
    },
  });

  // 이메일 인증 함수
  const handleVerifyEmail = async (actionCode: string) =>
    await applyActionCode(auth, actionCode)
      .then(async () => {
        if (auth.currentUser) {
          await auth.currentUser.reload().then(() => {
            setWhere(() => "main");
            setTimeout(() => {
              navigate("/home");
            }, 1000);
          });
        } else {
          setWhere(() => "login");
          setTimeout(() => {
            navigate("/auth/login");
          }, 1000);
        }
      })
      .catch((error) => {
        handleErrorActionCode(error.code);
      });

  // 비밀번호 재설정 액션 코드 인증
  const handleVerifyPasswordResetCode = async (actionCode: string) => {
    // Verify the password reset code is valid.
    await verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setAccountEmail(email);
      })
      .catch((error) => {
        handleErrorActionCode(error.code);
      });
  };

  // 비밀번호 재설정
  const handleUpdatePassword = async (data: { newPassword: string }) => {
    await confirmPasswordReset(auth, actionCode, data.newPassword)
      .then(async () => {
        if (auth.currentUser) {
          await auth.currentUser.reload().then(() => {
            // 비밀번호 성공적으로 바뀌었는데 로그인된 사용자가 있다면 로그아웃 시키기
            signOut(auth);
            setLoggedIn(false);
          });
        }

        setWhere(() => "로그인");
        setTimeout(() => {
          navigate("/auth/login");
        }, 1000);
      })
      .catch((error) => {
        handleAlertDialogOpen(
          t("failed_to_reset_password_title"),
          t("failed_to_reset_password_message")
        );
      });
  };

  // 액션코드 인증 에러 함수
  const handleErrorActionCode = (errorCode: string) => {
    if (errorCode.toLowerCase().includes("invalid-action-code")) {
      // 이미 인증 받은 코드로 판단
      if (auth.currentUser && loggedIn) {
        signOut(auth);
        setLoggedIn(false);
      }

      // 로그인 화면으로 이동
      handleRedirectToLogin();
    }
  };

  const handleRedirectToLogin = () => {
    handleAlertDialogOpen(
      "비정상적인 접근",
      <span>로그인 화면으로 이동됩니다.</span>
    );
    setTimeout(() => {
      cancelAlertDialogOpen();
      navigate("/auth/login");
    }, 1000);
  };

  useEffect(() => {
    if (auth) {
      const mode = searchParams.get("mode");
      const actionCode = searchParams.get("oobCode");
      setActionCode(() => actionCode ?? "");

      if (mode && actionCode) {
        // Handle the user management action.
        switch (mode) {
          case "verifyEmail":
            handleVerifyEmail(actionCode);
            break;
          case "resetPassword":
            handleVerifyPasswordResetCode(actionCode);
            break;
          default:
            if (auth.currentUser && loggedIn) {
              signOut(auth);
              setLoggedIn(false);
            }

            // 로그인 화면으로 이동
            handleRedirectToLogin();
        }
      } else {
        // 인증 메일이 아닌데 접속한 경우 메인으로 리턴
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <AuthLayout>
      {searchParams.get("mode") === "verifyEmail" ? (
        <div>
          <StyledTitle>Email Check</StyledTitle>
          <StyledFlexWrap flexDirection="column" gap={8}>
            <>
              <StyledSecondaryText size="mdlg">
                {!where
                  ? t("checking_email")
                  : t("email_authentication_completed")}
              </StyledSecondaryText>
              {where ? (
                <>
                  <StyledPrimaryText weight={400}>
                    {t("move_to_some_page_after_1_second", {
                      where: t(where),
                    })}
                  </StyledPrimaryText>
                  <StyledButtonWrap>
                    <StyledTextUnderlineButton
                      type="button"
                      onClick={() => navigate("/auth/login")}
                    >
                      {t("go_to_login")}
                    </StyledTextUnderlineButton>
                  </StyledButtonWrap>
                </>
              ) : null}
            </>
          </StyledFlexWrap>
        </div>
      ) : searchParams.get("mode") === "resetPassword" ? (
        <div>
          <StyledTitle>Reset Password</StyledTitle>
          {!where ? (
            <form onSubmit={handleSubmit(handleUpdatePassword)}>
              <div>
                <Input
                  inputName="aw-email"
                  placeholder={t("please_enter_email")}
                  type="text"
                  eventType="Custom"
                  sizeTheme={NormalInputStyle}
                  addonShow={true}
                  addonDirection="left"
                  addonName="user"
                  isShowLabel
                  labelDirection="TOP"
                  labelText={t("email")}
                  isFixBottomMargin
                  value={memorizedAccountEmail}
                  isReadOnly
                  isDisabled
                  onChange={() => {}}
                  labelType="AUTH"
                />
                <Input
                  inputName="aw-pw"
                  placeholder={t("please_enter_password")}
                  type="password"
                  sizeTheme={NormalInputStyle}
                  eventType="UseForm"
                  register={{
                    ...register("newPassword"),
                  }}
                  setFocus={() => setFocus("newPassword")}
                  isInvalid={errors.newPassword !== undefined}
                  errorMessages={[errors.newPassword?.message as string]}
                  addonShow={true}
                  addonDirection="left"
                  addonName="lock"
                  isShowLabel
                  labelDirection="TOP"
                  labelText={`${t("reset")} ${t("password")}`}
                  isFixBottomMargin
                  autoComplete="new-password"
                  labelType="AUTH"
                />
                <Input
                  inputName="aw-pw-cf"
                  placeholder={t("please_enter_password_again")}
                  type="password"
                  sizeTheme={NormalInputStyle}
                  eventType="UseForm"
                  register={{
                    ...register("newPasswordConfirm"),
                  }}
                  setFocus={() => setFocus("newPasswordConfirm")}
                  isInvalid={errors.newPasswordConfirm !== undefined}
                  errorMessages={[errors.newPasswordConfirm?.message as string]}
                  addonShow={true}
                  addonDirection="left"
                  addonName="lockCheck"
                  isShowLabel
                  labelDirection="TOP"
                  labelText={`${t("reset")} ${t("password_verification")}`}
                  isFixBottomMargin
                  labelType="AUTH"
                />
              </div>
              <Button
                type="submit"
                colorType="primary"
                sizeTheme={BigButtonStyles}
                text={t("reset_password")}
                fixWidth="100%"
              />
            </form>
          ) : (
            <StyledFlexWrap flexDirection="column" gap={8}>
              <StyledSecondaryText size="lg">
                {t("password_changed")}
              </StyledSecondaryText>
              <StyledPrimaryText weight={400}>
                {t("move_to_some_page_after_1_second", {
                  where: t(where),
                })}
              </StyledPrimaryText>
              <StyledButtonWrap>
                <StyledTextUnderlineButton
                  type="button"
                  onClick={() => navigate("/auth/login")}
                >
                  {t("go_to_login")}
                </StyledTextUnderlineButton>
              </StyledButtonWrap>
            </StyledFlexWrap>
          )}
        </div>
      ) : null}
    </AuthLayout>
  );
}

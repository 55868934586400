import { ThemeConst } from "@src/constructs/common";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { IDmeasureData, IRawFileData } from "@src/fetch/fetchChart";
import { IDeviceList } from "@src/fetch/fetchCommon";
import { useThemeStore, useUserInfoStore } from "@src/stores/useCommonStore";
import { useQueryActions } from "@src/stores/useQueryStore";
import { StyledDeviceIdText, StyledI18nSpan } from "@src/styles/commonStyles";
import { darkTheme, theme } from "@src/styles/theme";
import { ScriptableContext } from "chart.js";
import {
  addMilliseconds,
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
  format,
  parse,
  parseISO,
  setDate,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import JSZip from "jszip";
import React, { ReactNode, useCallback, useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

type MouseOrTouchEvent =
  | MouseEvent
  | TouchEvent
  | React.MouseEvent
  | React.TouchEvent;

export interface IUseFormSetValue {
  state: any;
  setValue: UseFormSetValue<any>;
  name: string;
  errorMessage?: string;
  clearErrors?: (name?: any) => void;
  dateFormat?: string;
  valueType?: "string" | "number";
}

export default function useCommonHooks() {
  const userInfo = useUserInfoStore();
  const themeType = useThemeStore();
  const { setShowCustomLoading } = useQueryActions();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { t } = useTranslation();

  const useTimeout = (
    callback: () => void,
    delay: number,
    dependency?: any,
    condition?: boolean
  ) => {
    const savedCallback = React.useRef<any>(null);

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      // condition이 undefined이거나 true일 때 실행
      if (condition === undefined || condition) {
        const id = setTimeout(tick, delay);
        return () => clearTimeout(id);
      }
    }, [delay, dependency, condition]);
  };

  /**
   * @description
   * - Css의 가상 선택자(nth-child)를 사용했는데 이중 테이블 펼쳐질 때 tr이 추가되면서 가상 선택자가 제대로 먹지 않는 상황이 발생
   * - 홀수/짝수 Row의 배경색을 추가하는 용도로 만든 hook
   * @param index: number
   * @returns tbody -> tr odd/even backgroundColor
   */
  const addTbodyBackgroundColor = useCallback(
    (index: number) => {
      return index % 2 === 0 && themeType === ThemeConst.Light
        ? theme.color.table.tbody.backgroundOdd
        : index % 2 === 0
        ? darkTheme.color.table.tbody.backgroundOdd
        : index % 2 !== 0 && themeType === ThemeConst.Light
        ? theme.color.table.tbody.backgroundEven
        : darkTheme.color.table.tbody.backgroundEven;
    },
    [themeType]
  );

  const checkOutsideClick = React.useCallback(
    (
      e: MouseOrTouchEvent,
      ref: React.RefObject<HTMLElement>,
      state: boolean,
      setState:
        | React.Dispatch<React.SetStateAction<boolean>>
        | ((open: boolean, skipSetBlur?: boolean | undefined) => void),
      ignoreList?: (React.RefObject<HTMLElement> | string)[]
    ) => {
      const target = e.target as HTMLElement;

      // state === true 조건 추가
      if (
        state &&
        ref.current !== null &&
        ref.current !== undefined &&
        !ref.current.contains(target) &&
        (!ignoreList ||
          ignoreList.every((val) =>
            typeof val === "string"
              ? !target.classList.contains(val)
              : !val.current?.contains(target)
          ))
      ) {
        setState(false);
      }

      e.preventDefault();
    },
    []
  );

  const useOutsideClickEffect = (
    ref: React.RefObject<HTMLElement>,
    state: boolean,
    setState:
      | React.Dispatch<React.SetStateAction<boolean>>
      | ((open: boolean, skipSetBlur?: boolean | undefined) => void),
    ignoreList?: (React.RefObject<HTMLElement> | string)[]
  ) => {
    const handleClickOutside = React.useRef<(event: MouseOrTouchEvent) => void>(
      () => {}
    );

    useEffect(() => {
      handleClickOutside.current = (e: MouseOrTouchEvent) => {
        const target = e.target as HTMLElement;

        // state === true 조건 추가
        if (
          state &&
          ref.current !== null &&
          ref.current !== undefined &&
          !ref.current.contains(target) &&
          (!ignoreList ||
            ignoreList.every((val) =>
              typeof val === "string"
                ? !target.classList.contains(val)
                : !val.current?.contains(target)
            ))
        ) {
          setState(false);
        }

        e.preventDefault();
      };
    }, [ref, state, setState, ignoreList]);

    useEffect(() => {
      const eventListener = (event: MouseOrTouchEvent) =>
        handleClickOutside.current(event);

      if (state) {
        document.addEventListener("mousedown", eventListener);
      } else {
        document.removeEventListener("mousedown", eventListener);
      }

      return () => {
        document.removeEventListener("mousedown", eventListener);
      };
    }, [state]);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent,
    keys: string[],
    fn: () => any
  ) => {
    e.stopPropagation();
    if (e.defaultPrevented || String(e.key.toLowerCase()) === "tab") {
      return;
    }

    const result =
      e.key.length === 1
        ? false
        : keys.some((key) => {
            const keyLower = String(key.toLowerCase());
            const eKeyLower = String(e.key.toLowerCase());
            return keyLower.includes(eKeyLower);
          });

    if (result) fn();

    e.preventDefault();
  };

  // 테마 변경될 때를 확인해서 무언가 처리할 경우 공통으로 사용(테마 토글 버튼, 차트 그리드에서 사용 중)
  const useThemeToggleChanged = (
    beforeThemeType: ThemeType,
    setBeforeThemeType: React.Dispatch<React.SetStateAction<ThemeType>>,
    delay: number
  ) => {
    const savedCallback = React.useRef<any>(null);

    useEffect(() => {
      function tick() {
        savedCallback.current = () => {
          if (themeType === beforeThemeType) {
            setBeforeThemeType(
              themeType === ThemeConst.Light
                ? ThemeConst.Dark
                : ThemeConst.Light
            );
          }
        };

        savedCallback.current();
      }

      const id = setTimeout(tick, delay);
      return () => {
        clearTimeout(id);
        savedCallback.current = null;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeType]);
  };

  // endDate setValue & Clear Error
  const useFormSetValue = ({
    state,
    setValue,
    name,
    errorMessage,
    clearErrors,
    dateFormat,
    valueType,
  }: IUseFormSetValue) => {
    useEffect(() => {
      if (state && errorMessage && clearErrors) {
        clearErrors(name);
      }

      if (state) {
        if (dateFormat) {
          setValue(name, format(new Date(state), dateFormat));
        } else {
          setValue(name, state);
        }
      } else {
        if (valueType === "number") setValue(name, 0);
        else setValue(name, "");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
  };

  const useCustomLoading = () => {
    return useEffect(() => {
      setShowCustomLoading(true);
    }, []);
  };

  // 데이터 리패치 시 커스텀 로딩을 사용해 컴포넌트가 사라졌다가 나타날 때 패치가 끝나자마자 바로 보여주면 데이터가 바뀌는게 눈에 보여서 자연스럽지 못한 것 같다.
  // 조금 더 늦게 컴포넌트를 띄워주도록 하는 hook
  const useLazyLoading = (
    isLoading: boolean | undefined,
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    delay: number
  ) => {
    const savedCallback = React.useRef<any>(null);

    useEffect(() => {
      if (isLoading) {
        setState(true);
      } else if (isLoading === false) {
        savedCallback.current = () => setState(false);

        const tick = () => {
          savedCallback.current();
        };
        const id = setTimeout(tick, delay);
        return () => clearTimeout(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
  };

  //
  /**
   * @description 공통 체크박스 핸들러
   * @param
   * - value: 1개 선택일 경우 number / 전체 선택일 경우 boolean
   * - setState: 변경하고싶은 state의 setState를 매개변수로 전달
   * - maxTrueLength?: 체크박스가 true인 개수 제한이 있을 경우 추가
   * - maxTrueLengthErrorMessage?: 개수 제한 에러 메세지 추가 (length, error messagge 둘다 보내줘야 함)
   */
  const handleCheckbox = React.useCallback(
    (
      value: number | boolean,
      setState: (value: React.SetStateAction<boolean[]>) => void,
      maxTrueLength?: number,
      maxTrueLengthErrorMessage?: string,
      visibleIndexes?: number[]
    ) => {
      setState((prev) => {
        const newData = [...prev];

        if (typeof value === "number") {
          // 최대 개수 제한
          if (maxTrueLength && maxTrueLengthErrorMessage) {
            const trueLength = newData.filter((item) => item).length;
            if (trueLength >= maxTrueLength && !newData[value]) {
              handleAlertDialogOpen(
                t("notification"),
                t(maxTrueLengthErrorMessage)
              );
              return newData;
            }
          }

          newData[value] = !newData[value];
          return newData;
        } else {
          if (visibleIndexes) {
            newData.forEach((_, i) => {
              if (visibleIndexes.includes(i)) {
                // A 배열의 인덱스가 visibleIndexes에 포함되는 경우
                newData[i] = value;
              } else {
                // A 배열의 인덱스가 visibleIndexes에 포함되지 않는 경우(페이징 등의 이유로 현재 보이지 않는 상태)
                newData[i] = false;
              }
            });
            return newData;
          } else {
            newData.map((_, i) => (newData[i] = value));
            return newData;
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const makeChartTitle = (
    data: {
      deviceId: string;
      secondText: string;
      isTranslationOnI18n: boolean;
      sensorId?: string;
    },
    customEl?: ReactNode
  ) => {
    if (customEl) {
      return customEl;
    } else if (data.isTranslationOnI18n) {
      return (
        <>
          <StyledDeviceIdText>{data.deviceId}</StyledDeviceIdText>
          <StyledI18nSpan
            data-str={`${data.sensorId ? `${data.sensorId} ` : ""}${t(
              data.secondText
            )}`}
          />
        </>
      );
    } else {
      return (
        <>
          <StyledDeviceIdText>{data.deviceId}</StyledDeviceIdText>
          <span>{`${data.sensorId ? `${data.sensorId} ` : ""}${t(
            data.secondText
          )}`}</span>
        </>
      );
    }
  };

  // 셀렉트 컴포넌트의 옵션 박스 열리면 선택된 옵션있으면 스크롤 이동하는 커스텀 훅
  const useScrollToSelectedOption = (
    optionsRef: React.RefObject<HTMLDivElement>,
    open: boolean
  ) => {
    useEffect(() => {
      if (open && optionsRef.current) {
        const refPromise = () => {
          return new Promise<boolean>((resolve) => {
            if (optionsRef.current) {
              const list =
                optionsRef.current.querySelector(".select__menu-list");
              const active = optionsRef.current.querySelector(
                ".select__option--is-selected"
              );

              list && active
                ? resolve(true)
                : setTimeout(() => refPromise().then(resolve), 100);
            }
          });
        };

        const func = async () => {
          const isTrue = await refPromise();
          if (isTrue && optionsRef.current) {
            const list = optionsRef.current.querySelector(".select__menu-list");
            const active = optionsRef.current.querySelector(
              ".select__option--is-selected"
            );

            if (list && active) {
              active.scrollIntoView({
                block: "center",
              });
            }
          }
        };
        func();
      }
    }, [open, optionsRef]);
  };

  // 이미지 인지 확인하는 함수
  const isImage = (file: File) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (allowedTypes.includes(file.type)) {
      return true;
    } else {
      handleAlertDialogOpen(
        t("notification"),
        t("non_image_files_files_are_excluded")
      );
      return false;
    }
  };

  /**
   * 초기 서치 파라미터 정하는 함수입니다.
   * @param path constructs/common.ts - MENU_LIST.menu.path;
   * @param paramsKeys 서치 파라미터 키 리스트 constructs/common.ts - MENU_LIST.menu.paramsKeys;
   * @param customParmas 커스텀 서치 파라미터 constructs/common.ts - MENU_LIST.menu.customParmas;
   * @param initDate 기간 조회 형식일 때 기본 조회 기간 constructs/common.ts - MENU_LIST.menu.initDate;
   * @param sensorTypeFilter 센서 타입(AC, CM ...) constructs/common.ts - MENU_LIST.menu.sensorTypeFilter;
   * @returns string
   */
  const initializeSearchParams = React.useCallback(
    (
      path: string,
      paramsKeys?: string[],
      customParmas?: { [key: string]: string },
      initDate?: "all" | "daily" | "weekly" | "monthly",
      sensorTypeFilter?: string[]
    ) => {
      if (userInfo.deviceGroupList && userInfo.deviceGroupList.length > 0) {
        const deviceId =
          userInfo.deviceList.find(
            (item) =>
              item.device_group_id ===
              userInfo.deviceGroupList[0].device_group_id
          )?.device_id ?? "";

        const params: { [key: string]: string } = customParmas
          ? { ...customParmas }
          : {};

        if (paramsKeys)
          paramsKeys.forEach((item) => {
            switch (item) {
              case "date":
                params.date = format(new Date(), "yyyy-MM-dd");

                break;
              case "endDate":
                if (initDate === "all") break;

                params.endDate = format(new Date(), "yyyy-MM-dd");
                break;
              case "startDate":
                if (initDate === "all") break;

                let startDate = new Date();

                if (initDate === "weekly") {
                  startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
                } else if (initDate === "monthly") {
                  startDate = startOfMonth(setDate(new Date(), 1));
                }

                params.startDate = format(startDate, "yyyy-MM-dd");
                break;
              case "sensorId":
                const sensorListFilter = userInfo.sensorList.filter((item) => {
                  const matchesDevice = item.device_id === deviceId;
                  const matchesSensorType = sensorTypeFilter
                    ? sensorTypeFilter.includes(
                        item.sensor_id.split("-")[1].split("_")[0]
                      )
                    : true;
                  return matchesDevice && matchesSensorType;
                });

                if (sensorListFilter.length > 0) {
                  params.sensorId = sensorListFilter[0].sensor_id;
                }
                break;
              case "deviceId":
                params.deviceId = deviceId;
                break;
              case "page":
                params.page = "1";
                break;
              case "size":
                params.size = "20";
                break;
            }
          });

        const url = `${path}?${Object.entries(params)
          .map(([key, val]) => `${key}=${val}`)
          .join("&")}`;
        return url;
      } else {
        return path;
      }
    },
    [userInfo]
  );

  /**
   * Query Params Update when be chagned paging
   */
  const useUpdateParamsOnPageChange = (
    paging: PagingType,
    setParams: React.Dispatch<React.SetStateAction<any>>
  ) => {
    return useEffect(() => {
      setParams((prev: any) => {
        if (prev) {
          const newData = { ...prev };
          newData.size = paging.size ?? undefined;
          newData.page = paging.page ?? undefined;
          return newData;
        }

        return null;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);
  };

  return {
    useTimeout,
    useOutsideClickEffect,
    useThemeToggleChanged,
    addTbodyBackgroundColor,
    checkOutsideClick,
    handleKeyDown,
    useFormSetValue,
    useCustomLoading,
    useLazyLoading,
    handleCheckbox,
    makeChartTitle,
    useScrollToSelectedOption,
    isImage,
    initializeSearchParams,
    useUpdateParamsOnPageChange,
  };
}
export const waitForCondition = async (
  lastMutationRef: React.MutableRefObject<boolean>
) =>
  new Promise((resolve) => {
    const checkCondition = () => {
      // 여기에 조건을 검사하는 코드를 추가합니다.
      const condition = lastMutationRef.current; // 조건을 true 또는 false로 설정합니다.

      if (condition) {
        // 조건이 충족되면 Promise를 해결합니다.
        resolve(true);
      } else {
        // 조건이 충족되지 않으면 일정 시간 후에 다시 검사합니다.
        setTimeout(checkCondition, 1000); // 1초 후에 다시 검사
      }
    };

    // 처음에 한 번 조건을 검사합니다.
    checkCondition();
  });

// ** AEName to AC-X
export const getSensorLabel = (
  ae?: string,
  returnType?: "only_type" | "type_axis"
) => {
  if (!ae) {
    return "";
  }

  // ae.000053-AC_S1Q2_02_Z
  const sensorLabel = ae.split("-")[1].split("_");

  if (returnType === "only_type") {
    return sensorLabel[0];
  } else if (returnType === "type_axis") {
    // return `${sensorLabel[0]}-${sensorLabel[sensorLabel.length - 1]}`;
    return sensorLabel.join("_");
  } else {
    // return `${sensorLabel[0]}-${sensorLabel[sensorLabel.length - 1]}(${
    //   ae.split("_")[ae.split("_").length - 2]
    // })`;
    return sensorLabel.join("_");
  }
};

/**
 * @description
 * - Dmeasure, Device State Line Chart time은 데이터 포맷이 ISOString이 아닐 경우 변경해서 리턴,
 * - 데이터가 val로 들어오는 경우 리턴 X
 * @param data - IDmeasureData[] | {
    time: string;
    value: number;
  }[]
 * @param key - string
 * @returns - 1개의 AE 차트 데이터의 키값이 key와 일치하는 데이터
 */
export const getChartData = (
  data:
    | IDmeasureData[]
    | {
        time: string;
        value: number;
      }[],
  key: string
) => {
  const newData: any[] = [];
  data.forEach((item: any) => {
    if (Object.keys(item).find((key) => key === "val")) {
      return;
    }

    return newData.push(
      key.includes("time") ? new Date(item[key]).toISOString() : item[key]
    );
  });
  return newData;
};

// * S3 File Key 중 날짜를 Date로 리턴
export const convertFileKeyToDateStr = (fileKey: string) => {
  let dateStr = "";

  // 문자열에 포함된 .의 개수를 세는 방법
  const dotCount = (fileKey.match(/\./g) || []).length;

  if (dotCount === 1) {
    dateStr = fileKey.split(".")[0].split("/").pop() ?? "";
  } else if (fileKey.includes(".jpg")) {
    dateStr = fileKey.split(".jpg")[0].split("/").pop() ?? "";
  } else if (fileKey.includes(".json")) {
    dateStr = fileKey.split(".json")[0].split("/").pop() ?? "";
  } else {
    dateStr = fileKey.split("/").pop() ?? "";
  }

  if (dateStr.length === 14) {
    const date = parse(dateStr, "yyyyMMddHHmmss", new Date());
    return format(date, "yyyy.MM.dd HH:mm:ss");
  }

  const date = parse(dateStr, "yyyyMMddHHmm", new Date());
  return format(date, "yyyy.MM.dd HH:mm");
};

export const converFileKeyString = (
  fileKey: string,
  deviceId: string,
  sensorId: string
) => {
  const newFileKey = fileKey.includes(".") ? fileKey.split(".")[0] : fileKey;
  const fileKeyArr = newFileKey.split("/");
  return `${deviceId}_${getSensorLabel(sensorId)}_${
    fileKeyArr[fileKeyArr.length - 1]
  }`;
};

export const convertDateToString = (
  date: Date | string,
  returnDate: "date" | "datetime_min" | "datetime_sec"
) => {
  let dateObject;
  if (typeof date === "string") dateObject = parseISO(date);
  else dateObject = date;
  return returnDate === "date"
    ? format(dateObject, "yyyy-MM-dd")
    : returnDate === "datetime_min"
    ? format(dateObject, "yyyy-MM-dd HH:mm")
    : format(dateObject, "yyyy-MM-dd HH:mm:ss");
};

// * 차트 이미지를 png로 저장할 때 파일 이름을 생성하는 함수
export const getPngFileTitle = (
  deviceId: string,
  dataType: "statistics" | "raw" | "status" | "PSD",
  sensorLabel?: string,
  fileKey?: string
) => {
  let newFileKey = "";
  if (fileKey) {
    newFileKey = fileKey.includes(".") ? fileKey.split(".")[0] : fileKey;
    newFileKey = newFileKey.split("/").pop() ?? "";
  }

  return sensorLabel && newFileKey
    ? `${deviceId}_${sensorLabel}_${dataType}_${newFileKey}_chart`
    : sensorLabel
    ? `${deviceId}_${sensorLabel}_${dataType}_chart`
    : `${deviceId}_${dataType}_chart`;
};

// * startDate - endDate 사이 시간 생성하는 함수
export const getDaysBetweenDates = (
  startDate: Date,
  endDate: Date,
  count: number,
  dateStringType?: string
) => {
  if (endDate.getMilliseconds() !== 0) {
    endDate.setMilliseconds(0); // milliseconds를 0으로 초기화
  }
  const endDateAddMs = addMilliseconds(endDate, 999);
  const milliseconds = differenceInMilliseconds(endDateAddMs, startDate);

  // hz가 1,10,100이여야 함(1초에 1개, 1초에 10개, 1초에 100개..)
  // 이벤트 데이터가 400개 들어오는데 start~end가 2~3초 차이라서 위 규칙에 맞지않음.
  // 아마도 startDate가 이벤트가 발생한 시간이고 이전 데이터가 추가로 더 들어오는 거 같음
  // 이벤트 데이터의 시간이 수정되기 전까지 seconds가 1, 10, 100이 맞으면 interval을 올림하고 아니면 그냥 사용해야 시간이 얼추 맞음
  const seconds = differenceInSeconds(addSeconds(endDate, 1), startDate);
  const hz = count / seconds;

  const interval =
    hz === 1 || hz === 10 || hz === 100
      ? Math.ceil(milliseconds / count)
      : milliseconds / count;
  const filledDates: string[] = [];

  if (dateStringType) {
    for (let i = 0; i < count; i++) {
      const date = addMilliseconds(startDate, i * interval);
      filledDates.push(format(date, dateStringType));
    }
  } else {
    for (let i = 0; i < count; i++) {
      const date = addMilliseconds(startDate, i * interval);
      filledDates.push(date.toISOString());
    }
  }

  return filledDates;
};

// * 로우 차트 zip 파일을 json 형태로 변환하는 함수
export const convertZipToJson: (data: Blob) => Promise<IRawFileData | null> =
  async (data: Blob) => {
    const zip = new JSZip();
    const extractedFiles = async (fileData: Blob) => {
      const file = await zip.loadAsync(fileData);

      // file.files의 vlaue(file obejct)만 추출해서 리턴
      return Object.values(file.files)[0] as JSZip.JSZipObject;
    };

    const result = (await extractedFiles(data).then(async (item) => {
      const data = JSON.parse(await item.async("string"));
      return data;
    })) as IRawFileData;

    return result;
  };

// * .ts 파일에서 Html Tag 사용이 필요해서 만든 span tag 생성 함수
// 고유한 key를 추가하기 위해 필요한 태그가 1개여도 text 매개변수를 array로 보낸다.
export const createTextSpanTags = (text: string[]) => {
  const elements = text.map((item, index) => {
    return React.createElement("span", { key: index }, item);
  });
  return elements;
};

export const pngImageDownload = (
  canvasEl: HTMLCanvasElement,
  fileName: string
) => {
  // * a 태그 생성하고 다운로드 할 url 추가해 클릭 트리거로 다운로드 후 버튼 삭제
  const fileDownload = (url: string, fileName: string) => {
    const imageLink = document.createElement("a");
    imageLink.href = url;
    imageLink.download = fileName;
    imageLink.click();
    imageLink.remove();
  };

  const url = canvasEl.toDataURL("images/png");
  fileDownload(url, fileName);
};

export const deviceListStringForCompare = (deviceList?: IDeviceList[]) => {
  return deviceList
    ? deviceList
        .sort((a, b) => {
          if (Number(a.deviceId) > Number(b.deviceId)) return 1;
          if (Number(a.deviceId) === Number(b.deviceId)) return 0;
          return -1;
        })
        .map(
          (item) =>
            `${item.deviceId}_${item.deviceName}_${item.sensorList.join("-")}`
        )
        .join("&")
    : undefined;
};

export const isLocal = () => process.env.REACT_APP_ENV === "local";
export const isDev = () => process.env.REACT_APP_ENV === "develop";
export const isProd = () => process.env.REACT_APP_ENV === "production";
export const searchToObject = (search: string) => {
  const arr = search
    .replace("?", "")
    .split("&")
    .map((item) => item.split("="));
  return Object.fromEntries(arr);
};
export const objectToSearch = (obj: object) => {
  return Object.entries(obj).join("&").replaceAll(",", "=");
};

// 제네릭 함수로 타입 확인하기
/**
 * @ex isDataType<S3FileDataResponse>(fileData);
 * @param data
 * @returns data
 */
export const isDataType = <T,>(data: any, key: string): data is T => {
  return data instanceof Object && key in data;
};

// value가 지수인지 확인
export const isExponential = (num: number | string) => {
  return /\d+\.?\d*e[+-]\d+/i.test(num.toString());
};

export const createChartGradient = (
  ctx: ScriptableContext<"bar">,
  positionColors: {
    position: number;
    color: string;
  }[]
) => {
  const gradient = ctx.chart.ctx.createLinearGradient(
    0,
    0,
    0,
    ctx.chart.height
  );
  positionColors.forEach((item) => {
    gradient.addColorStop(item.position, item.color);
  });
  return gradient;
};

// num1과 num2의 소수점 자리수 precision 만큼 값이 일치하는지 비교
export const areEqual = (num1: number, num2: number, precision: number) => {
  const diff = Math.abs(num1 - num2);
  return diff < Math.pow(10, -precision) / 2;
};

// 자식 Text 리턴 함수
export const getTextFromElement = (element: JSX.Element) => {
  let text = "";

  React.Children.forEach(element.props.children, (child) => {
    if (typeof child === "string") {
      text += child;
    } else if (React.isValidElement(child)) {
      text += getTextFromElement(child);
    }
  });

  return text;
};

// FormData로 변환하는 함수
export const convertToFormData = <T extends Record<string, any>>(
  data: T
): FormData => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    // value가 array일 때 for문으로 추가
    if (Array.isArray(value)) {
      value.forEach((val) => {
        formData.append(key, val);
      });
    } else {
      formData.append(key, value as string);
    }
  });

  return formData;
};

export const formatDate = (
  date: Date | string,
  limit: "day" | "min" | "sec"
) => {
  const myDate = new Date(date);
  switch (limit) {
    case "day":
      return format(myDate, "yyyy.MM.dd");
    case "min":
      return format(myDate, "yyyy.MM.dd HH:mm");
    case "sec":
      return format(myDate, "yyyy.MM.dd HH:mm:ss");
  }
};

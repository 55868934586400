import { Div } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import styled from "styled-components";

export const StyledHeightFixWrap = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1400px) {
    height: auto;
  }
`;

export const StyledLeftWrap = styled(Div)<{ isSummarySidebar: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 0 0 auto;
  width: ${({ isSummarySidebar }) => (isSummarySidebar ? "60%" : "55%")};
  min-width: 600px;
  height: 100%;

  @media only screen and (max-width: 1400px) {
    width: 100%;
    min-width: unset;
    height: auto;
  }
`;

export const StyledIndexWrap = styled(Div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;

  ${mediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  ${mediaQuery.mobile} {
    gap: 10px;
  }
`;

export const StyledPlaceWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;

  border-radius: 6px;
  box-shadow: 8px 8px 16px -6px rgba(0, 0, 0, 0.24);
  overflow: auto;

  position: relative;

  padding: 20;
  /* background: red; */
`;

export const StyledMapWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  min-height: 360px;
  position: relative;
`;
export const StyledMapButtonWrap = styled(Div)<{
  align: "LEFT_TOP" | "LEFT_BOTTOM" | "RIGHT_BOTTOM" | "RIGHT_TOP";
}>`
  display: flex;
  gap: 8px;
  padding: 10px;
  position: absolute;
  top: ${({ align }) =>
    align === "LEFT_TOP" || align === "RIGHT_TOP" ? "0px" : undefined};
  left: ${({ align }) =>
    align === "LEFT_TOP" || align === "LEFT_BOTTOM" ? "0px" : undefined};
  right: ${({ align }) =>
    align === "RIGHT_TOP" || align === "RIGHT_BOTTOM" ? "0px" : undefined};
  bottom: ${({ align }) =>
    align === "LEFT_BOTTOM" || align === "RIGHT_BOTTOM" ? "0px" : undefined};

  z-index: 100;

  & > .toggleMapWrap {
    transition: transform 0.24s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(50px);

    &.enter {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledRightWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: auto;
  /* overflow: hidden; */
  gap: 30px;

  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const StyledTitle = styled.span`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.common.fontSize.lg};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
  word-break: keep-all;
`;

export const StyledChartGridWrap = styled(Div)<{ viewLarger: boolean }>`
  display: grid;
  grid-template-columns: ${({ viewLarger }) =>
    viewLarger ? "1fr !important" : "repeat(6, 1fr)"};
  flex-wrap: wrap;
  flex: 0 0 auto;
  gap: 1.2vw;
  width: 100%;

  & > div {
    flex: 0 0 auto;
    min-width: 200px;
    min-height: 200px;
  }

  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const StyledStageWrap = styled(Div)`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
`;

export const StyledNormalBadge = styled(Div)<{ fixWidth?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0px 6px;
  width: ${({ fixWidth }) => fixWidth ?? "auto"};
  min-width: 42px;
  min-height: 18px;
  line-height: 18px;
  color: white;
  font-size: ${({ theme }) => theme.size.common.fontSize.sm};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};

  background-color: ${({ theme }) => theme.color.normal};
  border-radius: 20px;
`;

export const StyledAttentionBadge = styled(StyledNormalBadge)`
  background-color: ${({ theme }) => theme.color.attention};
`;

export const StyledWarnningBadge = styled(StyledNormalBadge)`
  background-color: ${({ theme }) => theme.color.invalid};
`;

export const StyledUnknownBadge = styled(StyledNormalBadge)`
  background-color: ${({ theme }) => theme.color.disabled};
`;

export const StyledPrimaryBadge = styled(StyledNormalBadge)`
  background-color: ${({ theme }) => theme.color.primary};
`;

export const StyledInterestSensorContentWrap = styled(Div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;

  ${mediaQuery.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const StyledRightDmeasureChartSlideWrap = styled(Div)`
  display: flex;
  flex: 1;
  height: 100%;

  & > div {
    flex: 1;
    height: 100%;

    & > div {
      flex: 1;
      height: 100%;

      & > div:nth-child(2) {
        flex: 1;
        height: 100%;
        & > div {
          flex: 1;
          height: 100%;

          & > div {
            flex: 1;
            height: 100%;
          }
        }
      }
    }
  }

  ${mediaQuery.largeLaptop} {
    flex: 0 0 auto;
    height: auto;

    & > div {
      flex: 0 0 auto;
      height: auto;

      & > div {
        flex: 0 0 auto;
        height: auto;

        & > div:nth-child(2) {
          flex: 0 0 auto;
          height: auto;

          & > div {
            flex: 0 0 auto;
            height: auto;

            & > div {
              flex: 0 0 auto;
              height: auto;
            }
          }
        }
      }
    }
  }
`;

import { Div } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import styled from "styled-components";

export const StyledTitle = styled.h3`
  margin-top: 10svh;
  margin-bottom: 26px;
  font-size: 30px;
  font-weight: 900;
  color: ${({ theme }) => theme.color.primary};

  ${mediaQuery.tablet} {
    margin-top: 20px;
  }
`;

export const StyledButtonWrap = styled(Div)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  margin-top: 40px;
`;

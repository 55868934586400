import styled, { css } from "styled-components";
import { ButtonStyleProps } from "./sizeTheme";

interface ButtonWrapStyleProps {
  sizeTheme: ButtonStyleProps;
  isDisabled?: boolean;
  colorType: string;
  text?: string;
  fixWidth?: number | string;
  maxWidth?: number;
  maxWidthStr?: string;
  iconColor?: string;
  iconHoverColor?: string;
}

// Button
export const CssPrimaryColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : "white"};

    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : "white"};
    }

    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.disabled : theme.color.primary};

    &:hover,
    &.active:hover {
      background-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.primaryHover};
    }

    &.active,
    &.active-scale {
      background-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.primaryActive};
    }
  }
`;

export const CssSecondaryColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : "white"};

    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : "white"};
    }

    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.disabled : theme.color.secondary};

    &:hover,
    &.active:hover {
      background-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.secondaryHover};
    }

    &.active,
    &.active-scale {
      background-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.secondaryActive};
    }
  }
`;

export const CssPrimaryNoBgColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : theme.color.primary};

    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.primary};
    }

    background-color: transparent;
  }

  &:hover,
  &.active:hover {
    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.primaryHover};
    }
  }

  &.active,
  &.active-scale {
    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.primaryActive};
    }
  }
`;
export const CssSecondaryNoBgColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : theme.color.secondary};

    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.secondary};
    }

    background-color: transparent;

    &:hover,
    &.active:hover {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.secondaryHover};
      svg {
        fill: ${({ theme, isDisabled }) =>
          isDisabled ? theme.color.font.disabled : theme.color.secondaryHover};
      }
    }

    &.active,
    &.active-scale {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.secondaryActive};
      svg {
        fill: ${({ theme, isDisabled }) =>
          isDisabled ? theme.color.font.disabled : theme.color.secondaryActive};
      }
    }
  }
`;

export const CssGrayToPrimaryNoBgColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : theme.color.sidebar.menuTitle};

    svg {
      fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.sidebar.menuTitle};
    }

    background-color: transparent;

    &:hover,
    &.active,
    &.active-scale {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.primary};
      svg {
        fill: ${({ theme, isDisabled }) =>
          isDisabled ? theme.color.font.disabled : theme.color.primary};
      }
    }
  }
`;

export const CssCustomColor = css<{
  isDisabled?: boolean;
  iconColor?: string;
  iconHoverColor?: string;
}>`
  & > button {
    color: ${({ theme, isDisabled, iconColor }) =>
      isDisabled ? theme.color.disabled : iconColor};

    svg {
      fill: ${({ theme, isDisabled, iconColor }) =>
        isDisabled ? theme.color.disabled : iconColor};
    }

    background-color: transparent;

    &:hover,
    &.active,
    &.active-scale {
      color: ${({ theme, isDisabled, iconHoverColor }) =>
        isDisabled ? theme.color.disabled : iconHoverColor};
      svg {
        fill: ${({ theme, isDisabled, iconHoverColor }) =>
          isDisabled ? theme.color.disabled : iconHoverColor};
      }
    }
  }
`;

// 공통 버튼 스타일
export const StyledButtonWrap = styled.div<ButtonWrapStyleProps>`
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ fixWidth }) => fixWidth};
  max-width: ${({ sizeTheme, maxWidth }) =>
    maxWidth
      ? `${maxWidth}px`
      : sizeTheme.maxWidth
      ? `${sizeTheme.maxWidth}px`
      : undefined};
  max-width: ${({ sizeTheme, maxWidthStr }) =>
    maxWidthStr
      ? `${maxWidthStr}px`
      : sizeTheme.maxWidth
      ? `${sizeTheme.maxWidth}px`
      : undefined};
  min-width: ${({ sizeTheme }) =>
    sizeTheme.minWidth ? `${sizeTheme.minWidth}px` : undefined};

  & > button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${({ sizeTheme }) =>
      `${sizeTheme.paddingTB}px ${sizeTheme.paddingLR}px`};
    width: 100%;
    height: auto;
    min-height: ${({ sizeTheme }) => sizeTheme.height}px;
    font-size: ${({ sizeTheme }) => sizeTheme.fontSize};
    font-weight: ${({ sizeTheme }) => sizeTheme.fontWeight};
    line-height: 1.2;
    border-radius: 2px;
    cursor: ${({ isDisabled }) => (isDisabled ? "unset" : "pointer")};
    transition: color 0.14s ease-in, background 0.14s ease-in,
      background-color 0.14s ease-in, border-color 0.14s ease-in;

    svg {
      transition: fill 0.14s ease-in, stroke 0.14s ease-in;
    }

    &.active-scale {
      transform: scale(1.3);
    }
  }
`;

export const StyledBgButtonWrap = styled(StyledButtonWrap)`
  ${({ colorType }) =>
    colorType === "primary"
      ? CssPrimaryColor
      : colorType === "secondary"
      ? CssSecondaryColor
      : colorType === "primaryNoBg"
      ? CssPrimaryNoBgColor
      : colorType === "secondaryNoBg"
      ? CssSecondaryNoBgColor
      : colorType === "grayToPrimaryNoBg"
      ? CssGrayToPrimaryNoBgColor
      : CssCustomColor}
`;

// Line Butotn
export const CssPrimaryLineColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : theme.color.primary};
    border-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.disabled : theme.color.primary};

    &:hover,
    &.active:hover {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.primaryHover};
      border-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.primaryHover};
      opacity: ${({ isDisabled }) => !isDisabled && 0.6};
    }
  }
`;

export const CssSecondaryLineColor = css<{ isDisabled?: boolean }>`
  & > button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.font.disabled : theme.color.secondary};
    border-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.disabled : theme.color.secondary};

    &:hover,
    &.active:hover {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.font.disabled : theme.color.secondaryHover};
      border-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.color.disabled : theme.color.secondaryHover};
      opacity: ${({ isDisabled }) => !isDisabled && 0.6};
    }
  }
`;

export const StyledLineButtonWrap = styled(StyledButtonWrap)`
  & > button {
    background-color: ${({ theme }) => theme.color.button.lineBackground};
    border-width: 1px;
    border-style: solid;
  }

  ${({ colorType }) =>
    colorType === "primary" ? CssPrimaryLineColor : CssSecondaryLineColor}
`;

// Icon Button
export const StyledIconButtonWrap = styled(StyledBgButtonWrap)`
  width: auto;
  max-width: unset;
  min-width: ${({ sizeTheme }) =>
    sizeTheme.minWidth ? `${sizeTheme.minWidth}px` : undefined};

  & > button {
    padding: ${({ text, sizeTheme }) =>
      text
        ? `${sizeTheme.paddingTB}px ${sizeTheme.paddingLR * 2}px ${
            sizeTheme.paddingTB
          }px ${sizeTheme.paddingLR * 2}px`
        : `${sizeTheme.paddingTB}px ${sizeTheme.paddingLR}px`};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const StyledGroupButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & > * button {
    border-radius: 0;
  }
  & > *:first-child {
    button {
      border-radius: 2px 0 0 2px;
    }
  }

  & > *:last-child button {
    border-radius: 0 2px 2px 0;
  }

  & > * + * {
    button {
      border-left: 0;
    }
  }

  ${StyledLineButtonWrap} {
    flex: 1;
    /* min-width: 60px; */

    button {
      background-color: ${({ theme }) => theme.color.input.background};
    }

    &:hover,
    &.active,
    &.active-scale {
      button:not(:disabled) {
        color: #ffffff;

        background-color: ${({ theme }) => theme.color.primary};
        border-color: ${({ theme }) => theme.color.primary};

        opacity: 1;
      }
    }
    &.active-scale {
      transform: scale(1.3);
    }

    &:hover:not(.active, .active-scale) {
      button:not(:disabled) {
        background-color: ${({ theme }) => theme.color.primaryHover};
      }
    }
  }
`;

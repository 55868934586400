import Card from "@src/components/Card";
import { SmallCardStyle } from "@src/components/Card/sizeTheme";
import Table from "@src/components/Table";
import { ColorTypeConst } from "@src/constructs/common";
import IconButton from "@src/components/Buttons/IconButton";
import { SmallIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import React from "react";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import { getSensorLabel } from "@src/hooks/useCommonHooks";
import Pagination from "@src/components/Pagination";
import { useTranslation } from "react-i18next";

// * 테이블

const head = [
  {
    value: "deviceGroup",
    label: "group",
  },
  {
    value: "deviceId",
    label: "device",
  },
  {
    value: "structureName",
    label: "install_location",
  },
  {
    value: "sensorName",
    label: "sensor",
  },
  {
    value: "occurrenceTime",
    label: "occurrence_time",
  },
];

const customGridColumnsMinMaxArray = [
  {
    min: "100px",
    max: "1.2fr",
  },
  {
    min: "100px",
    max: "1fr",
  },
  {
    min: "85px",
    max: "1fr",
  },
  {
    min: "70px",
    max: "0.8fr",
  },
  {
    min: "170px",
    max: "2fr",
  },
];

interface EventTableProps {
  isFetching?: boolean;
  data?: FileListResponse | null;
}

export default function EventTable({ isFetching, data }: EventTableProps) {
  const { t } = useTranslation();
  const [paging, setPaging] = React.useState<PagingType>({
    page: 1,
    size: 3,
  });

  return (
    <Card
      sizeTheme={SmallCardStyle}
      title={t("event_occurs")}
      headerButtons={
        <IconButton
          sizeTheme={SmallIconButtonStyles}
          text={t("show_all")}
          colorType="primary"
          iconName="move"
          onClick={() => {}}
        />
      }
    >
      {isFetching ? (
        <DataLoadingText text={t("loading_data")} />
      ) : data && data?.fileList.length > 0 ? (
        <>
          <Table
            colorType={ColorTypeConst.Primary}
            head={head}
            body={data.fileList.map((item) => {
              return (
                <tr>
                  <td>{item.deviceGroupName}</td>
                  <td>{item.deviceId}</td>
                  <td>{item.deviceName}</td>
                  <td>{getSensorLabel(item.sensorId)}</td>
                  <td>{item.date}</td>
                </tr>
              );
            })}
            minWidth={400}
            customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
            page={paging.page}
            size={paging.size}
            isLoading={isFetching}
            isCustomPaging
          />
          <Pagination
            page={paging.page}
            size={paging.size}
            count={data.count}
            groupLength={3}
            setPaging={setPaging}
            elementSize="small"
          />
        </>
      ) : (
        <DataLoadingText text={t("no_data")} />
      )}
    </Card>
  );
}

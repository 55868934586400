import ContentTable from "@src/components/ContentTable";
import { RangeSensorIdPagingRequestParams } from "@src/fetch/fetchChart";
import useCommonHooks, { getSensorLabel } from "@src/hooks/useCommonHooks";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import { useQueryActions, usePSDDataPaging } from "@src/stores/useQueryStore";
import { StyledDeviceIdText } from "@src/styles/commonStyles";
import { getDeviceIdBySensorId } from "@src/utils/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface Props {
  params: RangeSensorIdPagingRequestParams | null;
}

export default function ContentTablePSD({ params }: Props) {
  // * Hooks
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const userInfo = useUserInfoStore();
  const paging = usePSDDataPaging();
  const { useCustomLoading } = useCommonHooks();
  const { usePSDFileListQuery } = useQueryHooks();
  const { setPSDDataPaging: setPaging } = useQueryActions();
  useCustomLoading();

  // * State
  const [rawFileUrlForChart, setFileUrlForChart] = React.useState<string>(""); // 차트 페이지 안에서 초기화되지 않도록 하기 위함

  // * Query
  const { isFetching, data } = usePSDFileListQuery(
    params as RangeSensorIdPagingRequestParams
  );

  // * Memo
  const memorizedTableTitle = React.useMemo(() => {
    return (
      <>
        <StyledDeviceIdText>
          {data && data.fileList.length > 0
            ? data.fileList[0].deviceId
            : getDeviceIdBySensorId(
                userInfo,
                searchParams.get("sensorId") ?? ""
              ) ?? "device"}
        </StyledDeviceIdText>
        <span>
          {`${
            data && data.fileList.length > 0
              ? getSensorLabel(data.fileList[0].sensorId)
              : params
              ? getSensorLabel(params.sensorId)
              : getSensorLabel(searchParams.get("sensorId") ?? "") ?? ""
          } - ${t("psd_data")} ${t("file_list")}`}
        </span>
      </>
    );
  }, [data, params, searchParams, userInfo, t]);

  return (
    <ContentTable
      tableTitle={memorizedTableTitle}
      data={data ?? null}
      fileUrlForContent={rawFileUrlForChart}
      setFileUrlForContent={setFileUrlForChart}
      dataType="psd"
      paging={paging}
      setPaging={setPaging}
      isLoading={isFetching}
    />
  );
}

/**
 * 관리용 Api
 * - 디바이스 특이사항
 * - 어드민
 * - 성적서 생성
 */
import useNetworkHooks from "@src/hooks/useNetworkHooks";

export interface CreateScoreReportRequestParams extends DeivceIdRequestParams {
  fileKeys: string;
}

export interface DeviceSpecialInfoRequestParams
  extends OptionalRangeDateRequestParams {
  type: "inspection_date" | "modified_time";
}
export interface DeviceSpecialInfoResponse {
  deviceId: string;
  deviceName: string;
  structureId: string;
  structureName: string;
  deviceGroupId: number;
  deviceGroupName: string;
  dataUid: string;
  inspectionDate: string;
  modifiedTime: string;
  description: string;
  imageUrls: string[] | null;
  fileKeys: string[] | null;
}

export interface DeviceSpecialInfoFormDataPostPut {
  deviceId: string;
  description: string;
  inspectionDate: string;
  images?: (File | string)[];
}

export interface DeviceSpecialInfoFormDataDelete {
  deviceIdList: string[];
  dataUidList: string[];
}

export interface AdminScoreReportsFailMessage {
  deviceId: string;
  status: "fail";
  detail: string;
}
export interface AdminScoreReportsSuccessMessage {
  deviceId: string;
  status: "success";
  detail: string;
  fileName: string;
  usedRawFiles: string[];
  createdTime: string;
}

export interface AdminScoreReportsResponse {
  file: string | null;
  messages: (AdminScoreReportsFailMessage | AdminScoreReportsSuccessMessage)[];
}

export default function useFetchMenagementData() {
  const { useAxios } = useNetworkHooks();

  // * admin 관리자 권한으로 디바이스 업데이트하는 Api
  const FetchAdminDeviceUpdate: () => Promise<SuccessResponse | null> =
    async () => {
      const response = (await useAxios(
        "POST",
        "/admin/deviceUpdate/v2"
      )) as SuccessResponse;

      if (response) {
        return response;
      }
      return null;
    };

  // * admin 관리자 권한으로 유저 업데이트하는 Api
  const FetchAdminUserUpdate: () => Promise<SuccessResponse | null> =
    async () => {
      const response = (await useAxios(
        "POST",
        "/admin/userUpdate/v2"
      )) as SuccessResponse;

      if (response) {
        return response;
      }
      return null;
    };

  const FetchAdminScoreReports: (params: {
    deviceIdList: string;
  }) => Promise<AdminScoreReportsResponse | null> = async (params) => {
    const response = (await useAxios(
      "POST",
      "/admin/scoreReports/v2",
      params
    )) as AdminScoreReportsResponse;
    return response ?? null;
  };

  // * 설치 관리자 권한으로 성적서 생성하는 Api
  const FetchInstallerCreateScoreReport: (
    params: CreateScoreReportRequestParams
  ) => Promise<FileResponse | null> = async (
    params: CreateScoreReportRequestParams
  ) => {
    const config = {
      responseType: "arraybuffer",
    };

    const response = (await useAxios(
      "POST",
      "/installer/createScoreReport/v2",
      params,
      config
    )) as FileResponse;

    if (response) {
      return response;
    }
    return null;
  };

  // * 디바이스 특이사항 업데이트 Post Api
  const FetchDeviceSpecialInfoUpdate: (
    data: FormData
  ) => Promise<SuccessResponse | null> = async (data: FormData) => {
    const response = await useAxios(
      "POST",
      "/admin/deviceSpecialInfo/v2",
      undefined,
      undefined,
      data,
      "multipart/form-data"
    );
    if (response) {
      return response;
    }
    return null;
  };

  const FetchDeviceSpecialInfoModify: (
    data: FormData,
    dataUid: string
  ) => Promise<SuccessResponse | null> = async (
    data: FormData,
    dataUid: string
  ) => {
    const response = await useAxios(
      "PUT",
      `/admin/deviceSpecialInfo/v2/${dataUid}`,
      undefined,
      undefined,
      data,
      "multipart/form-data"
    );
    if (response) {
      return response;
    }
    return null;
  };

  const FetchDeviceSpecialInfoGet: (
    params: DeviceSpecialInfoRequestParams
  ) => Promise<DeviceSpecialInfoResponse[] | null> = async (
    params: DeviceSpecialInfoRequestParams
  ) => {
    const response = await useAxios(
      "GET",
      "/admin/deviceSpecialInfo/v2",
      params
    );
    if (response) {
      return response;
    }
    return null;
  };

  const FetchDeviceSpecialInfoDelete: (
    data: FormData
  ) => Promise<SuccessResponse | null> = async (data: FormData) => {
    const response = await useAxios(
      "DELETE",
      "/admin/deviceSpecialInfo/v2",
      undefined,
      undefined,
      data
    );
    if (response) {
      return response;
    }
    return null;
  };

  return {
    FetchAdminDeviceUpdate,
    FetchAdminUserUpdate,
    FetchInstallerCreateScoreReport,
    FetchDeviceSpecialInfoGet,
    FetchDeviceSpecialInfoUpdate,
    FetchDeviceSpecialInfoModify,
    FetchDeviceSpecialInfoDelete,
    FetchAdminScoreReports,
  };
}

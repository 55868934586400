import Loading from "@src/components/Loadings/Loading";
import IconButton from "@src/components/Buttons/IconButton";
import {
  NormalButtonStyles,
  NormalIconButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import { FlexAlignTypeConst } from "@src/constructs/common";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";
import useFetchMenagementData, {
  AdminScoreReportsFailMessage,
} from "@src/fetch/fetchManagement";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import { StyledFlexWrap, StyledPrimaryText } from "@src/styles/commonStyles";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, {
  initialSelectOption,
} from "@src/components/Inputs/Selects/Select";
import {
  NoramlCheckboxStyle,
  NormalInputStyle,
} from "@src/components/Inputs/sizeTheme";
import Card from "@src/components/Card";
import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import Checkbox from "@src/components/Inputs/Checkbox";
import AllCheckbox from "@src/components/Inputs/AllCheckbox";
import Button from "@src/components/Buttons/Button";
import useCommonHooks from "@src/hooks/useCommonHooks";
import Table from "@src/components/Table";
import fileSaver from "file-saver";
// import testData from "@pages/AdminPage/test_data";
// import testDat2 from "@pages/AdminPage/test_data_2";
// import { theme } from "@src/styles/theme";
// import DefaultLineChart from "@src/components/Charts/DefaultLineChart";

const failScoreReportsHead = [
  {
    value: "device",
    label: "device",
  },
  {
    value: "detail",
    label: "detail",
  },
];

export default function AdminPage() {
  const { t } = useTranslation();
  const userInfo = useUserInfoStore();
  const { useUserInfoQuery, useAdminScoreReportsQuery } = useQueryHooks();
  const { FetchAdminDeviceUpdate, FetchAdminUserUpdate } =
    useFetchMenagementData();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { handleNoticeDialogOpen } = useNoticeDialog();
  const { useCustomLoading } = useCommonHooks();
  const navigator = useNavigate();

  useCustomLoading();

  // State
  // const [fftChartData, setFftChartData] = React.useState({
  //   labels: [] as number[],
  //   datasets: [
  //     {
  //       label: "FFT data",
  //       data: [] as number[],
  //       borderWidth: 2,
  //       radius: 0,
  //       borderColor: theme.color.secondary,
  //       backgroundColor: theme.color.secondary,
  //       tension: 0.2,
  //     },
  //   ],
  // });

  // * QUERY
  const { data: userInfoQuerydata, refetch: refetchUserInfo } =
    useUserInfoQuery();

  const memoizedUserInfoQuerydata = React.useMemo(
    () => userInfoQuerydata,
    [userInfoQuerydata]
  );

  const {
    mutate: adminDeviceUpdateMutate,
    isLoading: isAdminDeviceUpdateLoading,
  } = useMutation(FetchAdminDeviceUpdate, {
    onSuccess: () => {
      handleAlertDialogOpen(
        t("notification"),
        t("update_success", {
          job: t("device_information"),
        })
      );

      refetchUserInfo();
    },
    onError: (e) => {
      // 에러 메세지 확인하고 TRY_AGAIN이 포함되어 있으면 에러 다이얼로그 노출
      const msg = String(e).replaceAll(" ", "_").toUpperCase();
      if (msg.includes("TRY_AGAIN")) {
        handleAlertDialogOpen(t("try_again_title"), t("try_again_message"));
      }
    },
  });

  const { mutate: adminUserUpdateMutate, isLoading: isAdminUserUpdateLoading } =
    useMutation(FetchAdminUserUpdate, {
      onSuccess: () => {
        handleAlertDialogOpen(
          t("notification"),
          t("update_success", {
            job: t("user_information"),
          })
        );
      },
      onError: (e) => {
        // 에러 메세지 확인하고 TRY_AGAIN이 포함되어 있으면 에러 다이얼로그 노출
        const msg = String(e).replaceAll(" ", "_").toUpperCase();
        if (msg.includes("TRY_AGAIN")) {
          handleAlertDialogOpen(t("try_again_title"), t("try_again_message"));
        }
      },
    });

  // 디바이스 정보 업데이트 버튼 핸들러
  const handleDeviceUpdateButton = React.useCallback(() => {
    handleNoticeDialogOpen(
      t("agree_admin_update_title", {
        job: t("device"),
      }),
      t("agree_admin_update_message", {
        job: t("device_information"),
      })
    ).then((value) => {
      if (value) {
        adminDeviceUpdateMutate();
      }
    });
  }, [adminDeviceUpdateMutate, handleNoticeDialogOpen, t]);

  // 유저 정보 업데이트 버튼 핸들러
  const handleUserUpdateButton = React.useCallback(() => {
    handleNoticeDialogOpen(
      t("agree_admin_update_title", { job: t("user") }),
      t("agree_admin_update_message", {
        job: t("user_information"),
      })
    ).then((value) => {
      if (value) {
        adminUserUpdateMutate();
      }
    });
  }, [adminUserUpdateMutate, handleNoticeDialogOpen, t]);

  useEffect(() => {
    if (memoizedUserInfoQuerydata) {
      if (memoizedUserInfoQuerydata.menuPermission < 1000) {
        return navigator("/error/invalidAccess");
      }
    }
  }, [memoizedUserInfoQuerydata, navigator]);

  // const [dragging, setDragging] = React.useState(false);

  // const handleDragEnter = (e: React.DragEvent) => {
  //   e.preventDefault();
  //   setDragging(true);
  // };

  // const handleDragLeave = (e: React.DragEvent) => {
  //   e.preventDefault();
  //   setDragging(false);
  // };

  // const handleDragOver = (e: React.DragEvent) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e: React.DragEvent) => {
  //   e.preventDefault();
  //   setDragging(false);

  //   let failCount = 0;

  //   const checkTypeNotInList = (type: string) => {
  //     const typeList = [
  //       "application/zip",
  //       "image/jpeg",
  //       "application/macbinary",
  //       "application/json",
  //     ];
  //     return !typeList.includes(type);
  //   };

  //   const files: File[] = [];
  //   console.log(
  //     "dataTransfer.items",
  //     e.dataTransfer.items,
  //     e.dataTransfer.items.length
  //   );
  //   for (let i = 0; i < e.dataTransfer.items.length; i++) {
  //     if (e.dataTransfer.items[i].kind === "file") {
  //       const file = e.dataTransfer.items[i].getAsFile() as File;
  //       const fileType = file.type;
  //       const fileName = file.name;

  //       if (!fileName.includes("_ae.") || checkTypeNotInList(fileType)) {
  //         failCount++;
  //         continue;
  //       }

  //       const [fileDate, fileSensor] = fileName.split("_ae.");

  //       // 날짜 규칙 , 센서명 규칙과 일치하는지 확인
  //       if (
  //         parse(fileDate, "yyyyMMdd_HHmmss", new Date())
  //           .toString()
  //           .toLowerCase()
  //           .includes("invalid") ||
  //         !fileSensor.includes("-") ||
  //         fileSensor.split("-")[1].split("_").length !== 4
  //       ) {
  //         failCount++;
  //         continue;
  //       }

  //       // 202311071450_ae.T4845a-AC_S1M_01_Z.zip
  //       // 20230918_160333_ae.inob0296b-CM_P2_01_X.jpg

  //       files.push(file);
  //     }
  //   }

  //   if (files.length > 0) {
  //     setFileList((prev) => {
  //       const newData = [...prev, ...files];
  //       // 중복 제거된 파일 리스트
  //       const uniqueFileList = removeDuplicateFiles(newData);
  //       return uniqueFileList;
  //     });
  //     // handle dropped files here
  //   }
  //   if (failCount > 0) {
  //     handleAlertDialogOpen(
  //       "알림",
  //       "파일 형식에 일치하지 않는 파일은 업로드에 실패했습니다."
  //     );
  //   }
  // };

  // // 중복 제거 함수
  // const removeDuplicateFiles = (fileList: File[]) => {
  //   // fileList을 reduce 메서드를 사용하여 처리합니다. 초기 값은 빈 배열([])입니다.
  //   const uniqueFiles = fileList.reduce((unique: File[], item: File) => {
  //     // unique 배열에 이미 같은 이름과 크기를 가진 파일이 있는지 확인합니다.
  //     // some 메서드는 조건을 만족하는 요소가 하나 이상 있는지 확인합니다.
  //     return unique.some(
  //       (file) =>
  //         file.name === item.name &&
  //         file.size === item.size &&
  //         file.type === item.type
  //     )
  //       ? unique // 이미 있는 파일이면 중복이므로 unique 배열을 그대로 반환합니다.
  //       : [...unique, item]; // 새로운 파일이면 unique 배열에 해당 파일을 추가하여 반환합니다.
  //   }, []);
  //   // 중복이 제거된 파일 리스트를 반환합니다.
  //   return uniqueFiles;
  // };

  // const [fileList, setFileList] = React.useState<File[]>([]);

  // * 성적서 생성
  const [selectedDeviceGroup, setSelectedDeviceGroup] =
    React.useState<SelectOption>(initialSelectOption);
  const [isSelectedDevicies, setIsSelectedDevicies] = React.useState<boolean[]>(
    []
  );
  const [selectedDevices, setSelectedDevices] = React.useState<string>("");
  const [isScoreReportFilesDownload, setIsScoreReportFilesDownload] =
    React.useState<boolean>(false);

  const { isFetching: isFetchingAdminScoreReport, data: adminScoreReportData } =
    useAdminScoreReportsQuery({
      deviceIdList: selectedDevices ?? "",
    });

  // 디바이스 그룹 리스트
  const memorizedDeviceGroupOptions = React.useMemo(() => {
    return (
      userInfo.deviceGroupList.map((group) => ({
        value: `${group.device_group_id}`,
        label: group.device_group_name,
      })) ?? undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.deviceGroupList]);

  // 디바이스 리스트
  const memorizedDeviceList = React.useMemo(() => {
    if (selectedDeviceGroup.value) {
      const list =
        userInfo.deviceList
          .filter(
            (item) => item.device_group_id === Number(selectedDeviceGroup.value)
          )
          .map((item) => ({
            deviceId: item.device_id,
            deviceName: item.device_name,
          })) ?? null;

      return list;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceGroup.value]);

  useEffect(() => {
    if (memorizedDeviceList && memorizedDeviceList.length > 0) {
      setIsSelectedDevicies(new Array(memorizedDeviceList?.length).fill(false));
    }
  }, [memorizedDeviceList]);

  const memorizedFailList = React.useMemo(() => {
    if (adminScoreReportData) {
      return adminScoreReportData.messages.filter(
        (item) => item.status === "fail"
      ) as AdminScoreReportsFailMessage[];
    }
    return null;
  }, [adminScoreReportData]);

  // 관리자 성적서가 api가 fetching 상태일 때 isScoreReportFilesDownload true로 수정
  useEffect(() => {
    if (isFetchingAdminScoreReport) {
      setIsScoreReportFilesDownload(true);
    }
  }, [isFetchingAdminScoreReport]);

  // 파일 저장
  useEffect(() => {
    if (adminScoreReportData) {
      if (adminScoreReportData.file !== null) {
        // Base64 문자열을 디코딩하여 Blob 객체 생성
        const byteCharacters = atob(adminScoreReportData.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/zip" });
        fileSaver.saveAs(blob, "score report.zip");
      }
      setIsScoreReportFilesDownload(false);
    }
  }, [adminScoreReportData]);

  // const fftTest = () => {
  //   const data = testDat2;
  //   const FFT = require("fft.js");

  //   // 샘플 데이터를 생성합니다.
  //   const sampleRate = 102.4; // 샘플링 주파수 (Hz)
  //   const duration = 10; // 신호 길이 (초)
  //   // const samples =
  //   const samples = sampleRate * duration;

  //   // 신호 배열을 생성합니다.
  //   const signal = new Float32Array(samples);

  //   // 로우 데이터를 signal 배열에 복사합니다.
  //   for (let i = 0; i < samples; i++) {
  //     if (i < data.data.length) {
  //       signal[i] = data.data[i];
  //     } else {
  //       signal[i] = 0;
  //     }
  //   }
  //   // FFT 계산
  //   const fft = new FFT(samples);
  //   const out = fft.createComplexArray();

  //   fft.realTransform(out, signal);
  //   fft.completeSpectrum(out);

  //   // FFT 결과를 처리합니다.
  //   const amplitudes = new Float32Array(samples / 2);
  //   for (let i = 0; i < samples / 2; i++) {
  //     const re = out[2 * i];
  //     const im = out[2 * i + 1];
  //     amplitudes[i] = Math.sqrt(re * re + im * im) / samples;
  //   }

  //   // 주파수 벡터 생성
  //   const frequencies = new Float32Array(samples / 2);
  //   for (let i = 0; i < samples / 2; i++) {
  //     frequencies[i] = (i * sampleRate) / samples;
  //   }

  //   setFftChartData((prev) => {
  //     const newData = { ...prev };
  //     newData.labels = Array.from(frequencies);
  //     newData.datasets[0].data = Array.from(amplitudes);

  //     newData.labels = newData.labels.slice(1);
  //     newData.datasets[0].data = newData.datasets[0].data.slice(1);

  //     const half = Math.ceil(newData.labels.length / 2);
  //     newData.labels = newData.labels.slice(0, half);
  //     newData.datasets[0].data = newData.datasets[0].data.slice(0, half);

  //     return newData;
  //   });
  // };

  // useEffect(() => {
  //   fftTest();
  // }, []);

  return (
    <>
      {isAdminDeviceUpdateLoading ||
      isAdminUserUpdateLoading ||
      isFetchingAdminScoreReport ||
      isScoreReportFilesDownload ? (
        <Loading />
      ) : null}
      <StyledFlexWrap
        flexDirection="column"
        align={FlexAlignTypeConst.FlexStart}
        gap={60}
      >
        <StyledFlexWrap align={FlexAlignTypeConst.FlexStart} gap={10}>
          <IconButton
            sizeTheme={NormalIconButtonStyles}
            iconName="deviceInfo"
            colorType="primary"
            text={t("update_device_info")}
            onClick={handleDeviceUpdateButton}
          />
          <IconButton
            sizeTheme={NormalIconButtonStyles}
            iconName="user"
            colorType="secondary"
            text={t("update_user_info")}
            onClick={handleUserUpdateButton}
          />
        </StyledFlexWrap>
        <div
          style={{
            width: "100%",
          }}
        >
          <Card sizeTheme={NormalCardStyle} title="성적서 한번에 생성">
            <StyledFlexWrap flexDirection="column" rowGap={40}>
              <StyledFlexWrap flexDirection="column">
                <Select
                  sizeTheme={NormalInputStyle}
                  name="deviceGroupSelect"
                  options={memorizedDeviceGroupOptions}
                  value={selectedDeviceGroup}
                  event={(value) => {
                    setSelectedDeviceGroup(value);
                  }}
                  showLabel
                  labelText={t("group")}
                  fixWidth={160}
                  labelFixWidth={60}
                />
                {memorizedDeviceList && memorizedDeviceList.length > 0 ? (
                  <StyledFlexWrap gap={12}>
                    <StyledPrimaryText
                      style={{
                        flex: "0 0 auto",
                        width: 60,
                      }}
                    >
                      {t("device")}
                    </StyledPrimaryText>
                    <StyledFlexWrap flexDirection="column" gap={8}>
                      <AllCheckbox
                        sizeTheme={NoramlCheckboxStyle}
                        fixWidth={40}
                        name="score-report"
                        label="All"
                        colorType="primary"
                        isCheckedArray={isSelectedDevicies}
                        handler={(checked) => {
                          setIsSelectedDevicies((prev) => {
                            const newData = [...prev];
                            newData.map((_, i) => (newData[i] = checked));
                            return newData;
                          });
                        }}
                      />
                      <StyledFlexWrap
                        align={FlexAlignTypeConst.FlexStart}
                        columnGap={20}
                        style={{
                          flexWrap: "wrap",
                        }}
                      >
                        {memorizedDeviceList?.map((item, index) => (
                          <Checkbox
                            sizeTheme={NoramlCheckboxStyle}
                            colorType="primary"
                            name="score-report"
                            label={`${item.deviceId}`}
                            index={index}
                            isChecked={isSelectedDevicies[index]}
                            handler={() => {
                              setIsSelectedDevicies((prev: boolean[]) => {
                                const newData = [...prev];
                                newData[index] = !newData[index];
                                return newData;
                              });
                            }}
                            fixWidth={70}
                          />
                        ))}
                      </StyledFlexWrap>
                    </StyledFlexWrap>
                  </StyledFlexWrap>
                ) : null}
                <div
                  style={{
                    alignSelf: "flex-end",
                  }}
                >
                  <Button
                    sizeTheme={NormalButtonStyles}
                    colorType="primary"
                    text="성적서 한번에 생성"
                    onClick={() => {
                      const indexes = isSelectedDevicies.reduce(
                        (acc, item, index) => {
                          if (item) {
                            acc.push(index);
                          }
                          return acc;
                        },
                        [] as number[]
                      );
                      const deviceIdList = memorizedDeviceList
                        ?.filter((_, index) => indexes.includes(index))
                        .map((item) => item.deviceId);
                      setSelectedDevices(deviceIdList?.join(",") ?? "");
                    }}
                    isDisabled={
                      selectedDeviceGroup.value === "" ||
                      isSelectedDevicies.filter((item) => item).length === 0
                    }
                  />
                </div>
              </StyledFlexWrap>
              <StyledFlexWrap flexDirection="column" gap={20}>
                {!isScoreReportFilesDownload && adminScoreReportData ? (
                  <StyledFlexWrap gap={12}>
                    <span>총 개수: {adminScoreReportData.messages.length}</span>
                    <span>
                      {`성공: ${
                        adminScoreReportData.messages.filter(
                          (item) => item.status === "success"
                        ).length
                      }`}
                    </span>
                    <span>
                      {`실패: ${
                        adminScoreReportData.messages.filter(
                          (item) => item.status === "fail"
                        ).length
                      }`}
                    </span>
                  </StyledFlexWrap>
                ) : null}
                {!isScoreReportFilesDownload &&
                memorizedFailList &&
                memorizedFailList.length > 0 ? (
                  <StyledFlexWrap flexDirection="column">
                    <StyledPrimaryText size="mdlg">
                      성적서 생성 실패 디바이스 리스트
                    </StyledPrimaryText>
                    <Table
                      colorType="primary"
                      head={failScoreReportsHead}
                      body={memorizedFailList.map((item) => (
                        <tr>
                          <td>{item.deviceId}</td>
                          <td>{t(item.detail)}</td>
                        </tr>
                      ))}
                    />
                  </StyledFlexWrap>
                ) : null}
              </StyledFlexWrap>
            </StyledFlexWrap>
          </Card>
        </div>

        {/* FFT 데이터 검증 
        <StyledFlexWrap
          gap={30}
          style={{
            width: "100%",
            height: "clamp(100px, 44%, 600px)",
          }}
        >
          <DefaultLineChart
            isXScaleTime={false}
            timeUnit={undefined}
            chartData={fftChartData}
            chartTitle="i000001/AC_BS7T9_01_Y/202407031200.json FFT Data"
            pngFileTitle="FFT Data"
            viewLarger={true}
            isInCard
            // showPngDownloadButton
            // showZoomButton
            isContentTableChart
          />
      </StyledFlexWrap>
      <div
        className={dragging ? "dragging" : ""}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          width: "100%",
          height: 300,
          border: "1px solid red",
        }}
      >
        <p>Drag and drop files here</p>
      </div> */}
      </StyledFlexWrap>
    </>
  );
}

import Icon from "@src/components/Icon";
import Select from "@src/components/Inputs/Selects/Select";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { StyledThemeSwitchWrap } from "@src/components/Sidebar/styles";
import ThemeTooggle from "@src/components/ThemeToggle";
import { BrowserSizeConst, ThemeConst } from "@src/constructs/common";
import useI18nHooks from "@src/hooks/useI18nHooks";
import { useBrowserSizeStore, useThemeStore } from "@src/stores/useCommonStore";
import { Div } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import React, { useEffect } from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface AuthLayoutProps {
  children: ReactNode;
}

const StyledAppContainer = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-height: 100svh;
  background-color: ${({ theme }) => theme.color.auth.background};
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin: 0 auto;
  padding: 80px;
  width: 100%;
  height: 100%;

  ${mediaQuery.tablet} {
    padding: 0;
  }
`;

// Wrapper Set
const StyledCardWrapper = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  background: ${({ theme }) => theme.color.auth.imageBackground};
  box-shadow: ${({ theme }) => theme.color.card.boxShadow};
  overflow: hidden;

  @media screen and (min-width: 1921px) {
    max-width: unset;
    width: 75%;
  }

  ${mediaQuery.tablet} {
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 15px;

    max-height: unset;
    height: 100%;
  }

  ${mediaQuery.mobile} {
    padding: 0;
  }
`;

const StyledImageWrap = styled(Div)`
  flex: 1;
  padding: 20px;
  overflow: hidden;
  position: relative;

  ${mediaQuery.tablet} {
    flex: 0 0 auto;

    width: 100%;
    height: auto;

    position: absolute;
    top: 0;
    left: 0;
  }

  ${mediaQuery.mobile} {
    display: flex;
    align-items: flex-end;
    height: 20%;
    position: static;
  }

  ${mediaQuery.smallMobile} {
    padding: 15px;
  }
`;

const StyledTextWrap = styled(Div)`
  position: relative;
  top: 80px;
  left: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  h3 {
    color: white;
    font-size: ${({ theme }) => theme.size.common.fontSize.lg};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.sm};
    word-break: keep-all;
    width: clamp(200px, 100%, 410px);
    line-height: 1.1rem;
    letter-spacing: -0.5px;

    ${mediaQuery.smallMobile} {
      font-size: ${({ theme }) => theme.size.common.fontSize.md};
    }
  }

  ${mediaQuery.tablet} {
    position: static;
    gap: 8px;

    svg {
      height: 24px;
    }
  }
`;

const StyledFormWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  background: ${({ theme }) => theme.color.auth.card};
  box-shadow: ${({ theme }) => theme.color.card.boxShadow};
  border-radius: 16px 0 0 16px;
  overflow-y: auto;

  input {
    width: 100%;
    height: 40px;
  }

  ${mediaQuery.laptop} {
    padding: 20px;
    max-width: 300px;
  }

  ${mediaQuery.tablet} {
    flex: 1;

    padding: 20px;

    width: clamp(280px, 100%, 480px);
    height: 100%;
    max-width: unset;
    max-height: 70svh;
    border-radius: 16px;

    position: relative;
  }

  ${mediaQuery.mobile} {
    padding: 40px 20px 20px;
    max-height: unset;
    border-radius: 16px 16px 0 0;
  }

  ${mediaQuery.smallMobile} {
    padding: 20px 15px 15px;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: -1%;
  mix-blend-mode: overlay;
  right: -420px;

  ${mediaQuery.tablet} {
    mix-blend-mode: color-dodge;
    opacity: 0.6;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
  }
`;

const StyledTranslationSelectWrap = styled(Div)`
  position: fixed;
  top: 10px;
  right: 10px;
`;

export default function AuthLayout({ children }: AuthLayoutProps) {
  const { t } = useTranslation();
  const browserSize = useBrowserSizeStore();
  const themeType = useThemeStore();
  const { selectedLanguage, setSelectedLanguage, handleChangeLanguage } =
    useI18nHooks();

  const [beforeThemeType, setBeforeThemeType] = React.useState<ThemeType>(
    themeType === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
  );

  // themeType이 변경되면 테마 트렌지션 클래스 추가했다가 1초 뒤에 삭제
  useEffect(() => {
    if (themeType === beforeThemeType) {
      setTimeout(() => {
        setBeforeThemeType((prev) =>
          prev === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
        );
      }, 1000);
    }
  }, [themeType, beforeThemeType]);

  return (
    <StyledAppContainer
      className={themeType === beforeThemeType ? "theme-changing" : undefined}
    >
      <StyledMain>
        <StyledTranslationSelectWrap>
          <Select
            sizeTheme={NormalInputStyle}
            name="lang"
            placeholder="language"
            value={selectedLanguage}
            options={[
              { value: "ko", label: t("ko") },
              { value: "en", label: t("en") },
            ]}
            event={(value) => {
              handleChangeLanguage(value.value);
              setSelectedLanguage({
                value: value.value,
                label: t(value.value),
              });
            }}
            fixWidth={120}
            showAddon
            addonName="language"
          />
        </StyledTranslationSelectWrap>
        <StyledCardWrapper>
          {browserSize && browserSize <= BrowserSizeConst.Tablet ? (
            <StyledImage
              src="/images/auth-bg.png"
              alt="gb auth background image"
            />
          ) : null}
          <StyledImageWrap>
            {browserSize && browserSize > BrowserSizeConst.Tablet ? (
              <StyledImage
                src="/images/auth-bg.png"
                alt="gb auth background image"
              />
            ) : null}
            <StyledTextWrap>
              <Icon iconName="gbWebLogoTextWhite" iconHeight={32} />
              <h3>{t("inoon_gb_information")}</h3>
            </StyledTextWrap>
          </StyledImageWrap>
          <StyledFormWrap>
            {children}
            <StyledThemeSwitchWrap
              style={{
                alignSelf: "flex-end",
              }}
            >
              <ThemeTooggle />
            </StyledThemeSwitchWrap>
          </StyledFormWrap>
        </StyledCardWrapper>
      </StyledMain>
    </StyledAppContainer>
  );
}

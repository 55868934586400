import React, { useEffect } from "react";
import { theme } from "@src/styles/theme";
import useCommonHooks, {
  getDaysBetweenDates,
  convertFileKeyToDateStr,
  getPngFileTitle,
  getSensorLabel,
  isDataType,
  areEqual,
} from "@src/hooks/useCommonHooks";
import { ChartDataset } from "chart.js";
import { differenceInSeconds, format } from "date-fns";
import DefaultLineChart from "@src/components/Charts/DefaultLineChart";
import {
  IPSDFileData,
  IRawFileData,
  S3JsonDataResponse,
} from "@src/fetch/fetchChart";
import { StyledDeviceIdText } from "@src/styles/commonStyles";
import { psdFreqStep } from "@src/constructs/common";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { ReadFileS3RequestParams } from "@src/fetch/fetchCommon";

interface RenderRawDataLineChartProps {
  fileUrl: string;
  setFileUrl: React.Dispatch<React.SetStateAction<string>>;
  dataType: S3BucketType;
}

// For Fake Loading!!!!!!!!!
export default function RenderChart({
  fileUrl,
  setFileUrl,
  dataType,
}: RenderRawDataLineChartProps) {
  // * Hook
  const { useCustomLoading } = useCommonHooks();
  const { useReadS3FileQuery } = useQueryHooks();
  const [fileDataParams, setFileDataParams] =
    React.useState<ReadFileS3RequestParams>({
      fileKey: "",
    });

  // use Custom Loading
  useCustomLoading();

  // State
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const beforeFilreUrl = React.useRef<string>("");
  const [chartData, setChartData] =
    React.useState<{
      labels: string[] | number[];
      datasets: ChartDataset[];
      pngFileTitle: string;
      chartTitle: NonNullable<React.ReactNode>;
      peakFreqIndex?: number[];
      peakOrder?: number[];
    } | null>(null);

  // * Query

  // fileData는 이미지가 아닐 경우에만 불러오도록 한다.
  const { data, isError, isFetched } = useReadS3FileQuery(
    fileDataParams,
    dataType
  );

  useEffect(() => {
    if (data && isFetched && isDataType<S3JsonDataResponse>(data, "fileData")) {
      // data의 타입이 로우 데이터인지 확인
      if (isDataType<IRawFileData>(data.fileData.file, "startTime")) {
        const newData = data.fileData.file;
        const startDate = new Date(
          format(new Date(newData.startTime), "yyyy-MM-dd HH:mm:ss.SSS")
        );
        const endDate = new Date(
          format(new Date(newData.endTime), "yyyy-MM-dd HH:mm:ss.SSS")
        );
        const startEndLabels = getDaysBetweenDates(
          startDate,
          endDate,
          Number(newData.count)
        );

        setChartData(() => ({
          labels: startEndLabels,
          datasets: [
            {
              label: "Raw data",
              data: newData.data,
              borderWidth: 1,
              radius: 0,
              borderColor: theme.color.primary,
              backgroundColor: theme.color.primary,
            },
          ],
          pngFileTitle: getPngFileTitle(
            data.deviceId,
            "raw",
            getSensorLabel(data.sensorId),
            fileUrl
          ),
          chartTitle: (
            <>
              <StyledDeviceIdText>{data.deviceId}</StyledDeviceIdText>
              <span>
                {`${getSensorLabel(data.sensorId)} - ${convertFileKeyToDateStr(
                  fileUrl
                )} Chart`}
              </span>
            </>
          ),
        }));
      } else if (isDataType<IPSDFileData>(data.fileData.file, "peakData")) {
        const myData = data.fileData.file;
        // data의 타입이 fft 데이터인지 확인

        const freq = new Array(myData.count)
          .fill(false)
          .map((_, index) => index * psdFreqStep);

        const freqIndex = myData.peakFreq
          .map((item) => {
            const foundIndex = freq.findIndex((f) => areEqual(f, item, 7)); // 7자리까지 비교
            return foundIndex;
          })
          .filter((item) => item !== -1);

        const peakFreqIndex = myData.data
          .map((_, index) => index) // 모든 인덱스를 가져옵니다.
          .filter((index) => freqIndex.includes(index));

        setChartData(() => ({
          labels: freq,
          datasets: [
            {
              label: "PSD Data",
              data: myData.data.map((item) => item),
              borderWidth: 2,
              hoverBorderWidth: 2,
              radius: myData.data.map((_item, index) =>
                freqIndex.includes(index) ? 6 : 0
              ),
              hoverRadius: myData.data.map((_item, index) =>
                freqIndex.includes(index) ? 6 : 0
              ),
              borderColor: theme.color.primary,
              pointBorderColor: theme.color.invalid,
              backgroundColor: "transparent",
              borderCapStyle: "round",
              hoverBackgroundColor: theme.color.invalid,
              pointStyle: "circle",
              pointBorderWidth: 2,
              pointHoverBorderWidth: 2.5,
              pointHoverBorderColor: theme.color.secondaryHover,
            },
          ],
          pngFileTitle: getPngFileTitle(
            data.deviceId,
            "PSD",
            getSensorLabel(data.sensorId),
            fileUrl
          ),
          chartTitle: (
            <>
              <StyledDeviceIdText>{data.deviceId}</StyledDeviceIdText>
              <span>
                {`${getSensorLabel(data.sensorId)} - ${convertFileKeyToDateStr(
                  fileUrl
                )} PSD Chart`}
              </span>
            </>
          ),
          peakFreqIndex: peakFreqIndex,
          peakOrder: myData.peak_order,
        }));
      }
      setIsLoading(false);
    } else if (!data && isFetched) {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);

  // 데이터 초기화
  useEffect(() => {
    if (fileUrl && fileUrl !== beforeFilreUrl.current) {
      beforeFilreUrl.current = fileUrl;
      setFileDataParams({
        fileKey: fileUrl,
      });
      setIsLoading(true);
      setChartData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl]);

  // error 발생한 경우 chartData, fileUrl 삭제
  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      setChartData(null);
      setFileUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <DefaultLineChart
      isXScaleTime={
        isDataType<S3JsonDataResponse>(data, "fileData") &&
        isDataType<IPSDFileData>(data.fileData.file, "peakData")
          ? false
          : true
      }
      timeUnit={
        isDataType<S3JsonDataResponse>(data, "fileData") &&
        isDataType<IPSDFileData>(data.fileData.file, "peakData")
          ? undefined
          : isDataType<S3JsonDataResponse>(data, "fileData") &&
            isDataType<IRawFileData>(data.fileData.file, "startTime") &&
            differenceInSeconds(
              new Date(data.fileData.file.endTime),
              new Date(data.fileData.file.startTime)
            ) <= 5
          ? "millisecond"
          : isDataType<S3JsonDataResponse>(data, "fileData") &&
            isDataType<IRawFileData>(data.fileData.file, "startTime") &&
            differenceInSeconds(
              new Date(data.fileData.file.endTime),
              new Date(data.fileData.file.startTime)
            ) <= 60
          ? "second"
          : "minute"
      }
      chartTitle={chartData?.chartTitle ?? ""}
      pngFileTitle={chartData?.pngFileTitle ?? ""}
      chartData={{
        labels: chartData?.labels ?? [],
        datasets: chartData?.datasets ?? [],
      }}
      isFetching={isLoading}
      isInCard
      showCloseBtn
      handleClose={() => setFileUrl("")}
      showPngDownloadButton
      showZoomButton
      // yScalePoint={isDataType<IPSDFileData>(data, "peakData") ? 6 : undefined}
      isContentTableChart
      peakFreqIndex={chartData?.peakFreqIndex}
      peakOrder={chartData?.peakOrder}
    />
  );
}

export interface MenuListType {
  title: string;
  menu: {
    path: string;
    activePathStr: string;
    name: string;
    iconName: string;
    menuPermission: number;
    paramsKeys?: string[];
    customParmas?: { [key: string]: string };
    initDate?: "all" | "daily" | "weekly" | "monthly";
    sensorTypeFilter?: string[];
    notice?: {
      title: string;
      message: string | string[];
    };
  }[];
  categoryPermission: number;
}

/**
 * 사이드바 메뉴 리스트 데이터
 * - title: 메뉴 상위 타이틀 (string) 
 * - menu: 메뉴 데이터 (object)
 *    - path: 전체 경로 (string) 
 *    - activePathStr : 전체 경로에서 active 표시할 일부 경로 (string) 
 *    - name: 메뉴 타이틀 (string) 
 *    - iconName: 아이콘 SVG 타이틀 (string) 
 *    - menuPermission: (number)
 *    - paramsKeys: 해당 페이지에서 사용하는 공용 url 파라미터 키 리스트 (?string[])
 *    - customParmas: 특정 페이지에서만 사용하는 url 파라미터 키 리스트 (?string[])
 *    - initDate: 기간으로 검색하는 페이지일 경우 기본 값 (?all | daily | weekly | monthly)
      - notice: 해당 페이지로 이동 후 페이지 타이틀 좌측에 표시할 안내문 (?object) 
        - title: (string) 
        - message: (string[]) 
 */
export const MENU_LIST: MenuListType[] = [
  {
    title: "",
    menu: [
      {
        path: "/home",
        activePathStr: "home",
        name: "dashboard",
        iconName: "dashboard",
        menuPermission: 0,
      },
    ],
    categoryPermission: 0,
  },
  {
    title: "Data",
    menu: [
      {
        path: `/dataByDeviceId/dmeasure`,
        activePathStr: "dataByDeviceId",
        name: "device_id_data",
        iconName: "chart",
        menuPermission: 0,
        paramsKeys: ["startDate", "endDate", "deviceId"],
      },
      {
        path: "/realtime",
        activePathStr: "realtime",
        name: "realtime_data",
        iconName: "realtimeData",
        menuPermission: 0,
        paramsKeys: ["sensorId"],
        notice: {
          title: "realtime_data_notice",
          message: [
            "실시간 데이터를 보고싶은 센서를 선택후 조회를 눌러주세요.",
            "실시간 데이터는 최대 30분 재생됩니다.",
          ],
        },
      },
      {
        path: "/psd",
        activePathStr: "psd",
        name: "psd_tention_data",
        iconName: "psd",
        paramsKeys: ["startDate", "endDate", "sensorId"],
        initDate: "weekly",
        menuPermission: 0,
      },
      {
        path: "/event",
        activePathStr: "event",
        name: "event_data",
        iconName: "event",
        paramsKeys: ["startDate", "endDate", "sensorId"],
        initDate: "monthly",
        menuPermission: 0,
      },
      // {
      //   path: "/map",
      //   name: "설치 현황",
      //   iconName: "map",
      // menuPermission: 0
      // },
      // {
      //   path: "/deviceInfo",
      //   name: "디바이스 정보",
      //   iconName: "deviceInfo",
      // menuPermission: 0
      // },
    ],
    categoryPermission: 0,
  },
  {
    title: "Management",
    menu: [
      // {
      //   path: "/userSetting",
      //   activePathStr: "userSetting",
      //   name: "사용자 설정",
      //   iconName: "user",
      // },
      // {
      //   path: "/groupSetting",
      //   activePathStr: "groupSetting",
      //   name: "그룹 설정",
      //   iconName: "groupSetting",
      // },
      {
        path: "/installationManagement",
        activePathStr: "installationManagement",
        name: "installation_management",
        iconName: "setting",
        menuPermission: 100,
      },
      {
        path: "/scoreReport",
        activePathStr: "scoreReport",
        name: "create_score_report",
        iconName: "scoreReport",
        paramsKeys: ["deviceId"],
        menuPermission: 100,
      },
    ],
    categoryPermission: 100,
  },
  {
    title: "Ino-on",
    menu: [
      {
        path: "/deviceSpecialInfo",
        activePathStr: "deviceSpecialInfo",
        name: "device_special_info",
        iconName: "deviceInfo",
        paramsKeys: ["startDate", "endDate"],
        customParmas: { type: "inspection_date" },
        initDate: "weekly",
        menuPermission: 1000,
      },
      {
        path: "/admin",
        activePathStr: "admin",
        name: "inoon_admin",
        iconName: "inoOnDefault",
        menuPermission: 1000,
      },
    ],
    categoryPermission: 1000,
  },
];

export const SIDEBAR_WIDTH = {
  ZERO: 0,
  EXPANTION: 260,
  SUMMARY: 70,
} as const;

export const ELEMENT_Z_INDEX = {
  SIDEBAR: 300,
  HEADER: 200,
  DIMFULLSCREEN: 1310,
  DIMFULLSCREENRANDOMICON: 1320,
  FLOATEVENT: 1100,
  DIALOG: 1300,
  NOTICEDIALOG: 1350,
  ALERTDIALOG: 1400,
  INFOFLOATBOX: 30,
} as const;

// for Keydown Event (useCommonHooks)
export const KEY_TYPE = {
  ENTER: "enter",
  DOWN: "down, arrowdown", // down=  IE/Edge
  UP: "up, arrowup", // up=  IE/Edge
  left: "left, arrowleft", // left=  IE/Edge
  right: "right, arrowright", // right=  IE/Edge
  esc: "esc",
  escape: "escape",
} as const;

// for Searchbar
export const DateTypeConst = {
  Range: "range",
  Single: "single",
} as const;

export const SearchTypeConst = {
  Device: "device",
  DeviceSensor: "deviceSensor",
} as const;

export const DeviceSearchTypeConst = {
  ID: "id",
  Alias: "alias",
} as const;

// for StyledFlexWrap
export const FlexAlignTypeConst = {
  Center: "center",
  FlexEnd: "flex-end",
  FlexStart: "flex-start",
  SpaceBetween: "space-between",
} as const;

export const ColorTypeConst = {
  Primary: "primary",
  Secondary: "secondary",
} as const;

export const BrowserSizeConst = {
  Mobile: 480,
  Tablet: 768,
  Laptop: 1024,
  DesktopSmall: 1400,
  Desktop: 1920,
} as const;

export const RequestMethodConst = {
  Get: "get",
  Post: "post",
  Put: "put",
  Delete: "delete",
} as const;

export const initialPaging: PagingType = {
  size: 20,
  page: 1,
} as const;

export const ThemeConst = {
  Light: "light",
  Dark: "dark",
} as const;

export const DataSearchTypeConst = {
  DeviceGroup: "deviceGroup",
  DeviceId: "deviceId",
  DeviceName: "deviceName",
  StructureId: "structureId",
  StructureName: "structureName",
  SensorId: "sensorId",
} as const;

export const DataAlignTypeConst = {
  DateAsc: "date_asc",
  DateDesc: "date_desc",
  AeAsc: "ae_asc",
  AeDesc: "ae_desc",
} as const;

export const S3DataTypeConst = {
  Raw: "raw",
  Event: "event",
  Image: "image",
};

export const gradientArray = [
  ["#82b0ecb0", "#93aee700"],
  ["#ec92ad89", "#ec92ad00"],
  ["#a97cdc68", "#a97cdc00"],
  ["#75ca7d62", "#75ca7d00"],
  ["#f6a14b68", "#f6a14b00"],
];

export const lineColorArray = [
  "#93ade7",
  "#ec92ad",
  "#a97cdc",
  "#75ca7e",
  "#f6a04b",
];

export const positionColors = [
  [
    {
      position: 0,
      color: gradientArray[0][0],
    },
    {
      position: 1,
      color: gradientArray[0][1],
    },
  ],
  [
    {
      position: 0,
      color: gradientArray[1][0],
    },
    {
      position: 1,
      color: gradientArray[1][1],
    },
  ],
  [
    {
      position: 0,
      color: gradientArray[2][0],
    },
    {
      position: 1,
      color: gradientArray[2][1],
    },
  ],
  [
    {
      position: 0,
      color: gradientArray[3][0],
    },
    {
      position: 1,
      color: gradientArray[3][1],
    },
  ],
  [
    {
      position: 0,
      color: gradientArray[4][0],
    },
    {
      position: 1,
      color: gradientArray[4][1],
    },
  ],
];

// export const psdFreqStep = 0.048828125 as const;
export const psdFreqStep = 0.0244140625 as const;

export const commonChartStyleOptions = {
  common: {
    borderWidth: 2,
    hoverRadius: 4,
    hoverBorderWidth: 4,
  },
  minMax: {
    pointBorderWidth: 0.5,
    pointStyle: "circle",
  },
  // standardMinMax: { //TODO: 상/하한 추가되면 사용
  //   pointRadius: 0,
  //   pointStyle: "line",
  // },
  gradientCommon: {
    borderWidth: 2,
    hoverRadius: 4,
    hoverBorderWidth: 4,
    tension: 0.4,
    pointBorderWidth: 0,
    radius: 0,
    hidden: false,
    fill: true,
  },
};

import React, { useEffect } from "react";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { ThemeProvider } from "styled-components";
import { darkTheme, theme } from "./styles/theme";
import { useThemeStore } from "./stores/useCommonStore";
import { ThemeConst } from "./constructs/common";

interface PortalProps {
  rootId: string;
  children: ReactNode;
  childrenObj?: ReactNode;
}

export default function Portal({ rootId, children, childrenObj }: PortalProps) {
  const themeType = useThemeStore();
  const target = React.useRef<HTMLElement | null>(
    document.getElementById(rootId)
  );
  const [init, setInit] = React.useState<boolean>(false);

  useEffect(() => {
    if (document.getElementById(rootId)) {
      target.current = document.getElementById(rootId);
    } else {
      target.current = document.createElement("div");
      target.current.setAttribute("id", rootId);
      document.body.appendChild(target.current);
    }
    setInit(true);

    // return () => {
    //   window.requestAnimationFrame(() => {
    //     if (target.current && target.current.childNodes.length === 0) {
    //       target.current.remove();
    //       target.current = null;
    //     }
    //   });
    // };
  }, [rootId]);

  return init
    ? createPortal(
        <ThemeProvider
          theme={themeType === ThemeConst.Light ? theme : darkTheme}
        >
          {children}
        </ThemeProvider>,
        target.current as HTMLElement
      )
    : null;
}

import React, { useEffect } from "react";
import { GroupBase, InputActionMeta, SingleValue } from "react-select";
import { StyledErrorText, StyledPrimaryLabel } from "@src/styles/commonStyles";
import ReactAsyncSelect from "react-select/async";
import {
  StyledSelect,
  StyledSelectBox,
  StyledSelectFloat,
  StyledSelectWithLabelWrap,
} from "../../styles";
import ReactSelectType from "react-select/dist/declarations/src/Select";
import useCommonHooks from "@src/hooks/useCommonHooks";
import Portal from "@src/Portal";
import { initialSelectOption } from "../Select";
import { KEY_TYPE } from "@constructs/common";
import { StyledTextWrap } from "../../styles";
import { InputStyleProps } from "../../sizeTheme";
import { useTranslation } from "react-i18next";

export interface CommonDeviceSearchSelectboxProps {
  sizeTheme: InputStyleProps;
  name: string;
  options?: SelectOption[];
  placeholder?: string;
  value?: SelectOption;
  defaultValue?: SelectOption;
  isDisabled?: boolean;
  isLoading?: boolean;
  isInvalid?: boolean;
  errorMessages?: string[];
  event: (value: SelectOption) => any;
  fixWidth?: number;
  showLabel?: boolean;
  labelText?: string;
}

// Label Optional Props
type OptionalLabelProps =
  | {
      showLabel: true;
      labelText: string;
      labelDirection?: "TOP" | "LEFT";
    }
  | {
      showLabel?: false;
      labelText?: never;
      labelDirection?: never;
    };

export type DeviceSearchSelectboxProps = CommonDeviceSearchSelectboxProps &
  OptionalLabelProps;

export default function DeviceSearchSelectbox({
  sizeTheme,
  name,
  options,
  placeholder,
  defaultValue,
  isDisabled,
  isLoading,
  isInvalid,
  errorMessages,
  event,
  fixWidth,
  value,
  showLabel,
  labelText,
  labelDirection,
}: DeviceSearchSelectboxProps) {
  // hook
  const { t } = useTranslation();
  const { useOutsideClickEffect, handleKeyDown, useScrollToSelectedOption } =
    useCommonHooks();

  // Ref
  const selectLabelRef = React.useRef<HTMLLabelElement>(null);
  const selectWrapRef = React.useRef<HTMLDivElement>(null);
  const selectRef =
    React.useRef<ReactSelectType<SelectOption, false, GroupBase<SelectOption>>>(
      null
    );
  const optionsRef = React.useRef<HTMLDivElement>(null);

  // States
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] =
    React.useState<SelectOption>(initialSelectOption);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [tabFocus, setTabFocus] = React.useState<boolean>(false);

  // Functions
  const handleMenuIsOpen = () => !menuIsOpen && setMenuIsOpen(true);
  const handleKeyDownOpen = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.target === selectWrapRef.current) {
      handleKeyDown(e, [KEY_TYPE.ENTER, KEY_TYPE.DOWN], handleMenuIsOpen);
    }
  };

  // Select Value Change
  const handleSelectValue = React.useCallback(
    (newValue: SingleValue<SelectOption>) => {
      if (newValue) {
        setSelectedValue(newValue);
        event(newValue);
      } else {
        setSelectedValue(initialSelectOption);
        event(initialSelectOption);
      }
    },
    [event]
  );

  // Select Input Value Change
  const handleInputValue = (newValue: string, _actionMeta: InputActionMeta) => {
    setInputValue(newValue);
  };

  // Input에 검색어 입력 시 Options 검색어로 필터링
  const filterOptions = (inputValue: string) => {
    let newData: SelectOption[];

    if (options) {
      newData = options.filter((option: SelectOption) => {
        if (
          option.value.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          return option;
        }
        return false;
      });
    } else {
      newData = [initialSelectOption].filter((option: SelectOption) => {
        if (
          option.value.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          return option;
        }
        return false;
      });
    }

    return newData;
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<SelectOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 300);
    });

  // Effects
  // Select Vlaue가 선택(변경)된 경우, Input은 읽기만 가능하도록 변경
  useEffect(() => {
    setMenuIsOpen(false);
    setTabFocus(false);
    if (selectRef.current && selectRef.current.inputRef) {
      if (selectedValue.value && selectedValue.label) {
        selectRef.current.inputRef.readOnly = true;
      } else {
        selectRef.current.inputRef.readOnly = false;
      }
    }
  }, [selectedValue]);

  // inputValue 변경될 때 Options Box 오픈
  useEffect(() => {
    if (inputValue.length > 0) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [inputValue]);

  // Option box 오픈될 때만 Custom Focusing 추가 (Clear 버튼 클릭 시 Focusing 되는 에러 해결)
  useEffect(() => {
    if (selectRef.current && selectRef.current.controlRef) {
      if (menuIsOpen) {
        selectRef.current.controlRef.className += " custom-focusing";
      } else {
        selectRef.current.controlRef.className.replace(" custom-focusing", "");
      }
    } else if (!menuIsOpen) {
      setTabFocus(false);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    if (selectRef.current && selectRef.current.controlRef) {
      if (tabFocus) {
        selectRef.current.controlRef.className += " custom-focusing";
      } else {
        selectRef.current.controlRef.className.replace(" custom-focusing", "");
      }
    }

    if (!tabFocus && selectRef.current && selectRef.current.controlRef) {
      selectRef.current.controlRef.className.replace(" custom-focusing", "");
    }
  }, [tabFocus]);

  useEffect(() => {
    if (
      (options &&
        value &&
        options.findIndex((item) => item.value === value.value)) !== -1
    ) {
      if (value) setSelectedValue(value);
    } else {
      setSelectedValue(initialSelectOption);
      event(initialSelectOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  // handle outside click
  useOutsideClickEffect(selectWrapRef, menuIsOpen, setMenuIsOpen, [
    selectLabelRef,
    optionsRef,
  ]);

  // handle options scroll to selected option
  useScrollToSelectedOption(optionsRef, menuIsOpen);

  return (
    <>
      <StyledSelectWithLabelWrap labelDirection={labelDirection}>
        {showLabel && (
          <StyledPrimaryLabel
            ref={selectLabelRef}
            style={{ cursor: "pointer" }}
            onClick={() => setMenuIsOpen((prev) => !prev)}
            labelLineHeight={
              labelDirection === "TOP"
                ? undefined
                : sizeTheme.height ?? undefined
            }
          >
            <span>{labelText}</span>
          </StyledPrimaryLabel>
        )}
        <StyledSelect
          menuIsOpen={menuIsOpen}
          sizeTheme={sizeTheme}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          fixWidth={fixWidth ?? 200}
        >
          <StyledSelectBox
            tabIndex={0}
            ref={selectWrapRef}
            onClick={handleMenuIsOpen}
            onTouchEnd={handleMenuIsOpen}
            onKeyDown={handleKeyDownOpen}
            onFocus={() => setTabFocus(true)}
            onBlur={() => setTabFocus(false)}
            className={tabFocus ? "tab-focused" : undefined}
          >
            <ReactAsyncSelect
              ref={selectRef}
              name={name}
              menuIsOpen={menuIsOpen}
              className="basic-single"
              classNamePrefix="select"
              placeholder={placeholder ?? "Write / Select Device"}
              defaultValue={defaultValue}
              value={
                options?.filter(
                  (option: SelectOption) =>
                    selectedValue.value !== "" &&
                    option.value === selectedValue.value
                ) ?? undefined
              }
              menuPlacement="auto"
              inputValue={inputValue}
              onChange={handleSelectValue}
              onInputChange={handleInputValue}
              defaultOptions={options}
              loadOptions={options ? promiseOptions : undefined}
              isDisabled={isDisabled ?? false}
              isLoading={isLoading ?? false}
              isClearable={options ? true : false}
              isSearchable={options ? true : false}
              isMulti={false}
              blurInputOnSelect
              closeMenuOnSelect
              menuPortalTarget={
                document.querySelector(`.${name}-options`) as HTMLElement
              }
              formatOptionLabel={(option) => (
                <div className="select__device">
                  <span className="select__device_id">{option.value}</span>
                  <span className="select__device_alias">{option.label}</span>
                </div>
              )}
            />
          </StyledSelectBox>
          {isInvalid && (
            <StyledTextWrap
              sizeTheme={sizeTheme}
              style={{
                marginBottom: 8,
              }}
            >
              {errorMessages &&
                errorMessages.map((item: string, index: number) => (
                  <StyledErrorText key={index}>{t(item)}</StyledErrorText>
                ))}
            </StyledTextWrap>
          )}
        </StyledSelect>
      </StyledSelectWithLabelWrap>
      {/* Select Option Box Portal */}
      <Portal rootId="select-portal">
        <StyledSelectFloat
          className={`${name}-options`}
          sizeTheme={sizeTheme}
          optionAlign="start"
          ref={optionsRef}
        />
      </Portal>
    </>
  );
}

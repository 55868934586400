import useNetworkHooks from "@src/hooks/useNetworkHooks";

export interface UserInfoResponse {
  name: {
    ko: string;
    en: string;
  };
  uid: string;
  menuPermission: number;
  userGroup: string;
  deviceGroups: {
    groupName: string;
    deviceList: IDeviceList[];
  }[];
}

export type UseYN = "Y" | "N";
export type SaleType = "SALE" | "RENT" | "NULL";
export type Servicetype = "DEVICE" | "FULL" | "NULL";
export type MonitoringType = "normal" | "construction";
export type SensorAxisType = "X" | "Y" | "Z" | "0" | "P";

export interface DeviceGroup {
  device_group_id: number;
  device_group_name: string;
  nation: string;
  modified_at: string;
}

export interface Structure {
  structure_id: string;
  structure_name: string;
  address?: string;
  monitoring_type: MonitoringType;
  modified_at: string;
}

export interface PhysicalSensor {
  physical_sensor_id: number;
  type: string;
  axis: SensorAxisType;
  model_name: string;
  manufacturer: string;
  parameter: number;
  unit: string;
  offset_value: number;
  note?: string;
  modified_at: string;
}

export interface DeviceInfo {
  sale_type: SaleType;
  sale_type_start_date: string;
  sale_type_end_date?: string;
  data_service_type: Servicetype;
  dtrigger: UseYN;
  published_score_report: UseYN;
}

export interface DeviceConfig {
  raw_period: number;
  offset_value: number;
  setting_value: number;
  use_trigger: UseYN;
  trigger_before_sec: number;
  trigger_after_sec: number;
  trigger_low: number;
  trigger_low_low: number;
  trigger_high: number;
  trigger_high_high: number;
}

export interface DeviceList {
  device_id: string;
  device_name: string;
  serial_number: string;
  device_group_id: number;
  info: DeviceInfo;
  config: DeviceConfig;
}

export interface SensorList {
  sensor_id: string;
  serial_number: string;
  device_id: string;
  structure_id: string;
  physical_sensor_id: number;
  info: SensorInfo;
  config: SensorConfig;
}

export interface SensorInfo {
  device_ch: number;
  original_axis: string;
  sale_type: SaleType;
  latitude?: number;
  longitude?: number;
}

export interface SensorConfig {
  raw_period: number;
  offset_value: number;
  setting_value: number;
  use_trigger: UseYN;
  trigger_before_sec: number;
  trigger_after_sec: number;
  trigger_low: number;
  trigger_low_low: number;
  trigger_high: number;
  trigger_high_high: number;
}

export interface UserInfoV2Response {
  name: {
    ko: string;
    en: string;
  };
  uid: string;
  menuPermission: number;
  userGroup: string;
  deviceGroupList: DeviceGroup[];
  deviceList: DeviceList[];
  sensorList: SensorList[];
  structureList: Structure[];
  physicalSensorList: PhysicalSensor[];
}

export interface DeviceListResponse {
  deviceList: IDeviceList[];
}
export interface IDeviceList {
  deviceId: string;
  deviceName: string;
  structureId: string;
  structureName: string;
  sensorList: string[];
}

export interface DeviceStateShortResponse
  extends DeviceGroupDeviceStructureInfoWithDtrigger {
  battery: number | null;
  solarChargeVolt: number | null;
  uptime: string;
  work: boolean | null;
}

// * S3 데이터 파일 읽기 리스폰스 타입 uid, fileKey
export interface ReadFileS3RequestParams {
  deviceId?: string;
  fileKey: string;
}

// * 이벤트 로우 데이터 파일 리스트
export interface RangeOptionalSearchRequestParams
  extends RangeDateRequestParams {
  alignType?: TableAlignType;
  searchType?: string;
  searchValue?: string;
}
export type RangeOptionalSearchPagingRequestParams =
  RangeOptionalSearchRequestParams & {
    page?: number;
    size?: number;
  };

export interface DtriggerFileListResponse extends PagingType {
  fileList: IDtriggerFileList[];
  count: number;
}
export interface IDtriggerFileList
  extends DeviceGroupDeviceStructureInfoWithDtrigger {
  sensorId: string;
  fileKey: string;
  date: string;
}

export interface DeviceLatLngResponse
  extends DeviceGroupDeviceStructureInfoWithDtrigger {
  longitude: string | null;
  latitude: string | null;
  work?: boolean;
}

export default function useFetchData() {
  const { useAxios } = useNetworkHooks();

  const FetchDeviceList: () => Promise<IDeviceList[] | null> = async () => {
    const response = (await useAxios(
      "GET",
      "/deviceList"
    )) as DeviceListResponse;

    if (response) {
      return response.deviceList;
    }
    return null;
  };

  const FetchUserInfo: () => Promise<UserInfoV2Response | null> = async () => {
    const response = (await useAxios(
      "GET",
      "/userInfo/v2"
    )) as UserInfoV2Response;

    if (response) {
      // TODO: Api 수정 필요
      const seen = new Set();
      const structureListSet = response.structureList.filter((item) => {
        const duplicate = seen.has(item.structure_id);
        seen.add(item.structure_id);
        return !duplicate;
      });

      const physicalSensorListSet = response.physicalSensorList.filter(
        (item) => {
          const duplicate = seen.has(item.physical_sensor_id);
          seen.add(item.physical_sensor_id);
          return !duplicate;
        }
      );

      const newData = { ...response };
      newData.physicalSensorList = physicalSensorListSet;
      newData.structureList = structureListSet;
      return newData;
    }
    return null;
  };

  const FetchDeviceStateShort: () => Promise<
    DeviceStateShortResponse[] | null
  > = async () => {
    const response = (await useAxios(
      "GET",
      "/deviceState/short/v2"
    )) as DeviceStateShortResponse[];

    if (response) {
      return response;
    }
    return null;
  };

  // * 디바이스 위/경도 조회하는 Api
  const FetchDeviceLatLng: () => Promise<DeviceLatLngResponse[] | null> =
    async () => {
      const result = (await useAxios(
        "GET",
        "/deviceLatLng/v2"
      )) as DeviceLatLngResponse[];

      if (result) {
        return result;
      }
      return null;
    };

  // * 디바이스의 dtrigger Y => N으로 초기화하는 Api
  const FetchResetDeviceDtrigger: (
    params: DeivceIdRequestParams
  ) => Promise<SuccessResponse | null> = async (
    params: DeivceIdRequestParams
  ) => {
    const response = (await useAxios(
      "POST",
      "/resetDeviceDtrigger/v2",
      params
    )) as SuccessResponse;

    if (response) {
      return response;
    }
    return null;
  };

  // * S3 파일 리스트 조회하는 Api
  const FetchS3FileList: (
    type: S3BucketType,
    params: CommonS3FileListRequestParams
  ) => Promise<FileListResponse | null> = async (
    type: S3BucketType,
    params: CommonS3FileListRequestParams
  ) => {
    const result = (await useAxios(
      "GET",
      `/s3FileList/${type}/v2`,
      params
    )) as FileListResponse;

    if (result) {
      return result;
    }
    return null;
  };

  // Range Date
  const FetchS3FileListRange: (
    type: S3BucketType,
    params: RangeS3FileListRequestParams
  ) => Promise<FileListResponse | null> = async (
    type: S3BucketType,
    params: RangeS3FileListRequestParams
  ) => {
    const result = (await useAxios(
      "GET",
      `/s3FileListRange/${type}/v2`,
      params
    )) as FileListResponse;

    if (result) {
      return result;
    }
    return null;
  };

  return {
    FetchDeviceList,
    FetchUserInfo,
    FetchDeviceStateShort,
    FetchDeviceLatLng,
    FetchResetDeviceDtrigger,
    FetchS3FileListRange,
    FetchS3FileList,
  };
}

import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import {
  BrowserSizeConst,
  ELEMENT_Z_INDEX,
  FlexAlignTypeConst,
} from "@constructs/common";
import Logo from "../Logo";
import UserInfoBox from "../UserInfoBox";
import IconButton from "../Buttons/IconButton";
import { NormalIconButtonStyles } from "../Buttons/sizeTheme";
import React from "react";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { mediaQuery } from "@src/styles/theme";
import { useBrowserSizeStore } from "@src/stores/useCommonStore";
import { Div, StyledFlexWrap } from "@src/styles/commonStyles";
import Select from "../Inputs/Selects/Select";
import { SmallInputStyle } from "../Inputs/sizeTheme";
import { useTranslation } from "react-i18next";
import useI18nHooks from "@src/hooks/useI18nHooks";
import InfoFloatBox from "../InfoFloatBox";

const StyledHeader = styled(motion.header)`
  margin-bottom: 10px;
  padding: 15px 30px 0;
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.color.background};
  position: relative;

  ${mediaQuery.laptop} {
    padding: 0 20px;
    height: auto;
  }
  ${mediaQuery.mobile} {
    padding: 0 15px;
    height: auto;
  }

  &::after {
    content: "";
    height: 20px;
    width: calc(100% - 60px);
    background: ${({ theme }) =>
      `linear-gradient(180deg, ${theme.color.background}, transparent)`};

    position: absolute;
    top: 100%;
    z-index: 1;
  }
`;

const StyledFixedHeader = styled(Div)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  padding: 10px;
  width: 100%;
  height: 54px;
  background-color: ${({ theme }) => theme.color.background};

  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ELEMENT_Z_INDEX.HEADER};

  ${mediaQuery.mobile} {
    height: auto;
  }
`;

interface HeaderProps {
  activeTitle: string;
  activeNotice?: {
    title: string;
    message: string | string[];
  };
}

const StyledBurgerSpace = styled(Div)`
  width: 36px;
  height: 34px;

  ${mediaQuery.mobile} {
    width: 28px;
    height: 28px;
  }
`;

const StyledSettingWrap = styled(Div)`
  position: relative;

  ${mediaQuery.mobile} {
    width: 28px;
    height: 28px;

    & > div:first-child {
      width: 100%;
      height: 100%;

      & > * {
        width: 100%;
        height: 100%;
        min-width: auto;

        & > * {
          /* 버튼 태그 */
          padding: 0;
          width: 100%;
          height: 100%;
          min-height: unset;
        }
      }
    }
  }
`;

const StyledSettingBox = styled(motion.div)`
  position: absolute;

  right: 0;
  top: calc(100% + 8px);

  & > div {
    margin-top: 0;
  }
`;

const StyledPageTitle = styled.h2`
  display: inline-block;

  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.common.fontSize.xlg};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};

  /* margin-bottom: 20px; */
  padding-bottom: 6px;
  border: 0;
  border-bottom-width: 3px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.primary};

  ${mediaQuery.mobile} {
    margin-bottom: 15px;
    padding-bottom: 6px;

    font-size: ${({ theme }) => theme.size.common.fontSize.lg};
    border-bottom-width: 4px;
  }
`;

export default function Header({ activeTitle, activeNotice }: HeaderProps) {
  // zustand & hooks
  const { t } = useTranslation();
  const browserSize = useBrowserSizeStore();
  const { useOutsideClickEffect } = useCommonHooks();
  const { selectedLanguage, setSelectedLanguage, handleChangeLanguage } =
    useI18nHooks();

  // State
  const [settingClicked, setSettingClicked] = React.useState<boolean>(false);
  const settingRef = React.useRef<HTMLDivElement>(null);

  const settingVariants = {
    initial: {
      scale: 0,
      originZ: "left",
      opacity: 0,
    },
    animate: {
      scale: 1,
      originZ: "left",
      opacity: 1,
    },
    exit: {
      scale: 0,
      opacity: 0,
      transition: {
        opacity: 0.1,
        scale: {
          duration: 0.2,
          delay: 0.04,
        },
      },
    },
  };

  // handle outside click
  useOutsideClickEffect(settingRef, settingClicked, setSettingClicked);

  return (
    <StyledHeader initial={false}>
      {browserSize && browserSize <= BrowserSizeConst.Laptop && (
        <StyledFixedHeader>
          <StyledBurgerSpace />
          <Logo
            type={"default"}
            fixHeight={browserSize <= BrowserSizeConst.Mobile ? 26 : 32}
          />
          <StyledSettingWrap ref={settingRef}>
            <div>
              <IconButton
                sizeTheme={NormalIconButtonStyles}
                colorType="primaryNoBg"
                iconName="setting"
                onClick={() => {
                  setSettingClicked((prev) => !prev);
                }}
              />
            </div>
            <StyledSettingBox>
              <AnimatePresence mode="wait">
                {settingClicked && (
                  <motion.div
                    key="settingBox"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{
                      transformOrigin: "right",
                    }}
                    variants={settingVariants}
                  >
                    <UserInfoBox />
                  </motion.div>
                )}
              </AnimatePresence>
            </StyledSettingBox>
          </StyledSettingWrap>
        </StyledFixedHeader>
      )}
      <StyledFlexWrap align={FlexAlignTypeConst.SpaceBetween}>
        <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
          {activeNotice ? (
            <InfoFloatBox
              title={t(activeNotice.title)}
              iconAlign="left"
              floatBoxAlign="bottom"
              contentText={activeNotice.message}
            />
          ) : null}
          <StyledPageTitle>{t(activeTitle)}</StyledPageTitle>
        </StyledFlexWrap>
        <Select
          sizeTheme={SmallInputStyle}
          name="lang"
          placeholder="lang.."
          value={selectedLanguage}
          options={[
            { value: "ko", label: t("ko") },
            { value: "en", label: t("en") },
          ]}
          event={(value) => {
            handleChangeLanguage(value.value);
            setSelectedLanguage({
              value: value.value,
              label: t(value.value),
            });
          }}
          fixWidth={110}
          showAddon
          addonName="language"
        />
      </StyledFlexWrap>
    </StyledHeader>
  );
}

import { ReactNode } from "react";
import { NoticeDialogProvider } from "./NoticeDialogProvider";
import { AlertDialogProvider } from "./AlertDialogProvider";
import { DialogProvider } from "./DialogProvider";

export interface ProviderProps {
  children: ReactNode;
}

export default function AllContextProvider({ children }: ProviderProps) {
  return (
    <DialogProvider>
      <NoticeDialogProvider>
        <AlertDialogProvider>{children}</AlertDialogProvider>
      </NoticeDialogProvider>
    </DialogProvider>
  );
}

import { ReadFileS3RequestParams } from "./fetchCommon";
import useNetworkHooks from "@src/hooks/useNetworkHooks";
import SensorChartData from "@src/models/SensorChartData";
import DeviceChartData from "@src/models/DeviceChartData";

// 통계, 스테이트 파라미터 타입
// * startDate, endDate, deviceId로 조회한다.
export interface RangeDeviceIdRequestParams extends RangeDateRequestParams {
  deviceId: string;
}
export interface RangeOptionalDeviceIdSensorIdRequestParams
  extends RangeDateRequestParams {
  deviceId?: string;
  sensorId?: string;
}

// 로우 데이터 파라미터 타입
// * startDate, endDate, sensorId로 조회한다.
export interface RangeSensorIdRequestParams extends RangeDateRequestParams {
  sensorId: string;
}

export interface RangeSensorIdOrSensorListRequestParams
  extends RangeDateRequestParams {
  sensorId?: string;
  sensorList?: string;
}

export type RangeSensorIdPagingRequestParams = PagingType &
  RangeSensorIdRequestParams;

// * 통계 리스폰스 타입
export interface IDmeasureData {
  avg: number;
  min: number;
  max: number;
  std: number;
  rms: number;
  time: string;
}
export interface DmeasureDataResponse
  extends DeviceGroupDeviceStructureSensorInfo {
  dmeasureList: IDmeasureData[] | null;
}

export interface DmeasureDataBySensorResponse
  extends DeviceGroupDeviceStructureSensorInfo {
  sensorId: string;
  dmeasureList: IDmeasureData[];
}

// 로우 데이터(raw, dtrigger..)
export interface IRawFileData {
  startTime: string;
  endTime: string;
  count: string;
  data: number[];
}

// 케이블(psd) 데이터
export interface IPSDFileData {
  time: string;
  step: number;
  count: number;
  data: number[];
  peakCount: number;
  peakData: number[];
  peakFreq: number[];
  peak_order: number[];
  cTension: number;
}

// 로우, 이벤트, PSD 데이터와 파일명 타입
export interface S3JsonFileData<T> {
  file: T;
  name: string;
}

// S3 파일이 Json일 때 리턴
export interface S3JsonDataResponse
  extends DeviceGroupDeviceStructureSensorInfo {
  fileData: S3JsonFileData<IRawFileData | IPSDFileData>;
}

// * 상태 리스폰스 타입
export interface IDeviceState {
  type: string;
  data: {
    time: string;
    value: number;
  }[];
}
export interface DeviceStateDataResponse
  extends DeviceGroupDeviceStructureInfo {
  deviceStates: IDeviceState[];
}

export interface S3PresignedUrlRequestParams {
  type: string;
  fileKey: string;
}

export interface S3PresignedUrlResponse
  extends DeviceGroupDeviceStructureSensorInfo {
  presignedUrl: string;
}

// * psd 케이블 장력 차트 타입
export interface PSDCableTensionResponse
  extends DeviceGroupDeviceStructureSensorInfo {
  cableTensionData:
    | {
        time: string;
        value: number;
      }[]
    | null;
}

export default function useFetchChartData() {
  const { useAxios } = useNetworkHooks();

  // * 디바이스 ID로 조회하는 통계 데이터 Api
  const FetchDmeasureData: (
    params: RangeOptionalDeviceIdSensorIdRequestParams,
    chartStyle?: "fill" | "gradient"
  ) => Promise<SensorChartData[] | null> = async (
    params: RangeOptionalDeviceIdSensorIdRequestParams,
    chartStyle?: "fill" | "gradient"
  ) => {
    const result = (await useAxios(
      "GET",
      "/dmeasureData/v2",
      params
    )) as DmeasureDataResponse[];

    if (result) {
      const data = result.map((item) => new SensorChartData(item, chartStyle));
      return data;
    }
    return null;
  };

  // * 디바이스 ID로 조회하는 상태 데이터 Api
  const FetchStateData: (
    params: RangeDeviceIdRequestParams
  ) => Promise<DeviceChartData | null> = async (
    params: RangeDeviceIdRequestParams
  ) => {
    const result = (await useAxios(
      "GET",
      "/deviceStatesData/v2",
      params
    )) as DeviceStateDataResponse;

    if (result) {
      return new DeviceChartData(result);
    }
    return null;
  };

  // * 로우 데이터 다운로드 Api
  const FetchReadS3File = async (
    params: ReadFileS3RequestParams,
    dataType: string
  ) => {
    const config = {
      responseType:
        params.fileKey.includes(".jpg") || params.fileKey.includes(".xlsx")
          ? "arraybuffer"
          : "json",
    };

    const result = (await useAxios(
      "GET",
      `/readFile/s3/${dataType}/v2`,
      {
        fileKey: params.fileKey,
      },
      config
    )) as any;

    if (result) {
      if (config.responseType === "arraybuffer") {
        return result as FileResponse;
      } else {
        return result as S3JsonDataResponse;
      }
    }
    return null;
  };

  // * Get S3 Presigned Url Api
  const FetchS3PresignedUrl: (
    params: S3PresignedUrlRequestParams
  ) => Promise<S3PresignedUrlResponse | null> = async (
    params: S3PresignedUrlRequestParams
  ) => {
    const result = (await useAxios("GET", "/s3PresignedUrl/v2", {
      type: params.type,
      fileKey: params.fileKey,
    })) as S3PresignedUrlResponse;

    if (result) {
      return result;
    }
    return null;
  };

  // * PSD 케이블 장력 조회하는 Api
  const FetchPSDCableTension: (
    params: RangeSensorIdRequestParams
  ) => Promise<SensorChartData | null> = async (
    params: RangeSensorIdRequestParams
  ) => {
    const result = (await useAxios(
      "GET",
      "/psdCableTension/v2",
      params
    )) as PSDCableTensionResponse;

    if (result) {
      return new SensorChartData(result);
    }
    return null;
  };

  return {
    FetchDmeasureData,
    FetchStateData,
    FetchReadS3File,
    FetchS3PresignedUrl,
    FetchPSDCableTension,
  };
}

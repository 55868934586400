import styled from "styled-components";

const StyledTabWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 20px;

  h4 {
    color: ${({ theme }) => theme.color.tab.default};
    font-size: ${({ theme }) => theme.size.common.fontSize.lg};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
    transition: color 0.24s ease-out;

    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
    }

    &:hover:not(.active) {
      color: ${({ theme }) => theme.color.tab.hover};
    }
  }
`;

interface TabProps {
  contents: string[];
  tabActiveNumber: number;
  tabActiveOnClick: (index: number) => void;
}

export default function Tab({
  contents,
  tabActiveNumber,
  tabActiveOnClick,
}: TabProps) {
  return (
    <StyledTabWrap>
      {contents.map((item, index) => (
        <h4
          key={index}
          className={tabActiveNumber === index ? "active" : undefined}
          onClick={() => tabActiveOnClick(index)}
        >
          {item}
        </h4>
      ))}
    </StyledTabWrap>
  );
}

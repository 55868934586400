// This is a React Router v6 app
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  BrowserRouter as Router,
} from "react-router-dom";
import HomePage from "@src/pages/HomePage";
import ChartPage from "@src/pages/ChartPage";
import AuthRoutes from "./AuthRoutes";
import AppLayout from "@src/layout/AppLayout";
import React, { useEffect } from "react";
import { auth } from "@src/firebase";
import { User, onAuthStateChanged } from "firebase/auth";
import {
  useCommonStoreActions,
  useLoggedInStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import EventPage from "@src/pages/EventPage";
import AdminPage from "@src/pages/AdminPage";
import InvalidAccess from "@src/pages/ErrorPage/InvalidAccess";
import NotFound from "@src/pages/ErrorPage/NotFound";
import PSDPage from "@src/pages/PSDPage";
import LoadingRandomIcon from "@src/components/Loadings/LoadingRandomIcon";
import InstallationManagementPage from "@src/pages/InstallationMangementPage";
import ScoreReportPage from "@src/pages/ScoreReportPage";
import DeviceSpecialInfoPage from "@src/pages/DeviceSpecialInfoPage";
import RealtimeDataPage from "@src/pages/RealtimeDataPage";

function AppRoutes() {
  const loggedIn = useLoggedInStore();
  const userInfo = useUserInfoStore();
  const { setLoggedIn } = useCommonStoreActions();
  const [authRouterUser, setAuthRouterUser] = React.useState<User | null>(null);
  const [authRouterLoading, setAuthRouterLoading] =
    React.useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        // 이메일 인증된 사용자만
        setAuthRouterUser(user);
      } else {
        setAuthRouterUser(null);
      }
      setAuthRouterLoading(false); // 데이터 로딩 완료 후 로딩 상태 변경
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (authRouterUser) {
      setLoggedIn(true);
    }
  }, [authRouterUser, setLoggedIn]);

  if (authRouterLoading) {
    return <LoadingRandomIcon />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                !authRouterLoading && authRouterUser && loggedIn
                  ? "/home"
                  : "/auth/login"
              }
            />
          }
        />
        {/* AppLayout으로 감싸기 */}
        {!authRouterLoading && authRouterUser && loggedIn ? (
          <Route
            element={
              <AppLayout>
                <Outlet />
              </AppLayout>
            }
          >
            <Route path="/home" element={<HomePage />} />
            <Route
              path={"/dataByDeviceId/:chartType"}
              element={<ChartPage />}
            />
            <Route path={"/realtime"} element={<RealtimeDataPage />} />
            <Route path={"/psd"} element={<PSDPage />} />
            <Route path="/event" element={<EventPage />} />
            {userInfo.menuPermission >= 100 ? (
              <>
                <Route
                  path="/installationManagement"
                  element={<InstallationManagementPage />}
                />
                <Route path="/ScoreReport" element={<ScoreReportPage />} />
              </>
            ) : null}
            {userInfo.menuPermission >= 1000 ? (
              <>
                <Route
                  path="/deviceSpecialInfo"
                  element={<DeviceSpecialInfoPage />}
                />
                <Route path="/admin" element={<AdminPage />} />
              </>
            ) : null}

            {/* <Route
        path="/installationManagement"
        element={
          <AuthenticationGuard component={<InstallationManagementPage />} />
        }
        />
        <Route
          path="/deviceInfo"
          element={<AuthenticationGuard component={<DeviceInfoPage />} />}
        />  */}
          </Route>
        ) : null}

        {/* Auth Routes */}
        <Route path="/auth/*" element={<AuthRoutes />} />

        {/* Invlid Access */}
        <Route path="/error/invalidAccess" element={<InvalidAccess />} />

        {/* 404 not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

const AppRoutesMemo = React.memo(AppRoutes);
export default AppRoutesMemo;

import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import React from "react";
import { User } from "firebase/auth";
import { auth } from "@src/firebase";

export default function NotFound() {
  const [authRouterUser, setAuthRouterUser] = React.useState<User | null>(null);
  const [authRouterLoading, setAuthRouterLoading] =
    React.useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthRouterUser(user);
      } else {
        setAuthRouterUser(null);
      }
      setAuthRouterLoading(false); // 데이터 로딩 완료 후 로딩 상태 변경
    });

    return () => unsubscribe();
  }, []);

  if (!authRouterLoading && !authRouterUser) {
    return <Navigate to="/auth/login" />;
  }
  return <div>404 Not Found</div>;
}

import Button from "../../Buttons/Button";
import { NormalButtonStyles } from "../../Buttons/sizeTheme";
import RangeDate from "../../Datepickers/RangeDate";
import SingleDate from "../../Datepickers/SingleDate";
import DeviceSearchSelect from "@src/components/Inputs/Selects/DeviceSearchSelect";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import Select from "@src/components/Inputs/Selects/Select";
import { DateTypeConst, FlexAlignTypeConst } from "@constructs/common";
import { CardSizeProps, NormalCardStyle } from "@src/components/Card/sizeTheme";
import {
  StlyedSearchItemsWrap,
  StyledSearchRowWrap,
  StyledSelectsWrap,
} from "../styles";
import { useTranslation } from "react-i18next";
import useSearchbarHooks, {
  CommonSearchHooksProps,
  OptionalDateType,
  OptionalSearchedSensorProps,
} from "@src/hooks/useSearchbarHooks";

export interface CommonSearchRowProps {
  inCard?: boolean;
  cardSizeTheme?: CardSizeProps;
  layoutGridColumn?: string;
}

export type OptionalDeviceSearchRowProps = CommonSearchRowProps &
  OptionalDateType &
  OptionalSearchedSensorProps &
  CommonSearchHooksProps;

export default function OptionalDeviceSearchRow({
  dateType,
  minDate,
  maxDate,
  searchedDate,
  searchedSensor,
  selectedSensor,
  setSelectedSensor,
  inCard,
  cardSizeTheme,
  getUrlParams,
  sensorTypeFilter,
  paging,
  setPaging,
  hasContentUrl,
  hasViewLarger,
  layoutGridColumn,
}: OptionalDeviceSearchRowProps) {
  const { t } = useTranslation();

  const dateParams = searchedDate
    ? {
        searchedDate,
        dateType,
        minDate,
        maxDate,
      }
    : {};

  const sensorParams = searchedSensor
    ? {
        searchedSensor,
        sensorTypeFilter,
        selectedSensor,
        setSelectedSensor,
      }
    : {};

  const {
    handleSubmit,
    onSubmit,
    onError,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    errors,
    handleDeviceGroupSelect,
    handleDeviceSelect,
    handleSelectedSensorForSearch,
    date,
    setDate,
    submitButtonRef,
    selectedDeviceGroup,
    selectedDevice,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    memorizedSensorOptions,
  } = useSearchbarHooks({
    searchedDevice: true,
    ...dateParams,
    ...sensorParams,
    getUrlParams,
    paging,
    setPaging,
    hasContentUrl,
    hasViewLarger,
  });

  return (
    <>
      <StyledSearchRowWrap
        inCard={inCard}
        cardSizeTheme={cardSizeTheme ?? NormalCardStyle}
        align={FlexAlignTypeConst.SpaceBetween}
        marginBottom={24}
        layoutGridColumn={layoutGridColumn}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <StlyedSearchItemsWrap>
            {searchedDate && (
              <>
                {/* Range or Single */}
                {dateType === DateTypeConst.Range ? (
                  <RangeDate
                    sizeTheme={NormalInputStyle}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    isInvalid={errors.endDate?.message !== undefined}
                    errorMessages={[errors.endDate?.message ?? ""]}
                    showChoseDateButtons
                    showLabel
                    labelText={t("period")}
                  />
                ) : (
                  <SingleDate
                    sizeTheme={NormalInputStyle}
                    date={date}
                    setDate={setDate}
                    isInvalid={errors.date?.message !== undefined}
                    errorMessages={[errors.date?.message ?? ""]}
                    minDate={minDate}
                    maxDate={maxDate}
                    showLabel
                    labelText={t("period")}
                  />
                )}
              </>
            )}
            <StyledSelectsWrap>
              {/* 비즈니스 그룹 추가 */}
              <Select
                sizeTheme={NormalInputStyle}
                name="deviceGroupSelect"
                options={memorizedDeviceGroupOptions}
                value={selectedDeviceGroup}
                event={handleDeviceGroupSelect}
                isInvalid={errors.deviceGroup?.message !== undefined}
                errorMessages={[errors.deviceGroup?.message ?? ""]}
                showLabel
                labelText={t("group")}
                fixWidth={160}
              />
              <DeviceSearchSelect
                sizeTheme={NormalInputStyle}
                name="deviceSearchSelect"
                options={memorizedDeviceOptions}
                value={selectedDevice}
                event={handleDeviceSelect}
                isInvalid={errors.deviceId?.message !== undefined}
                errorMessages={[errors.deviceId?.message ?? ""]}
                showLabel
                labelText={t("device")}
              />
              {searchedSensor && (
                <Select
                  sizeTheme={NormalInputStyle}
                  name="sensorSearchSelect"
                  options={memorizedSensorOptions}
                  value={selectedSensor}
                  event={handleSelectedSensorForSearch}
                  fixWidth={150}
                  isInvalid={errors.sensorId?.message !== undefined}
                  errorMessages={[errors.sensorId?.message ?? ""]}
                  showLabel
                  labelText={t("sensor")}
                  placeholder="Select"
                />
              )}
            </StyledSelectsWrap>
          </StlyedSearchItemsWrap>
          <Button
            type="submit"
            sizeTheme={NormalButtonStyles}
            colorType="primary"
            text={t("search")}
            buttonRef={submitButtonRef}
          />
        </form>
      </StyledSearchRowWrap>
    </>
  );
}

import { useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "@assets/scss/index.scss";
import { theme, darkTheme } from "@styles/theme";
import AlertDialog from "@components/Dialogs/AlertDialog";
import NoticeDialog from "@components/Dialogs/NoticeDialog";
import { StyledCommonTabFocusing } from "./styles/commonStyles";
import { BrowserSizeConst, ThemeConst } from "@constructs/common";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppRoutes from "./routes/AppRoutes";
import { useCommonStoreActions, useThemeStore } from "./stores/useCommonStore";
import { useNavermaps } from "react-naver-maps";
import { useAlertDialog } from "./contexts/AlertDialogProvider";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useLanguageStore,
  useLanguageStoreActions,
} from "./stores/useTranslationStore";

export const GlobalStyles = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.color.background};
    transition: backgroundColor 0s;
    height: 100svh;
    overflow: hidden;
  }
  #root {
    display: flex;
    flex-direction: column;
    height: 100svh;
    background-color: ${({ theme }) => theme.color.background};
    font-size: ${({ theme }) => theme.size.common.fontSize.base};
    overflow: hidden;
  }

  // tab focusing
  *:focus-visible {
    ${StyledCommonTabFocusing}
  }

  // Scrollbar
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0,0,0,0);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.scrollbar};
    border-radius: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.scrollbar};


  /* Google Map Marker Z-Index & Initializing Style */
  .gm-style > * > * > * > * > div:has(.cluster-open) {
    z-index: 2000 !important;
  }

  .gm-style > * > * > * > * > div:has(.cluster-enter) {
    z-index: 1000 !important;
  }


  div[aria-label="google-info-window-custom"] {
    padding: 0 !important;
    background: transparent !important;
    box-shadow: unset !important;
    width: auto;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: unset !important;

    & > *:first-child {
      padding: 0;
      overflow: unset !important;
      background: transparent;
      box-shadow: unset;
      max-height: unset;
      
    }

    &  + .gm-style-iw-tc {
      display: none;
    }

    button[aria-label="Close"] {
      display: none !important;
    }
  }

  /* Naver Map, Google Map Marker Custom Style */
  .marker-picker {
    position: absolute;
    bottom: -6px;
    left: 15px;
    width: 0px;
    height: 0px;
    border-width: 6px 4px 0px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.normal} transparent transparent;
    pointer-events: none;

    &:bofore {
      opacity: 0.1;
      position: absolute;
      bottom: -2px;
      left: -5px;
      width: 10px;
      height: 3px;
      background-color: rgb(0, 0, 0);
      filter: blur(1px);
      content: "";
    }
    &:after {
      width: 0px;
      height: 0px;
      border-width: 9px 6px 0px;
      border-style: solid;
      border-color: rgb(255, 255, 255) transparent transparent;
      position: absolute;
      top: -11px;
      left: -6px;
      content: "";
    }
  }

  /* For Google Map */
  .gm-style  {
    .cluster-enter,
    .cluster-open {
      transform: translateX(calc(50% - 20px)) scale(1.1) !important;
    }
  }

  /* For Naver Map */
  .cluster-enter,
  .cluster-open {
    transform: scale(1.1) !important;
  }

  .marker-icon {
    display: inline-flex;
    align-items: center;
    gap: 3px; 
    padding: 4px 12px 4px 8px;
    max-width: 120px;
    background: #ffffff;
    border: 1.5px solid ${({ theme }) => theme.color.normal};
    border-radius: 30px;
    position: relative;
    transition: transform 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);



    .marker-svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      svg {
        fill: ${({ theme }) => theme.color.normal};
      }
    }

    .marker-text {
      display: flex;
      flex-direction: column;
      gap: 2px;
      overflow: hidden;

      .marker-device-id {
        font-size: 13px;
        font-weight: 600;
      }
      .marker-device-alias {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
      }
    }
    
    .cluster-count {
      display: flex;
      align-self: flex-start;
      justify-content: flex-end;
      justify-self: flex-end;
      padding: 3px 5px;
      color: #ffffff;
      font-size: 11px;
      font-weight: 500;
      background-color: ${({ theme }) => theme.color.normal};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      transform: translateX(100%);
      position: absolute;
      top: -5px;
      right: 11px;
    }

    &.not-work {
      border-color: ${({ theme }) => theme.color.notWork};

      .marker-svg {
        svg {
          fill: ${({ theme }) => theme.color.notWork};
        }
      }
      
      .marker-picker {
        border-color: ${({ theme }) =>
          theme.color.notWork} transparent transparent;
      }

      .cluster-count {
        background-color: ${({ theme }) => theme.color.notWork};
      }
    }

    &.warning {
      border-color: ${({ theme }) => theme.color.invalid};

      .marker-svg {
        svg {
          fill: ${({ theme }) => theme.color.invalid};
        }
      }
      
      .marker-picker {
        border-color: ${({ theme }) =>
          theme.color.invalid} transparent transparent;
      }

      .cluster-count {
        background-color: ${({ theme }) => theme.color.invalid};
      }
    }

    &:hover {
      position: relative;
      z-index: 1;
      box-shadow: 0 0 8px 2px rgb(127 180 44 / 70%);

      .cluster-count {
        box-shadow: 0 0 8px 2px rgb(127 180 44 / 70%);
      }

      &.not-work, &.not-work .cluster-count {
        box-shadow: 0 0 8px 2px rgb(85 85 85 / 70%);
      }

      &.warning,  &.warning .cluster-count {
        box-shadow: 0 0 8px 2px rgb(180 44 44 / 50%);
      }
    }
  }

  .cluster-info-box-parent {
    display: flex;
    position: absolute;
    left: 0px;
    bottom: calc(100% + 8px);
    z-index: 101;
  }

  .cluster-info-box {
    display: flex;
    flex: 0 0 auto;
    max-width: 160px;
    max-height: 130px;
    overflow-y: auto;
    text-overflow: ellipsis;
    flex-direction: column;
    width: auto;
    background: white;
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
      width: 4px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.scrollbar};
      background-clip: padding-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.color.scrollbar};

    & > * {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px;
      font-size: 12px;
      font-weight: 400;
      color: #333;
      cursor: pointer;

      & + * {
        border-top: 1px solid #ededed;
      }

      & > *:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .did {
        padding: 2px 4px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        background: ${({ theme }) => theme.color.normal};
        border-radius: 2px;

        &.not-work {
          background: ${({ theme }) => theme.color.font.default};
        }

        &.warning {
          background: ${({ theme }) => theme.color.invalid};
        }
      }

      .dname {
        color: ${({ theme }) => theme.color.normal};

        &.not-work {
          color: ${({ theme }) => theme.color.font.default};
        }

        &.warning {
          color: ${({ theme }) => theme.color.invalid};
        }
      }


      &:hover {
        background: ${({ theme }) => theme.color.background};
      }
    }
  }

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
  }

  #select-portal {
    position: fixed;
    top: 0;
    z-index: 2000;
  }
`;

function App() {
  // hooks
  const { t } = useTranslation();
  const themeType = useThemeStore();
  const { setBrowserSize } = useCommonStoreActions();
  const navermaps = useNavermaps();
  const { handleAlertDialogOpen } = useAlertDialog();
  const language = useLanguageStore();
  const { setLanguage } = useLanguageStoreActions();
  const { i18n } = useTranslation();

  // State
  const [notificationPermission, setNotificationPermission] = React.useState(
    window && window.Notification !== undefined
      ? Notification.permission
      : undefined
  );

  const useRequestNotificationPermission = () => {
    return React.useEffect(() => {
      // 이미 권한을 요청했으면 다시 요청하지 않음
      if (
        notificationPermission === "granted" ||
        notificationPermission === "denied" ||
        notificationPermission === undefined
      ) {
        return;
      }

      // 알림 권한 요청
      const requestPermission = async () => {
        try {
          const permission = await Notification.requestPermission();

          if (permission) {
            setNotificationPermission(permission);

            if (permission === "granted") {
              // 알림 권한 허용
              return handleAlertDialogOpen(
                t("arrow_push_notifications_title"),
                t("arrow_push_notifications_message")
              );
            } else {
              // 알림 권한이 거부
              return handleAlertDialogOpen(
                t("refuse_push_notifications_title"),
                t("refuse_push_notifications_message")
              );
            }
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
      };

      requestPermission();
    }, []);
  };

  // for media query
  const convertWidthToString = (size: number) => {
    return size > 1400
      ? BrowserSizeConst.Desktop
      : size <= 1400 && size > 1024
      ? BrowserSizeConst.DesktopSmall
      : size <= 1024 && size > 768
      ? BrowserSizeConst.Laptop
      : size <= 768 && size > 480
      ? BrowserSizeConst.Tablet
      : BrowserSizeConst.Mobile;
  };

  const resizeEvent = () => {
    setBrowserSize(convertWidthToString(window.innerWidth));
  };

  useEffect(() => {
    setBrowserSize(convertWidthToString(window.innerWidth));

    window.addEventListener("resize", resizeEvent);

    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  });

  function setViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setViewportHeight(); // 초기화
    window.addEventListener("resize", setViewportHeight);

    return () => {
      window.removeEventListener("resize", setViewportHeight);
    };
  }, []);

  useRequestNotificationPermission();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 20,
        suspense: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: true,
        retry: false,
        networkMode: "offlineFirst",
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        const message = error as Error;

        if (message.message.includes("unknown_user")) {
          handleAlertDialogOpen(
            t("notification"),
            t("need_to_update_account_information")
          );
        }
        if (query.meta && query.meta.errorMessage) {
          handleAlertDialogOpen("Alert Dialog", [`${query.meta.errorMessage}`]);
        }
        return "ERROR";
      },
      onSuccess: (data) => {
        console.log("onSuccess", data);
      },
    }),
  });

  useEffect(() => {
    console.log("navermaps used...");
  }, [navermaps]);

  // html 태그의 lang attr을 추적하고, setLanguage 한다.
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, _observer) => {
      for (let mutation of mutationsList) {
        if (
          mutation.target !== null &&
          mutation.type === "attributes" &&
          mutation.attributeName === "lang"
        ) {
          const target = mutation.target as HTMLElement;
          const newLangValue =
            target.getAttribute("lang") === "ko"
              ? "ko"
              : target.getAttribute("lang") === "en"
              ? "en"
              : "ko";
          console.log(
            `HTML lang 속성이 변경되었습니다. 새로운 값: ${newLangValue}`
          );

          setLanguage(newLangValue);
        }
      }
    });

    // MutationObserver를 시작하고 감시할 속성 및 변화 유형을 지정합니다.
    const observerConfig = {
      attributes: true, // 속성 변경 감시
      attributeFilter: ["lang"], // 감시할 속성 목록
      attributeOldValue: true, // 이전 값 기억 여부
    };

    // MutationObserver를 대상 노드에 연결하고 감시를 시작합니다.
    const targetNode = document.querySelector("html") as Node;
    observer.observe(targetNode, observerConfig);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 전역변수 language가 변경되면 i18n을 해당 언어로 업데이트한다.
  useEffect(() => {
    i18n
      .changeLanguage(language)
      .then(() => {
        console.log(`언어가 ${language}로 변경되었습니다.`);
        // 언어 변경 후 필요한 작업 수행
      })
      .catch((error) => {
        console.error("언어 변경에 실패했습니다.", error);
        // 실패 시 처리할 내용
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themeType === ThemeConst.Light ? theme : darkTheme}>
        <GlobalStyles />
        {/* Routes */}
        <AppRoutes />
        {/* Routes */}
        <AlertDialog />
        <NoticeDialog />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;

import {
  commonChartStyleOptions,
  lineColorArray,
  positionColors,
} from "@src/constructs/common";
import {
  DmeasureDataResponse,
  IDmeasureData,
  PSDCableTensionResponse,
} from "@src/fetch/fetchChart";
import {
  createChartGradient,
  getChartData,
  getPngFileTitle,
  getSensorLabel,
  isDataType,
} from "@src/hooks/useCommonHooks";
import { theme } from "@src/styles/theme";
import { ChartDataset, ScriptableContext } from "chart.js";

const DmeasureDataLabels = ["min", "max", "std", "rms", "avg"];

class SensorChartData {
  deviceGroupId: number;
  deviceGroupName: string;
  deviceId: string;
  deviceName: string;
  structureId: string;
  structureName: string;
  sensorId: string;
  title: {
    deviceId: string;
    sensorId: string | undefined;
    secondText: string;
    isTranslationOnI18n: boolean;
  };
  chart: ChartProps | null;

  constructor(
    item: DmeasureDataResponse | PSDCableTensionResponse,
    chartStyle?: "fill" | "gradient" | "borderTransparent"
  ) {
    this.deviceGroupId = item.deviceGroupId;
    this.deviceGroupName = item.deviceGroupName;
    this.deviceId = item.deviceId;
    this.deviceName = item.deviceName;
    this.structureId = item.structureId;
    this.structureName = item.structureName;
    this.sensorId = item.sensorId;
    this.title = {
      deviceId: item.deviceId,
      sensorId: getSensorLabel(item.sensorId),
      secondText: isDataType<DmeasureDataResponse>(item, "dmeasureList")
        ? "statistics_data"
        : isDataType<DmeasureDataResponse>(item, "cableTensionData")
        ? "tension_data"
        : "chart_data",
      isTranslationOnI18n: false,
    };
    this.chart =
      isDataType<DmeasureDataResponse>(item, "dmeasureList") &&
      item.dmeasureList &&
      item.dmeasureList.length > 0
        ? {
            pngFileTitle: getPngFileTitle(
              item.deviceId,
              "statistics",
              getSensorLabel(item.sensorId)
            ),
            datasets: DmeasureDataLabels.map((dLabel, dIndex) => {
              const colorOptions =
                chartStyle === "gradient"
                  ? {
                      backgroundColor: (ctx: ScriptableContext<"bar">) =>
                        createChartGradient(ctx, positionColors[dIndex]),
                      ...commonChartStyleOptions.gradientCommon,
                      fill: true,
                    }
                  : {
                      backgroundColor: lineColorArray[dIndex],
                      ...commonChartStyleOptions.common,
                      ...(dLabel === "min" || dLabel === "max" // label이 min이나 max일 경우  commonChartStyleOptions.minMax 추가
                        ? commonChartStyleOptions.minMax
                        : null),
                    };

              return {
                label: dLabel,
                data: getChartData(
                  item.dmeasureList as IDmeasureData[],
                  dLabel
                ),
                borderColor:
                  chartStyle === "borderTransparent"
                    ? "transparent"
                    : lineColorArray[dIndex],
                hidden: false,
                ...colorOptions,
              };
            }),
            labels: getChartData(item.dmeasureList, "time"),
          }
        : isDataType<PSDCableTensionResponse>(item, "cableTensionData") &&
          item.cableTensionData
        ? {
            pngFileTitle: getPngFileTitle(
              item.deviceId,
              "PSD",
              getSensorLabel(item.sensorId)
            ),
            datasets: [
              {
                label: "tension",
                data: getChartData(item.cableTensionData, "value"),
                borderWidth: 0,
                hidden: false,
                fill: false,
                pointStyle: "circle",
                radius: 4, // dot size
                borderColor: theme.color.primary, // for data text
                pointBorderWidth: 1.5, // dot border width
                pointBorderColor: theme.color.primaryHover,
                backgroundColor: theme.color.primary,
                hoverRadius: 6,
                hoverBorderWidth: 3,
                pointHoverBorderColor: theme.color.secondaryHover,
                hoverBackgroundColor: theme.color.secondary,
              } as ChartDataset,
            ],
            labels: getChartData(item.cableTensionData, "time"),
          }
        : null;
  }
}

// 0 ~ 3000 고정

export default SensorChartData;

import OptionalDeviceSearchRow from "@components/Searchbar/OptionalDeviceSearchRow";
import { DateTypeConst } from "@constructs/common";
import { NormalCardStyle } from "@components/Card/sizeTheme";
import React from "react";
import {
  RangeSensorIdPagingRequestParams,
  RangeSensorIdRequestParams,
} from "@src/fetch/fetchChart";
import { useSearchParams } from "react-router-dom";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import DefaultLineChart from "@src/components/Charts/DefaultLineChart";
import {
  StyledFlexWrap,
  StyledGridWrap,
  StyledPrimaryText,
  StyledRelativeFullWrap,
} from "@src/styles/commonStyles";
import ContentTablePSD from "./ContentTablePSD";
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import { usePSDDataPaging, useQueryActions } from "@src/stores/useQueryStore";
import { StyledChartGridWrap } from "../ChartPage/styles";
import useCommonHooks, { getSensorLabel } from "@src/hooks/useCommonHooks";
import { useTranslation } from "react-i18next";

export default function PSDPage() {
  const { t } = useTranslation();
  const { useCustomLoading, useUpdateParamsOnPageChange } = useCommonHooks();

  // use Custom Loading
  useCustomLoading();

  // * Url params
  const [searchParams] = useSearchParams();

  // * Hooks
  const { makeChartTitle } = useCommonHooks();
  const userInfo = useUserInfoStore();
  const { usePSDCableTensionQuery } = useQueryHooks();
  const paging = usePSDDataPaging();
  const { setPSDDataPaging: setPaging } = useQueryActions();

  // * State
  const [cableTensionDataParams, setCableTensionDataParams] =
    React.useState<RangeSensorIdRequestParams | null>(null); // cableTension, state 공용
  const [selectedSensor, setSelectedSensor] =
    React.useState<SelectOption>(initialSelectOption);
  const [paramsPSD, setParamsPSD] =
    React.useState<RangeSensorIdPagingRequestParams | null>(null);

  // * Dates
  const now = new Date();
  const maxDate = new Date();
  const minDate = new Date(now.setFullYear(now.getFullYear() - 5));

  // * QUERIES
  const { isFetching: isFetchingCalbeTension, data: cableTensionData } =
    usePSDCableTensionQuery(
      cableTensionDataParams as RangeSensorIdRequestParams
    );

  // * Functions
  // 서치바 검색 함수
  const getUrlParams = React.useCallback(
    (data: SearchParameterValues | null) => {
      if (data && data.startDate && data.endDate && data.sensorId) {
        setCableTensionDataParams({
          startDate: data.startDate ?? "",
          endDate: data.endDate ?? "",
          sensorId: data.sensorId ?? "",
        });

        setParamsPSD({
          startDate: data.startDate ?? "",
          endDate: data.endDate ?? "",
          sensorId: data.sensorId ?? "",
          size: paging.size,
          page: paging.page,
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // 페이징 변경 시 파라미터 업데이트
  useUpdateParamsOnPageChange(paging, setParamsPSD);

  const showTensionChart = React.useMemo(() => {
    const deviceId = userInfo.sensorList.find(
      (item) => item.sensor_id === searchParams.get("sensorId")
    )?.device_id;
    return deviceId
      ? userInfo.deviceList.find(
          (item) =>
            (item.device_group_id === 11 || item.device_group_id === 25) &&
            item.device_id === deviceId
        )
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("sensorId")]);

  return (
    <StyledGridWrap
      templateColumns="100%"
      templateRows="max-content minmax(400px, 1fr)"
    >
      {/* SearchRow */}
      <OptionalDeviceSearchRow
        dateType={DateTypeConst.Range}
        minDate={minDate}
        maxDate={maxDate}
        searchedDate
        searchedSensor
        inCard
        cardSizeTheme={NormalCardStyle}
        selectedSensor={selectedSensor}
        setSelectedSensor={setSelectedSensor}
        getUrlParams={getUrlParams}
        sensorTypeFilter={["AC"]}
        paging={paging}
        setPaging={setPaging}
        hasContentUrl
      />
      <StyledRelativeFullWrap id="chart-wrap">
        {/* 장력 - 11(이순신대교)만 노출 */}
        {showTensionChart ? (
          <StyledFlexWrap flexDirection="column">
            <StyledPrimaryText size="lg">장력 차트</StyledPrimaryText>
            <StyledChartGridWrap
              viewLarger
              style={{
                margin: `15px 0px 40px`,
              }}
            >
              <DefaultLineChart
                chartTitle={
                  cableTensionData
                    ? makeChartTitle(cableTensionData.title)
                    : makeChartTitle({
                        deviceId:
                          userInfo.sensorList.find(
                            (item) => item.sensor_id === paramsPSD?.sensorId
                          )?.device_id ?? "",
                        secondText: "tension_data",
                        isTranslationOnI18n: false,
                        sensorId: getSensorLabel(paramsPSD?.sensorId ?? ""),
                      })
                }
                pngFileTitle={
                  cableTensionData?.chart
                    ? cableTensionData.chart.pngFileTitle
                    : ""
                }
                chartData={
                  cableTensionData?.chart
                    ? {
                        labels: cableTensionData.chart.labels,
                        datasets: cableTensionData.chart.datasets,
                      }
                    : { labels: [], datasets: [] }
                }
                fixHeight={360}
                isInCard
                showZoomButton
                showPngDownloadButton
                isXScaleTime
                timeUnit="hour"
                yScaleMin={0}
                // yScaleMax={2000}
                isFetching={isFetchingCalbeTension}
              />
            </StyledChartGridWrap>
          </StyledFlexWrap>
        ) : null}

        {/* 첨두 추정 */}
        <>
          <StyledPrimaryText
            size="lg"
            style={{
              marginBottom: 15,
            }}
          >
            {t("peak_estimation")}
          </StyledPrimaryText>
          <ContentTablePSD params={paramsPSD} />
        </>
      </StyledRelativeFullWrap>
    </StyledGridWrap>
  );
}

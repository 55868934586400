import ContentTable from "@src/components/ContentTable";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import { RangeSensorIdPagingRequestParams } from "@src/fetch/fetchChart";
import useCommonHooks, { getSensorLabel } from "@src/hooks/useCommonHooks";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useQueryActions, useRawDataPaging } from "@src/stores/useQueryStore";
import { StyledDeviceIdText } from "@src/styles/commonStyles";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import { getDeviceIdBySensorId } from "@src/utils/common";

interface RawProps {
  params: RangeSensorIdPagingRequestParams | null;
}

function Raw({ params }: RawProps) {
  // Hook
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const userInfo = useUserInfoStore();
  const rawDataPaging = useRawDataPaging();
  const { useRawDataQuery } = useQueryHooks();
  const { setRawDataPaging } = useQueryActions();
  const { useCustomLoading } = useCommonHooks();

  useCustomLoading();

  // * State
  const [newData, setNewData] = React.useState<FileListResponse | null>(null);
  const [rawFileUrlForChart, setRawFileUrlForChart] =
    React.useState<string>(""); // 차트 페이지 안에서 초기화되지 않도록 하기 위함

  // * Query
  const { data: raw, isFetching } = useRawDataQuery(
    params as RangeSensorIdPagingRequestParams
  );

  // * Memo
  const memorizedTableTitle = React.useMemo(() => {
    return (
      <>
        <StyledDeviceIdText>
          {raw && raw.fileList.length > 0
            ? raw.fileList[0].deviceId
            : getDeviceIdBySensorId(
                userInfo,
                searchParams.get("sensorId") ?? ""
              ) ?? "device"}
        </StyledDeviceIdText>
        <span>
          {`${
            raw && raw.fileList.length > 0
              ? getSensorLabel(raw.fileList[0].sensorId)
              : params
              ? getSensorLabel(params.sensorId)
              : getSensorLabel(searchParams.get("sensorId") ?? "") ?? ""
          } ${t("raw_data")} ${t("file_list")}`}
        </span>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw, params, searchParams, t]);

  useEffect(() => {
    if (raw) setNewData(raw);
  }, [raw]);

  // file url이 업데이트되면 url 파라미터의 contentUrl도 업데이트한다.
  // useEffect(() => {
  //   const newSearchParams = new URLSearchParams(searchParams);
  //   const contentUrl = searchParams.get("contentUrl");

  //   if (rawFileUrlForChart && rawFileUrlForChart !== contentUrl) {
  //     newSearchParams.set("contentUrl", rawFileUrlForChart);
  //     return setSearchParams(newSearchParams.toString());
  //   } else if (!rawFileUrlForChart) {
  //     newSearchParams.delete("contentUrl");
  //     return setSearchParams(newSearchParams.toString());
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rawFileUrlForChart]);

  return (
    <>
      {searchParams.get("sensorId") ? (
        <ContentTable
          tableTitle={memorizedTableTitle}
          data={newData ?? null}
          fileUrlForContent={rawFileUrlForChart}
          setFileUrlForContent={setRawFileUrlForChart}
          dataType="raw"
          paging={rawDataPaging}
          setPaging={setRawDataPaging}
          isLoading={isFetching}
        />
      ) : (
        <DataLoadingText text={t("there_is_no_sensor")} />
      )}
    </>
  );
}

const RawMemo = React.memo(
  Raw,
  (prevProps, nextProps) => prevProps.params === nextProps.params
);
export default RawMemo;

import { theme } from "@src/styles/theme";

export interface DialogStyleProps {
  padding: number;
  minWidth?: number;
  maxWidth: number;
  minHeight?: number;
  titleFontSize: string;
  titleFontWeight: number;
  titleIconWidth: number;
  titleIconHeight: number;
}

export const SmallDialogStyles: DialogStyleProps = {
  padding: 60,
  maxWidth: 360,
  minHeight: 180,
  titleFontSize: theme.size.common.fontSize.md,
  titleFontWeight: theme.size.common.fontWeight.md,
  titleIconWidth: 20,
  titleIconHeight: 20,
};

export const NormalDialogStyles: DialogStyleProps = {
  ...SmallDialogStyles,
  padding: 40,
  maxWidth: 600,
  minHeight: 240,
  titleIconWidth: 30,
  titleIconHeight: 30,
};

export const BigDialogStyles: DialogStyleProps = {
  padding: 40,
  maxWidth: 1000,
  titleFontSize: theme.size.common.fontSize.lg,
  titleFontWeight: theme.size.common.fontWeight.lg,
  titleIconWidth: 36,
  titleIconHeight: 36,
};

import Icon from "@src/components/Icon";
import {
  Div,
  StyledExtendImageWrap,
  StyledFlexWrap,
  StyledFloatButonWrap,
  StyledPrimaryLabel,
  StyledPrimaryText,
} from "@src/styles/commonStyles";
import styled, { css } from "styled-components";
import { StyledLabelInputWrap } from "../styles";
import { theme } from "@src/styles/theme";
import React, { useEffect } from "react";
import IconButton from "@src/components/Buttons/IconButton";
import {
  NormalIconButtonStyles,
  SmallIconButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import Slide from "@src/components/Slide";
import { useTranslation } from "react-i18next";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { FlexAlignTypeConst } from "@src/constructs/common";

const StyledWrap = styled(Div)<{ fixHeight: number; isFucused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  width: 100%;
  height: ${({ fixHeight }) => fixHeight}px;
  background-color: ${({ theme }) => theme.color.input.background};
  border-radius: 4px;
  border: ${({ theme }) => `1px dashed ${theme.color.input.border}`};

  ${({ isFucused }) =>
    isFucused &&
    css`
      @keyframes paste {
        0% {
          border-color: ${({ theme }) => theme.color.primary};
        }
        49% {
          border-color: ${({ theme }) => theme.color.primary};
        }
        50% {
          border-color: ${({ theme }) => theme.color.primaryHover};
        }
        99% {
          border-color: ${({ theme }) => theme.color.primaryHover};
        }
        100% {
          border-color: ${({ theme }) => theme.color.primary};
        }
      }

      background-color: ${({ theme }) => theme.color.userInfoBox.background};
      border: 2px dashed ${theme.color.primary};
      animation: paste 1s infinite forwards;
      position: relative;
    `};
`;

const InnerWrapCss = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  aspect-ratio: 1/1;
  background-color: ${({ theme }) => theme.color.input.background};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.input.border};

  position: relative;

  cursor: pointer;
`;

const StyledImageWrap = styled(Div)`
  ${InnerWrapCss}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

interface CommonUploadImageProps {
  pasteRef: React.RefObject<HTMLButtonElement>;
  index: number;
  fixHeight: number;
  inputId: string;
  selcetedImageFiles: {
    files: (File | string)[];
    previews: string[];
  } | null;
  setSelectedImageFiles: React.Dispatch<
    React.SetStateAction<{
      files: (File | string)[];
      previews: string[];
    } | null>
  >;
  isPasting: boolean;
  handleIsPasting: (val: boolean) => void;
}

// Label Optional Props
type OptionalLabelProps =
  | {
      showLabel: true;
      labelText: string;
      labelDirection?: "TOP" | "LEFT";
    }
  | {
      showLabel?: false;
      labelText?: never;
      labelDirection?: never;
    };

type UploadImageProps = CommonUploadImageProps & OptionalLabelProps;

export default function UploadImage({
  pasteRef,
  index,
  fixHeight,
  showLabel,
  labelText,
  labelDirection,
  selcetedImageFiles,
  setSelectedImageFiles,
  inputId,
  isPasting,
  handleIsPasting,
}: UploadImageProps) {
  // Hook
  const { t } = useTranslation();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { isImage, useOutsideClickEffect } = useCommonHooks();

  // State
  const [isImageExtend, setIsImageExtend] = React.useState<number>(-1);

  // Ref
  const inputRef = React.useRef<HTMLInputElement>(null);
  const labelRef = React.useRef<HTMLSpanElement>(null);

  // handle outside click
  useOutsideClickEffect(pasteRef, isPasting, handleIsPasting, [
    labelRef,
    "dialog-confirm-btn",
    `upload-image-wrap-${index}`,
    "field-add-btn",
  ]);

  // 파일이 이미지인지 확인하고 추가하는 공통 함수 (업로드, 붙여넣기 둘다 사용)
  const handleFileAdd = async (fileList: FileList | null) => {
    return new Promise(
      (resolve: (value: string) => void, reject: (value: string) => void) => {
        if (fileList && fileList.length > 0) {
          // FileList를 배열로 변환
          const filesArray = Array.from(fileList);

          // 모든 파일에 대해 타입을 조회하고 이미지 파일만 선택
          const validImageFiles = filesArray.filter((file) => isImage(file));

          // 현재 추가된 이미지와 이미 추가되어있는 이미지 개수의 합이 5개가 넘는지 확인
          if (
            (selcetedImageFiles &&
              selcetedImageFiles.files.length + validImageFiles.length > 5) ||
            validImageFiles.length > 5
          ) {
            handleAlertDialogOpen(
              t("notification"),
              t("up_to_5_image_files_can_be_registered")
            );
            return;
          }

          if (validImageFiles.length > 0) {
            // 각 이미지 파일에 대해 base64로 디코딩하여 미리 보여주기
            const promises = validImageFiles.map((file) => {
              return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result as string;
                  resolve(base64String);
                };
                reader.readAsDataURL(file);
              });
            });

            // 모든 이미지 파일이 성공적으로 처리되면 상태 업데이트
            Promise.all(promises).then((base64Images) => {
              setSelectedImageFiles((prev) => {
                if (prev) {
                  const newData = {
                    files: [...prev.files, ...validImageFiles],
                    previews: [...prev.previews, ...base64Images],
                  };
                  return newData;
                }
                return {
                  files: validImageFiles,
                  previews: base64Images,
                };
              });
              resolve("SUCCESS");
            });
          } else {
            reject("There is no any image files");
          }
        } else {
          reject("There is no any files");
        }
      }
    );
  };

  // 파일 업로드로 이미지 첨부 시 핸들러
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await handleFileAdd(event.target.files)
      .catch((err) => {
        console.log("이미지 업로드 실패:", err);
      })
      .finally(() => {
        event.target.value = "";
      });
  };

  // 클립보드 이벤트로 이미지 첨부 시 핸들러
  const handleImagePaste = async (e: ClipboardEvent) => {
    e.preventDefault();

    if (e.clipboardData && e.clipboardData.files.length > 0) {
      await handleFileAdd(e.clipboardData.files);
    } else {
      handleAlertDialogOpen(t("notification"), t("no_file"));
      handleIsPasting(true);
    }
  };

  // 파일 삭제 핸들러
  const handleDeleteFile = (index?: number) => {
    if (index === undefined) {
      setSelectedImageFiles(null);
      return;
    }

    setSelectedImageFiles((prev) => {
      if (prev) {
        const newData = { ...prev };
        newData.files.splice(index, 1);
        newData.previews.splice(index, 1);

        return newData.files.length === 0 ? null : newData;
      }
      return null;
    });
  };

  // 붙여넣기 활성화 핸들러
  const handlePasteActivate = React.useCallback(() => {
    if (!selcetedImageFiles || selcetedImageFiles.files.length < 5) {
      handleIsPasting(!isPasting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasting, selcetedImageFiles]);

  // 업로드 파일 핸들러
  const handleUploadFile = React.useCallback(() => {
    inputRef.current?.click();
  }, []);

  // Effect
  useEffect(() => {
    setIsImageExtend(-1);
  }, [selcetedImageFiles]);

  useEffect(() => {
    if (selcetedImageFiles && selcetedImageFiles.files.length >= 5) {
      handleIsPasting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selcetedImageFiles]);

  // isPasting===true: 붙여넣기 이벤트 추가
  useEffect(() => {
    if (isPasting) {
      document.addEventListener("paste", handleImagePaste);
    }

    return () => {
      document.removeEventListener("paste", handleImagePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasting, selcetedImageFiles]);

  return (
    <StyledLabelInputWrap
      labelDirection={labelDirection}
      style={{
        width: "100%",
      }}
    >
      {showLabel && (
        <StyledPrimaryLabel
          ref={labelRef}
          style={{ cursor: "pointer" }}
          onClick={handlePasteActivate}
          labelLineHeight={labelDirection ? undefined : fixHeight ?? undefined}
        >
          {labelText}
        </StyledPrimaryLabel>
      )}
      <StyledWrap
        className={`upload-image-wrap-${index}`}
        fixHeight={fixHeight}
        isFucused={isPasting}
      >
        <input
          ref={inputRef}
          id={inputId}
          type="file"
          style={{
            display: "none",
          }}
          name="files[]"
          multiple
          onChange={handleImageUpload}
        />
        {selcetedImageFiles &&
        (selcetedImageFiles.files.length > 0 ||
          selcetedImageFiles.previews.length > 0) ? (
          <StyledFlexWrap
            gap={10}
            style={{
              flexWrap: "nowrap",
              overflowX: "auto",
            }}
          >
            {selcetedImageFiles?.files &&
            selcetedImageFiles?.files.length < 5 ? (
              <StyledImageWrap>
                <StyledFloatButonWrap>
                  <IconButton
                    buttonRef={pasteRef}
                    sizeTheme={SmallIconButtonStyles}
                    colorType="primary"
                    iconName="pasteImage"
                    onClick={handlePasteActivate}
                    isActive={isPasting}
                  />
                  <IconButton
                    sizeTheme={SmallIconButtonStyles}
                    colorType="primary"
                    iconName="uploadFile"
                    onClick={handleUploadFile}
                  />
                </StyledFloatButonWrap>
              </StyledImageWrap>
            ) : null}
            {selcetedImageFiles?.previews.map((item, index) => (
              <StyledImageWrap
                key={index}
                onClick={
                  isImageExtend !== -1
                    ? (e: React.MouseEvent<HTMLDivElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsImageExtend(-1);
                      }
                    : undefined
                }
              >
                <img src={item} alt={`upload preview-${index}`} />
                <StyledFloatButonWrap>
                  <IconButton
                    sizeTheme={SmallIconButtonStyles}
                    colorType="primary"
                    iconName="search"
                    onClick={() => {
                      setIsImageExtend(index);
                    }}
                  />
                  <IconButton
                    sizeTheme={SmallIconButtonStyles}
                    colorType="primary"
                    iconName="delete"
                    onClick={() => handleDeleteFile(index)}
                  />
                </StyledFloatButonWrap>
              </StyledImageWrap>
            ))}
          </StyledFlexWrap>
        ) : (
          <StyledFlexWrap
            flexDirection="column"
            gap={15}
            align={FlexAlignTypeConst.Center}
            vertical={FlexAlignTypeConst.Center}
          >
            <StyledFlexWrap align={FlexAlignTypeConst.Center}>
              <Icon
                iconName="addImage"
                iconWidth={24}
                iconHeight={24}
                iconColor={theme.color.primary}
              />
              <StyledFlexWrap>
                <StyledPrimaryText size="md" weight={400}>
                  Add Images
                </StyledPrimaryText>
                <span>JPG, PNG, GIF format</span>
              </StyledFlexWrap>
            </StyledFlexWrap>
            <StyledFlexWrap>
              <IconButton
                buttonRef={pasteRef}
                sizeTheme={NormalIconButtonStyles}
                colorType="primary"
                iconName="pasteImage"
                iconWidth={24}
                iconHeight={24}
                onClick={handlePasteActivate}
                text="Activate Paste"
                isActive={isPasting}
              />
              <IconButton
                sizeTheme={NormalIconButtonStyles}
                colorType="primary"
                iconName="uploadFile"
                iconWidth={24}
                iconHeight={24}
                onClick={handleUploadFile}
                text="Upload file"
              />
            </StyledFlexWrap>
          </StyledFlexWrap>
        )}
      </StyledWrap>
      {selcetedImageFiles && isImageExtend !== -1 ? (
        <StyledExtendImageWrap
          tabIndex={0}
          onClick={() => setIsImageExtend(-1)}
          style={{
            cursor: "pointer",
          }}
        >
          <Slide startIndex={isImageExtend} autoSlide={false}>
            {selcetedImageFiles.previews.map((item, index) => (
              <StyledFlexWrap
                key={index}
                flexDirection="column"
                style={{
                  display: "inline-flex",
                  maxWidth: "90%",
                  position: "relative",
                }}
              >
                <img src={item} alt={`device special info-${index}`} />
              </StyledFlexWrap>
            ))}
          </Slide>
        </StyledExtendImageWrap>
      ) : null}
    </StyledLabelInputWrap>
  );
}

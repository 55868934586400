import React, { useEffect } from "react";
import {
  StyledCameraDimContainer,
  StyledCameraMotionWrap,
  StyledChartGridWrap,
  StyledDateText,
  StyledDeviceIdText,
} from "./styles";
import useFetchChartData, {
  RangeSensorIdPagingRequestParams,
  S3JsonDataResponse,
} from "@src/fetch/fetchChart";
import Card from "@src/components/Card";
import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import { StyledFlexWrap, StyledPrimaryText } from "@src/styles/commonStyles";
import { FlexAlignTypeConst } from "@src/constructs/common";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useSearchParams } from "react-router-dom";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import ContentTable from "@src/components/ContentTable";
import { useMutation } from "@tanstack/react-query";
import useCommonHooks, {
  convertFileKeyToDateStr,
  getSensorLabel,
  isDataType,
} from "@src/hooks/useCommonHooks";
import fileSaver from "file-saver";
import Button from "@src/components/Buttons/Button";
import { NormalButtonStyles } from "@src/components/Buttons/sizeTheme";
import Pagination from "@src/components/Pagination";
import LoadingOnPage from "@src/components/Loadings/LoadingOnPage";
import useFilesDownloadHooks from "@src/hooks/useFilesDownloadHooks";
import Loading from "@src/components/Loadings/Loading";
import { useTranslation } from "react-i18next";

export interface CameraProps {
  selectedSensor: string;
  viewLarger: boolean;
  paging: PagingType;
  setPaging: (paging: PagingType) => void;
  params: RangeSensorIdPagingRequestParams | null;
}

export default function Camera({
  selectedSensor,
  viewLarger,
  paging,
  setPaging,
  params,
}: CameraProps) {
  // * Hooks
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { useCamereDataQuery } = useQueryHooks();
  const { FetchReadS3File } = useFetchChartData();
  const { useCustomLoading } = useCommonHooks();
  const { getImageBlob } = useFilesDownloadHooks("image");

  useCustomLoading();

  // * State
  const [firstDataInit, setFirstDataInit] = React.useState<boolean>(false);
  const [imageFileUrlForShow, setImageFileUrlForShow] =
    React.useState<string>("");
  const [isImageFloat, setIsImageFloat] = React.useState<number | null>(null);

  // * Queries
  const { isFetching, data: cameraData } = useCamereDataQuery(
    params as RangeSensorIdPagingRequestParams
  );

  // 카드형에서 JPG 다운로드 버튼 클릭해서 파일 다운로드하는 Mutation
  const {
    mutate: getS3FilesDownloadMutate,
    isLoading: isGetS3FilesMutateLoading,
  } = useMutation<
    S3JsonDataResponse | FileResponse | null,
    unknown,
    {
      fileKey: string;
    },
    unknown
  >((params) => FetchReadS3File(params, "image"), {
    onSuccess: (data) => {
      if (cameraData && isImageFloat !== null) {
        if (data && isDataType<FileResponse>(data, "file")) {
          const blob = getImageBlob([data])[0];
          fileSaver.saveAs(blob.file, blob.name);
        }
      }
    },
  });

  // * Functions
  // 카드를 클릭하면 이미지 다이얼로그가 뜨는 함수
  const handleCardClick = React.useCallback((index: number) => {
    setIsImageFloat(index);
  }, []);

  // X 버튼을 클릭하면 이미지 다이얼로그를 닫는 함수
  const handleCardClose = React.useCallback(() => {
    setIsImageFloat(null);
  }, []);

  // Card Dialog에서 JPG 저장 클릭하면 이미지 다운로드하는 함수
  const handleFileDownload = React.useCallback(() => {
    if (cameraData && isImageFloat !== null) {
      const params = {
        fileKey: cameraData.fileList[isImageFloat].fileKey,
      };
      getS3FilesDownloadMutate(params);
    }
  }, [isImageFloat, cameraData, getS3FilesDownloadMutate]);

  // Card Title 리턴
  const getCardTitle = (deviceId: string, date: string) => (
    <StyledFlexWrap
      align={FlexAlignTypeConst.SpaceBetween}
      vertical={FlexAlignTypeConst.Center}
      style={{
        width: "100%",
      }}
    >
      <StyledDeviceIdText>{deviceId}</StyledDeviceIdText>
      <StyledDateText>{t("filming_date", { date })}</StyledDateText>
    </StyledFlexWrap>
  );

  // * Effects
  useEffect(() => {
    if (cameraData) setFirstDataInit(true);
  }, [cameraData]);

  const memorizedTableTitle = React.useMemo(() => {
    return (
      <>
        <StyledDeviceIdText>
          {cameraData && cameraData.fileList.length > 0
            ? cameraData.fileList[0].deviceId
            : searchParams.get("deviceId") ?? "device"}
        </StyledDeviceIdText>
        <span>
          {`${
            cameraData && cameraData.fileList.length > 0
              ? getSensorLabel(cameraData.fileList[0].sensorId)
              : params
              ? getSensorLabel(params.sensorId)
              : getSensorLabel(searchParams.get("sensorId") ?? "") ?? ""
          } ${t("image")} ${t("file_list")}`}
        </span>
      </>
    );
  }, [cameraData, params, searchParams, t]);

  return (
    <>
      {isGetS3FilesMutateLoading ? <Loading /> : null}

      {firstDataInit && searchParams.get("sensorId") ? (
        viewLarger ? (
          <ContentTable
            data={cameraData ?? null}
            tableTitle={memorizedTableTitle}
            fileUrlForContent={imageFileUrlForShow}
            setFileUrlForContent={setImageFileUrlForShow}
            dataType="image"
            paging={paging}
            setPaging={setPaging}
            isLoading={isFetching}
          />
        ) : cameraData ? (
          <div
            style={{
              overflow: isFetching ? "hidden" : "auto",
            }}
          >
            <StyledFlexWrap
              align={FlexAlignTypeConst.FlexEnd}
              style={{
                marginBottom: 8,
              }}
            >
              <StyledPrimaryText size="md">
                Total: {cameraData.count}
              </StyledPrimaryText>
            </StyledFlexWrap>
            <StyledChartGridWrap viewLarger={viewLarger}>
              {cameraData.fileList.map((item: IFileListData, index: number) => {
                const formatDate = convertFileKeyToDateStr(item.fileKey);
                return (
                  <Card
                    key={index}
                    isClickable
                    cardClickEvent={() => handleCardClick(index)}
                    sizeTheme={NormalCardStyle}
                    title={getCardTitle(
                      cameraData.fileList[0].deviceId,
                      formatDate
                    )}
                  >
                    <StyledFlexWrap flexDirection="column">
                      <div>
                        <img
                          src={item.imageUrl}
                          alt="카메라 사진"
                          style={{
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                    </StyledFlexWrap>
                  </Card>
                );
              })}
            </StyledChartGridWrap>
            {isImageFloat !== null ? (
              <StyledCameraDimContainer
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <StyledCameraMotionWrap
                  initial={{
                    opacity: 0,
                    scale: 0.6,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={{ opacity: 0, scale: 0.6 }}
                  transition={{
                    duration: 0.3,
                    type: "spring",
                    damping: 40,
                    stiffness: 600,
                  }}
                >
                  <Card
                    sizeTheme={NormalCardStyle}
                    title={getCardTitle(
                      cameraData.fileList[isImageFloat].deviceId,
                      convertFileKeyToDateStr(
                        cameraData.fileList[isImageFloat].fileKey
                      )
                    )}
                    showCloseBtn
                    handleClose={handleCardClose}
                  >
                    <StyledFlexWrap
                      flexDirection="column"
                      vertical={FlexAlignTypeConst.FlexEnd}
                      gap={15}
                    >
                      <div
                        style={{
                          width: "100%",
                          maxHeight: "calc(100svh - 190px)",
                        }}
                      >
                        <img
                          src={cameraData.fileList[isImageFloat].imageUrl ?? ""}
                          alt="카메라 사진"
                          style={{
                            alignSelf: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                      <Button
                        sizeTheme={NormalButtonStyles}
                        colorType="primary"
                        type="button"
                        text={t("save_jpg")}
                        onClick={handleFileDownload}
                        isDisabled={isGetS3FilesMutateLoading}
                      />
                    </StyledFlexWrap>
                  </Card>
                </StyledCameraMotionWrap>
              </StyledCameraDimContainer>
            ) : null}
            <div>
              <Pagination
                page={paging.page}
                size={paging.size}
                count={cameraData.count}
                groupLength={5}
                showSizeSelect
                setPaging={setPaging}
                elementSize="normal"
              />
            </div>
          </div>
        ) : (
          <DataLoadingText text={t("no_data")} />
        )
      ) : (
        <DataLoadingText
          text={
            searchParams.get("sensorId") &&
            selectedSensor !== searchParams.get("sensorId")
              ? t("please_click_search_button")
              : !searchParams.get("sensorId")
              ? t("select_sensor")
              : t("no_data")
          }
        />
      )}
      {(!firstDataInit || !viewLarger) && isFetching ? (
        <LoadingOnPage parentId="chart-wrap" text={t("loading_data")} />
      ) : null}
    </>
  );
}

import { mediaQuery } from "@src/styles/theme";
import styled from "styled-components";

export const StyledPaginationWrap = styled.div<{ align: FlexAlignType }>`
  display: flex;
  justify-content: ${({ align }) => align};
  flex: 0 0 auto;
  align-items: center;
  row-gap: 15px;
`;

export const StyledIconButtonWrap = styled.div`
  display: inline-flex;

  ${mediaQuery.mobile} {
    & > div {
      min-width: 20px;
      min-height: 20px;
    }
    button {
      min-height: 20px;
    }
  }
`;

export const StyledNumberWrap = styled.div`
  display: flex;
  gap: 4px;

  margin: 0 6px;
`;
export const StyledNumber = styled.button<{ elementSize: "small" | "normal" }>`
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: ${({ elementSize }) =>
    elementSize === "small" ? "16px" : "24px"};
  height: ${({ elementSize }) => (elementSize === "small" ? "16px" : "24px")};
  color: ${({ theme }) => theme.color.paginaion.font};
  font-size: ${({ theme, elementSize }) =>
    elementSize === "small"
      ? theme.size.common.fontSize.xmd
      : theme.size.common.fontSize.md};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: ${({ elementSize }) => (elementSize === "small" ? "-4px" : "-6px")};
    width: 100%;
    height: ${({ elementSize }) => (elementSize === "small" ? "2px" : "3px")};
    background-color: transparent;
  }
  &::after {
    content: attr(data-num);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.color.primary};
  }

  &.active::before {
    background-color: ${({ theme }) => theme.color.primary};
  }

  ${mediaQuery.mobile} {
    width: 20px;
    height: 20px;

    font-size: ${({ theme }) => theme.size.common.fontSize.md};

    &::before {
      bottom: -4px;
      height: 2px;
    }
  }
`;

export const StyledSizeSelectWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

import { theme } from "@src/styles/theme";

export interface FloatBoxStyleProps {
  padding: number;
  minWidth?: number;
  maxWidth: number;
  minHeight?: number;
  titleFontSize: string;
  titleFontWeight: number;
  fontSize: string;
}

export const SmallFloatBoxStyles: FloatBoxStyleProps = {
  padding: 12,
  maxWidth: 360,
  minHeight: 180,
  titleFontSize: theme.size.common.fontSize.base,
  titleFontWeight: theme.size.common.fontWeight.md,
  fontSize: theme.size.common.fontSize.sm,
};

export const NormalFloatBoxStyles: FloatBoxStyleProps = {
  padding: 16,
  maxWidth: 480,
  minHeight: 240,
  titleFontSize: theme.size.common.fontSize.md,
  titleFontWeight: theme.size.common.fontWeight.lg,
  fontSize: theme.size.common.fontSize.sm,
};

import {
  commonChartStyleOptions,
  lineColorArray,
} from "@src/constructs/common";
import { DeviceStateDataResponse } from "@src/fetch/fetchChart";
import { getChartData, getPngFileTitle } from "@src/hooks/useCommonHooks";

class DeviceChartData {
  deviceGroupId: number;
  deviceGroupName: string;
  deviceId: string;
  deviceName: string;
  structureId: string;
  structureName: string;
  titleList:
    | {
        deviceId: string;
        secondText: string;
        isTranslationOnI18n: boolean;
      }[]
    | null;
  chartList: ChartProps[] | null;

  constructor(item: DeviceStateDataResponse) {
    this.deviceGroupId = item.deviceGroupId;
    this.deviceGroupName = item.deviceGroupName;
    this.deviceId = item.deviceId;
    this.deviceName = item.deviceName;
    this.structureId = item.structureId;
    this.structureName = item.structureName;
    this.titleList =
      item.deviceStates && item.deviceStates.length > 0
        ? item.deviceStates.map((state) => ({
            deviceId: item.deviceId,
            secondText: state.type,
            isTranslationOnI18n: true,
          }))
        : null;
    this.chartList =
      item.deviceStates && item.deviceStates.length > 0
        ? item.deviceStates.map((state, index) => ({
            dataType: state.type,
            pngFileTitle: getPngFileTitle(item.deviceId, "status"),
            datasets: [
              {
                label: state.type,
                data: getChartData(state.data, "value"),
                borderColor: lineColorArray[index],
                backgroundColor: lineColorArray[index],
                ...commonChartStyleOptions.common,
                hidden: false,
              },
            ],
            labels: getChartData(state.data, "time"),
          }))
        : null;
  }
}

export default DeviceChartData;

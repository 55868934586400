import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SignUp from "@src/pages/AuthPage/SignUp";
import React, { useEffect } from "react";
import { User } from "firebase/auth";
import { auth } from "@src/firebase";
import Login from "@src/pages/AuthPage/Login";
import { useLoggedInStore } from "@src/stores/useCommonStore";
import AdditionalWork from "@src/pages/AuthPage/AdditionalWork";
import ResetPassword from "@src/pages/AuthPage/ResetPassword";
import LoadingRandomIcon from "@src/components/Loadings/LoadingRandomIcon";

export default function AuthRoutes() {
  const { pathname } = useLocation();
  const loggedIn = useLoggedInStore();
  const [authRouterUser, setAuthRouterUser] = React.useState<User | null>(null);
  const [authRouterLoading, setAuthRouterLoading] =
    React.useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthRouterUser(user);
      } else {
        setAuthRouterUser(null);
      }
      setAuthRouterLoading(false); // 데이터 로딩 완료 후 로딩 상태 변경
    });

    return () => unsubscribe();
  }, []);

  if (authRouterLoading) {
    return <LoadingRandomIcon />;
  }

  if (
    !authRouterLoading &&
    authRouterUser &&
    loggedIn &&
    !pathname.includes("/additionalWork")
  ) {
    return <Navigate to="/home" />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/additionalWork" element={<AdditionalWork />} />
    </Routes>
  );
}

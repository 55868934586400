import AuthLayout from "@layout/AuthLayout";
import Input from "@components/Inputs/Input";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@src/components/Buttons/Button";
import {
  BigButtonStyles,
  NormalButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "@src/firebase";
import { useNavigate } from "react-router-dom";
import { useCommonStoreActions } from "@src/stores/useCommonStore";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import { StyledButtonWrap, StyledTitle } from "../styles";
import { StyledTextUnderlineButton } from "@src/styles/commonStyles";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";

interface IUser {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("not_valid_email_format")
    .required("email_is_required"),
  password: yup
    .string()
    .required("password_is_required")
    .min(12, "enter_at_least_12")
    .max(40, "enter_up_to_40")
    .matches(
      // eslint-disable-next-line
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{12,}[^\s]*$/,
      "password_guide_line"
    ),
});

export default function LoginFirebase() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoggedIn } = useCommonStoreActions();
  const { handleSendEmailVerification } = useFirebaseHooks();
  const { handleAlertDialogOpen } = useAlertDialog();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // 초기값 설정
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: IUser) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        if (!user.emailVerified) {
          // 이메일 인증 X
          handleAlertDialogOpen(t("complete_email_verification_title"), [
            <span>{t("complete_email_verification_message")}</span>,
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Button
                type="button"
                colorType="primary"
                sizeTheme={NormalButtonStyles}
                text={t("receive_verification_email_again")}
                fixWidth="100%"
                onClick={() => handleSendEmailVerification(user)}
              />
            </div>,
          ]);

          signOut(auth)
            .then(() => {
              // Sign-out successful.
            })
            .catch((error) => {
              // An error happened.
              console.log("Falied Logout Firebase", error);
            });
        } else {
          setLoggedIn(true);
          navigate("/home");
        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-login-credentials": // 로그인 실패
            handleAlertDialogOpen(t("notification"), t("user_not_found"));
            break;
          case "auth/too-many-requests": // 요청 초과
            handleAlertDialogOpen(t("notification"), t("try_again_later"));
            break;
        }
      });
  };

  return (
    <AuthLayout>
      <div>
        <StyledTitle>Welcome Back!</StyledTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              inputName="login-email"
              placeholder={t("please_enter_email")}
              type="text"
              eventType="UseForm"
              sizeTheme={NormalInputStyle}
              register={{
                ...register("email"),
              }}
              setFocus={() => setFocus("email")}
              isInvalid={errors.email !== undefined}
              errorMessages={[errors.email?.message as string]}
              addonShow={true}
              addonDirection="left"
              addonName="user"
              isShowLabel
              labelDirection="TOP"
              labelText={t("email")}
              isFixBottomMargin
              labelType="AUTH"
            />
            <Input
              inputName="login-pw"
              placeholder={t("please_enter_password")}
              type="password"
              sizeTheme={NormalInputStyle}
              eventType="UseForm"
              register={{
                ...register("password"),
              }}
              setFocus={() => setFocus("password")}
              isInvalid={errors.password !== undefined}
              errorMessages={[errors.password?.message as string]}
              addonShow
              addonDirection="left"
              addonName="lock"
              isShowLabel
              labelDirection="TOP"
              labelText={t("password")}
              isFixBottomMargin
              labelType="AUTH"
            />
            <Button
              type="submit"
              colorType="primary"
              sizeTheme={BigButtonStyles}
              text={t("login")}
              fixWidth="100%"
            />
          </div>
        </form>
        <StyledButtonWrap>
          <StyledTextUnderlineButton
            type="button"
            onClick={() => navigate("/auth/signUp")}
          >
            {t("simply_sign_up_with_email_password")}
          </StyledTextUnderlineButton>
          <StyledTextUnderlineButton
            type="button"
            onClick={() => navigate("/auth/resetPassword")}
          >
            {t("reset_password")}
          </StyledTextUnderlineButton>
        </StyledButtonWrap>
      </div>
    </AuthLayout>
  );
}

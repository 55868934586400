import React, { useEffect } from "react";
import ReactSelect, { GroupBase, SingleValue } from "react-select";
import ReactSelectType from "react-select/dist/declarations/src/Select";
import { StyledErrorText, StyledPrimaryLabel } from "@src/styles/commonStyles";
import {
  StyledSelect,
  StyledSelectBox,
  StyledSelectFloat,
  StyledSelectWithLabelWrap,
} from "../../styles";
import useCommonHooks from "@src/hooks/useCommonHooks";
import Portal from "@src/Portal";
import { KEY_TYPE } from "@constructs/common";
import { StyledTextWrap } from "../../styles";
import { InputStyleProps } from "../../sizeTheme";
import Icon from "@src/components/Icon";
import { theme } from "@src/styles/theme";
import { useTranslation } from "react-i18next";

export const initialSelectOption = {
  value: "",
  label: "",
} as const;

interface CommonSelectProps {
  sizeTheme: InputStyleProps;
  name: string;
  options?: SelectOption[];
  placeholder?: string;
  value?: SelectOption;
  index?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isInvalid?: boolean;
  errorMessages?: string[];
  event: (value: SelectOption) => any;
  fixWidth: number;
  showOnlyValue?: boolean;
}

// Label Optional Props
type OptionalLabelProps =
  | {
      showLabel: true;
      labelText: string;
      labelDirection?: "TOP" | "LEFT";
      labelFixWidth?: number;
    }
  | {
      showLabel?: false;
      labelText?: never;
      labelDirection?: never;
      labelFixWidth?: never;
    };

type OptionalAddonProps =
  | {
      showAddon: true;
      addonName: string;
    }
  | {
      showAddon?: false;
      addonName?: never;
    };

export type SelectProps = CommonSelectProps &
  OptionalLabelProps &
  OptionalAddonProps;

function SelectComponent({
  sizeTheme,
  name,
  options,
  placeholder,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  isInvalid,
  errorMessages,
  event,
  fixWidth,
  value,
  showOnlyValue,
  showLabel,
  labelText,
  showAddon,
  addonName,
  labelDirection,
  labelFixWidth,
}: SelectProps) {
  // hook
  const { t } = useTranslation();
  const { useOutsideClickEffect, handleKeyDown, useScrollToSelectedOption } =
    useCommonHooks();

  // Ref
  const selectLabelRef = React.useRef<HTMLLabelElement>(null);
  const selectWrapRef = React.useRef<HTMLDivElement>(null);
  const selectRef =
    React.useRef<ReactSelectType<SelectOption, false, GroupBase<SelectOption>>>(
      null
    );
  const optionsRef = React.useRef<HTMLDivElement>(null);

  // States
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<SelectOption>(
    value ?? initialSelectOption
  );
  const [tabFocus, setTabFocus] = React.useState<boolean>(false);

  // Functions
  const handleMenuIsOpen = () => !menuIsOpen && setMenuIsOpen(true);
  const handleKeyDownOpen = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.target === selectWrapRef.current) {
      handleKeyDown(e, [KEY_TYPE.ENTER, KEY_TYPE.DOWN], handleMenuIsOpen);
    }
  };

  // Select Value Change
  const handleSelectValue = (newValue: SingleValue<SelectOption>) => {
    if (newValue) {
      setSelectedValue(newValue);
      event(newValue);
    } else {
      setSelectedValue(initialSelectOption);
      event(initialSelectOption);
    }
  };

  // Effects
  // Select Vlaue가 선택(변경)된 경우, Input은 읽기만 가능하도록 변경
  useEffect(() => {
    setMenuIsOpen(false);
    setTabFocus(false);
    if (selectRef.current && selectRef.current.inputRef) {
      if (selectedValue.value && selectedValue.label) {
        selectRef.current.inputRef.readOnly = true;
      } else {
        selectRef.current.inputRef.readOnly = false;
      }
    }
  }, [selectedValue]);

  // Option box 오픈될 때만 Custom Focusing 추가 (Clear 버튼 클릭 시 Focusing 되는 에러 해결)
  useEffect(() => {
    if (selectRef.current && selectRef.current.controlRef) {
      if (menuIsOpen) {
        selectRef.current.controlRef.className += " custom-focusing";
      } else {
        selectRef.current.controlRef.className.replace(" custom-focusing", "");
      }
    } else if (!menuIsOpen) {
      setTabFocus(false);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    if (selectRef.current && selectRef.current.controlRef) {
      if (tabFocus) {
        selectRef.current.controlRef.className += " custom-focusing";
      } else {
        selectRef.current.controlRef.className.replace(" custom-focusing", "");
      }
    }

    if (!tabFocus && selectRef.current && selectRef.current.controlRef) {
      selectRef.current.controlRef.className.replace(" custom-focusing", "");
    }
  }, [tabFocus]);

  useEffect(() => {
    if (
      (options &&
        value &&
        options.findIndex((item) => item.value === value.value)) !== -1
    ) {
      if (value) setSelectedValue(value);
    } else {
      setSelectedValue(initialSelectOption);
      event(initialSelectOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  // handle outside click
  useOutsideClickEffect(selectWrapRef, menuIsOpen, setMenuIsOpen, [
    selectLabelRef,
    optionsRef,
  ]);

  // handle options scroll to selected option
  useScrollToSelectedOption(optionsRef, menuIsOpen);

  return (
    <>
      <StyledSelectWithLabelWrap labelDirection={labelDirection}>
        {showLabel && (
          <StyledPrimaryLabel
            ref={selectLabelRef}
            style={{ cursor: "pointer" }}
            onClick={() => setMenuIsOpen((prev) => !prev)}
            labelLineHeight={
              labelDirection === "TOP"
                ? undefined
                : sizeTheme.height ?? undefined
            }
            labelFixWidth={labelFixWidth}
          >
            <span>{labelText}</span>
          </StyledPrimaryLabel>
        )}
        <StyledSelect
          menuIsOpen={menuIsOpen}
          sizeTheme={sizeTheme}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          fixWidth={fixWidth}
          showOnlyValue={showOnlyValue}
        >
          <StyledSelectBox
            tabIndex={tabFocus ? -1 : 0}
            ref={selectWrapRef}
            onClick={handleMenuIsOpen}
            onTouchEnd={handleMenuIsOpen}
            onKeyDown={handleKeyDownOpen}
            onFocus={() => setTabFocus(true)}
            onBlur={() => setTabFocus(false)}
            className={tabFocus ? "tab-focused" : undefined}
          >
            {showAddon ? (
              <div className="select-addon">
                <Icon
                  iconName={addonName}
                  iconWidth={16}
                  iconHeight={16}
                  iconColor={theme.color.input.border}
                />
              </div>
            ) : null}
            <ReactSelect
              tabIndex={-1}
              ref={selectRef}
              name={name}
              menuIsOpen={menuIsOpen}
              className="basic-single"
              classNamePrefix="select"
              placeholder={placeholder ?? "Select Option"}
              value={
                selectedValue.value && selectedValue.label
                  ? selectedValue
                  : null
              }
              onChange={handleSelectValue}
              options={options}
              isDisabled={isDisabled ?? false}
              isLoading={isLoading ?? false}
              isClearable={isClearable ?? false}
              isSearchable={isSearchable ?? false}
              isMulti={false}
              blurInputOnSelect
              closeMenuOnSelect
              menuPortalTarget={
                document.querySelector(`.${name}-options`) as HTMLElement
              }
              menuPlacement="auto"
            />
          </StyledSelectBox>
          {isInvalid && (
            <StyledTextWrap
              sizeTheme={sizeTheme}
              style={{
                marginBottom: 8,
              }}
            >
              {errorMessages &&
                errorMessages.map((item: string, index: number) => (
                  <StyledErrorText key={index}>{t(item)}</StyledErrorText>
                ))}
            </StyledTextWrap>
          )}
        </StyledSelect>
      </StyledSelectWithLabelWrap>

      {/* Select Option Box Portal */}
      <Portal rootId="select-portal">
        <StyledSelectFloat
          ref={optionsRef}
          className={`${name}-options`}
          sizeTheme={sizeTheme}
          showAddon={showAddon}
        />
      </Portal>
    </>
  );
}

const Select = React.memo(SelectComponent, (prev, next) => {
  return (
    prev.value?.value === next.value?.value &&
    prev.value?.label === next.value?.label &&
    prev.options === next.options &&
    prev.isInvalid === next.isInvalid &&
    prev.errorMessages === next.errorMessages
  );
});

export default Select;

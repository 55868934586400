import Icon from "@src/components/Icon";
import React, { useEffect } from "react";
import { StyledCheckbox, StyledCheckboxWrap } from "../styles";
import { CheckboxStyleProps, NoramlCheckboxStyle } from "../sizeTheme";

interface AllCheckboxProps {
  sizeTheme?: CheckboxStyleProps;
  fixWidth?: number;
  name: string;
  colorType: "primary" | "secondary" | "table";
  isCheckedArray: boolean[];
  label?: string;
  isDisabled?: boolean;
  handler: (allCheck: boolean, visibleIndexes?: number[]) => void;
  visibleIndexes?: number[];
}

export function AllCheckboxComponent({
  sizeTheme = NoramlCheckboxStyle,
  fixWidth,
  name,
  colorType,
  isCheckedArray,
  label,
  isDisabled,
  handler,
  visibleIndexes,
}: AllCheckboxProps) {
  const [ischecked, setIsChecked] = React.useState<boolean>(false);
  const [isSomeChecked, setIsSomeChecked] = React.useState<boolean>(false);

  const onChange = () => {
    let isAllTrue = false;

    if (visibleIndexes) {
      // visibleIndexes가 있을 경우 전체 중 해당 번호들만
      isAllTrue = isCheckedArray
        .filter((_, i) => visibleIndexes.includes(i))
        .every((item) => item === true);
    } else {
      // 전부 다 true일 때 전체를 False로 변경
      // 1개 이상 False일 때 전체를 True로 변경
      isAllTrue = isCheckedArray.every((item) => item === true);
    }

    handler(!isAllTrue, visibleIndexes);
    setIsSomeChecked(false);
  };

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onChange();
    }
  };

  // ischecked, isSomeChecked 체크
  useEffect(() => {
    if (isCheckedArray.length > 0) {
      setIsChecked(isCheckedArray.every((item) => item === true));
    }

    const isAllTrue = isCheckedArray.filter((item) => item === true);

    if (isAllTrue.length > 0 && isAllTrue.length !== isCheckedArray.length) {
      setIsSomeChecked(true);
    } else {
      setIsSomeChecked(false);
    }
  }, [isCheckedArray]);

  return (
    <StyledCheckboxWrap
      textSize={sizeTheme.fontSize}
      textWeight={sizeTheme.fontWeight}
      fixWidth={fixWidth}
    >
      <label
        htmlFor={`checkbox-${name}-all-control`}
        style={{
          cursor: "pointer",
        }}
        tabIndex={0}
        onKeyDown={handleKeydown}
      >
        <StyledCheckbox
          isSomeChecked={isSomeChecked}
          isCheckedArray={ischecked}
          isDisabled={isDisabled}
          colorType={colorType}
          checkboxWidth={sizeTheme.boxWidth}
          checkboxHeight={sizeTheme.boxHeight}
        >
          {isSomeChecked && !ischecked ? (
            <Icon
              iconName="indeterminateCheck"
              iconWidth={sizeTheme.iconWidth}
              iconHeight={sizeTheme.boxWidth === 20 ? 4 : 3}
            />
          ) : (
            <Icon
              iconName="check"
              iconWidth={sizeTheme.iconWidth}
              iconHeight={sizeTheme.iconHeight}
              iconColor="tranparent"
            />
          )}
          <input
            id={`checkbox-${name}-all-control`}
            name={name}
            type="checkbox"
            checked={ischecked}
            disabled={isDisabled}
            onChange={onChange}
            style={{
              display: "none",
            }}
          />
        </StyledCheckbox>
        {label && <span>{label}</span>}
      </label>
    </StyledCheckboxWrap>
  );
}

/**
 * @Description
 *  - isCheckedArray가 이전과 같을 경우 재렌더링하지 않는다.
 *  - handler로 보내는 isCheckedArray setState 업데이트할 때 되도록 splice를 사용하도록 한다.
 * -------------
 * @Param
 * - colorType: "primary" | "secondary" | "table"
 * - isCheckedArray: boolean[];
 * - lable?: string (체크박스 우측에 표시될 문자열)
 * - isDisabled?: boolean (체크박스 disabled)
 * - handler: (allCheck: boolean) => void;
 * ------------------------------------------------
 */
const AllCheckbox = React.memo(
  AllCheckboxComponent,
  (prev, next) =>
    prev.isCheckedArray === next.isCheckedArray &&
    prev.visibleIndexes === next.visibleIndexes &&
    prev.isDisabled === next.isDisabled
);
export default AllCheckbox;

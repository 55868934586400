import { useCallback, ReactNode } from "react";
import { createPortal } from "react-dom";
import {
  StlyedDialogFooter,
  StlyedDialogWrap,
  StyledContentWrap,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDimContainer,
  StyledTitleBox,
} from "@src/components/Dialogs/styles";
import Icon from "@src/components/Icon";
import {
  StyledFlexWrap,
  StyledIconButton,
  StyledIconFloatWrap,
} from "@src/styles/commonStyles";
import { DialogButtonStyles } from "@src/components/Buttons/sizeTheme";
import Button from "@src/components/Buttons/Button";
import { DialogStyleProps, SmallDialogStyles } from "../sizeTheme";
import { FlexAlignTypeConst, ELEMENT_Z_INDEX } from "@constructs/common";
import React from "react";
import { useTranslation } from "react-i18next";

interface DialogProps {
  sizeTheme: DialogStyleProps;
  children: ReactNode;
  title?: ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmEvent?: () => Promise<void> | void;
  cancelEvent?: () => void;
  cancelPromiseEvent?: () => Promise<void>;
  customMaxWidth?: number;
  cancelButtonText?: string;
  confirmButtonText?: string;
  rightCloseEvent?: () => void;
  rightClosePromiseEvent?: () => Promise<void>;
}

/**
 * z-index - 1300
 * - 확인 누르면 한번 더 확인하는 경우 useDialogStore의 confirmType 사용
 * @returns Dialog
 */
function Dialog({
  sizeTheme,
  children,
  title,
  isOpen,
  setIsOpen,
  confirmEvent,
  cancelEvent,
  cancelPromiseEvent,
  customMaxWidth,
  cancelButtonText,
  confirmButtonText,
  rightCloseEvent,
  rightClosePromiseEvent,
}: DialogProps) {
  const { t } = useTranslation();
  const dialogRoot = document.getElementById("dialog") as HTMLElement;

  // hook
  // const { isDialogOpen, dialogTitle, cancelDialogOpen } = useDialog();

  const handleConfirmClick = useCallback(() => {
    if (confirmEvent) confirmEvent();
  }, [confirmEvent]);

  const handleCancelClick = useCallback(() => {
    // 닫을 때 지워야하는 value등 초기화하는 event
    if (cancelPromiseEvent) {
      cancelPromiseEvent();
      return;
    } else if (cancelEvent) {
      cancelEvent();
    }

    setIsOpen(false);
  }, [cancelPromiseEvent, cancelEvent, setIsOpen]);

  const handleRightCloseClick = useCallback(() => {
    // 우측 상단 클로즈 버튼 이벤트 분리할 때
    if (rightCloseEvent) {
      rightCloseEvent();
    } else if (rightClosePromiseEvent) {
      rightClosePromiseEvent();
      return;
    } else if (cancelPromiseEvent) {
      // 닫을 때 지워야하는 value등 초기화하는 event
      cancelPromiseEvent();
      return;
    } else if (cancelEvent) {
      cancelEvent();
    }

    setIsOpen(false);
  }, [
    cancelPromiseEvent,
    cancelEvent,
    setIsOpen,
    rightCloseEvent,
    rightClosePromiseEvent,
  ]);

  return isOpen
    ? createPortal(
        <StyledDimContainer
          open={isOpen}
          customZIndex={ELEMENT_Z_INDEX.DIALOG}
          sizeTheme={sizeTheme}
          customMaxWidth={customMaxWidth}
        >
          <StlyedDialogWrap>
            <StyledDialogHeader>
              <StyledTitleBox>
                <h4>{title}</h4>
              </StyledTitleBox>
              <StyledIconFloatWrap floatTop={10} floatRight={10}>
                <StyledIconButton type="button" onClick={handleRightCloseClick}>
                  <Icon
                    iconName="close"
                    iconWidth={SmallDialogStyles.titleIconWidth}
                    iconHeight={SmallDialogStyles.titleIconHeight}
                  />
                </StyledIconButton>
              </StyledIconFloatWrap>
            </StyledDialogHeader>
            <StyledDialogBody>
              <StyledContentWrap>{children}</StyledContentWrap>
            </StyledDialogBody>
            <StlyedDialogFooter>
              <StyledFlexWrap
                align={FlexAlignTypeConst.FlexEnd}
                gap={12}
                rowGap={10}
                columnGap={10}
              >
                <Button
                  type="button"
                  colorType="secondary"
                  sizeTheme={DialogButtonStyles}
                  text={cancelButtonText ?? t("cancel")}
                  onClick={handleCancelClick}
                />
                <Button
                  className="dialog-confirm-btn"
                  type="button"
                  colorType="primary"
                  sizeTheme={DialogButtonStyles}
                  text={confirmButtonText ?? t("confirm")}
                  onClick={handleConfirmClick}
                />
              </StyledFlexWrap>
            </StlyedDialogFooter>
          </StlyedDialogWrap>
        </StyledDimContainer>,
        dialogRoot
      )
    : null;
}

const DialogMemo = React.memo(
  Dialog,
  (prevProps, nextProps) =>
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.title === nextProps.title &&
    prevProps.children === nextProps.children &&
    prevProps.cancelButtonText === nextProps.cancelButtonText &&
    prevProps.confirmButtonText === nextProps.confirmButtonText &&
    prevProps.confirmEvent === nextProps.confirmEvent &&
    prevProps.cancelEvent === nextProps.children
);
export default DialogMemo;

import React from "react";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { RangeOptionalSearchPagingRequestParams } from "@src/fetch/fetchCommon";
import ContentTable from "@src/components/ContentTable";
import {
  useEventRawDataPaging,
  useQueryActions,
} from "@src/stores/useQueryStore";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import { DateTypeConst } from "@src/constructs/common";
import TableSearchRow from "@src/components/Searchbar/TableSearchRow";
import {
  StyledGridWrap,
  StyledRelativeFullWrap,
} from "@src/styles/commonStyles";
import { useTranslation } from "react-i18next";

const searchTypeOptions = [
  { value: "deviceGroupName", label: "그룹 이름" },
  { value: "deviceId", label: "디바이스 아이디" },
  { value: "deviceName", label: "디바이스명" },
  { value: "structureName", label: "설치 위치" },
];

export default function EventPage() {
  // * Hooks
  const { t } = useTranslation();
  const { useDtriggerFileListQuery } = useQueryHooks();
  const paging = useEventRawDataPaging();
  const { setEventRawDataPaging: setPaging } = useQueryActions();
  const { useCustomLoading, useUpdateParamsOnPageChange } = useCommonHooks();

  // * State
  const [params, setParams] =
    React.useState<RangeOptionalSearchPagingRequestParams | null>(null);
  const [fileUrlForContent, setFileUrlForContent] = React.useState<string>("");

  // * Dates
  const now = new Date();
  const maxDate = new Date();
  const minDate = new Date(now.setFullYear(now.getFullYear() - 5));

  // * QUERY
  const { data, isFetching } = useDtriggerFileListQuery(
    params as RangeOptionalSearchPagingRequestParams
  );

  useCustomLoading();

  // * Function
  const getUrlParams = React.useCallback(
    (data: SearchParameterValues | null) => {
      if (data) {
        if (data.searchType && data.searchValue) {
          setParams({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            size: paging.size,
            page: paging.page,
            searchType: data.searchType,
            searchValue: data.searchValue,
          });
        } else {
          setParams({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            size: paging.size,
            page: paging.page,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paging]
  );

  // 페이징 변경 시 파라미터 업데이트
  useUpdateParamsOnPageChange(paging, setParams);

  return (
    <StyledGridWrap
      templateColumns="100%"
      templateRows="max-content minmax(400px, 1fr)"
    >
      <TableSearchRow
        inCard
        cardSizeTheme={NormalCardStyle}
        getUrlParams={getUrlParams}
        searchTypeOptions={searchTypeOptions}
        searchedDate
        dateType={DateTypeConst.Range}
        paging={paging}
        setPaging={setPaging}
        hasContentUrl
        minDate={minDate}
        maxDate={maxDate}
      />
      <StyledRelativeFullWrap id="chart-wrap">
        <ContentTable
          dataType="event"
          data={data ?? null}
          tableTitle={`${t("event_data")} ${t("file_list")}`}
          isLoading={isFetching}
          fileUrlForContent={fileUrlForContent}
          setFileUrlForContent={setFileUrlForContent}
          paging={paging}
          setPaging={setPaging}
        />
      </StyledRelativeFullWrap>
    </StyledGridWrap>
  );
}

import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { theme } from "@src/styles/theme";
import IconButton from "@src/components/Buttons/IconButton";
import { BigNoSpaceIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import { useBrowserSizeStore } from "@src/stores/useCommonStore";
import { BrowserSizeConst, FlexAlignTypeConst } from "@src/constructs/common";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { StyledFlexWrap } from "@src/styles/commonStyles";

const variants = {
  //등장 애니메이션 (애니메이션 진행률:0%)
  enter: (goBack: boolean) => ({
    x: goBack ? "-100%" : "100%",
    opacity: 0,
  }),
  //메인 애니메이션 (50%, 슬라이드가 가운데로 왔을 때의 상태)
  center: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 },
  },
  //종료 애니메이션 (100%)
  exit: (goBack: boolean) => ({
    x: goBack ? "100%" : "-100%",
    opacity: 0,
    transition: { duration: 0.5 },
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface SlideProps {
  children: JSX.Element[];
  getChartTitle?: (index: number) => void;
  startIndex?: number;
  autoSlide: boolean;
}

export const Slide = ({
  children,
  getChartTitle,
  startIndex,
  autoSlide,
}: SlideProps) => {
  const browserSize = useBrowserSizeStore();
  const { useTimeout } = useCommonHooks();

  const [init, setInit] = React.useState<boolean>(false);
  const [goBack, setGoBack] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = React.useState<number>(startIndex ?? 0);

  useTimeout(
    () => {
      if (activeIndex === children.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex((prev) => prev + 1);
      }
    },
    8000,
    activeIndex,
    autoSlide
  );

  React.useEffect(() => {
    setActiveIndex(startIndex ?? 0);
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (getChartTitle) {
      getChartTitle(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <StyledFlexWrap
      align={FlexAlignTypeConst.Center}
      vertical={FlexAlignTypeConst.Center}
      flexDirection="column"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
          height: "100%",
          flex: 1,
        }}
      >
        <AnimatePresence custom={goBack}>
          <motion.div
            key={activeIndex}
            variants={variants}
            custom={goBack}
            initial={!init ? "center" : "enter"}
            animate="center"
            exit="exit"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                setActiveIndex((prev) =>
                  prev === children.length - 1 ? 0 : prev + 1
                );
                setGoBack(false);
              } else if (swipe > swipeConfidenceThreshold) {
                setActiveIndex((prev) =>
                  prev === 0 ? children.length - 1 : prev - 1
                );
                setGoBack(true);
              }
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            {children[activeIndex]}
          </motion.div>
        </AnimatePresence>

        {/* 슬라이더 */}
        {children.length > 1 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: 0,
              }}
            >
              <IconButton
                colorType="custom"
                sizeTheme={BigNoSpaceIconButtonStyles}
                onClick={() => {
                  setActiveIndex((prev) =>
                    prev === 0 ? children.length - 1 : prev - 1
                  );
                  setGoBack(true);
                }}
                iconName="slide"
                iconWidth={
                  browserSize && browserSize >= BrowserSizeConst.DesktopSmall
                    ? 36
                    : 24
                }
                iconHeight={
                  browserSize && browserSize >= BrowserSizeConst.DesktopSmall
                    ? 36
                    : 24
                }
                iconColor="rgba(0,0,0,0.2)"
                iconHoverColor={theme.color.primary}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                right: 0,
                transform: "rotateZ(180deg)",
              }}
            >
              <IconButton
                colorType="custom"
                sizeTheme={BigNoSpaceIconButtonStyles}
                onClick={() => {
                  setActiveIndex((prev) =>
                    prev === children.length - 1 ? 0 : prev + 1
                  );
                  setGoBack(false);
                }}
                iconName="slide"
                iconWidth={
                  browserSize && browserSize >= BrowserSizeConst.DesktopSmall
                    ? 36
                    : 24
                }
                iconHeight={
                  browserSize && browserSize >= BrowserSizeConst.DesktopSmall
                    ? 36
                    : 24
                }
                iconColor="rgba(0,0,0,0.2)"
                iconHoverColor={theme.color.primary}
              />
            </div>
          </>
        ) : null}
      </div>
      {/* 인디케이터 */}
      {children.length > 1 ? (
        <div
          style={{
            flex: "0 0 auto",
            display: "flex",
            gap: 8,
            marginTop: 6,
            position: "relative",
          }}
        >
          {Array.isArray(children) &&
            children.map((_item, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    background:
                      activeIndex === index
                        ? theme.color.primary
                        : "rgba(0,0,0,0.2)",
                  }}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                />
              );
            })}
        </div>
      ) : null}
    </StyledFlexWrap>
  );
};

export default Slide;

import { Div, StyledFloatBox } from "@src/styles/commonStyles";
import styled from "styled-components";

export const StyledDateWrap = styled(Div)<{ fixWidth: number }>`
  display: inline-flex;
  flex-direction: column;
  width: ${({ fixWidth }) => fixWidth}px;
`;

export const StyledCalrendar = styled(StyledFloatBox)`
  .react-datepicker__day {
    background-color: unset !important;
  }

  /* Calendar Initial */
  &.react-datepicker {
    display: flex;
    gap: 20px;

    font-family: "Pretendard Variable", Pretendard, -apple-system,
      BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

    padding: 6px 14px;
    box-shadow: unset;
    border: 0;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    display: none;
  }
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    font-weight: 600;
    font-size: 1rem;
  }

  .react-datepicker__header {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    padding: 6px 0;

    & > * {
      margin: 0;
    }

    .react-datepicker__month-dropdown-container {
      order: 2;
    }
  }

  .react-datepicker__month {
    margin: 0;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .react-datepicker__week {
    font-size: 0;
    display: flex;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    padding: 0px 8px;
    margin: 0;
    font-size: 0.8rem;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
  }

  /* Calendar Header */
  .custom-react-datepicker__select-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    position: relative;

    .prev,
    .next {
      position: absolute;
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
    }
  }

  .react-datepicker__month-container:nth-of-type(1) {
    .react-datepicker__current-month {
      display: none;
    }

    &.react-datepicker__month-container:not(:last-child) .next {
      display: none;
    }
  }

  .react-datepicker__month-container:not(:nth-of-type(1)) {
    .custom-react-datepicker__current-month {
      display: none;
    }

    .prev {
      display: none;
    }
  }

  .react-datepicker__month-container {
    overflow: hidden;
  }

  /* Day Name */
  .react-datepicker__day-name {
    padding: 0;
    color: ${({ theme }) => theme.color.font.default};
    font-size: 0.7rem;
    font-weight: 500;
  }

  /* Today */
  .react-datepicker__day--today {
    .day-number {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  /* Day */
  .react-datepicker__day {
    &[aria-disabled="true"],
    &[aria-disabled="true"]:hover {
      .day-bg {
        background-color: transparent !important;
      }
      .day-number {
        color: ${({ theme }) => theme.color.calendar.fontDisabled} !important;
      }
      cursor: unset;
      user-select: none;
    }

    height: 1.7rem;
    background-color: transparent;

    position: relative;

    & > div {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
    }

    &,
    * {
      transition: none;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
    }

    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--in-range {
      .day-bg {
        background-color: ${({ theme }) =>
          theme.color.calendar.backgroundRanges};
      }
      .day-number {
        color: ${({ theme }) => theme.color.primary};
      }
    }

    &:hover:not([aria-disabled="true"]),
    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selected {
      .day-bg {
        background-color: ${({ theme }) => theme.color.primary};
        border-radius: 50%;
      }
      .day-number {
        color: white;
      }

      &.react-datepicker__day:nth-child(1) {
        .day-number {
          color: #ffa1a1;
        }
      }
    }

    /* 이미 선택된 range에 마우스 올릴 때(선택된 Range 중 스타트 날짜로 변경) */
    &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):hover,
    &.react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):hover {
      .day-bg {
        border-radius: 0;
      }
    }

    /* Start Next Element, End Element(not Start!) -> add day connect background */
    &.react-datepicker__day--selecting-range-start
      + .react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--range-start + .react-datepicker__day--in-range,
    &.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start),
    &.react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
      .day-connect {
        background-color: ${({ theme }) =>
          theme.color.calendar.backgroundRanges};
        height: 100%;
        width: 25px;
        transform: translateX(-20px);
      }
    }

    &.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start),
    &.react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
      .day-connect {
        transform: translateX(-6px);
      }
    }

    /* Start Next Element = End Element일 때 */
    &.react-datepicker__day--selecting-range-start
      + .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--range-start
      + .react-datepicker__day--in-range.react-datepicker__day--range-end {
      .day-connect {
        background-color: ${({ theme }) =>
          theme.color.calendar.backgroundRanges};
        height: 100%;
        width: 40px;
        transform: translateX(-20px);
      }
    }

    /* Z index 설정 */
    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__day--range-start {
      z-index: 2;
    }

    &.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--range-end {
      z-index: 1;
    }

    &.react-datepicker__day--in-selecting-range:hover,
    &.react-datepicker__day--in-range:hover {
      z-index: 2;
    }

    &.react-datepicker__day--selecting-range-start
      + .react-datepicker__day--in-selecting-range:hover,
    &.react-datepicker__day--range-start
      + .react-datepicker__day--in-range:hover {
      z-index: 1;
    }
  }

  .day-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .day-number {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.color.font.default};

    position: absolute;
    top: 0;
    left: 0;
  }

  /* Sunday Color */
  .react-datepicker__week > *:nth-child(1) {
    .day-number {
      color: ${({ theme }) => theme.color.invalid};
    }
  }
`;

export const StyledDateWithLabelWrap = styled(Div)<{
  labelDirection?: "TOP" | "LEFT";
}>`
  display: flex;
  justify-content: flex-start;
  gap: 8px 12px;
  flex-direction: ${({ labelDirection }) =>
    labelDirection === "TOP" ? "column" : "row"};
`;

export const StyledRangeDateWithButtonsWrap = styled(Div)`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
`;

import useQueryHooks from "@src/hooks/useQueryHooks";
import useCommonHooks, {
  convertToFormData,
  formatDate,
} from "@src/hooks/useCommonHooks";
import * as yup from "yup";
import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import {
  StyledDeviceIdText,
  StyledExtendImageWrap,
  StyledFlexWrap,
  StyledFloatButonWrap,
  StyledGridWrap,
  StyledPrimaryText,
  StyledRelativeFullWrap,
  StyledTabButtonWrap,
} from "@src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import Tab from "@src/components/Tab";
import React, { useEffect } from "react";
import IconButton from "@src/components/Buttons/IconButton";
import {
  BigButtonStyles,
  NormalIconButtonStyles,
  NormalNoSpaceIconButtonStyles,
  SmallIconButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import DateSearchRow from "@src/components/Searchbar/DateSearchRow";
import {
  ColorTypeConst,
  DateTypeConst,
  FlexAlignTypeConst,
} from "@src/constructs/common";
import Card from "@src/components/Card";
import Table from "@src/components/TableNew";
import Pagination from "@src/components/Pagination";
import { useDialog } from "@src/contexts/DialogProvider";
import Dialog from "@src/components/Dialogs/Dialog";
import { NormalDialogStyles } from "@src/components/Dialogs/sizeTheme";
import Form from "./Form";
import {
  FieldErrors,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Button from "@src/components/Buttons/Button";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetchMenagementData, {
  DeviceSpecialInfoFormDataPostPut,
  DeviceSpecialInfoFormDataDelete,
  DeviceSpecialInfoRequestParams,
} from "@src/fetch/fetchManagement";
import { useMutation } from "@tanstack/react-query";
import Loading from "@src/components/Loadings/Loading";
import Slide from "@src/components/Slide";
import {
  useDeviceSpecialInfoDataPaging,
  useQueryActions,
} from "@src/stores/useQueryStore";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { StyledFigureWrap } from "./styles";

// * 테이블 헤더
const head = [
  {
    value: "deviceGroup",
    label: "group",
  },
  {
    value: "device",
    label: "device",
  },
  {
    value: "structureName",
    label: "install_location",
  },
  {
    value: "inspectionDate",
    label: "inspection_date",
  },
  {
    value: "description",
    label: "description",
  },
  {
    value: "image",
    label: "image",
  },
  {
    value: "modifiedTime",
    label: "modified_time",
  },
  { value: "edit", label: "edit" },
  {
    value: "delete",
    label: "delete",
  },
];
const customGridColumnsMinMaxArray = [
  {
    min: "140px",
    max: "0.8fr",
  },
  {
    min: "210px",
    max: "1fr",
  },
  {
    min: "120px",
    max: "0.7fr",
  },
  {
    min: "140px",
    max: "140px",
  },
  {
    min: "300px",
    max: "3fr",
  },
  {
    min: "220px",
    max: "1.5fr",
  },
  {
    min: "168px",
    max: "168px",
  },
  {
    min: "90px",
    max: "90px",
  },
  {
    min: "90px",
    max: "90px",
  },
];

const previewHead = [
  {
    value: "deviceGroup",
    label: "group",
  },
  {
    value: "device",
    label: "device",
  },
  {
    value: "date",
    label: "date",
  },
  {
    value: "description",
    label: "description",
  },
  {
    value: "image",
    label: "image",
  },
];
const customGridColumnsMinMaxArrayPreviewTable = [
  {
    min: "120px",
    max: "0.7fr",
  },
  {
    min: "200px",
    max: "1fr",
  },
  {
    min: "120px",
    max: "120px",
  },
  {
    min: "250px",
    max: "3fr",
  },
  {
    min: "210px",
    max: "1.5fr",
  },
];

// * Interface

interface IImageExtendNumber {
  type: "data" | "submit" | "initialize";
  parent: number;
  index: number;
}

interface DeviceSpecialInfoMutateType {
  data: FormData;
}

interface ModifyDeviceSpecialInfoMutateType
  extends DeviceSpecialInfoMutateType {
  dataUid: string;
}

export interface IField {
  deviceGroupId: number;
  deviceGroupName: string;
  deviceId: string;
  deviceName: string;
  inspectionDate: string;
  description: string;
  images: {
    files: (File | string)[];
    previews: string[];
  } | null;
}

export interface IFields {
  deviceSpecialInfo: IField[];
}

export default function DeviceSpecialInfoPage() {
  // * hooks
  const { t } = useTranslation();
  const { useFormSetValue } = useCommonHooks();
  const { useDeviceSpecialInfoQuery } = useQueryHooks();
  const { useCustomLoading } = useCommonHooks();
  const { handleNoticeDialogOpen } = useNoticeDialog();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { handleDialogOpen } = useDialog();
  const paging = useDeviceSpecialInfoDataPaging();
  const { setDeviceSpecialInfoDataPaging: setPaging } = useQueryActions();
  const { handleCheckbox } = useCommonHooks();
  const {
    FetchDeviceSpecialInfoUpdate,
    FetchDeviceSpecialInfoModify,
    FetchDeviceSpecialInfoDelete,
  } = useFetchMenagementData();

  useCustomLoading();

  // * useForm
  const schema = {
    deviceSpecialInfo: yup.array().of(
      yup.object().shape({
        deviceGroupId: yup.string().required("select_group"),
        deviceId: yup.string().required("select_device"),
        inspectionDate: yup.string().nullable().required("select_date"),
        description: yup.string().required("please_enter_description"),
        images: yup.mixed().nullable(),
      })
    ),
  };

  const methods = useForm<IFields, any>({
    resolver: yupResolver(yup.object().shape(schema)),
    defaultValues: {
      deviceSpecialInfo: [
        {
          deviceGroupId: 0,
          deviceGroupName: "",
          deviceId: "",
          deviceName: "",
          inspectionDate: "",
          description: "",
          images: null,
        },
      ],
    },
  });

  const { control, handleSubmit, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "deviceSpecialInfo",
  });

  // * State
  const [tabActive, setTabActive] = React.useState<number>(0);
  const [maxDate] = React.useState<Date>(new Date());
  const [open, setOpen] = React.useState<boolean>(false);
  const [submitData, setSubmitData] = React.useState<IField[] | null>(null);
  const [customSearchParams, setCustomSearchParams] = React.useState({
    type: tabActive === 0 ? "inspection_date" : "modified_time",
  });
  const [imageExtendNumber, setImageExtendNumber] =
    React.useState<IImageExtendNumber | null>(null);
  const [params, setParams] =
    React.useState<DeviceSpecialInfoRequestParams | null>(null);
  const [modifyingDataUid, setModifyingDataUid] =
    React.useState<string | null>(null);
  const [isCheckedRow, setIsCheckedRow] = React.useState<boolean[]>([]);
  const [editNotice, setEditNotice] = React.useState<{
    index: number;
    number: number;
  }>({
    index: -1,
    number: 0,
  });
  const [deleteNotice, setDeleteNotice] = React.useState<{
    type: number | "CHECKBOX";
    number: number;
  }>({
    type: -1,
    number: 0,
  });
  const [isPastingAll, setIsPastingAll] = React.useState<boolean[]>([true]);

  // * Ref
  const formRef = React.useRef<HTMLFormElement>(null);

  // * QUERY
  const {
    isFetching,
    data: deviceSpecialInfoData,
    refetch,
  } = useDeviceSpecialInfoQuery(params);

  // * Mutate

  // Update Mutate
  const { isLoading, mutateAsync: updateDeviceSpecialInfoMutateAsync } =
    useMutation<
      SuccessResponse | null,
      unknown,
      DeviceSpecialInfoMutateType,
      unknown
    >(async (params) => {
      return FetchDeviceSpecialInfoUpdate(params.data);
    });

  // Modify Mutate
  const {
    mutateAsync: modifyDeviceSpecialInfoMutateAsync,
    isLoading: isLoadingModify,
  } = useMutation<
    SuccessResponse | null,
    unknown,
    ModifyDeviceSpecialInfoMutateType,
    unknown
  >(async (params) => {
    return FetchDeviceSpecialInfoModify(params.data, params.dataUid).then(
      (val) => {
        setSubmitData(null);
        setModifyingDataUid(null);
        handleAlertDialogOpen(t("notification"), t("data_has_been_modified"));
        return val;
      }
    );
  });

  // Delete Mutate
  const { mutate: deleteDeviceSpecialInfoMutate, isLoading: isLoadingDelete } =
    useMutation<
      SuccessResponse | null,
      unknown,
      DeviceSpecialInfoMutateType,
      unknown
    >(async (params) => {
      return FetchDeviceSpecialInfoDelete(params.data)
        .then((val) => {
          handleAlertDialogOpen(t("notification"), t("data_has_been_deleted"));
          refetch({ stale: true });
          return val;
        })
        .catch((e) => {
          return e;
        });
    });

  // * Functions

  // 다이얼로그 오픈 함수
  const hadleDialogOpen = () => {
    remove();
    handleAppendField();
    handleDialogOpen(t("set_up_interest_sensor"));
    setOpen(true);
  };

  const handleRequestSubmit = async () => {
    formRef.current?.requestSubmit();
  };

  const onSubmit: SubmitHandler<IFields> = React.useCallback(
    async (data: IFields) => {
      setSubmitData(() => {
        if (data.deviceSpecialInfo.length > 0) return data.deviceSpecialInfo;
        else return null;
      });
    },
    []
  );

  const onError: SubmitErrorHandler<IFields> = (data: FieldErrors<IFields>) => {
    return data;
  };

  // useForm Field append handler
  const handleAppendField = React.useCallback((data?: IField) => {
    append(
      data ?? {
        deviceGroupId: 0,
        deviceGroupName: "",
        deviceId: "",
        deviceName: "",
        inspectionDate: "",
        description: "",
        images: null,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 다이얼로그 취소 비동기 함수
  const cancelPromiseEvent = React.useCallback(async () => {
    let isWritedData = false;

    if (modifyingDataUid && deviceSpecialInfoData) {
      // 수정 모드일 때 데이터가 변경됐는지 확인
      const data = deviceSpecialInfoData.find(
        (item) => item.dataUid === modifyingDataUid
      );

      isWritedData =
        modifyingDataUid && watch("deviceSpecialInfo").length === 1
          ? watch("deviceSpecialInfo").every((item) => {
              const imagesStr = (
                item.images?.previews ? item.images?.previews.join(",") : null
              ) as any;

              return (
                item.deviceGroupId !== data?.deviceGroupId ||
                item.deviceGroupName !== data?.deviceGroupName ||
                item.deviceId !== data?.deviceId ||
                item.inspectionDate !==
                  data?.inspectionDate.replaceAll(".", "-") ||
                item.description !== data?.description ||
                (data?.imageUrls !== null &&
                  imagesStr !== data?.imageUrls?.join(","))
              );
            })
          : false;
    } else {
      isWritedData = watch("deviceSpecialInfo").some((item) => {
        const imagesStr = item.images as any;
        return (
          item.deviceGroupId !== 0 ||
          item.deviceGroupName !== "" ||
          item.deviceId !== "" ||
          item.inspectionDate !== "" ||
          item.description !== "" ||
          imagesStr !== ""
        );
      });
    }

    if (isWritedData) {
      const result = await handleNoticeDialogOpen(
        t("notification"),
        t("entered_information_will_be_disappear_and_sure_close_dialog")
      );
      if (result) {
        remove();
        setOpen(false);
        setModifyingDataUid(null);
        setSubmitData(null);
      }
    } else {
      remove();
      setOpen(false);
      setModifyingDataUid(null);
      setSubmitData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("deviceSpecialInfo"), modifyingDataUid]);

  // Edit button handler
  const handleEditButton = React.useCallback(
    async (index: number) => {
      setEditNotice((prev) => ({ index, number: prev.number + 1 }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Delete button handler
  const handleDeleteButton = React.useCallback(
    async (item: number | "CHECKBOX") => {
      setDeleteNotice((prev) => ({ type: item, number: prev.number + 1 }));
    },
    []
  );

  // 최종 데이터를 생성하는 함수
  const handleCreate = React.useCallback(async () => {
    if (submitData) {
      Promise.all(
        submitData.map((item) => {
          const data = {
            deviceId: item.deviceId,
            inspectionDate: item.inspectionDate,
            description: item.description,
            images: item.images?.files ?? undefined,
          };
          const formData =
            convertToFormData<DeviceSpecialInfoFormDataPostPut>(data);

          if (modifyingDataUid) {
            const params = {
              data: formData,
              dataUid: modifyingDataUid,
            };
            return modifyDeviceSpecialInfoMutateAsync(params);
          } else {
            const params = {
              data: formData,
            };
            return updateDeviceSpecialInfoMutateAsync(params);
          }
        })
      )
        .then((results) => {
          // 모든 뮤테이션 완료 후에 할 작업을 여기에 추가
          setOpen(false);
          setSubmitData(null);
          refetch({ stale: true });
        })
        .catch((error) => {
          console.error("뮤테이션 중 에러 발생:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitData]);

  // 이미지 확대 토글 버튼
  const handleImageExtendToggle = React.useCallback(
    (
      type: "data" | "submit" | "initialize",
      index?: number,
      parentIndex?: number
    ) => {
      if (type === "initialize") {
        setImageExtendNumber(null);
      } else if (index !== undefined && parentIndex !== undefined) {
        setImageExtendNumber({
          type,
          index,
          parent: parentIndex,
        });
      }
    },
    []
  );

  // DateSearchbar 조회 버튼 클릭 시 실행되는 함수 - api params change
  const getUrlParams = React.useCallback(
    (data: DateParameterValues | null) => {
      if (data) {
        // startDate & endDate가 없으면 전체 조회라 전달 X
        setParams({
          ...(data.startDate && data.endDate
            ? { startDate: data.startDate, endDate: data.endDate }
            : {}),
          type: tabActive === 0 ? "inspection_date" : "modified_time",
        });

        setPaging({
          page: 1,
          size: paging.size,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabActive]
  );

  // 붙여넣기 활성화 핸들러
  const handleIsPasting = React.useCallback((val: boolean, index: number) => {
    setIsPastingAll((prev) => {
      const newData = [...prev];
      newData.fill(false);
      newData[index] = val;
      return newData;
    });
  }, []);

  // * Memo
  // 다이얼로그 폼 컨텐트
  const content = React.useMemo(() => {
    if (fields.length === 0) {
      handleAppendField();
    }

    return (
      <>
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit, onError)}
          style={{
            marginBottom: 20,
            width: "100%",
          }}
        >
          <StyledFlexWrap flexDirection="column" gap={30}>
            {fields.map((_field, index) => (
              <Form
                key={index}
                index={index}
                remove={remove}
                methods={methods}
                useFormSetValue={useFormSetValue}
                isPasting={isPastingAll[index]}
                handleIsPasting={(val) => handleIsPasting(val, index)}
              />
            ))}
          </StyledFlexWrap>
        </form>
        {modifyingDataUid ? null : (
          <Button
            className="field-add-btn"
            sizeTheme={BigButtonStyles}
            colorType="primary"
            onClick={() => handleAppendField()}
            text={`+ ${t("Add")}`}
            fixWidth="100%"
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    fields,
    methods,
    useFormSetValue,
    handleSubmit,
    onSubmit,
    remove,
    modifyingDataUid,
    isPastingAll,
    submitData,
  ]);

  // td 태그 안에 들어가는 이미지 컴포넌트
  const ImagesInTd = ({
    item,
    parentIndex,
    dataType,
  }: {
    item: string[];
    parentIndex: number;
    dataType: "submit" | "data";
  }) => {
    return (
      <StyledFlexWrap
        gap={10}
        align={FlexAlignTypeConst.FlexStart}
        vertical={FlexAlignTypeConst.Center}
        style={{
          flexWrap: "wrap",
        }}
      >
        {item.map((image, imgIndex) => (
          <StyledFigureWrap key={imgIndex}>
            <img
              src={image}
              style={{
                width: "100%",
              }}
              alt={`device_special_info_image--${parentIndex}-${imgIndex}`}
            />
            <StyledFloatButonWrap>
              <IconButton
                sizeTheme={SmallIconButtonStyles}
                colorType="primary"
                iconName="search"
                onClick={() => {
                  handleImageExtendToggle(dataType, imgIndex, parentIndex);
                }}
              />
            </StyledFloatButonWrap>
          </StyledFigureWrap>
        ))}
      </StyledFlexWrap>
    );
  };

  // 디바이스 특이사항 테이블
  const tableBody = React.useMemo(() => {
    return deviceSpecialInfoData
      ? deviceSpecialInfoData.map((item, index) => {
          return {
            trOptions: {
              dataRowIndex: index,
            },
            tdOptions: [
              {
                dataKey: "deviceGroupName",
                dataValue: item.deviceGroupName,
                tdValue: item.deviceGroupName,
              },
              {
                dataKey: "device",
                dataValue: `${item.deviceId}_${item.deviceName}`,
                tdValue: (
                  <>
                    <StyledDeviceIdText boxSize="SMALL">
                      {item.deviceId}
                    </StyledDeviceIdText>
                    <span
                      style={{
                        marginLeft: 6,
                      }}
                    >
                      {item.deviceName}
                    </span>
                  </>
                ),
              },
              {
                dataKey: "structureName",
                dataValue: item.structureName,
                tdValue: item.structureName,
              },
              {
                dataKey: "inspectionDate",
                dataValue: formatDate(item.inspectionDate, "day"),
                tdValue: formatDate(item.inspectionDate, "day"),
              },
              {
                dataKey: "description",
                dataValue: item.description,
                tdValue: <pre>{item.description}</pre>,
              },
              {
                dataKey: "image",
                dataValue: "",
                tdValue: item.imageUrls ? (
                  <ImagesInTd
                    item={item.imageUrls}
                    parentIndex={index}
                    dataType="data"
                  />
                ) : (
                  ""
                ),
              },
              {
                dataKey: "modifiedTime",
                dataValue: formatDate(item.modifiedTime, "sec"),
                tdValue: formatDate(item.modifiedTime, "min"),
              },
              {
                dataKey: "edit",
                dataValue: "",
                tdValue: (
                  <IconButton
                    sizeTheme={NormalNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName="edit"
                    onClick={() => handleEditButton(index)}
                  />
                ),
              },
              {
                dataKey: "delete",
                dataValue: "",
                tdValue: (
                  <IconButton
                    sizeTheme={NormalNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName="delete"
                    onClick={() => handleDeleteButton(index)}
                  />
                ),
              },
            ],
          };
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSpecialInfoData]);

  // 다이얼로그 미리보기 컨텐트
  const previewContent = React.useMemo(() => {
    return (
      <>
        <Table
          colorType="primary"
          head={previewHead}
          customGridColumnsMinMaxArray={
            customGridColumnsMinMaxArrayPreviewTable
          }
          body={
            submitData
              ? submitData.map((item, index) => {
                  return {
                    trOptions: {
                      dataRowIndex: index,
                    },
                    tdOptions: [
                      {
                        dataKey: "deviceGroupName",
                        dataValue: item.deviceGroupName,
                        tdValue: item.deviceGroupName,
                      },
                      {
                        dataKey: "device",
                        dataValue: `${item.deviceId}_${item.deviceName}`,
                        tdValue: (
                          <>
                            <StyledDeviceIdText boxSize="SMALL">
                              {item.deviceId}
                            </StyledDeviceIdText>
                            <span
                              style={{
                                marginLeft: 6,
                              }}
                            >
                              {item.deviceName}
                            </span>
                          </>
                        ),
                      },
                      {
                        dataKey: "date",
                        dataValue: formatDate(item.inspectionDate, "day"),
                        tdValue: formatDate(item.inspectionDate, "day"),
                      },
                      {
                        dataKey: "description",
                        dataValue: item.description,
                        tdValue: <pre>{item.description}</pre>,
                      },
                      {
                        dataKey: "image",
                        dataValue: item.images
                          ? item.images.previews.join("_")
                          : "",
                        tdValue: item.images ? (
                          <ImagesInTd
                            item={item.images.previews}
                            parentIndex={index}
                            dataType="submit"
                          />
                        ) : (
                          ""
                        ),
                      },
                    ],
                  };
                })
              : null
          }
        />
        <StyledPrimaryText
          size="md"
          style={{
            marginTop: 20,
          }}
        >
          입력하신 내용을 확인하고 등록 버튼을 늘러주세요.
        </StyledPrimaryText>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitData]);

  // tabActive 변경 시 url 파라미터: type을 수정한다
  const handleTabButton = (index: number) => {
    setTabActive(index);
    setCustomSearchParams({
      type: index === 0 ? "inspection_date" : "modified_time",
    });
  };

  // 테이블 체크 박스 이펙트
  useEffect(() => {
    if (deviceSpecialInfoData) {
      setIsCheckedRow(new Array(deviceSpecialInfoData.length).fill(false));
    } else {
      setIsCheckedRow([]);
    }
  }, [deviceSpecialInfoData, paging.page]);

  // 데이터 수정 이펙트
  useEffect(() => {
    if (
      editNotice.index !== -1 &&
      editNotice.number > 0 &&
      deviceSpecialInfoData
    ) {
      remove();
      handleAppendField({
        deviceGroupId: deviceSpecialInfoData[editNotice.index].deviceGroupId,
        deviceGroupName:
          deviceSpecialInfoData[editNotice.index].deviceGroupName,
        deviceId: deviceSpecialInfoData[editNotice.index].deviceId,
        deviceName: deviceSpecialInfoData[editNotice.index].deviceName,
        description: deviceSpecialInfoData[editNotice.index].description,
        inspectionDate: deviceSpecialInfoData[editNotice.index].inspectionDate,
        images:
          deviceSpecialInfoData[editNotice.index].fileKeys &&
          deviceSpecialInfoData[editNotice.index].imageUrls
            ? {
                files: deviceSpecialInfoData[editNotice.index].fileKeys ?? [""],
                previews: deviceSpecialInfoData[editNotice.index].imageUrls ?? [
                  "",
                ],
              }
            : null,
      });
      setModifyingDataUid(deviceSpecialInfoData[editNotice.index].dataUid);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNotice]);

  // 데이터 삭제 이펙트
  // handleDeleteButton을 통해 deleteNotice가 변경되면 삭제 진행
  // isCheckedRaw를 handleDeleteButton에서 제대로 알지 못해서 이펙트에서 삭제 진행
  useEffect(() => {
    if (
      deleteNotice.number > 0 &&
      deleteNotice.type !== -1 &&
      deviceSpecialInfoData
    ) {
      let data = {
        deviceIdList: [""],
        dataUidList: [""],
      };

      if (deleteNotice.type === "CHECKBOX") {
        const indexes = isCheckedRow.reduce((acc, value, index) => {
          if (value) {
            acc.push(index);
          }
          return acc;
        }, [] as number[]);

        data.deviceIdList = deviceSpecialInfoData
          ?.filter((_, i) => indexes.includes(i))
          .map((item) => item.deviceId) ?? [""];
        data.dataUidList = deviceSpecialInfoData
          ?.filter((_, i) => indexes.includes(i))
          .map((item) => item.dataUid) ?? [""];
      } else {
        const item = deviceSpecialInfoData[deleteNotice.type];
        data.deviceIdList = [item.deviceId ?? ""];
        data.dataUidList = [item.dataUid ?? ""];
      }

      // 데이터가 있을 경우에 실행
      if (data.deviceIdList.length > 0 && data.dataUidList.length > 0) {
        const formData =
          convertToFormData<DeviceSpecialInfoFormDataDelete>(data);
        const params = {
          data: formData,
        };

        const handleDelete = async () => {
          const result = await handleNoticeDialogOpen(t("notification"), [
            t("are_you_sure_you_want_to_delete"),
          ]);
          if (result) {
            deleteDeviceSpecialInfoMutate(params);
          }
        };
        handleDelete();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNotice]);

  // refetch 됐을 때 페이징 수정 필요할 경우 수정하는 이펙트
  useEffect(() => {
    const pagingMinLength = (paging.page - 1) * paging.size + 1;
    if (deviceSpecialInfoData) {
      if (deviceSpecialInfoData.length < pagingMinLength) {
        const pageCount = Math.ceil(deviceSpecialInfoData.length / paging.size);

        setPaging({
          page: pageCount < 1 ? 1 : pageCount,
          size: paging.size,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSpecialInfoData]);

  // fileds last index - image pasting active
  useEffect(() => {
    handleIsPasting(true, fields.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {isLoading || isLoadingModify || isLoadingDelete ? <Loading /> : null}
      <StyledGridWrap
        templateColumns="100%"
        templateRows="max-content max-content minmax(400px, 1fr)"
        layoutGridInherit
      >
        <DateSearchRow
          maxDate={maxDate}
          dateType={DateTypeConst.Range}
          getUrlParams={getUrlParams}
          inCard
          customSearchParams={customSearchParams}
          searchedTotalPeriod
        />
        <StyledTabButtonWrap>
          <Tab
            contents={[
              t("special_information_by_inspection_date"),
              t("special_information_by_modified_time"),
            ]}
            tabActiveNumber={tabActive}
            tabActiveOnClick={handleTabButton}
          />
          <IconButton
            sizeTheme={NormalIconButtonStyles}
            colorType="primary"
            iconName="addDoc"
            text={t("add_special_information")}
            onClick={hadleDialogOpen}
          />
        </StyledTabButtonWrap>
        <StyledRelativeFullWrap id="wrap">
          <Card sizeTheme={NormalCardStyle}>
            <Table
              colorType={ColorTypeConst.Primary}
              head={head}
              body={tableBody}
              isCheckableRow
              isCheckedRow={isCheckedRow}
              checkboxName="device-speicl-info"
              handleCheckedRow={(index: number) =>
                handleCheckbox(index, setIsCheckedRow)
              }
              handleCheckedAll={(bool: boolean, visibleIndexes?: number[]) =>
                handleCheckbox(
                  bool,
                  setIsCheckedRow,
                  undefined,
                  undefined,
                  visibleIndexes
                )
              }
              minWidth={400}
              customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
              page={paging.page}
              size={paging.size}
              isCustomPaging
              isLoading={isFetching}
              isSortable
              isSearchable
              elementSize="normal"
              customButtons={
                <IconButton
                  sizeTheme={NormalIconButtonStyles}
                  text={t("selected_data_delete")}
                  colorType="primary"
                  iconName="delete"
                  onClick={() => handleDeleteButton("CHECKBOX")}
                  isDisabled={!isCheckedRow.some((item) => item === true)}
                />
              }
            />
            {deviceSpecialInfoData && deviceSpecialInfoData.length > 0 ? (
              <Pagination
                page={paging.page}
                size={paging.size}
                count={deviceSpecialInfoData?.length ?? 0}
                groupLength={5}
                setPaging={setPaging}
                elementSize="normal"
                showSizeSelect
              />
            ) : null}
          </Card>
        </StyledRelativeFullWrap>
      </StyledGridWrap>
      <Dialog
        sizeTheme={NormalDialogStyles}
        isOpen={open}
        setIsOpen={setOpen}
        confirmEvent={!submitData ? handleRequestSubmit : handleCreate}
        cancelPromiseEvent={
          !submitData
            ? cancelPromiseEvent
            : async () => {
                setSubmitData(null);
              }
        }
        rightClosePromiseEvent={cancelPromiseEvent}
        title={t("device_special_info")}
        customMaxWidth={!submitData ? 640 : 1400}
        cancelButtonText={!submitData ? t("cancel") : t("prev")}
        confirmButtonText={
          !submitData ? t("next") : modifyingDataUid ? t("edit") : t("create")
        }
      >
        {!submitData ? content : previewContent}
      </Dialog>

      {/* submitData or deviceSpecialInfoData 이미지 확대 */}
      {imageExtendNumber !== null ? (
        <>
          {imageExtendNumber.type === "data" &&
          deviceSpecialInfoData &&
          deviceSpecialInfoData[imageExtendNumber.parent].imageUrls ? (
            <StyledExtendImageWrap
              tabIndex={0}
              onClick={() => handleImageExtendToggle("initialize")}
              style={{
                cursor: "pointer",
              }}
            >
              <Slide startIndex={imageExtendNumber.index} autoSlide={false}>
                {deviceSpecialInfoData[
                  imageExtendNumber.parent
                ]?.imageUrls?.map((item, index) => (
                  <StyledFlexWrap
                    flexDirection="column"
                    style={{
                      display: "inline-flex",
                      maxWidth: "90%",
                      maxHeight: "90svh",
                      position: "relative",
                    }}
                    key={index}
                  >
                    <img src={item} alt={`preview_${index}`} />
                  </StyledFlexWrap>
                )) ?? []}
              </Slide>
            </StyledExtendImageWrap>
          ) : imageExtendNumber.type === "submit" &&
            submitData &&
            submitData[imageExtendNumber.parent].images?.previews ? (
            <StyledExtendImageWrap
              tabIndex={0}
              onClick={() => handleImageExtendToggle("initialize")}
              style={{
                cursor: "pointer",
              }}
            >
              <Slide startIndex={imageExtendNumber.index} autoSlide={false}>
                {submitData[imageExtendNumber.parent]?.images?.previews?.map(
                  (item, index) => (
                    <StyledFlexWrap
                      flexDirection="column"
                      style={{
                        display: "inline-flex",
                        maxWidth: "90%",
                        maxHeight: "90svh",
                        position: "relative",
                      }}
                      key={index}
                    >
                      <img src={item} alt={`preview_${index}`} />
                    </StyledFlexWrap>
                  )
                ) ?? []}
              </Slide>
            </StyledExtendImageWrap>
          ) : null}
        </>
      ) : null}
    </>
  );
}

import { ThemeConst } from "@src/constructs/common";
import { UserInfoV2Response } from "@src/fetch/fetchCommon";
import { User } from "firebase/auth";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const initializeUserInfo = {
  name: { ko: "", en: "" },
  uid: "",
  menuPermission: 0,
  userGroup: "",
  deviceGroupList: [],
  structureList: [],
  deviceList: [],
  sensorList: [],
  physicalSensorList: [],
};

interface CommonStoreItems {
  browserSize: BrowserSize | null;
  interestSensorList: string[] | null;
  loadingOnPageInit: boolean;
}

interface StorageStoreItems {
  theme: ThemeType;
  isSummarySidebar: boolean;
  eventDataset: EventData[] | null;
  loggedIn: boolean;
  userInfo: UserInfoV2Response;
}

interface CommonStoreActions {
  actions: {
    setTheme: (theme: ThemeType) => void;
    setBrowserSize: (browserSize: BrowserSize) => void;
    setIsSummarySidebar: (isSummarySidebar?: boolean) => void;
    setUserInfo: (userInfo: UserInfoV2Response) => void;
    setEventDataset: (eventDataset: EventData[] | null) => void;
    setLoggedIn: (loggedIn: boolean) => void;
    setInterestSensorList: (interestSensorList: string[] | null) => void;
    setLoadingOnPageInit: (LoadingOnPageInit: boolean) => void;
  };
}

type CommonStoreTypes = CommonStoreItems &
  StorageStoreItems &
  CommonStoreActions;

export const useCommonStore = create<
  CommonStoreTypes,
  [["zustand/persist", StorageStoreItems]]
>(
  persist(
    (set) => ({
      theme: ThemeConst.Light,
      browserSize: null,
      isSummarySidebar: false,
      userInfo: initializeUserInfo,
      deviceList: undefined,
      eventDataset: null,
      loggedIn: false,
      interestSensorList: null,
      loadingOnPageInit: false,
      actions: {
        setTheme: (theme: ThemeType) => {
          set((prev) => ({ ...prev, theme }));
        },
        setBrowserSize: (browserSize: BrowserSize) => {
          set((prev) => ({ ...prev, browserSize }));
        },
        setIsSummarySidebar: (isSummarySidebar?: boolean) => {
          if (isSummarySidebar !== undefined) {
            set((prev) => ({ ...prev, isSummarySidebar }));
          } else {
            set((prev) => ({
              ...prev,
              isSummarySidebar: !prev.isSummarySidebar,
            }));
          }
        },
        setDeviceList: (deviceList?: SelectOption[]) => {
          set((prev) => ({ ...prev, deviceList }));
        },
        setUserInfo: (userInfo: UserInfoV2Response) => {
          set((prev) => ({ ...prev, userInfo }));
        },
        setEventDataset: (eventDataset: EventData[] | null) => {
          set((prev) => ({ ...prev, eventDataset }));
        },
        setLoggedIn: (loggedIn: boolean) => {
          set((prev) => ({ ...prev, loggedIn }));
        },
        setUser: (user: User | null) => {
          set((prev) => ({ ...prev, user }));
        },
        setInterestSensorList: (interestSensorList: string[] | null) => {
          set((prev) => ({ ...prev, interestSensorList }));
        },
        setLoadingOnPageInit: (loadingOnPageInit: boolean) => {
          set((prev) => ({ ...prev, loadingOnPageInit }));
        },
      },
    }),
    {
      name: "gbStores",
      partialize: ({
        actions,
        browserSize,
        interestSensorList,
        ...rest
      }: CommonStoreTypes) => rest,
      // storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// * ITEMS
export const useThemeStore = () => useCommonStore((state) => state.theme);
export const useBrowserSizeStore = () =>
  useCommonStore((state) => state.browserSize);
export const useIsSummarySidebarStore = () =>
  useCommonStore((state) => state.isSummarySidebar);
export const useUserInfoStore = () => useCommonStore((state) => state.userInfo);
export const useEventDatasetStore = () =>
  useCommonStore((state) => state.eventDataset);
export const useLoggedInStore = () => useCommonStore((state) => state.loggedIn);
export const useInterestSensorListStore = () =>
  useCommonStore((state) => state.interestSensorList);
export const useLoadingOnPageInitStore = () =>
  useCommonStore((state) => state.loadingOnPageInit);

// * ACTIONS
export const useCommonStoreActions = () =>
  useCommonStore((state) => state.actions);

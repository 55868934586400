import React from "react";
import Icon from "@components/Icon";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputStyleProps } from "../sizeTheme";
import { StyledErrorText, StyledPrimaryLabel } from "@src/styles/commonStyles";
import {
  StyledAddonWrap,
  StyledInputBox,
  StyledInputTabBox,
  StyledInputWrap,
  StyledLabel,
  StyledLabelInputWrap,
  StyledTextWrap,
} from "../styles";
import IconButton from "@src/components/Buttons/IconButton";
import { XXSmallNoSpaceIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import { useTranslation } from "react-i18next";

/* 👇 Type Setting */
interface InputCommonProps {
  inputName: string;
  type?: string;
  sizeTheme: InputStyleProps;
  placeholder: string;
  addonShow?: boolean;
  addonName?: string;
  addonDirection?: "left" | "right";
  errorMessages?: string[];
  eventType: "Custom" | "UseForm";
  isFoucs?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  isDate?: boolean;
  isFixBottomMargin?: boolean;
  autoComplete?: string;
  fixWidth?: number;
  minHeight?: number;
}

type ConditionalInputProps =
  | {
      eventType: "Custom";
      value: string;
      onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
      register?: never;
      setFocus?: never;
      name?: any;
    }
  | {
      eventType: "UseForm";
      register: UseFormRegisterReturn<string>;
      setFocus: () => void;
      onChange?: never;
      name?: never;
      value?: never;
    };

type ConditionalClearableProps =
  | {
      isClearable: true;
      clearValue: () => void;
      getValue: string;
    }
  | {
      isClearable?: undefined;
      clearValue?: never;
      getValue?: never;
    };

type LabelInputProps =
  | {
      isShowLabel: true;
      labelText: string;
      labelDirection: "TOP" | "LEFT";
      labelFixWidth?: number;
      labelType: "AUTH" | "SEARCH";
    }
  | {
      isShowLabel?: never;
      labelText?: never;
      labelDirection?: never;
      labelFixWidth?: never;
      labelType?: never;
    };

type InputProps = ConditionalInputProps &
  InputCommonProps &
  LabelInputProps &
  ConditionalClearableProps;

export default function Input({
  inputName,
  sizeTheme,
  eventType,
  value,
  onChange,
  register,
  setFocus,
  placeholder,
  addonShow,
  addonName,
  addonDirection,
  errorMessages,
  labelDirection,
  labelFixWidth,
  labelText,
  labelType,
  isFixBottomMargin,
  autoComplete,
  isFoucs,
  isInvalid,
  isReadOnly,
  isDisabled,
  isDate,
  isShowLabel,
  isClearable,
  getValue,
  clearValue,
  fixWidth,
  minHeight,
}: InputProps) {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  // input의 이벤트를 위한 prop을 정한다. (onChange, focusing 등)
  // 타입이 UseFormInputProps이라면 register
  // 타입이 아니면 value, onChange, ref를 넣어준다.
  const inputProps =
    eventType === "UseForm"
      ? {
          ...register,
        }
      : {
          value,
          onChange,
          ref: inputRef,
        };

  const handleFocus = (e: React.MouseEvent) => {
    if (eventType === "UseForm") {
      setFocus();
    } else {
      inputRef.current?.focus();
    }
  };

  const handleLabelClick = (e: React.MouseEvent) => {
    if (eventType === "UseForm") {
      setFocus();
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <StyledLabelInputWrap
      labelDirection={labelDirection}
      style={{
        width: "100%",
      }}
    >
      {isShowLabel && labelType === "AUTH" ? (
        <StyledLabel
          labelFixWidth={labelFixWidth}
          onClick={(e) => handleLabelClick(e)}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {labelText}
        </StyledLabel>
      ) : isShowLabel && labelType === "SEARCH" ? (
        <StyledPrimaryLabel
          style={{ cursor: "pointer" }}
          onClick={(e) => handleLabelClick(e)}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          labelLineHeight={
            labelDirection === "TOP" ? undefined : sizeTheme.height ?? undefined
          }
        >
          <span>{labelText}</span>
        </StyledPrimaryLabel>
      ) : null}
      <StyledInputWrap>
        <StyledInputTabBox>
          <StyledInputBox
            className={isFoucs ? "focus" : undefined}
            sizeTheme={sizeTheme}
            aria-disabled={isDisabled}
            aria-readonly={isReadOnly}
            onClick={handleFocus}
            addonDirection={addonDirection}
            isInvalid={isInvalid}
            isDate={isDate}
            fixWidth={fixWidth}
            style={{
              minHeight: minHeight ? minHeight : sizeTheme.height,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            {addonShow && (
              <StyledAddonWrap>
                <Icon
                  iconName={addonName ?? ""}
                  iconWidth={sizeTheme.addonWidth ?? 0}
                  iconHeight={sizeTheme.addonHeight ?? 0}
                />
              </StyledAddonWrap>
            )}
            <textarea
              name={inputName}
              placeholder={placeholder}
              {...inputProps}
              readOnly={isReadOnly || isDate}
              disabled={isDisabled}
              autoComplete={autoComplete}
              style={{
                padding: 0,
                width: "100%",
                height: "100%",
                border: 0,
                resize: "none",
                lineHeight: "1rem",
              }}
            />
            {isClearable === true ? (
              <div
                className="btn-clear"
                style={{
                  width: 14,
                  order: 3,
                }}
              >
                {value || getValue ? (
                  <IconButton
                    sizeTheme={XXSmallNoSpaceIconButtonStyles}
                    colorType="grayToPrimaryNoBg"
                    iconName="close"
                    onClick={() => clearValue()}
                  />
                ) : null}
              </div>
            ) : null}
          </StyledInputBox>
        </StyledInputTabBox>
        {(isInvalid || isFixBottomMargin) && (
          <StyledTextWrap
            isFixBottomMargin={isFixBottomMargin}
            sizeTheme={sizeTheme}
          >
            {errorMessages &&
              errorMessages.map((item, i) => (
                <StyledErrorText key={i}>{t(item)}</StyledErrorText>
              ))}
          </StyledTextWrap>
        )}
      </StyledInputWrap>
    </StyledLabelInputWrap>
  );
}

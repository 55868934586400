import ReactDOM from "react-dom/client";
import App from "./App";
import { NavermapsProvider } from "react-naver-maps";
import AllContextProvider from "./contexts/AllContextProvider";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <NavermapsProvider ncpClientId="ypp08nsb8w">
    <AllContextProvider>
      <App />
    </AllContextProvider>
  </NavermapsProvider>
);

import { AxiosRequestConfig, AxiosResponse } from "axios";

const makeErrors = (str: string) => {
  if (str.includes("{")) {
    const jsonToObj = JSON.parse(str.replaceAll(`'`, `"`));
    return jsonToObj.errors;
  }
  return {
    message: str,
  };
};

class AxiosResponseData {
  errors?: IFailedResponse;
  data?: any;

  constructor(response: AxiosResponse<CustomResponseType, AxiosRequestConfig>) {
    this.errors = response.data.message
      ? makeErrors(response.data.message)
      : undefined;
    this.data = response.data.message ? undefined : response.data;
  }
}

export default AxiosResponseData;

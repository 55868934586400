import { UserInfoV2Response } from "@src/fetch/fetchCommon";
import { differenceInMinutes, parseISO } from "date-fns";

export const getDeviceIdBySensorId = (
  userInfo: UserInfoV2Response,
  sensorId: string
) => {
  return (
    userInfo.sensorList.find((item) => item.sensor_id === sensorId)
      ?.device_id ?? undefined
  );
};

/**
 * 주어진 날짜 라벨 배열을 기반으로 시간 단위(시간 또는 분)를 계산합니다.
 *
 * @param {string[]} labels - ISO 형식의 날짜 문자열 배열.
 * @returns {string} - 총 차이가 60분 이상 - "hour" / X - "minute" 반환
 *
 * 라벨 배열을 순회하며 각 날짜 간의 분 단위 차이를 누적하여 계산
 * 총 차이가 60분 이상이면 "hour"를 반환하고, 그렇지 않으면 "minute"을 반환
 *
 * 예시:
 * const labels = ["2023-07-04T10:00:00Z", "2023-07-04T11:00:00Z"];
 * console.log(getTimeUnit(labels)); // "hour" 반환
 */
export const getTimeUnit = (labels: string[]) => {
  return labels.reduce(
    (accumulator, currentValue) => ({
      previousDate: currentValue,
      total:
        accumulator.total +
        differenceInMinutes(
          parseISO(currentValue),
          parseISO(accumulator.previousDate)
        ),
    }),
    {
      previousDate: labels[0],
      total: 0,
    }
  ).total >= 60
    ? "hour"
    : "minute";
};

import { StyledFlexWrap } from "@src/styles/commonStyles";
import styled from "styled-components";
import IconButton from "../Buttons/IconButton";
import { XSmallNoSpaceIconButtonStyles } from "../Buttons/sizeTheme";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import { useTranslation } from "react-i18next";

const StyledUserInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 16px;
  width: 100%;
  min-width: 210px;
  background-color: ${({ theme }) => theme.color.userInfoBox.background};
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

const StyledUserInfoText = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  line-height: ${({ theme }) => theme.size.common.fontSize.xlg};

  & > span {
    color: ${({ theme }) => theme.color.userInfoBox.font};
    font-size: ${({ theme }) => theme.size.common.fontSize.mdlg};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};

    &:nth-child(1)::after {
      content: attr(data-str);
      margin: 0 6px 0 2px;
      color: ${({ theme }) => theme.color.userInfoBox.font};
      font-size: ${({ theme }) => theme.size.common.fontSize.base};
      font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
    }
  }
`;

export default function UserInfoBox() {
  const userInfo = useUserInfoStore();
  const { handleLogout } = useFirebaseHooks();
  const { t, i18n } = useTranslation();

  return (
    <StyledUserInfoWrap>
      <StyledUserInfoText>
        <span data-str={t("sir")}>
          {i18n.language === "ko" ? userInfo.name.ko : userInfo.name.en}
        </span>
        <span>{t("hello")}</span>
      </StyledUserInfoText>
      <StyledFlexWrap gap={12}>
        <IconButton
          sizeTheme={XSmallNoSpaceIconButtonStyles}
          colorType="primaryNoBg"
          iconName="logout"
          onClick={handleLogout}
        />
      </StyledFlexWrap>
    </StyledUserInfoWrap>
  );
}

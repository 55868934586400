import { useUserInfoStore } from "@src/stores/useCommonStore";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useCommonHooks, { getSensorLabel } from "./useCommonHooks";
import React from "react";
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { parse } from "date-fns";
import { DateTypeConst } from "@src/constructs/common";
import useUserInfoHooks from "./useUserInfoHooks";

export interface CommonSearchHooksProps {
  disableAutoSearch?: boolean;
  searchedDevice?: boolean;
  searchedSensor?: boolean;
  searchedTotalPeriod?: boolean;
  hasContentUrl?: boolean;
  hasViewLarger?: boolean;
  paging?: PagingType;
  setPaging?: (paging: PagingType) => void;
  getUrlParams: (data: SearchParameterValues | null) => void;
  customSearchParams?: {
    [key: string]: string;
  };
}

export interface SearchDateProps {
  dateType: DateType;
  maxDate?: Date;
  minDate?: Date;
}

export type ConditionSearchType =
  | {
      searchedConditionSearch: true;
      searchTypeOptions: SelectOption[];
    }
  | {
      searchedConditionSearch?: never;
      searchTypeOptions?: undefined;
    };

export type OptionalDateType =
  | {
      searchedDate?: never;
      dateType?: never;
      maxDate?: never;
      minDate?: never;
    }
  | ({
      searchedDate: true;
    } & SearchDateProps);

export type OptionalSearchedSensorProps =
  | {
      searchedSensor: true;
      sensorTypeFilter?: string[];
      selectedSensor: SelectOption;
      setSelectedSensor: React.Dispatch<React.SetStateAction<SelectOption>>;
    }
  | {
      searchedSensor?: never;
      sensorTypeFilter?: never;
      selectedSensor?: never;
      setSelectedSensor?: never;
    };

type useSearchbarHooksProps = ConditionSearchType &
  OptionalDateType &
  OptionalSearchedSensorProps &
  CommonSearchHooksProps;

export default function useSearchbarHooks({
  disableAutoSearch,
  searchedDate,
  searchedTotalPeriod,
  dateType,
  searchedDevice,
  searchedSensor,
  selectedSensor,
  setSelectedSensor,
  sensorTypeFilter,
  hasContentUrl,
  hasViewLarger,
  paging,
  setPaging,
  getUrlParams,
  customSearchParams,
  searchedConditionSearch,
  searchTypeOptions,
}: useSearchbarHooksProps) {
  // Hooks
  const userInfo = useUserInfoStore();
  const { useFormSetValue } = useCommonHooks();
  const {
    selectedDeviceGroup,
    setSelectedDeviceGroup,
    selectedDevice,
    setSelectedDevice,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    memorizedSensorOptions,
  } = useUserInfoHooks({ sensorTypeFilter });

  // State
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [selectedSearchType, setSelectedSearchType] =
    React.useState<SelectOption>(initialSelectOption);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const [init, setInit] = React.useState<boolean>(false);
  const [onSubmitedFirst, setOnSubmitedFirst] = React.useState<boolean>(false);

  // 초기값
  const conditionSearchDefaultValues = {
    searchType: "",
    searchValue: "",
  };

  // const dateDefaultValues = dateType && {
  //   ...(dateType === DateTypeConst.Range
  //     ? {
  //         startDate:  format(startDate ?? new Date(), "yyyy-MM-dd"),
  //         endDate: format(endDate ?? new Date(), "yyyy-MM-dd"),
  //       }
  //     : {
  //         date: format(date ? date : new Date(), "yyyy-MM-dd"),
  //       }),
  // };

  const deviceDefaultValues = {
    deviceId: undefined,
  };

  const sensorDefaultValues = {
    sensorId: undefined,
  };

  // * add Schema
  const deviceIdSchema = searchedDevice && {
    deviceGroup: yup.string().required("select_group"),
    deviceId: yup.string().required("select_device"),
  };

  const sensorSchema = searchedSensor && {
    sensorId: yup.string().required("select_sensor"),
  };

  const dateSchema = !searchedTotalPeriod && {
    ...(dateType === DateTypeConst.Range
      ? {
          startDate: yup.string().nullable().required("select_date"),
          endDate: yup.string().nullable().required("select_date"),
        }
      : dateType === DateTypeConst.Single
      ? {
          date: yup.string().nullable().required("select_date"),
        }
      : null),
  };

  const allSchema = {
    ...deviceIdSchema,
    ...sensorSchema,
    ...dateSchema,
  };

  // * useForm
  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
  } = useForm<SearchParameterValues, any>({
    resolver: yupResolver(yup.object().shape(allSchema)),
    defaultValues: {
      ...conditionSearchDefaultValues,
      // ...dateDefaultValues,
      ...deviceDefaultValues,
      ...sensorDefaultValues,
    },
  });

  // setValues
  useFormSetValue({
    name: "startDate",
    state: startDate,
    setValue,
    dateFormat: "yyyy-MM-dd",
  });
  useFormSetValue({
    name: "endDate",
    state: endDate,
    setValue,
    errorMessage: errors.endDate?.message,
    clearErrors,
    dateFormat: "yyyy-MM-dd",
  });
  useFormSetValue({
    name: "date",
    state: date,
    setValue,
    errorMessage: errors.date?.message,
    clearErrors,
    dateFormat: "yyyy-MM-dd",
  });
  useFormSetValue({
    name: "deviceGroup",
    state: selectedDeviceGroup.value,
    setValue,
    errorMessage: errors.deviceGroup?.message,
    clearErrors,
  });
  useFormSetValue({
    name: "deviceId",
    state: selectedDevice.value,
    setValue,
    errorMessage: errors.deviceId?.message,
    clearErrors,
  });
  useFormSetValue({
    name: "sensorId",
    state: selectedSensor?.value,
    setValue,
    errorMessage: errors.sensorId?.message,
    clearErrors,
  });
  useFormSetValue({
    name: "searchType",
    state: selectedSearchType.value,
    setValue,
    errorMessage: errors.searchType?.message,
    clearErrors,
  });
  useFormSetValue({
    name: "searchValue",
    state: searchValue,
    setValue,
    errorMessage: errors.searchValue?.message,
    clearErrors,
  });

  // * Functions
  const onSearchValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    []
  );

  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * searchParams를 업데이트/변경 합니다.
   *
   * @param {object} data - searchParams에 추가할 key:value 데이터
   * @param {string} addType - "add": 기존에 추가 / "create": data로 새로 생성
   * @returns null
   */
  const updateSearchParams = React.useCallback(
    (
      data: { [key: string]: string | number | null },
      addType: "add" | "create"
    ) => {
      let newSearchParams: URLSearchParams;
      if (addType === "add")
        newSearchParams = new URLSearchParams(searchParams);
      else {
        newSearchParams = new URLSearchParams();
      }

      Object.entries(data).forEach(([key, value]) => {
        if (value) newSearchParams.set(key, `${value}`);
      });

      setSearchParams(newSearchParams, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const onSubmit: SubmitHandler<SearchParameterValues> = React.useCallback(
    (data: SearchParameterValues) => {
      if (data.searchType === "" && data.searchValue !== "") {
        setError("searchType", {
          message: "검색 조건을 선택하세요.",
        });
        return;
      }

      const newParams: { [key: string]: number | string | null } = {};

      const contentUrl = searchParams.get("contentUrl");
      const viewLarger = searchParams.get("viewLarger");

      if (searchedDate) {
        if (dateType === DateTypeConst.Single && data.date) {
          newParams.date = data.date;
        } else if (data.startDate && data.endDate) {
          newParams.startDate = data.startDate;
          newParams.endDate = data.endDate;
        }
      }

      if (searchedSensor && data.sensorId) {
        newParams.sensorId = data.sensorId;
      } else if (searchedDevice && data.deviceId) {
        newParams.deviceId = data.deviceId;
      }

      // onSubmitedFirst가 false일 경우 처음 검색이기 때문에(새로고침) 서치 파라미터 페이지로 / 그외에는 새로 검색하는 것이기 때문에 page를 1로 초기화
      if (paging && setPaging) {
        const page = !onSubmitedFirst
          ? "1"
          : searchParams.get("page") ||
            (paging.page === 0 ? "1" : `${paging.page}`);
        const size =
          searchParams.get("size") ||
          (paging.size === 0 ? "20" : `${paging.size}`);

        newParams.page = page;
        newParams.size = size;

        setPaging({
          page: Number(page),
          size: Number(size),
        });

        if (!onSubmitedFirst) {
          setOnSubmitedFirst(true);
        }
      }

      if (hasContentUrl && contentUrl) {
        newParams.contentUrl = contentUrl;
      }

      if (hasViewLarger && viewLarger) {
        newParams.viewLarger = viewLarger;
      }

      if (customSearchParams) {
        Object.entries(customSearchParams).map(
          ([key, value]) => (newParams[key] = value)
        );
      }

      getUrlParams(data);
      updateSearchParams(newParams, "create");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, customSearchParams, onSubmitedFirst]
  );

  const onError = React.useCallback(
    (data: FieldErrors<SearchParameterValues>) => {
      console.log("onError", data);
      getUrlParams(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // 비즈니스그룹 셀렉트 클릭 이벤트
  const handleDeviceGroupSelect = React.useCallback((value: SelectOption) => {
    // state, useForm value 값 변경
    setSelectedDeviceGroup(value);
    setValue("deviceGroup", value.value);

    // 클리어 에러
    if (value.value && value.label && errors.deviceGroup?.message) {
      clearErrors("deviceGroup");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 디바이스 셀렉트 클릭 이벤트
  const handleDeviceSelect = React.useCallback(
    (value: SelectOption) => {
      // state, useForm value 값 변경
      setSelectedDevice(value);
      setValue("deviceId", value.value);

      // 클리어 에러
      if (value.value) {
        clearErrors("deviceId");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSelectedSensorForSearch = (value?: SelectOption) => {
    if (searchedSensor) {
      if (!value) {
        // 초기화
        setSelectedSensor(initialSelectOption);
        setValue("sensorId", "");
      } else {
        setValue("sensorId", value.value);
        setSelectedSensor(value);

        if (value.value && value.label && errors.sensorId?.message) {
          clearErrors("sensorId");
        }
      }
    }
  };

  // * Effects
  // 처음 searchParam을 searchParamsArr 키에 맞는 기본 값으로 정한다.
  useEffect(() => {
    if (!init && searchParams) {
      let deviceGroup = userInfo.deviceGroupList[0];
      let device = userInfo.deviceList.find(
        (item) => item.device_group_id === deviceGroup.device_group_id
      );
      let sensor = userInfo.sensorList.find(
        (item) => item.device_id === device?.device_id
      );

      const searchParamsArr = Array.from(searchParams.entries());

      if (searchParamsArr.length > 0) {
        searchParamsArr.forEach(([key, val]) => {
          switch (key) {
            case "startDate":
              setStartDate(parse(val, "yyyy-MM-dd", new Date()));
              break;
            case "endDate":
              setEndDate(parse(val, "yyyy-MM-dd", new Date()));
              break;
            case "date":
              setDate(parse(val, "yyyy-MM-dd", new Date()));
              break;
            case "sensorId":
              if (setSelectedSensor) {
                sensor = userInfo.sensorList.find(
                  (item) => item.sensor_id === val
                );
                device =
                  userInfo.deviceList.find(
                    (item) => item.device_id === sensor?.device_id
                  ) ?? device;
                deviceGroup =
                  userInfo.deviceGroupList.find(
                    (item) => item.device_group_id === device?.device_group_id
                  ) ?? deviceGroup;

                setSelectedDeviceGroup({
                  value: `${deviceGroup.device_group_id}`,
                  label: deviceGroup.device_group_name,
                });

                setSelectedDevice({
                  value: device?.device_id ?? "",
                  label: device?.device_name ?? "",
                });

                setSelectedSensor({
                  value: sensor?.sensor_id ?? "",
                  label: sensor?.sensor_id
                    ? getSensorLabel(sensor?.sensor_id)
                    : "",
                });
              }

              break;
            case "deviceId":
              device = userInfo.deviceList.find(
                (item) => item.device_id === val
              );
              deviceGroup =
                userInfo.deviceGroupList.find(
                  (item) => item.device_group_id === device?.device_group_id
                ) ?? deviceGroup;

              setSelectedDeviceGroup({
                value: `${deviceGroup.device_group_id}`,
                label: deviceGroup.device_group_name,
              });
              setSelectedDevice({
                value: device?.device_id ?? "",
                label: device?.device_name ?? "",
              });
              break;
            case "searchType":
              if (searchedConditionSearch) {
                const index = searchTypeOptions.findIndex(
                  (item) => item.value === val
                );
                setSelectedSearchType(searchTypeOptions[index]);
              }
              break;
            case "searchValue":
              setSearchValue(val);
              break;
            default:
              break;
          }
        });
      }

      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, searchParams]);

  // 처음에 검색 조건 설정이 완료된 후 disableAutoSearch가 true가 아닐 경우 submit 버튼 트리거
  useEffect(() => {
    if (init && !disableAutoSearch) {
      submitButtonRef.current?.click();
    }
  }, [init, disableAutoSearch]);

  // searchParams가 업데이트 되거나 selectedSensor?.value가 변경되고 센서 옵션에 매칭이 되면 submit 버튼 트리거
  useEffect(() => {
    if (!init || disableAutoSearch) {
      return;
    }

    if (searchedSensor && selectedSensor.value !== "") {
      if (memorizedSensorOptions && memorizedSensorOptions.length > 0) {
        const index = memorizedSensorOptions.findIndex(
          (item) => item.value === selectedSensor?.value
        );
        if (index !== -1) {
          // 선택된 센서가 센서 옵션에 유효할 때만 클릭
          submitButtonRef.current?.click();
        }
      }
    }

    if (!searchedSensor) {
      submitButtonRef.current?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, selectedSensor?.value]);

  // paging이 업데이트가 됐고 현재 searchParams랑 다를 경우 searchParams를 업데이트한다.
  useEffect(() => {
    if (init) {
      const page = searchParams.get("page");
      const size = searchParams.get("size");
      if (
        paging &&
        page &&
        size &&
        (paging.size !== Number(size) || paging.page !== Number(page))
      ) {
        updateSearchParams(paging, "add");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  // customSearchParam가 업데이트 되면 네비게이트 실행
  useEffect(() => {
    if (customSearchParams && init) {
      updateSearchParams(customSearchParams, "add");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSearchParams]);

  useEffect(() => {
    if (searchedSensor && init) {
      if (memorizedSensorOptions) {
        const index =
          memorizedSensorOptions?.findIndex(
            (item) => item.value === selectedSensor.value
          ) ?? -1;

        if (index !== -1) {
          setValue("sensorId", selectedSensor.value);
          return;
        } else if (memorizedSensorOptions.length > 0) {
          handleSelectedSensorForSearch(memorizedSensorOptions[0]);
          return;
        }
      } else {
        handleSelectedSensorForSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorizedSensorOptions, searchedSensor]);

  return {
    init,
    setValue,
    errors,
    clearErrors,
    date,
    setDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleSubmit,
    onSubmit,
    onError,
    handleDeviceGroupSelect,
    handleDeviceSelect,
    handleSelectedSensorForSearch,
    submitButtonRef,
    updateSearchParams,
    selectedDeviceGroup,
    setSelectedDeviceGroup,
    selectedDevice,
    setSelectedDevice,
    selectedSensor,
    setSelectedSensor,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    memorizedSensorOptions,
    selectedSearchType,
    setSelectedSearchType,
    searchValue,
    setSearchValue,
    onSearchValueChange,
  };
}

import { ReactElement } from "react";
import Icon from "../Icon";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { theme } from "@src/styles/theme";
import styled from "styled-components";

const StyledLoadingWrap = styled.div<{ displayStr: string }>`
  display: ${({ displayStr }) => displayStr};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  user-select: none;
  pointer-events: none;
`;

export default function LoadingSpinner(): ReactElement {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const display = isFetching || isMutating ? "flex" : "none";

  return (
    <StyledLoadingWrap displayStr={display}>
      <Icon iconName="loading" iconColor={theme.color.primary} />
      <span>Loading...</span>
    </StyledLoadingWrap>
  );
}

import { IColor, ICommonColor } from "./theme.d";

const commonColor: ICommonColor = {
  primary: "#6A7CDD",
  primaryHover: "#5567BE",
  primaryActive: "#364AA8",
  primaryShadow: "rgba(106, 124, 221, 0.4)",
  secondary: "#A579D9",
  secondaryHover: "#8D66BA",
  secondaryActive: "#70489F",
  secondaryShadow: "rgba(165, 121, 217, 0.4)",
  // invalid: "#d64747",
  invalid: "#FF3C3C",
  invalidShadow: "rgba(255, 60, 60, 0.40)",
  normal: "#6EB73F",
  attention: "#FFAC27",
  notWork: "#111111",
};

const light: IColor = {
  ...commonColor,
  scrollbar: "#8d94a766",
  background: "#F2F4F8",
  dimBackground: "rgba(0,0,0,0.15)",
  disabled: "#d6d1da",
  font: {
    default: "#333",
    placeHolder: "#b8b3bf",
    disabled: "#9699B4",
  },
  input: {
    background: "white",
    border: "#b8b3bf",
    disabled: "#D6D8E5",
    selectHover: "#EAECFC",
    label: "#9398A8",
  },
  floatBox: {
    boxShadow: "rgba(106, 124, 221, 0.61)",
    invalidBoxShadow: "rgba(209, 59, 59, 0.33)",
    floatIcon: "#6D7486",
    keyColorBoxBackground: "#e6e8ec",
  },
  card: {
    dimBackground: "rgba(0, 0, 0, 0.4)",
    background: "white",
    backgroundHover: "white",
    border: "#EAEBEF",
    boxShadow: "2px 6px 15px rgba(102, 107, 134, 0.1)",
    boxShadowHover: "4px 12px 20px 1px rgba(102, 107, 134, 0.34)",
  },
  button: {
    lineBackground: "white",
  },
  chart: {
    ticks: {
      color: "#788097",
    },
    grid: {
      border: "#efefef",
    },
  },
  table: {
    checkbox: "#6A7CDD",
    checkboxHover: "#5567BE",
    checkboxShadow: "rgba(106, 124, 221, 0.4)",
    thead: {
      backgroundPrimary: "#EAF3FF",
      fontPrimary: "#6A7CDD",
      backgroundSecondary: "#F0E3FF",
      fontSecondary: "#A579D9",
    },
    tbody: {
      backgroundOdd: "#f1f4f8",
      backgroundEven: "white",
      // backgroundEvne: "#f1f4f8"
      backgroundHoverPrimary: "rgba(106, 124, 221, 0.2)",
      backgroundActivePrimary: "rgba(106, 124, 221, 0.4)",
      backgroundHoverSecondary: "#DFCEF4",
      backgroundActiveSecondary: "#A579D9",
      font: "#646464",
      fontActive: "white",
    },
  },
  sidebar: {
    boxShadow: "7px 0 20px rgba(197, 196, 209, 0.3)",
    menu: "#A6A8B5",
    menuTitle: "#D1D5DE",
    navLine: "#E2E6F0",
  },
  userInfoBox: {
    background: "#E7ECFA",
    font: "#5D5C71",
  },
  calendar: {
    font: "#ffffff",
    fontDisabled: "#e5e6ea",
    backgroundHover: "#aaa",
    fontHover: "#ffffff",
    backgroundRanges: "#dfefff",
    fontRanges: "#ffffff",
  },
  tab: {
    default: "#ACB1C1",
    hover: "#8A8D96",
  },
  paginaion: {
    icon: "#ACB6EB",
    font: "#BBC6DE",
  },
  auth: {
    background: "#C8D1EF",
    // imageBackground: "linear-gradient(140deg, #6A7CDD, #A579D9)",
    imageBackground: "linear-gradient(150deg, #6A7CDD 20%, #A579D9 85%)",
    card: "#E7ECFA",
  },
  event: {
    dimBackground:
      "linear-gradient(270deg, rgba(56, 63, 112, 0.37) 10%, transparent)",
  },
};

const dark: IColor = {
  ...commonColor,
  scrollbar: "#8d94a733",
  background: "#1C1C27",
  dimBackground: "rgba(0,0,0,0.4)",
  disabled: "#d6d1da",
  font: {
    default: "#d4d3db",
    placeHolder: "#545062",
    disabled: "#424660",
  },
  input: {
    background: "#15151F",
    border: "#0D0D0D",
    disabled: "#656883",
    selectHover: "#1B1B26",
    label: "#6D7486",
  },
  floatBox: {
    boxShadow: "rgba(106, 124, 221, 1)",
    invalidBoxShadow: "rgba(191, 51, 51, 0.67)",
    floatIcon: "#ffffff",
    keyColorBoxBackground: "#3b3f49",
  },
  card: {
    dimBackground: "rgba(65, 67, 86, 0.6)",
    background: "#272734",
    backgroundHover: "#272734",
    border: "#21212C",
    boxShadow: "2px 8px 25px rgba(4, 4, 5, 0.1)",
    boxShadowHover: "4px 12px 25px 4px rgba(4, 4, 5, 0.74)",
  },
  button: {
    lineBackground: "#111",
  },
  chart: {
    ticks: {
      color: "#8991a7",
    },
    grid: {
      border: "#2E2E2E",
    },
  },
  table: {
    checkbox: "white",
    checkboxHover: "#FFF8A8",
    checkboxShadow: "rgba(255, 255, 255, 0.4)",
    thead: {
      backgroundPrimary: "#6A7CDD",
      fontPrimary: "white",
      backgroundSecondary: "#A579D9",
      fontSecondary: "wihte",
    },
    tbody: {
      backgroundOdd: "#323241",
      backgroundEven: "#21212C",
      backgroundHoverPrimary: "rgba(106, 124, 221, 0.24)",
      backgroundActivePrimary: "rgba(106, 124, 221, 0.5)",
      backgroundHoverSecondary: "#A579D9",
      backgroundActiveSecondary: "#A579D9",
      font: "white",
      fontActive: "white",
    },
  },
  sidebar: {
    boxShadow: "7px 0 20px rgba(10, 10, 15, 0.6)",
    menu: "#A6A8B5",
    menuTitle: "#575A64",
    navLine: "#2B2B39",
  },
  userInfoBox: {
    background: "#222639",
    font: "#ffffff",
  },
  calendar: {
    font: "#ffffff",
    fontDisabled: "#2c2e36",
    backgroundHover: "#aaa",
    fontHover: "#ffffff",
    backgroundRanges: "#bbddff",
    fontRanges: "#ffffff",
  },
  tab: {
    default: "#ACB1C1",
    hover: "#8A8D96",
  },
  paginaion: {
    icon: "#ACB6EB",
    font: "#BBC6DE",
  },
  auth: {
    background: "#1C1C27",
    imageBackground: "linear-gradient(150deg, #6A7CDD 20%, #A579D9 85%)",
    card: "#21212C",
  },
  event: {
    dimBackground:
      "linear-gradient(270deg, rgba(20, 21, 31, 0.51) 10%, transparent)",
  },
};

const color = {
  light,
  dark,
};

export default color;

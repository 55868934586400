import { auth, db, messaging } from "@src/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { doc, setDoc, getDoc, Timestamp, deleteDoc } from "firebase/firestore";
import { addMonths } from "date-fns";
import {
  User,
  getIdToken,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  initializeUserInfo,
  useCommonStoreActions,
  useLoggedInStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import { ReactNode } from "react";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";
import { useTranslation } from "react-i18next";
import { getSensorLabel } from "./useCommonHooks";
import { useQueryClient } from "@tanstack/react-query";

interface IUserFcmToken {
  uid: string;
  fcmToken: string;
  expired: Timestamp;
}

interface IUserInterestSensor {
  uid: string;
  sensorList: string[];
  createdAt: Timestamp;
}

export default function useFirebaseHooks() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userInfo = useUserInfoStore();
  const { setLoggedIn, setUserInfo, setInterestSensorList } =
    useCommonStoreActions();
  const { handleAlertDialogOpen, cancelAlertDialogOpen } = useAlertDialog();
  const { handleNoticeDialogOpen } = useNoticeDialog();
  const loggedIn = useLoggedInStore();

  // firestore user uid
  const getAsyncFirebaseUser = async () =>
    await new Promise<User>((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user as User);
      }, reject);
    });

  // Firestore에 저장된 토큰이 있는지 확인하기
  async function sendTokenToServer(currentToken: string) {
    const result = await isTokenSentToServer(currentToken);
    const user = await getAsyncFirebaseUser();

    if (user && loggedIn) {
      if (!result) {
        // Sending token to server...
        try {
          // 한달 후로 추가하기
          await setDoc(doc(db, "userFcmToken", user.uid), {
            uid: user.uid,
            fcmToken: currentToken,
            expired: Timestamp.fromDate(addMonths(new Date(), 1)),
          });
        } catch (e) {
          console.log("sendTokenToServer error =>", e);
        }
      } else {
        // Token already sent to server so won't send it again / unless it changes
      }
    }
  }

  // Firestore의 userFcmToken 컬렉션의 uid 문서에 토큰이 이미 존재하는지 확인
  async function isTokenSentToServer(currentToken: string) {
    const user = await getAsyncFirebaseUser();

    if (user && loggedIn) {
      const docRef = doc(db, "userFcmToken", user.uid);
      const data = await getDoc(docRef).then(
        (docSnap) => docSnap.data() as IUserFcmToken
      );

      if (data) {
        const expired =
          data.expired.seconds * 1000 + data.expired.nanoseconds / 1000000;
        const now = new Date().getTime();

        // Firestore에 저장된 토큰과 현재 토큰이 다른 경우 (기기 변경 가능성) DB 업데이트
        if (data.fcmToken !== currentToken) {
          // Token is Different
          return false;
        }

        // 현재가 expired보다 크거나 같을 경우 (만료됐을 경우) DB 업데이트
        if (now >= expired) {
          // should be token update
          return false;
        }

        // DB 업데이트 X
        return true;
      } else {
        // No such document! Have to be token update
        return false;
      }
    }
  }

  //토큰값 얻기
  const getTokenHook = () =>
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
    })
      .then((currentToken) => {
        if (currentToken && loggedIn) {
          // Send the token to your server and update the UI if necessary
          sendTokenToServer(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // noticeNotificationPermissionDenied();
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        if (err.message.includes("not granted and blocked instead")) {
          // 푸시 알림 거부되었다는 팝업 한번 띄워주기
          // noticeNotificationPermissionDenied();
        }
      });

  //포그라운드 메시지 수신
  const onMessageHook = (
    eventDataset: EventData[] | null,
    setEventDataset: (eventDataset: EventData[] | null) => void
  ) => {
    return onMessage(messaging, (payload) => {
      if (payload.data) {
        let newData: EventData = {
          msg: payload.data.msg,
          sensorId: payload.data.sensorId,
          deviceId: payload.data.deviceId,
          deviceName: payload.data.deviceName,
          structureId: payload.data.structureId,
          structureName: payload.data.structureName,
          messageSendTime: payload.data.messageSendTime,
        };

        if (
          getSensorLabel(payload.data.sensorId, "only_type") === "SC" ||
          getSensorLabel(payload.data.sensorId, "only_type") === "CM"
        ) {
          newData = {
            ...newData,
            time: payload.data.time,
            detail: payload.data.detail,
          };
        } else {
          newData = {
            ...newData,
            start: payload.data.startTime,
            end: payload.data.endTime,
            data: JSON.parse(payload.data.data),
            val: Number(payload.data.val),
          };
        }

        if (eventDataset) {
          setEventDataset([...eventDataset, newData]);
        } else {
          setEventDataset([newData]);
        }
      }
    });
  };

  // 이메일 인증 메일 발송 함수
  const handleSendEmailVerification = (user: User) =>
    sendEmailVerification(user)
      .then(() => {
        handleAlertDialogOpen(
          t("send_auth_email_title"),
          t("send_auth_email_message")
        );
        setTimeout(() => {
          cancelAlertDialogOpen();
          navigate("/auth/login");
        }, 1000);
      })
      .catch((e) => {
        console.log("이메일 인증 링크 전송을 실패하였습니다.", e);
      });

  const setInitializeUserInfo = () => {
    // 계정 관련 정보 스토리지 초기화
    setUserInfo(initializeUserInfo);
    const gbStores = localStorage.getItem("gbStores");
    const newData = JSON.parse(gbStores as string);
    newData.state.userInfo = initializeUserInfo;
    localStorage.setItem("gbStores", JSON.stringify(newData));
  };

  const handleLogout = async () => {
    // Firebase Logout
    if (auth) {
      const result = await handleNoticeDialogOpen(
        t("logout_notification_title"),
        t("logout_notification_message")
      );

      if (result) {
        setLoggedIn(false);
        setInitializeUserInfo();
        setInterestSensorList([]);
        navigate("/auth/login");

        // 로그아웃 하기 전에 지우기
        if (auth.currentUser?.uid) {
          await deleteDoc(doc(db, "userFcmToken", auth.currentUser?.uid));
        }

        signOut(auth)
          .then(async () => {
            // Sign-out successful.
            console.log("Sign out..");
            queryClient.invalidateQueries(["useFetchUserInfo"]); // 'user' 쿼리 무효화
            queryClient.removeQueries(["user"]); // 'user' 쿼리 캐시 삭제
            queryClient.clear();
          })
          .catch((error) => {
            console.log("Failed Logout Firebase", error);
          });
      }
    } else {
      navigate("/auth/login");
    }
  };

  const handleForceLogout = async (title?: string, message?: ReactNode) => {
    // Firebase Logout
    if (auth) {
      handleAlertDialogOpen(
        title ?? t("invalid_user_title"),
        message ?? t("invalid_user_message")
      );

      setTimeout(async () => {
        cancelAlertDialogOpen();
        setLoggedIn(false);
        setInitializeUserInfo();
        setInterestSensorList([]);
        navigate("/auth/login");

        // 로그아웃 하기 전에 지우기
        if (auth.currentUser?.uid) {
          await deleteDoc(doc(db, "userFcmToken", auth.currentUser?.uid));
        }

        signOut(auth)
          .then(async () => {
            // Sign-out successful.
            console.log("Sign out..");
            queryClient.invalidateQueries(["useFetchUserInfo"]); // 'user' 쿼리 무효화
            queryClient.removeQueries(["user"]); // 'user' 쿼리 캐시 삭제
            queryClient.clear();
          })
          .catch((error) => {
            console.log("Failed Logout Firebase", error);
          });
      }, 1000);
    } else {
      navigate("/auth/login");
    }
  };

  const getUserInterestSensor: (
    uid?: string
  ) => Promise<IUserInterestSensor | null> = async (uid?: string) => {
    if (uid) {
      const docRef = doc(db, "userInterestSensor", uid);
      const data = await getDoc(docRef).then(
        (docSnap) => docSnap.data() as IUserInterestSensor
      );

      if (!data) {
        // 0번째 그룹 0번째 디바이스의 0번째 센서 임의로 넣기
        const newData = await setDoc(docRef, {
          uid,
          sensorList: [userInfo.sensorList[0].sensor_id],
          createdAt: Timestamp.fromDate(new Date()),
        }).then(async () => {
          const data = await getDoc(docRef).then(
            (docSnap) => docSnap.data() as IUserInterestSensor
          );
          return data;
        });
        return newData;
      }

      return data;
    } else {
      const user = await getAsyncFirebaseUser();

      if (user) {
        const docRef = doc(db, "userInterestSensor", user.uid);
        const data = await getDoc(docRef).then(
          (docSnap) => docSnap.data() as IUserInterestSensor
        );

        if (!data) {
          // 0번째 그룹 0번째 디바이스의 0번째 센서 임의로 넣기
          const newData = await setDoc(docRef, {
            uid: user.uid,
            sensorList: [userInfo.sensorList[0].sensor_id],
            createdAt: Timestamp.fromDate(new Date()),
          }).then(async () => {
            const data = await getDoc(docRef).then(
              (docSnap) => docSnap.data() as IUserInterestSensor
            );
            return data;
          });
          return newData;
        }

        return data;
      }
    }

    return null;
  };

  const setUserInterestSensor = async (sensorList: string[]) => {
    const user = await getAsyncFirebaseUser();

    if (user) {
      const docRef = doc(db, "userInterestSensor", user.uid);
      await setDoc(docRef, {
        sensorList: sensorList,
        createdAt: Timestamp.fromDate(new Date()),
        uid: user.uid,
      });
    }
  };

  const getUserIdToken = async () => {
    const user: User = await new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user as User);
      }, reject);
    });
    if (user) {
      const token = await getIdToken(user);

      if (token) {
        return token;
      } else {
        return false;
      }
    }
  };

  return {
    getTokenHook,
    onMessageHook,
    setInitializeUserInfo,
    handleSendEmailVerification,
    handleLogout,
    handleForceLogout,
    getUserInterestSensor,
    setUserInterestSensor,
    getUserIdToken,
    getAsyncFirebaseUser,
  };
}

import IconButton from "../Buttons/IconButton";
import {
  NormalPaginationIconButtonStyles,
  SmallPaginationIconButtonStyles,
} from "../Buttons/sizeTheme";
import {
  StyledIconButtonWrap,
  StyledNumber,
  StyledNumberWrap,
  StyledPaginationWrap,
  StyledSizeSelectWrap,
} from "./styles";
import { darkTheme, theme } from "@src/styles/theme";
import React, { useEffect } from "react";
import {
  BrowserSizeConst,
  FlexAlignTypeConst,
  ThemeConst,
} from "@constructs/common";
import Select from "../Inputs/Selects/Select";
import { NormalInputStyle } from "../Inputs/sizeTheme";
import { useBrowserSizeStore, useThemeStore } from "@src/stores/useCommonStore";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  groupLength?: 3 | 5 | 10;
  page: number;
  size: number;
  count: number;
  align?: FlexAlignType;
  showSizeSelect?: boolean;
  elementSize: "small" | "normal";
  setPaging: (paging: PagingType) => void;
  refetch?: (paging: PagingType) => void;
}

function Pagination({
  groupLength,
  page,
  size,
  count,
  align,
  showSizeSelect,
  setPaging,
  elementSize,
  refetch,
}: PaginationProps) {
  const { t } = useTranslation();
  const themeType = useThemeStore();
  const browserSize = useBrowserSizeStore();

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [currentSize, setCurrentSize] = React.useState<number>(0);
  const [currentGroupLength, setCurrentGroupLength] = React.useState<number>(
    browserSize &&
      browserSize <= BrowserSizeConst.Mobile &&
      groupLength &&
      groupLength > 5
      ? 5
      : groupLength ?? 5
  );
  const [pageNumbers, setPagingNumbers] = React.useState<number[]>([0]);
  const [maxNumber, setMaxNumber] = React.useState<number>(0);

  useEffect(() => {
    setMaxNumber(() => {
      return Math.ceil(count / size);
    });
  }, [size, count]);

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page, currentPage]);

  useEffect(() => {
    if (size !== currentSize) {
      setCurrentSize(size);
    }
  }, [size, currentSize]);

  useEffect(() => {
    setPagingNumbers(() => {
      if (currentGroupLength >= maxNumber) {
        return new Array(maxNumber).fill(0).map((_i, index) => index + 1);
      } else {
        const newData = [page];
        const half = Math.ceil((currentGroupLength - 1) / 2);

        // page 보다 작은 숫자 unshift
        new Array(half).fill(0).forEach((_) => {
          const number = newData[0] - 1;

          if (number >= 1) {
            newData.unshift(number);
          }
        });

        // page 보다 큰 숫자 push
        const newHalf = currentGroupLength - newData.length;
        new Array(newHalf).fill(0).forEach((_) => {
          const nextNum = newData[newData.length - 1] + 1;
          const prevNum = newData[0] - 1;

          if (nextNum <= maxNumber) {
            newData.push(nextNum);
          } else if (prevNum) {
            newData.unshift(prevNum);
          }
        });

        return newData;
      }
    });
  }, [currentPage, currentGroupLength, maxNumber, page]);

  // 한번에 보여지는 page number의 수
  useEffect(() => {
    if (browserSize && browserSize <= BrowserSizeConst.Mobile) {
      setCurrentGroupLength(() => 5);
    } else if (groupLength) {
      setCurrentGroupLength(() => groupLength);
    }
  }, [groupLength, browserSize]);

  const FrontButton = () => {
    return (
      <IconButton
        sizeTheme={
          elementSize === "small"
            ? SmallPaginationIconButtonStyles
            : NormalPaginationIconButtonStyles
        }
        colorType="custom"
        iconName="front"
        iconColor={
          themeType === ThemeConst.Light
            ? theme.color.paginaion.icon
            : darkTheme.color.paginaion.icon
        }
        iconHoverColor={theme.color.primary}
        onClick={() => {
          setPaging({ size, page: 1 });

          if (refetch) {
            refetch({ size, page: 1 });
          }
        }}
        isDisabled={pageNumbers[0] === 1}
      />
    );
  };

  const BackButton = () => {
    return (
      <IconButton
        sizeTheme={
          elementSize === "small"
            ? SmallPaginationIconButtonStyles
            : NormalPaginationIconButtonStyles
        }
        colorType="custom"
        iconName="back"
        iconColor={
          themeType === ThemeConst.Light
            ? theme.color.paginaion.icon
            : darkTheme.color.paginaion.icon
        }
        iconHoverColor={theme.color.primary}
        onClick={() => {
          setPaging({ size, page: maxNumber });

          if (refetch) {
            refetch({ size, page: maxNumber });
          }
        }}
        isDisabled={pageNumbers[pageNumbers.length - 1] === maxNumber}
      />
    );
  };

  const PrevButton = () => {
    return (
      <IconButton
        sizeTheme={
          elementSize === "small"
            ? SmallPaginationIconButtonStyles
            : NormalPaginationIconButtonStyles
        }
        colorType="custom"
        iconName="arrowLeft"
        iconColor={
          themeType === ThemeConst.Light
            ? theme.color.paginaion.icon
            : darkTheme.color.paginaion.icon
        }
        iconHoverColor={theme.color.primary}
        onClick={() => {
          setPaging({ size, page: page - 1 });

          if (refetch) {
            refetch({ size, page: page - 1 });
          }
        }}
        isDisabled={currentPage === 1}
      />
    );
  };

  const NextButton = () => {
    return (
      <IconButton
        sizeTheme={
          elementSize === "small"
            ? SmallPaginationIconButtonStyles
            : NormalPaginationIconButtonStyles
        }
        colorType="custom"
        iconName="arrowRight"
        iconColor={
          themeType === ThemeConst.Light
            ? theme.color.paginaion.icon
            : darkTheme.color.paginaion.icon
        }
        iconHoverColor={theme.color.primary}
        onClick={() => {
          setPaging({ size, page: page + 1 });
          if (refetch) {
            refetch({ size, page: page + 1 });
          }
        }}
        isDisabled={currentPage === maxNumber}
      />
    );
  };

  // Size Select
  const sizeSelectOptions = React.useMemo(() => {
    return [
      {
        value: "10",
        label: t("view_each", { num: 10 }),
      },
      {
        value: "15",
        label: t("view_each", { num: 15 }),
      },
      {
        value: "20",
        label: t("view_each", { num: 20 }),
      },
      {
        value: "30",
        label: t("view_each", { num: 30 }),
      },
      {
        value: "50",
        label: t("view_each", { num: 50 }),
      },
      {
        value: "100",
        label: t("view_each", { num: 100 }),
      },
    ];
  }, [t]);

  const handleSizeSelect = (value: SelectOption) => {
    // 현재 보고 있는 데이터들 중  1번째 데이터 번호
    const now = size * page - size - 1;
    const newSize = Number(value.value);
    let newPage = 1;
    // 새로운 맥스넘버
    const newMaxNumber = Math.ceil(count / newSize);

    new Array(newMaxNumber).fill(0).forEach((_, index) => {
      if (now > index * newSize && now < (index + 1) * newSize) {
        newPage = index + 1;
      }
    });

    setPaging({ size: newSize as SizeType, page: newPage });
    setMaxNumber(newMaxNumber);

    if (refetch) {
      refetch({ size: newSize, page: newPage });
    }
  };

  return (
    <StyledPaginationWrap
      align={align ?? FlexAlignTypeConst.SpaceBetween}
      style={{
        flexWrap: "wrap",
        marginTop: 15,
      }}
    >
      <StyledPaginationWrap
        align={align ?? FlexAlignTypeConst.Center}
        style={
          showSizeSelect
            ? undefined
            : {
                flex: 1,
                alignSelf: "center",
              }
        }
      >
        <StyledIconButtonWrap>
          <FrontButton />
          <PrevButton />
        </StyledIconButtonWrap>
        <StyledNumberWrap>
          {pageNumbers.map((item, index) => {
            return (
              <StyledNumber
                elementSize={elementSize}
                key={index}
                className={item === page ? "active" : undefined}
                onClick={() => {
                  setCurrentPage(item);
                  setPaging({ size, page: item });

                  if (refetch) {
                    refetch({ size, page: item });
                  }
                }}
                data-num={item}
              />
            );
          })}
        </StyledNumberWrap>
        <StyledIconButtonWrap>
          <NextButton />
          <BackButton />
        </StyledIconButtonWrap>
      </StyledPaginationWrap>
      {showSizeSelect && (
        <StyledSizeSelectWrap>
          <Select
            name="size"
            sizeTheme={NormalInputStyle}
            options={sizeSelectOptions}
            placeholder="Select Size"
            event={handleSizeSelect}
            fixWidth={140}
            value={{
              value: String(size),
              label: t("view_each", { num: size }),
            }}
          />
        </StyledSizeSelectWrap>
      )}
    </StyledPaginationWrap>
  );
}

const PaginationMemo = React.memo(
  Pagination,
  (prevProps, nextProps) =>
    prevProps.page === nextProps.page &&
    prevProps.size === nextProps.size &&
    prevProps.count === nextProps.count
);

export default PaginationMemo;

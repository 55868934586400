import styled from "styled-components";
import { CardSizeProps } from "./sizeTheme";
import { ReactNode } from "react";
import { darkTheme, mediaQuery, theme } from "@src/styles/theme";
import {
  Div,
  StyledIconButton,
  StyledIconFloatWrap,
} from "@src/styles/commonStyles";
import React from "react";
import Icon from "../Icon";
import { useThemeStore } from "@src/stores/useCommonStore";
import { ThemeConst } from "@src/constructs/common";
import DataLoadingText from "../Loadings/DataLoadingText";
import { useTranslation } from "react-i18next";

const StyledLoadingWrap = styled(Div)<{
  bgColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.color.card.background};
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledCardWrapper = styled(Div)<{
  sizeTheme: CardSizeProps;
  isClickable?: boolean;
  isHeaderIn?: boolean;
}>`
  flex: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${({ isHeaderIn }) =>
    isHeaderIn ? "max-content minmax(50px,1fr)" : "minmax(50px,1fr)"};

  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.card.background};
  border: 1px solid ${({ theme }) => theme.color.card.border};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.color.card.boxShadow};

  position: relative;
  overflow: hidden;

  &:hover {
    background: ${({ theme, isClickable }) =>
      isClickable ? theme.color.card.backgroundHover : undefined};
    box-shadow: ${({ theme, isClickable }) =>
      isClickable ? theme.color.card.boxShadowHover : undefined};
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : undefined)};
  }
`;

const StyledCardHeader = styled(Div)<{
  isheaderButtons: boolean;
  sizeTheme: CardSizeProps;
}>`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  padding: ${({ sizeTheme }) =>
    `${sizeTheme.paddingTB}px ${sizeTheme.paddingLR}px 0`};
  width: 100%;

  h5 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    flex: 1;

    width: ${({ isheaderButtons }) => (isheaderButtons ? "auto" : "100%")};
    max-width: ${({ isheaderButtons }) => (isheaderButtons ? "90%" : "100%")};

    color: ${({ theme }) => theme.color.font.default};
    font-size: ${({ theme }) => theme.size.common.fontSize.md};
    font-weight: ${({ theme }) => theme.size.common.fontWeight.lg};
    line-height: ${({ theme }) => theme.size.common.fontSize.lg};
    word-break: keep-all;
  }

  ${mediaQuery.mobile} {
    padding: 25px 15px 0;
  }
`;

const StyledHeaderButtonsWrap = styled(Div)`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  min-width: max-content;

  & > * {
    margin: 0;
  }
`;

export const StyledCardBody = styled(Div)<{ sizeTheme: CardSizeProps }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ sizeTheme }) =>
    `${sizeTheme.paddingTB}px ${sizeTheme.paddingLR}px`};
  height: auto;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  ${mediaQuery.mobile} {
    padding: 10px 15px 25px;
  }
`;

interface CommonCardProps {
  layoutGridColumn?: string;
  sizeTheme: CardSizeProps;
  title?: NonNullable<ReactNode>;
  headerButtons?: ReactNode;
  topButtons?: ReactNode;
  children: ReactNode;
  style?: any;
  isLoading?: boolean;
  loadingText?: string;
  event?: () => void;
}
type ClickableCarProps =
  | {
      isClickable: true;
      cardClickEvent: () => void;
    }
  | {
      isClickable?: never;
      cardClickEvent?: never;
    };

type CardProps = CommonCardProps & CloseableComponentProps & ClickableCarProps;

export default function Card({
  sizeTheme,
  title,
  topButtons,
  headerButtons,
  children,
  style,
  isLoading,
  loadingText,
  showCloseBtn,
  handleClose,
  isClickable,
  cardClickEvent,
  layoutGridColumn,
}: CardProps) {
  // hooks
  const { t } = useTranslation();
  const themeType = useThemeStore();

  const handleCloseCallback = React.useCallback(() => {
    if (handleClose) {
      handleClose();
    }
  }, [handleClose]);

  return (
    // <StyledCardContainer>
    <StyledCardWrapper
      style={style}
      sizeTheme={sizeTheme}
      isClickable={isClickable}
      onClick={isClickable ? cardClickEvent : undefined}
      layoutGridColumn={layoutGridColumn}
      isHeaderIn={title || headerButtons ? true : false}
    >
      {showCloseBtn && (
        <StyledIconFloatWrap floatTop={10} floatRight={10}>
          <StyledIconButton type="button" onClick={handleCloseCallback}>
            <Icon
              iconName="close"
              iconWidth={16}
              iconHeight={16}
              iconColor={
                themeType === ThemeConst.Light
                  ? theme.color.floatBox.floatIcon
                  : darkTheme.color.floatBox.floatIcon
              }
            />
          </StyledIconButton>
        </StyledIconFloatWrap>
      )}
      {topButtons ?? null}
      {(title || headerButtons) && (
        <StyledCardHeader
          sizeTheme={sizeTheme}
          isheaderButtons={headerButtons ? true : false}
        >
          <h5>{title}</h5>
          {headerButtons && (
            <StyledHeaderButtonsWrap>{headerButtons}</StyledHeaderButtonsWrap>
          )}
        </StyledCardHeader>
      )}
      <StyledCardBody sizeTheme={sizeTheme}>
        {isLoading ? (
          <>
            {children}
            <StyledLoadingWrap>
              <DataLoadingText
                text={loadingText ?? t("loading_data")}
                showIcon
                size="sm"
              />
              {/* <Icon iconName="loading" iconColor={theme.color.primary} /> */}
            </StyledLoadingWrap>
          </>
        ) : (
          children
        )}
      </StyledCardBody>
    </StyledCardWrapper>
    // </StyledCardContainer>
  );
}

// const CardMemo = React.memo(
//   Card,
//   (prevProps, nextProps) =>
//     prevProps.children === nextProps.children &&
//     prevProps.isLoading === nextProps.isLoading
// );

// export default CardMemo;

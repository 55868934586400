import { Link } from "react-router-dom";
import Icon from "../Icon";
import { useThemeStore } from "@src/stores/useCommonStore";
import { ThemeConst } from "@src/constructs/common";

interface LogoProps {
  type: "small" | "default";
  fixWidth?: number;
  fixHeight?: number;
}

export default function Logo({ type, fixWidth, fixHeight }: LogoProps) {
  const themeType = useThemeStore();

  return (
    <h1>
      <Link
        to="/home"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {type === "small" ? (
          <Icon
            iconName={
              themeType === ThemeConst.Light
                ? "gbWebSmallLogoLight"
                : "gbWebSmallLogoDark"
            }
            iconWidth={50}
            iconHeight={32}
          />
        ) : (
          <Icon
            iconName={
              themeType === ThemeConst.Light
                ? "gbWebLogoLight"
                : "gbWebLogoDark"
            }
            iconWidth={fixWidth ?? 155}
            iconHeight={fixHeight ?? 32}
          />
        )}
      </Link>
    </h1>
  );
}

import { Div } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import styled from "styled-components";

export const StlyedDialogWrap = styled(Div)`
  background-color: ${({ theme }) => theme.color.card.background};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.color.card.boxShadow};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

export const StyledDialogHeader = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 20px;
  position: relative;
`;
export const StyledDialogBody = styled(Div)`
  flex: 1;
  padding: 0 20px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  font-size: 14px;
  color: ${({ theme }) => theme.color.font.default};

  & > * {
    /* padding: 20px 0px; */
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

export const StlyedDialogFooter = styled(Div)`
  flex: 0 0 auto;
  padding: 20px;
`;

export const StyledTitleBox = styled(Div)`
  display: inline-flex;
  align-items: center;
  gap: 10px;

  & > h4 {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.color.font.default};
  }
`;

export const StyledContentWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledMessageWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDimContainer = styled(Div)<{
  open: boolean;
  customZIndex: number;
  sizeTheme: any;
  padding?: number;
  customMaxWidth?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  padding: ${({ padding }) => padding ?? 40}px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  background-color: ${({ theme }) => theme.color.card.dimBackground};
  z-index: ${({ customZIndex }) => customZIndex};

  ${StlyedDialogWrap} {
    max-width: ${({ customMaxWidth, sizeTheme }) =>
      customMaxWidth ? customMaxWidth : sizeTheme.maxWidth ?? 400}px;
    min-height: ${({ sizeTheme }) => sizeTheme.minHeight ?? 240}px;
    max-height: 100%;
  }

  ${StyledTitleBox} {
    & > h4 {
      font-size: ${({ sizeTheme }) => sizeTheme.titleFontSize};
    }
  }

  ${mediaQuery.mobile} {
    padding: 20px;
  }
`;

import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import { StyledFlexWrap, StyledGridWrap } from "@src/styles/commonStyles";
import React, { useEffect } from "react";
import DefaultLineChart from "@src/components/Charts/DefaultLineChart";
import { theme } from "@src/styles/theme";
import useNetworkHooks from "@src/hooks/useNetworkHooks";
import { useTranslation } from "react-i18next";
import Loading from "@src/components/Loadings/Loading";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { v4 as uuidv4 } from "uuid";
import WebsocketDeviceSearchRow from "@src/components/Searchbar/WebsocketDeviceSearchRow";
import useCommonHooks, { getSensorLabel } from "@src/hooks/useCommonHooks";
import { useSearchParams } from "react-router-dom";
// import HeatmapChart from "@src/components/Charts/HeatmapChart";

export default function RealtimeDataPage() {
  // * Hooks
  const { t } = useTranslation();
  const { useWebSocket } = useNetworkHooks();
  const { useCustomLoading } = useCommonHooks();
  const { handleAlertDialogOpen } = useAlertDialog();
  const [searchParams] = useSearchParams();
  useCustomLoading();

  // * State
  const [selectedSensor, setSelectedSensor] =
    React.useState<SelectOption>(initialSelectOption);
  const [clientId] = React.useState<string>(() => uuidv4());
  const [dataParams, setDataParams] = React.useState({ sensorId: "" });
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const wsRef = React.useRef<WebSocket | null>(null);
  const realtimeInitializingRef = React.useRef<any>(null);

  const [chartData, setChartData] = React.useState(() => ({
    labels: [] as number[],
    datasets: [
      {
        label: "Raw data",
        data: [] as number[],
        borderWidth: 1.2,
        radius: 0,
        borderColor: theme.color.primary,
        backgroundColor: theme.color.primary,
      },
    ],
  }));

  const [fftChartData, setFftChartData] = React.useState({
    labels: [] as number[],
    datasets: [
      {
        label: "FFT data",
        data: [] as number[],
        borderWidth: 2,
        radius: 0,
        borderColor: theme.color.secondary,
        backgroundColor: theme.color.secondary,
        tension: 0.2,
      },
    ],
  });

  // const [spectrum, setSpectrum] = React.useState<number[][]>([]);

  // 웹소켓 onMessage 함수
  const getUrlParams = (data: SearchParameterValues | null) => {
    if (data) {
      setDataParams((prev) => {
        const newData = { ...prev };
        newData.sensorId = data.sensorId ?? "";

        // 기존 상태와 비교하여 변경사항이 있는 경우에만 업데이트
        if (newData.sensorId !== prev.sensorId) {
          return newData;
        }
        return prev; // 변경사항이 없으면 기존 상태를 반환
      });
    }
  };

  const onMessage = React.useCallback(
    (event: MessageEvent<any>) => {
      try {
        const data = JSON.parse(event.data);

        if ("raw" in data) {
          setIsLoading(false);

          // setChart(newData);
          // 새로운 chartDatasets 배열 생성
          setChartData((prev) => {
            const newData = { ...prev };
            const newChartData = data.raw.data;
            let lastNum = 0;

            if (newData.labels.length > 0) {
              lastNum = newData.labels[newData.labels.length - 1];
            }

            newChartData.forEach((item: number, i: number) => {
              newData.labels.push(lastNum + 1 + i);
              newData.datasets[0].data.push(item);
            });

            if (newData.labels.length > 2100) {
              newData.labels.splice(0, 100);
              newData.datasets[0].data.splice(0, 100);
            }

            // FFT 데이터 생성
            if (newData.datasets[0].data.length === 2100) {
              const FFT = require("fft.js");

              // 샘플 데이터를 생성합니다.
              const sampleRate = 100; // 샘플링 주파수 (Hz)
              const duration = 20.48; // 신호 길이 (초)
              // const samples =
              const samples = sampleRate * duration;

              // 신호 배열을 생성합니다.
              const signal = new Float32Array(samples);

              // 로우 데이터를 signal 배열에 복사합니다.
              for (let i = 0; i < samples; i++) {
                if (i < newData.datasets[0].data.length) {
                  signal[i] = newData.datasets[0].data[i];
                } else {
                  signal[i] = 0;
                }
              }
              // FFT 계산
              const fft = new FFT(samples);
              const out = fft.createComplexArray();

              fft.realTransform(out, signal);
              fft.completeSpectrum(out);

              // FFT 결과를 처리합니다.
              const amplitudes = new Float32Array(samples / 2);
              for (let i = 0; i < samples / 2; i++) {
                const re = out[2 * i];
                const im = out[2 * i + 1];
                amplitudes[i] = Math.sqrt(re * re + im * im) / samples;
              }

              // 주파수 벡터 생성
              const frequencies = new Float32Array(samples / 2);
              for (let i = 0; i < samples / 2; i++) {
                frequencies[i] = (i * sampleRate) / samples;
              }

              setFftChartData((prev) => {
                const newData = { ...prev };
                newData.labels = Array.from(frequencies);
                newData.datasets[0].data = Array.from(amplitudes);

                newData.labels = newData.labels.slice(1);
                newData.datasets[0].data = newData.datasets[0].data.slice(1);

                const half = Math.ceil(newData.labels.length / 2);
                newData.labels = newData.labels.slice(0, half);
                newData.datasets[0].data = newData.datasets[0].data.slice(
                  0,
                  half
                );

                return newData;
              });

              // // STFT 파라미터
              const windowSize = 256; // 윈도우 크기
              const hopSize = 128; // 홉 크기

              // 결과 저장
              const stftResult: number[][] = [];
              for (
                let start = 0;
                start + windowSize <= samples;
                start += hopSize
              ) {
                const window = signal.slice(start, start + windowSize);
                const fft = new FFT(windowSize);
                const out = fft.createComplexArray();
                fft.realTransform(out, window);
                fft.completeSpectrum(out);

                let amplitudes = new Float32Array(Math.ceil(windowSize / 2));
                for (let i = 0; i < Math.ceil(windowSize / 2); i++) {
                  const re = out[2 * i];
                  const im = out[2 * i + 1];
                  amplitudes[i] = Math.sqrt(re * re + im * im) / windowSize;
                }
                // 일반 배열로 변환하여 저장
                amplitudes = amplitudes.slice(1);
                stftResult.push(Array.from(amplitudes));
              }

              // setSpectrum(stftResult);
            }

            return newData;
          });
        } else {
          const msg = data.message.replaceAll(" ", "_");
          if (msg.includes("successfully_connected")) {
            wsRef.current?.send("start_realtime");
          }
        }
      } catch (e) {
        console.log("Websocket error: ", e);
        stateReset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const stateReset = React.useCallback(() => {
    setIsRunning(false);

    setChartData({
      labels: [] as number[],
      datasets: [
        {
          label: "Raw data",
          data: [] as number[],
          borderWidth: 1.2,
          radius: 0,
          borderColor: theme.color.primary,
          backgroundColor: theme.color.primary,
        },
      ],
    });

    setFftChartData({
      labels: [] as number[],
      datasets: [
        {
          label: "FFT data",
          data: [] as number[],
          borderWidth: 2,
          radius: 0,
          borderColor: theme.color.secondary,
          backgroundColor: theme.color.secondary,
          tension: 0.2,
        },
      ],
    });

    // setSpectrum([]);
    wsRef.current = null;
  }, []);

  const onClose = (event: CloseEvent) => {
    stateReset();
  };

  const handleCloseBeforeNewConnect = async () => {
    return new Promise((resolve) => {
      if (wsRef.current) {
        wsRef?.current?.send("end_realtime");

        wsRef.current.onclose = (event: CloseEvent) => {
          stateReset();

          setTimeout(() => {
            resolve("closed");
          }, 100);
        };
      }
    });
  };

  const handleCloseRealtime = () => {
    wsRef?.current?.send("end_realtime");
    setDataParams({ sensorId: "" });
  };

  // 소켓 연결
  useEffect(() => {
    if (dataParams.sensorId) {
      const AsyncFn = async () => {
        wsRef.current = await useWebSocket(
          `realtimeData/${clientId}`,
          dataParams,
          onMessage,
          onClose
        ).then((val) => {
          setIsLoading(true);
          setIsRunning(true);
          return val;
        });
      };

      const AsyncCloseFn = async () => {
        await handleCloseBeforeNewConnect().then(() => {
          AsyncFn();
        });
      };

      // 웹소켓이 이미 있을 경우 종료 한 다음 오픈
      if (wsRef.current) {
        // 종료 먼저
        AsyncCloseFn();
        return;
      }

      // 종료할 웹소켓 없어서 바로 시작
      AsyncFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParams]);

  useEffect(() => {
    if (isLoading) {
      realtimeInitializingRef.current = setTimeout(() => {
        setIsLoading(false);
        wsRef.current?.send("end_realtime");
        setDataParams({ sensorId: "" });

        handleAlertDialogOpen(
          t("notification"),
          t("failed_to_get_realtime_data", {
            sensorId: dataParams.sensorId,
          })
        );
        realtimeInitializingRef.current = null;
      }, 3500);
    } else {
      if (realtimeInitializingRef.current) {
        clearTimeout(realtimeInitializingRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fftLoadingText = React.useMemo(() => {
    return isRunning && chartData.labels.length <= 2000
      ? t("realtime_fft_loading_message", {
          fftLength: 2048,
          currentLength: Math.ceil(chartData.labels.length),
        })
      : undefined;
  }, [isRunning, chartData, t]);

  return (
    <StyledGridWrap
      templateColumns="100%"
      templateRows={
        getSensorLabel(dataParams.sensorId, "only_type") === "AC" ||
        getSensorLabel(searchParams.get("sensorId") ?? "", "only_type") === "AC"
          ? "max-content minmax(400px, 1fr)"
          : "max-content 0.6fr"
      }
    >
      <WebsocketDeviceSearchRow
        inCard
        cardSizeTheme={NormalCardStyle}
        searchedSensor
        getUrlParams={getUrlParams}
        selectedSensor={selectedSensor}
        setSelectedSensor={setSelectedSensor}
        isRunning={isRunning}
        handleClose={handleCloseRealtime}
      />
      <StyledFlexWrap flexDirection="column" gap={30}>
        {isLoading ? <Loading /> : null}
        <DefaultLineChart
          isXScaleTime={false}
          timeUnit={undefined}
          chartData={chartData}
          chartTitle={`${dataParams.sensorId} Realtime Data`}
          pngFileTitle="Realtime Data"
          viewLarger={true}
          isInCard
          isContentTableChart
        />
        {(getSensorLabel(dataParams.sensorId, "only_type") === "AC" ||
          getSensorLabel(searchParams.get("sensorId") ?? "", "only_type") ===
            "AC") && (
          <StyledFlexWrap
            gap={30}
            style={{
              width: "100%",
              height: "clamp(100px, 44%, 600px)",
            }}
          >
            <DefaultLineChart
              isXScaleTime={false}
              timeUnit={undefined}
              chartData={fftChartData}
              chartTitle="FFT Data"
              pngFileTitle="FFT Data"
              viewLarger={true}
              isInCard
              isContentTableChart
              isFetching={isRunning && fftChartData.labels.length === 0}
              fetchingText={fftLoadingText}
            />
            {/* <HeatmapChart
              title="FFT Spectrum Data"
              // x가 타임 y가 주파수 y의 값을 색으로
              data={[
                {
                  z: spectrum,
                  x:
                    spectrum.length > 0
                      ? Array.from({ length: spectrum.length }, (_, i) => i)
                      : [],
                  y:
                    spectrum.length > 0
                      ? Array.from(
                          { length: spectrum[0].length },
                          (_, i) => (i * 2048) / (2 * spectrum[0].length)
                        )
                      : [],
                  type: "heatmap",
                },
              ]}
              layout={{ xaxis: { title: "x" }, yaxis: { title: "y" } }}
            /> */}
          </StyledFlexWrap>
        )}
      </StyledFlexWrap>
    </StyledGridWrap>
  );
}

/**
 * - Read userInfo Api를 호출하고 전역 변수에 담아서 사용한다.
 * - 자주 사용하는 유저 정보를 스테이트에 담아 관리하는 커스텀 훅
 */
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import {
  useCommonStoreActions,
  useLoggedInStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import React, { useEffect } from "react";
import useQueryHooks from "./useQueryHooks";
import { getSensorLabel } from "./useCommonHooks";

interface useUserInfoHooksProps {
  sensorTypeFilter?: string[];
  initialSelectedDeviceGroup?: SelectOption;
  initialSelectedDevice?: SelectOption;
  initialSelectedSensor?: SelectOption;
  autoSelectFirstOption?: boolean;
  // selectedDeviceGroup?: SelectOption;
  // setSelectedDeviceGroup?: React.Dispatch<React.SetStateAction<SelectOption>>;
}

export default function useUserInfoHooks({
  sensorTypeFilter,
  initialSelectedDeviceGroup,
  initialSelectedDevice,
  initialSelectedSensor,
  autoSelectFirstOption = true,
}: useUserInfoHooksProps = {}) {
  // * Hook
  const loggedIn = useLoggedInStore();
  const userInfo = useUserInfoStore();
  const { setUserInfo } = useCommonStoreActions();
  const { useUserInfoQuery } = useQueryHooks();

  // * Query
  const { data: userInfoData } = useUserInfoQuery();

  // * State
  const [selectedDeviceGroup, setSelectedDeviceGroup] =
    React.useState<SelectOption>(
      initialSelectedDeviceGroup ?? initialSelectOption
    );
  const [selectedDevice, setSelectedDevice] = React.useState<SelectOption>(
    initialSelectedDevice ?? initialSelectOption
  );
  const [selectedSensor, setSelectedSensor] = React.useState<SelectOption>(
    initialSelectedSensor ?? initialSelectOption
  );

  // * Memo
  const memorizedUserInfoQuerydata = React.useMemo(
    () => userInfoData,
    [userInfoData]
  );

  // * userInfo 데이터로 디바이스 그룹 리스트를 value/label 형태로 가져오는 메모
  const memorizedDeviceGroupOptions = React.useMemo(
    () =>
      userInfo.deviceGroupList
        ? userInfo.deviceGroupList.map((item) => ({
            value: `${item.device_group_id}`,
            label: item.device_group_name,
          }))
        : undefined,
    [userInfo]
  );

  /**
   * userInfo, selectedDeviceGroup.value -> get device options
   */
  const memorizedDeviceOptions = React.useMemo(() => {
    if (selectedDeviceGroup.value) {
      const newData = userInfo.deviceList
        ? userInfo.deviceList
            .filter(
              (item) =>
                item.device_group_id === Number(selectedDeviceGroup.value)
            )
            .map((item) => ({
              value: item.device_id,
              label: item.device_name,
            }))
        : undefined;

      if (newData && newData.length > 0) {
        if (selectedDevice.value) {
          const isValueIn = newData
            .flatMap((item) => item.value)
            .includes(selectedDevice.value);

          if (!isValueIn) {
            // selectedDevice 값이 이미 존재하지만 해당 값이 디바이스 옵션에 없으면 0번째로 변경
            setSelectedDevice(newData[0]);
          }
        } else {
          setSelectedDevice(newData[0]);
        }
        return newData;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, selectedDeviceGroup.value]);

  const memorizedSensorOptions = React.useMemo(() => {
    return userInfo.sensorList
      ? userInfo.sensorList
          .filter((item) => item.device_id === selectedDevice.value)
          .filter((item) => {
            if (sensorTypeFilter !== undefined) {
              return sensorTypeFilter.find(
                (str) => item.sensor_id.split("-")[1].split("_")[0] === str
              );
            }
            return true;
          })
          .map((item) => {
            return {
              value: item.sensor_id,
              label: getSensorLabel(item.sensor_id) ?? "",
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice.value, sensorTypeFilter]);

  // * Functions
  /**
   * selectedDeviceGroup 스테이트를 업데이트하는 함수
   * @param value - 디바이스 그룹 아이디
   */
  const handleSelectedDeviceGroup = React.useCallback(
    (value?: number) => {
      if (!value) {
        setSelectedDeviceGroup(initialSelectOption);
        return;
      }

      const newData = userInfo.deviceGroupList.find(
        (item) => item.device_group_id === value
      );

      if (newData) {
        setSelectedDeviceGroup({
          value: `${newData.device_group_id}`,
          label: newData.device_group_name,
        });
      } else {
        setSelectedDeviceGroup(initialSelectOption);
      }
    },
    [userInfo]
  );

  /**
   * selectedDevice 스테이트를 업데이트하는 함수
   * @param value - 디바이스 아이디
   */
  const handleSelectedDevice = React.useCallback(
    (value?: string) => {
      const newData = userInfo.deviceList.find(
        (item) => item.device_id === value
      );

      if (newData) {
        setSelectedDevice({
          value: newData.device_id,
          label: newData.device_name,
        });
      } else {
        setSelectedDevice(initialSelectOption);
      }
    },
    [userInfo]
  );

  /**
   * selectedSensor 스테이트를 업데이트하는 함수
   * @param value - 센서 아이디
   */
  const handleSelectedSensor = React.useCallback(
    (value?: string) => {
      const newData = userInfo.sensorList.find(
        (item) => item.sensor_id === value
      );

      if (newData) {
        setSelectedSensor({
          value: newData.sensor_id,
          label: getSensorLabel(newData.sensor_id),
        });
      } else {
        setSelectedSensor(initialSelectOption);
      }
    },
    [userInfo]
  );

  const getDeviceSensorInfo: (
    key: "deviceId" | "sensorId",
    value: string
  ) =>
    | DeviceGroupDeviceStructureSensorListInfo
    | DeviceGroupDeviceStructureSensorInfo
    | undefined = React.useCallback(
    (key: "deviceId" | "sensorId", value: string) => {
      if (key === "deviceId") {
        const device = userInfo.deviceList.find(
          (item) => item.device_id === value
        );

        const deviceGroup = userInfo.deviceGroupList.find(
          (item) => item.device_group_id === device?.device_group_id
        );
        const sensors = userInfo.sensorList.filter(
          (item) => item.device_id === device?.device_id
        );
        const structure = userInfo.structureList.find(
          (item) => item.structure_id === sensors[0].structure_id
        );

        return {
          deviceGroupId: deviceGroup?.device_group_id ?? 0,
          deviceGroupName: deviceGroup?.device_group_name ?? "",
          deviceId: device?.device_id ?? "",
          deviceName: device?.device_name ?? "",
          structureId: structure?.structure_id ?? "",
          structureName: structure?.structure_name ?? "",
          sensorList: sensors.flatMap((item) => item.sensor_id) ?? "",
        };
      } else if (key === "sensorId") {
        const sensor = userInfo.sensorList.find(
          (item) => item.sensor_id === value
        );

        const device = userInfo.deviceList.find(
          (item) => item.device_id === sensor?.device_id
        );

        const deviceGroup = userInfo.deviceGroupList.find(
          (item) => item.device_group_id === device?.device_group_id
        );
        const structure = userInfo.structureList.find(
          (item) => item.structure_id === sensor?.structure_id
        );

        return {
          deviceGroupId: deviceGroup?.device_group_id ?? 0,
          deviceGroupName: deviceGroup?.device_group_name ?? "",
          deviceId: device?.device_id ?? "",
          deviceName: device?.device_name ?? "",
          structureId: structure?.structure_id ?? "",
          structureName: structure?.structure_name ?? "",
          sensorId: sensor?.sensor_id ?? "",
        };
      }
    },
    [userInfo]
  );

  // * Effect
  // userInfo 전역 변수 업데이트
  React.useEffect(() => {
    if (memorizedUserInfoQuerydata && loggedIn) {
      if (
        JSON.stringify(userInfo) !== JSON.stringify(memorizedUserInfoQuerydata)
      ) {
        setUserInfo(memorizedUserInfoQuerydata);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorizedUserInfoQuerydata, userInfo]);

  // 선택된 디바이스 그룹이 없을 경우 0번째 디바이스 그룹 선택
  useEffect(() => {
    if (
      autoSelectFirstOption &&
      selectedDeviceGroup.value === "" &&
      userInfo.deviceGroupList &&
      userInfo.deviceGroupList.length > 0
    ) {
      handleSelectedDeviceGroup(
        Number(userInfo.deviceGroupList[0].device_group_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, selectedDeviceGroup, userInfo]);

  return {
    selectedDeviceGroup,
    setSelectedDeviceGroup,
    selectedDevice,
    setSelectedDevice,
    selectedSensor,
    setSelectedSensor,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    memorizedSensorOptions,
    handleSelectedDeviceGroup,
    handleSelectedDevice,
    handleSelectedSensor,
    getDeviceSensorInfo,
  };
}

import { StyledFlexWrap, StyledPrimaryText } from "../../styles/commonStyles";
import Icon from "../Icon";
import { FlexAlignTypeConst, ThemeConst } from "../../constructs/common";
import { darkTheme, theme } from "../../styles/theme";
import {
  useCommonStoreActions,
  useThemeStore,
} from "../../stores/useCommonStore";
import { useEffect } from "react";
import Portal from "@src/Portal";

interface LoadingOnPageProps {
  parentId?: string;
  text?: string;
}

/**
 *
 * @returns Main 영역 가운데에 Loading 아이콘을 띄웁니다.
 */
export default function LoadingOnPage({ parentId, text }: LoadingOnPageProps) {
  const themeType = useThemeStore();
  const { setLoadingOnPageInit } = useCommonStoreActions();

  useEffect(() => {
    setLoadingOnPageInit(true);
    return () => {
      setLoadingOnPageInit(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Portal rootId={parentId ?? "loading-on-page-wrap"}>
      <StyledFlexWrap
        align={FlexAlignTypeConst.Center}
        vertical={FlexAlignTypeConst.Center}
        flexDirection="column"
        style={{
          flex: 1,
          width: "calc(100% + 20px)",
          height: "calc(100% + 20px)",
          background:
            themeType === ThemeConst.Light
              ? theme.color.background
              : darkTheme.color.background,
          position: "absolute",
          inset: -10,
          zIndex: 100,
          gap: 40,
        }}
      >
        <Icon iconName="loading" iconColor={theme.color.primary} />
        {text ? (
          <StyledPrimaryText size="md" weight={300}>
            {text}
          </StyledPrimaryText>
        ) : null}
      </StyledFlexWrap>
    </Portal>
  );
}

import styled from "styled-components";

export const StyledFigureWrap = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: clamp(80px, calc(100% / 3 - 10px), 150px);
  min-height: 32;
`;

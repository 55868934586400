import { DefaultTheme } from "styled-components";
import color from "./color";
import size from "./size";
import { ThemeConst } from "@src/constructs/common";

export const mediaQuery = {
  smallMobile: "@media screen and (max-width: 400px)",
  mobile: "@media screen and (max-width: 480px)",
  tablet: "@media screen and (max-width: 768px)",
  laptop: "@media screen and (max-width: 1024px)",
  largeLaptop: "@media screen and (max-width: 1400px)",
};

export const theme: DefaultTheme = {
  type: ThemeConst.Light,
  color: color.light,
  size: size,
};

export const darkTheme: DefaultTheme = {
  ...theme,
  type: ThemeConst.Dark,
  color: color.dark,
};

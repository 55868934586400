import {
  StyledFloatBox,
  StyledIconButton,
  StyledIconFloatWrap,
} from "@src/styles/commonStyles";
import { ReactNode } from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { darkTheme, theme } from "@src/styles/theme";
import { FloatBoxStyleProps } from "./sizeTheme";
import { useThemeStore } from "@src/stores/useCommonStore";
import { ThemeConst } from "@src/constructs/common";
import React from "react";

interface StyledFloatBoxCustomProps {
  sizeTheme: FloatBoxStyleProps;
}

const StyledContentSpan = styled.span``;
const StyledFloatHeader = styled.div`
  position: relative;
`;
const StyledFloatBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFloatBoxCustom = styled(StyledFloatBox)<StyledFloatBoxCustomProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${StyledFloatHeader} {
    padding: ${({ sizeTheme }) => sizeTheme.padding}px;
    padding-bottom: 0;

    p {
      font-size: ${({ sizeTheme }) => sizeTheme.titleFontSize};
      font-weight: ${({ sizeTheme }) => sizeTheme.titleFontWeight};
      color: ${({ theme }) => theme.color.font.default};
    }
  }

  ${StyledFloatBody} {
    padding: ${({ sizeTheme }) => sizeTheme.padding}px;
    padding-top: 0;

    color: ${({ theme }) => theme.color.font.default};
    font-size: ${({ sizeTheme }) => sizeTheme.fontSize};

    & > span {
      line-height: ${({ sizeTheme }) => `calc(${sizeTheme.fontSize} + 3px)`};
    }
  }
`;

interface CommonFloatBoxProps {
  sizeTheme: FloatBoxStyleProps;
  title?: string;
  isWarning?: boolean;
}

export type OptionalContentProps =
  | {
      children: ReactNode;
      contentText?: never;
    }
  | { contentText?: string | string[]; children?: ReactNode };

export type FloatBoxProps = CommonFloatBoxProps &
  CloseableComponentProps &
  OptionalContentProps;

function FloatBox({
  sizeTheme,
  title,
  showCloseBtn,
  handleClose,
  isWarning,
  children,
  contentText,
}: FloatBoxProps) {
  const themeType = useThemeStore();

  return (
    <StyledFloatBoxCustom sizeTheme={sizeTheme} isInvalid={isWarning}>
      {(title || showCloseBtn) && (
        <StyledFloatHeader>
          {title && <p>{title}</p>}
          {showCloseBtn && (
            <StyledIconFloatWrap floatTop={10} floatRight={10}>
              <StyledIconButton type="button" onClick={handleClose}>
                <Icon
                  iconName="close"
                  iconWidth={16}
                  iconHeight={16}
                  iconColor={
                    themeType === ThemeConst.Light
                      ? theme.color.floatBox.floatIcon
                      : darkTheme.color.floatBox.floatIcon
                  }
                />
              </StyledIconButton>
            </StyledIconFloatWrap>
          )}
        </StyledFloatHeader>
      )}
      <StyledFloatBody>
        {children ? (
          children
        ) : Array.isArray(contentText) ? (
          contentText.map((item, index) => (
            <StyledContentSpan key={index}>{item}</StyledContentSpan>
          ))
        ) : (
          <StyledContentSpan>{contentText}</StyledContentSpan>
        )}
      </StyledFloatBody>
    </StyledFloatBoxCustom>
  );
}

const FloatBoxMemo = React.memo(
  FloatBox,
  (prevProps, nextProps) => prevProps.children === nextProps.children
);
export default FloatBoxMemo;

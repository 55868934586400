import { useEffect } from "react";
import Portal from "../../Portal";
import { StyledDimFullScreen } from "../../styles/commonStyles";
import Icon from "../Icon";

/**
 *
 * @returns Portal을 사용해 #root 엘리먼트가 아닌 #hidden 엘리먼트에 Loading 컴포넌트를 띄웁니다.
 */
export default function Loading() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <Portal rootId="loading">
      <StyledDimFullScreen>
        <Icon iconName="loading" />
      </StyledDimFullScreen>
    </Portal>
  );
}

import {
  FlexAlignTypeConst,
  BrowserSizeConst,
  ColorTypeConst,
} from "@src/constructs/common";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import {
  useBrowserSizeStore,
  useCommonStoreActions,
  useInterestSensorListStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import { StyledFlexWrap } from "@src/styles/commonStyles";
import React, { useEffect } from "react";
import { StyledInterestSensorContentWrap, StyledTitle } from "../styles";
import IconButton from "@src/components/Buttons/IconButton";
import {
  SmallIconButtonStyles,
  XSmallNoSpaceIconButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import Table from "@src/components/Table";
import Pagination from "@src/components/Pagination";
import Dialog from "@src/components/Dialogs/Dialog";
import { BigDialogStyles } from "@src/components/Dialogs/sizeTheme";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";
import { useTranslation } from "react-i18next";
import useUserInfoHooks from "@src/hooks/useUserInfoHooks";
import { SmallInputStyle } from "@src/components/Inputs/sizeTheme";
import Select from "@src/components/Inputs/Selects/Select";
import TableNew from "@src/components/TableNew";
import LoadingSpinner from "@src/components/Loadings/LoadingSpinner";

const head = [
  {
    value: "deviceId",
    label: "device",
  },
  {
    value: "structureName",
    label: "install_location",
  },
  {
    value: "sensorId",
    label: "sensor",
  },
];

const selectedSensorHead = [...head, { value: "delete", label: "delete" }];
const customGridColumnsMinMaxArray = [
  {
    min: "85px",
    max: "0.6fr",
  },
  {
    min: "100px",
    max: "1fr",
  },
  {
    min: "210px",
    max: "210px",
  },
];

const selectedSensorCustomGridColumns = [
  ...customGridColumnsMinMaxArray,
  {
    min: "60px",
    max: "60px",
  },
];

interface ISensorListData {
  index: number;
  groupId: number;
  groupName: string;
  deviceId: string;
  deviceName: string;
  sensorId: string;
}

interface InterestSensorSettingDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InterestSensorSettingDialog({
  open,
  setOpen,
}: InterestSensorSettingDialogProps) {
  // hooks
  const { handleAlertDialogOpen } = useAlertDialog();
  const { handleNoticeDialogOpen } = useNoticeDialog();
  const userInfo = useUserInfoStore();
  const browserSize = useBrowserSizeStore();
  const { setUserInterestSensor } = useFirebaseHooks();
  const interestSensorList = useInterestSensorListStore();
  const { setInterestSensorList } = useCommonStoreActions();
  const { t } = useTranslation();
  const {
    selectedDeviceGroup,
    memorizedDeviceGroupOptions,
    handleSelectedDeviceGroup,
  } = useUserInfoHooks();

  // state
  const [isCheckedRow, setIsCheckedRow] = React.useState<boolean[]>([]);
  const [allSensorList, setAllSensorList] =
    React.useState<ISensorListData[] | null>(null);
  const [deviceGroupSensorList, setDeviceGroupSensorList] =
    React.useState<ISensorListData[] | null>(null);
  const [deviceGroupTablePaging, setDeviceGroupSensorTablePaging] =
    React.useState<PagingType>({
      page: 1,
      size: browserSize && browserSize <= BrowserSizeConst.Laptop ? 5 : 10,
    });
  const [selectedSensorList, setSelectedSensorList] = React.useState<
    ISensorListData[]
  >([]);
  const [deleteSelectedSensorIndex, setDeleteSelectedSensorIndex] =
    React.useState<{ index: number; called: number }>({
      index: -1,
      called: 0,
    }); // delete 버튼 클릭 시 삭제를 위해 index 저장하는 state
  const [deviceGroupTablePagingCount, setDeviceGroupTablePagingCount] =
    React.useState<number>(0);

  // Functions
  // SelectedSensorList를 firestore의 센서(interestSensorList)로 변경
  const handleSelectedSensorList = (interestSensorList: string[]) => {
    setSelectedSensorList(() => {
      const newData = [] as ISensorListData[];

      interestSensorList.forEach((intSensor) => {
        allSensorList?.forEach((item) => {
          if (item.sensorId === intSensor) {
            const data: ISensorListData = {
              index: item.index,
              groupId: item.groupId,
              groupName: item.groupName,
              deviceId: item.deviceId,
              deviceName: item.deviceName,
              sensorId: item.sensorId,
            };
            newData.push(data);
          }
        });
      });

      return newData;
    });
  };

  // 맨 처음 isCheckedRow의 true를 interesetSensorList(firestore)에서 가져와서 세팅한다.
  const handleIsCheckedRowToInterestSensors = () => {
    if (allSensorList) {
      setIsCheckedRow(() => {
        if (interestSensorList) {
          const interestSensorIndex = [] as number[];
          allSensorList.forEach((sensor, index) => {
            interestSensorList.forEach((intSensor) => {
              if (sensor.sensorId === intSensor) {
                interestSensorIndex.push(index);
              }
            });
          });

          const newData = new Array(allSensorList.length).fill(false);
          interestSensorIndex.map((item) => (newData[item] = true));
          return newData;
        }
        return new Array(allSensorList.length).fill(false);
      });
    }
  };

  // Checkbox Toggle of Device Info Table
  const handleRowCheckButton = React.useCallback((index: number) => {
    setIsCheckedRow((prev) => {
      const newData = [...prev];
      const trueLength = newData.filter((item) => item).length;
      if (trueLength >= 5 && !newData[index]) {
        handleAlertDialogOpen(
          t("notification"),
          t("chose_up_to_five_interest_sensors")
        );
      } else {
        newData[index] = !newData[index];
        return newData;
      }
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 관심 센서 리스트 초기화 핸들러
   */
  const handleIsCheckedRowToBeFalse = () =>
    setIsCheckedRow((prev) => {
      const newData = new Array(prev.length).fill(false);
      return newData;
    });

  // 관심 센서 Firestore 변경
  const handleSetInterestSensor = React.useCallback(async () => {
    if (selectedSensorList.length === 0) {
      handleAlertDialogOpen(
        t("notification"),
        t("set_at_least_one_interest_sensor")
      );
      return;
    } else if (interestSensorList && selectedSensorList.length > 0) {
      if (
        interestSensorList.sort().join("_") ===
        selectedSensorList
          .map((item) => item.sensorId)
          .sort()
          .join("_")
      ) {
        handleAlertDialogOpen(
          t("notification"),
          t("same_interest_sensors_select_again")
        );
        return;
      }
    }
    const againCheck = await handleNoticeDialogOpen(
      t("notification"),
      t("confirm_modify_interest_sensor")
    );

    if (againCheck) {
      const sensorList = selectedSensorList.map((item) => item.sensorId);
      await setUserInterestSensor(sensorList).then(async () => {
        setOpen(false);
        setInterestSensorList(sensorList);
        handleAlertDialogOpen(t("notification"), t("interest_sensor_changed"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensorList, interestSensorList]);

  /**
   * 관심 센서 설정 다이얼로그 종료 핸들러
   * - 변경 사항 없는 경우: 바로 팝업 닫기
   * - 변경 사항 있는 경우: 알림 다이얼로그 -> 확인 후 팝업 닫기
   */
  const handleCancelSetInterestSensor = async () => {
    if (interestSensorList && selectedSensorList) {
      if (
        interestSensorList.sort().join("_") ===
        selectedSensorList
          .map((item) => item.sensorId)
          .sort()
          .join("_")
      ) {
        // 변경 사항이 없는 경우 바로 팝업 닫기
        setOpen(false);
        return;
      }
    }

    const againCheck = await handleNoticeDialogOpen(
      t("notification"),
      t("interest_sensors_do_not_change")
    );

    if (againCheck) {
      // isCheckedRow의 true를 interestSensorList index로 세팅한다.
      handleIsCheckedRowToInterestSensors();
      setOpen(false);
    }
  };

  /**
   * 디바이스 그룹 센서 리스트 바디 메모
   */
  const MemorizedDeviceGroupSensorListTableBody = React.useMemo(
    () =>
      deviceGroupSensorList
        ? deviceGroupSensorList.map((item) => {
            return {
              trOptions: {
                dataRowIndex: item.index,
              },
              tdOptions: [
                {
                  dataKey: "deviceId",
                  dataValue: item.deviceId,
                  tdValue: item.deviceId,
                },
                {
                  dataKey: "deviceName",
                  dataValue: item.deviceName,
                  tdValue: item.deviceName,
                },
                {
                  dataKey: "sensorId",
                  dataValue: item.sensorId,
                  tdValue: item.sensorId,
                },
              ],
            };
          })
        : null,
    [deviceGroupSensorList]
  );

  const getCustomPagingCount = React.useCallback((value: number) => {
    setDeviceGroupTablePagingCount(value);
  }, []);

  const DeviceGroupSensorListTable = React.useMemo(
    () => (
      <div>
        <StyledFlexWrap
          align={FlexAlignTypeConst.FlexStart}
          vertical={FlexAlignTypeConst.Center}
          gap={10}
          style={{
            minHeight: 32,
            marginBottom: 10,
          }}
        >
          <Select
            sizeTheme={SmallInputStyle}
            name="deviceGroupSelect"
            placeholder={t("group")}
            options={memorizedDeviceGroupOptions}
            value={selectedDeviceGroup}
            event={(value) => handleSelectedDeviceGroup(Number(value.value))}
            fixWidth={150}
          />
          <StyledTitle>{t("device_group_sensors")}</StyledTitle>
        </StyledFlexWrap>
        <StyledFlexWrap
          flexDirection="column"
          style={{
            minHeight:
              browserSize && browserSize <= BrowserSizeConst.Laptop
                ? "unset"
                : 422,
          }}
        >
          <TableNew
            colorType={ColorTypeConst.Primary}
            head={head}
            body={MemorizedDeviceGroupSensorListTableBody}
            minWidth={240}
            customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
            isCheckableRow
            checkboxName="device_group-sensor-list"
            isCheckedRow={isCheckedRow}
            handleCheckedRow={handleRowCheckButton}
            isCustomPaging
            getCustomPagingCount={getCustomPagingCount}
            page={deviceGroupTablePaging.page}
            size={deviceGroupTablePaging.size}
            isLoading={!deviceGroupSensorList}
            isSearchable
            searchInputLocation="BOTTOM_RIGHT"
          />
        </StyledFlexWrap>
        <Pagination
          page={deviceGroupTablePaging.page}
          size={deviceGroupTablePaging.size}
          count={
            deviceGroupTablePagingCount === 0
              ? 1
              : deviceGroupTablePagingCount > 0
              ? deviceGroupTablePagingCount
              : deviceGroupSensorList?.length ?? 0
          }
          groupLength={5}
          setPaging={(index) =>
            setDeviceGroupSensorTablePaging((prev) => {
              const newData = { ...prev };
              newData.page = index.page;
              return newData;
            })
          }
          elementSize="normal"
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      deviceGroupSensorList,
      isCheckedRow,
      deviceGroupTablePaging.page,
      deviceGroupTablePaging.size,
      t,
      deviceGroupTablePagingCount,
      getCustomPagingCount,
    ]
  );

  // * CONTENT
  const SelectedSensorListTable = React.useMemo(
    () => (
      <div>
        <StyledFlexWrap
          align={FlexAlignTypeConst.SpaceBetween}
          vertical={FlexAlignTypeConst.Center}
          gap={10}
          style={{
            minHeight: 32,
            marginBottom: 10,
          }}
        >
          <StyledTitle>{t("selected_sensors")}</StyledTitle>
          <IconButton
            sizeTheme={SmallIconButtonStyles}
            text={t("reset_selection")}
            colorType="primary"
            iconName="setting"
            onClick={handleIsCheckedRowToBeFalse}
          />
        </StyledFlexWrap>
        <Table
          colorType={ColorTypeConst.Primary}
          head={selectedSensorHead}
          body={selectedSensorList.map((item, index) => {
            return (
              <tr key={item.index}>
                <td>{item.deviceId}</td>
                <td>{item.deviceName}</td>
                <td>{item.sensorId}</td>
                <td>
                  <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                    <IconButton
                      sizeTheme={XSmallNoSpaceIconButtonStyles}
                      colorType="grayToPrimaryNoBg"
                      iconName="delete"
                      onClick={() => {
                        setDeleteSelectedSensorIndex((prev) => ({
                          index,
                          called: prev.called + 1,
                        }));
                      }}
                    />
                  </StyledFlexWrap>
                </td>
              </tr>
            );
          })}
          minWidth={240}
          customGridColumnsMinMaxArray={selectedSensorCustomGridColumns}
          isLoading={!interestSensorList || !selectedSensorList}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSensorList, isCheckedRow, t]
  );

  useEffect(() => {
    if (userInfo.sensorList && !allSensorList) {
      const newData = [] as ISensorListData[];
      userInfo.sensorList.forEach((item) => {
        if (
          item.sensor_id !== "empty" &&
          item.sensor_id.split("-")[1].split("_")[0] !== "CM" &&
          item.sensor_id.split("-")[1].split("_")[0] !== "SC"
        ) {
          const device = userInfo.deviceList.find(
            (device) => device.device_id === item.device_id
          );
          const deviceGroup = userInfo.deviceGroupList.find(
            (dg) => dg.device_group_id === device?.device_group_id
          );

          const data: ISensorListData = {
            index: newData.length,
            groupId: deviceGroup?.device_group_id ?? -1,
            groupName: deviceGroup?.device_group_name ?? "",
            deviceId: item.device_id,
            deviceName: device?.device_name ?? "",
            sensorId: item.sensor_id,
          };
          newData.push(data);
        }
      });

      setAllSensorList(() => newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.sensorList]);

  useEffect(() => {
    if (allSensorList && interestSensorList) {
      handleIsCheckedRowToInterestSensors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSensorList, interestSensorList]);

  useEffect(() => {
    if (interestSensorList) {
      handleSelectedSensorList(interestSensorList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestSensorList]);

  // isCheckedRow가 변경되면 SelectedSensorList를 업데이트한다.
  useEffect(() => {
    setSelectedSensorList(() => {
      const newData = [] as ISensorListData[];
      const trueIndex = isCheckedRow
        .map((value, index) => (value ? index : -1))
        .filter((index) => index !== -1);

      trueIndex.forEach((index) => {
        allSensorList?.forEach((item) => {
          if (item.index === index) {
            const data: ISensorListData = {
              index: item.index,
              groupId: item.groupId,
              groupName: item.groupName,
              deviceId: item.deviceId,
              deviceName: item.deviceName,
              sensorId: item.sensorId,
            };

            newData.push(data);
          }
        });
      });

      return newData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedRow]);

  // 1024 이하일 때  Size 변경
  useEffect(() => {
    if (
      browserSize &&
      (browserSize <= BrowserSizeConst.Laptop ||
        browserSize > BrowserSizeConst.Laptop)
    ) {
      setDeviceGroupSensorTablePaging((prev) => {
        const newData = { ...prev };
        newData.page = 1;
        newData.size = browserSize <= BrowserSizeConst.Laptop ? 5 : 10;
        return newData;
      });
    }
  }, [browserSize]);

  // Delete 버튼 클릭 후 선택된 센서 삭제하는 이펙트
  useEffect(() => {
    if (deleteSelectedSensorIndex.index !== -1) {
      const index = selectedSensorList[deleteSelectedSensorIndex.index].index;
      setIsCheckedRow((prev) => {
        const newData = [...prev];
        newData[index] = false;
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedSensorIndex.called]);

  // 전체 센서 리스트를 디바이스 그룹 센서 리스트로 업데이트
  useEffect(() => {
    if (allSensorList && selectedDeviceGroup.value) {
      const newData = allSensorList.filter(
        (item) => item.groupId === Number(selectedDeviceGroup.value)
      );
      setDeviceGroupSensorList(newData);
    }
  }, [allSensorList, selectedDeviceGroup.value]);

  return open && deviceGroupSensorList ? (
    <Dialog
      sizeTheme={BigDialogStyles}
      isOpen={open}
      setIsOpen={setOpen}
      confirmEvent={handleSetInterestSensor}
      cancelPromiseEvent={handleCancelSetInterestSensor}
      title={t("set_up_interest_sensor")}
    >
      <StyledInterestSensorContentWrap>
        {DeviceGroupSensorListTable}
        {SelectedSensorListTable}
      </StyledInterestSensorContentWrap>
    </Dialog>
  ) : open ? (
    <LoadingSpinner />
  ) : null;
}

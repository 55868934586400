import IconButton from "@src/components/Buttons/IconButton";
import { XXSmallNoSpaceIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import SingleDate from "@src/components/Datepickers/SingleDate";
import DeviceSearchSelect from "@src/components/Inputs/Selects/DeviceSearchSelect";
import Select from "@src/components/Inputs/Selects/Select";
import Textarea from "@src/components/Inputs/Textarea";
import UploadImage from "@src/components/Inputs/UploadImage";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { FlexAlignTypeConst } from "@src/constructs/common";
import { StyledFlexWrap } from "@src/styles/commonStyles";
import React, { useEffect } from "react";
import { UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IFields } from ".";
import { StyledPrimaryBadge } from "../HomePage/styles";
import useUserInfoHooks from "@src/hooks/useUserInfoHooks";
import { IUseFormSetValue } from "@src/hooks/useCommonHooks";

const StyledFormWrap = styled(StyledFlexWrap)`
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.table.tbody.backgroundOdd};
  width: 100%;
  position: relative;
`;

interface FormProps {
  key: string | number;
  index: number;
  remove: UseFieldArrayRemove;
  methods: UseFormReturn<IFields, any, undefined>;
  useFormSetValue: (arg0: IUseFormSetValue) => void;
  isPasting: boolean;
  handleIsPasting: (val: boolean) => void;
}

function FormComponent({
  key,
  index,
  remove,
  methods,
  useFormSetValue,
  isPasting,
  handleIsPasting,
}: FormProps) {
  // * Hooks

  // * useForm
  const {
    register,
    setFocus,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = methods;

  const { t } = useTranslation();
  const {
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    handleSelectedDeviceGroup,
    handleSelectedDevice,
    selectedDeviceGroup,
    selectedDevice,
  } = useUserInfoHooks({
    initialSelectedDeviceGroup: {
      value: watch(`deviceSpecialInfo.${index}.deviceGroupId`).toString(),
      label: watch(`deviceSpecialInfo.${index}.deviceGroupName`),
    } ?? { value: -1, label: "" },
    initialSelectedDevice: {
      value: watch(`deviceSpecialInfo.${index}.deviceId`) || "",
      label: watch(`deviceSpecialInfo.${index}.deviceName`) || "",
    },
    autoSelectFirstOption: false,
  });

  // * State
  const [date, setDate] = React.useState<Date | null>(
    watch(`deviceSpecialInfo.${index}.inspectionDate`)
      ? new Date(watch(`deviceSpecialInfo.${index}.inspectionDate`))
      : null
  );

  const [selectedImageFiles, setSelectedImageFiles] = React.useState<{
    files: (File | string)[];
    previews: string[];
  } | null>(
    watch(`deviceSpecialInfo.${index}.images`)
      ? watch(`deviceSpecialInfo.${index}.images`)
      : null
  );

  // * Form values
  useFormSetValue({
    name: `deviceSpecialInfo.${index}.deviceGroupId`,
    state: Number(selectedDeviceGroup.value),
    setValue,
    errorMessage: errors?.deviceSpecialInfo?.[index]?.deviceGroupId?.message,
    clearErrors,
    valueType: "number",
  });

  useFormSetValue({
    name: `deviceSpecialInfo.${index}.deviceGroupName`,
    state: selectedDeviceGroup.label,
    setValue,
    errorMessage: errors?.deviceSpecialInfo?.[index]?.deviceGroupName?.message,
    clearErrors,
  });

  useFormSetValue({
    name: `deviceSpecialInfo.${index}.deviceId`,
    state: selectedDevice.value,
    setValue,
    errorMessage: errors?.deviceSpecialInfo?.[index]?.deviceId?.message,
    clearErrors,
  });

  useFormSetValue({
    name: `deviceSpecialInfo.${index}.deviceName`,
    state: selectedDevice.label,
    setValue,
  });

  useFormSetValue({
    name: `deviceSpecialInfo.${index}.inspectionDate`,
    state: date,
    setValue,
    errorMessage: errors?.deviceSpecialInfo?.[index]?.inspectionDate?.message,
    clearErrors,
    dateFormat: "yyyy-MM-dd",
  });
  useFormSetValue({
    name: `deviceSpecialInfo.${index}.images`,
    state: selectedImageFiles,
    setValue,
  });

  // * Effects
  // index - 1이 0이랑 같거나 클 때 새로 추가된 Form에 index - 1 Form의
  // 비즈니스 그룹, 디바이스 아이디, 디바이스 명, 점검일을 가져오는 이펙트
  useEffect(() => {
    if (index - 1 >= 0) {
      if (watch(`deviceSpecialInfo.${index - 1}.deviceGroupId`)) {
        handleSelectedDeviceGroup(
          watch(`deviceSpecialInfo.${index - 1}.deviceGroupId`) ?? undefined
        );
      }

      if (watch(`deviceSpecialInfo.${index - 1}.deviceId`)) {
        handleSelectedDevice(
          watch(`deviceSpecialInfo.${index - 1}.deviceId`) ?? undefined
        );
      }

      if (watch(`deviceSpecialInfo.${index - 1}.inspectionDate`)) {
        setDate(
          new Date(watch(`deviceSpecialInfo.${index - 1}.inspectionDate`))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(`deviceSpecialInfo.${index - 1}`)]);

  const pasteRef = React.useRef<HTMLButtonElement>(null);

  return (
    <StyledFormWrap flexDirection="column" gap={20}>
      <div
        style={{
          position: "absolute",
          top: 8,
          left: 8,
        }}
      >
        <StyledPrimaryBadge>No. {index + 1}</StyledPrimaryBadge>
      </div>
      {index !== 0 ? (
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <IconButton
            sizeTheme={XXSmallNoSpaceIconButtonStyles}
            colorType="grayToPrimaryNoBg"
            iconName="close"
            onClick={() => {
              remove(index);
            }}
          />
        </div>
      ) : null}
      <StyledFlexWrap
        gap={20}
        style={{
          flexWrap: "wrap",
          marginTop: 20,
        }}
        align={FlexAlignTypeConst.FlexStart}
      >
        <Select
          sizeTheme={NormalInputStyle}
          name="device-special-info-group"
          options={memorizedDeviceGroupOptions}
          value={selectedDeviceGroup}
          event={(value) => handleSelectedDeviceGroup(Number(value.value))}
          fixWidth={150}
          showLabel
          labelText={t("group")}
          labelDirection="TOP"
          isInvalid={
            errors?.deviceSpecialInfo?.[index]?.deviceGroupId?.message !==
            undefined
          }
          errorMessages={[
            errors.deviceSpecialInfo?.[index]?.deviceGroupId?.message ?? "",
          ]}
        />
        <DeviceSearchSelect
          sizeTheme={NormalInputStyle}
          name="device-special-info-device"
          options={memorizedDeviceOptions}
          value={selectedDevice}
          event={(value) => handleSelectedDevice(value.value)}
          fixWidth={220}
          showLabel
          labelText={t("device")}
          labelDirection="TOP"
          isInvalid={errors?.deviceSpecialInfo?.[index]?.deviceId !== undefined}
          errorMessages={[
            errors.deviceSpecialInfo?.[index]?.deviceId?.message ?? "",
          ]}
        />
        <SingleDate
          sizeTheme={NormalInputStyle}
          date={date}
          setDate={setDate}
          maxDate={new Date()}
          showLabel
          labelText={t("inspection_date")}
          labelDirection="TOP"
          isInvalid={
            errors?.deviceSpecialInfo?.[index]?.inspectionDate !== undefined
          }
          errorMessages={[
            errors.deviceSpecialInfo?.[index]?.inspectionDate?.message ?? "",
          ]}
        />
      </StyledFlexWrap>
      <Textarea
        sizeTheme={NormalInputStyle}
        placeholder={t("please_enter_description")}
        eventType="UseForm"
        register={{ ...register(`deviceSpecialInfo.${index}.description`) }}
        setFocus={() => setFocus(`deviceSpecialInfo.${index}.description`)}
        inputName="device-special-info-desc"
        isShowLabel
        labelText={t("description")}
        labelType="SEARCH"
        labelDirection="TOP"
        minHeight={100}
        isInvalid={
          errors?.deviceSpecialInfo?.[index]?.description !== undefined
        }
        errorMessages={[
          errors.deviceSpecialInfo?.[index]?.description?.message ?? "",
        ]}
      />
      <UploadImage
        pasteRef={pasteRef}
        key={key}
        index={index}
        showLabel
        labelText={t("image")}
        labelDirection="TOP"
        fixHeight={160}
        selcetedImageFiles={selectedImageFiles}
        setSelectedImageFiles={setSelectedImageFiles}
        inputId={`deviceSpecialInfo.${index}.images`}
        isPasting={isPasting}
        handleIsPasting={handleIsPasting}
      />
    </StyledFormWrap>
  );
}

const Form = React.memo(FormComponent, (prevProps, nextProps) => {
  return (
    prevProps.key === nextProps.key &&
    prevProps.index === nextProps.index &&
    prevProps.methods === nextProps.methods &&
    prevProps.isPasting === nextProps.isPasting
  );
});
export default Form;

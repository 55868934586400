import React, { createContext, useContext } from "react";
import { ProviderProps } from "./AllContextProvider";

const DialogContext = createContext({
  isDialogOpen: false,
  dialogTitle: "",
  handleDialogOpen: (title: string) => {},
  cancelDialogOpen: () => {},
});

export function DialogProvider({ children }: ProviderProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState<string>("");

  /**
   * Default Dialog 컴포넌트 오픈
   * ***
   * @param
   * - title: Dialog 타이틀
   */
  const handleDialogOpen = (title: string) => {
    setDialogTitle(title);
    setIsDialogOpen(true);
  };

  const cancelDialogOpen = React.useCallback(() => {
    setDialogTitle("");
    setIsDialogOpen(false);
  }, []);

  const value = {
    isDialogOpen,
    dialogTitle,
    handleDialogOpen,
    cancelDialogOpen,
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
}

export function useDialog() {
  return useContext(DialogContext);
}

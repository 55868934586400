import AuthLayout from "@layout/AuthLayout";
import Input from "@components/Inputs/Input";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@src/components/Buttons/Button";
import { BigButtonStyles } from "@src/components/Buttons/sizeTheme";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "@src/firebase";
import { useNavigate } from "react-router-dom";
import { StyledButtonWrap, StyledTitle } from "../styles";
import { StyledTextUnderlineButton } from "@src/styles/commonStyles";
import React from "react";
import Loading from "@src/components/Loadings/Loading";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleAlertDialogOpen, cancelAlertDialogOpen } = useAlertDialog();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("not_valid_email_format")
      .required("email_is_required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // 초기값 설정
      email: "",
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, data.email).then(() => {
        handleAlertDialogOpen(
          t("notification"),
          t("password_reset_email_sent")
        );
        setTimeout(() => {
          cancelAlertDialogOpen();
          navigate("/auth/login");
        }, 1000);
      });
    } catch (error: any) {
      const errorCode = error.code;
      if (
        errorCode.toLowerCase().includes("user-not-found") ||
        errorCode.toLowerCase().includes("missing-email") ||
        errorCode.toLowerCase().includes("invalid-email")
      ) {
        handleAlertDialogOpen(t("notification"), t("user_not_found"));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <AuthLayout>
        <div>
          <StyledTitle>Reset Password</StyledTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input
                inputName="reset-pw-email"
                placeholder={t("please_enter_email")}
                type="text"
                eventType="UseForm"
                sizeTheme={NormalInputStyle}
                register={{
                  ...register("email"),
                }}
                setFocus={() => setFocus("email")}
                isInvalid={errors.email !== undefined}
                errorMessages={[errors.email?.message as string]}
                addonShow={true}
                addonDirection="left"
                addonName="user"
                isShowLabel
                labelDirection="TOP"
                labelText={t("email")}
                isFixBottomMargin
                labelType="AUTH"
              />
              <Button
                type="submit"
                colorType="primary"
                sizeTheme={BigButtonStyles}
                text={t("send_password_reset_mail")}
                fixWidth="100%"
              />
            </div>
          </form>
          <StyledButtonWrap>
            <StyledTextUnderlineButton
              type="button"
              onClick={() => navigate("/auth/signUp")}
            >
              {t("simply_sign_up_with_email_password")}
            </StyledTextUnderlineButton>
            <StyledTextUnderlineButton
              type="button"
              onClick={() => navigate("/auth/login")}
            >
              {t("go_to_login")}
            </StyledTextUnderlineButton>
          </StyledButtonWrap>
        </div>
      </AuthLayout>
    </>
  );
}

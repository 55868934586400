import OptionalDeviceSearchRow from "@components/Searchbar/OptionalDeviceSearchRow";
import { DateTypeConst } from "@constructs/common";
import { NormalCardStyle } from "@components/Card/sizeTheme";
import Tab from "@components/Tab";
import React, { useEffect } from "react";
import IconButton from "@components/Buttons/IconButton";
import { NormalIconButtonStyles } from "@components/Buttons/sizeTheme";
import {
  RangeDeviceIdRequestParams,
  RangeSensorIdPagingRequestParams,
} from "@src/fetch/fetchChart";
import { StyledChartGridWrap, StyledStateChartGridWrap } from "./styles";
import useCommonHooks, { getSensorLabel } from "@src/hooks/useCommonHooks";
import { initialSelectOption } from "@src/components/Inputs/Selects/Select";
import {
  useCameraDataPaging,
  useQueryActions,
  useRawDataPaging,
  useSmartCameraDataPaging,
} from "@src/stores/useQueryStore";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useQueryHooks from "@src/hooks/useQueryHooks";
import DefaultLineChart from "@src/components/Charts/DefaultLineChart";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import Camera from "./Camera";
import {
  StyledFlexWrap,
  StyledRelativeFullWrap,
  StyledTabButtonWrap,
} from "@src/styles/commonStyles";
import Raw from "./Raw";
import LoadingOnPage from "@src/components/Loadings/LoadingOnPage";
import { useTranslation } from "react-i18next";
import { getTimeUnit } from "@src/utils/common";

const customOptions = {
  interaction: {
    mode: "index",
    intersect: false, // 모든 위치에서 클릭 가능하게
  },
};

const yScaleMinArray = [
  {
    type: "battery",
    val: 0,
  },
  {
    type: "solarchargevolt",
    val: 0,
  },
  {
    type: "RSSI",
    val: -200,
  },
  {
    type: "temperature",
    val: -20,
  },
  {
    type: "humidity",
    val: 0,
  },
];

const yScaleMaxArray = [
  {
    type: "battery",
    val: 4.5,
  },
  {
    type: "solarchargevolt",
    val: 16,
  },
  {
    type: "RSSI",
    val: -40,
  },
  {
    type: "temperature",
    val: 70,
  },
  {
    type: "humidity",
    val: 100,
  },
];

export default function ChartPage() {
  // * Hooks
  const { t } = useTranslation();
  const { chartType } = useParams();
  const [firstSearched, setFirstSearched] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rawDataPaging = useRawDataPaging();
  const cameraDataPaging = useCameraDataPaging();
  const smartCameraDataPaging = useSmartCameraDataPaging();
  const { useStateDataQuery, useDmeasureDataQuery } = useQueryHooks();
  const { useCustomLoading, makeChartTitle, useUpdateParamsOnPageChange } =
    useCommonHooks();
  const { setRawDataPaging, setCameraDataPaging, setSmartCameraDataPaging } =
    useQueryActions();

  useCustomLoading();

  // * Vars
  const chartTypeList = ["dmeasure", "raw", "state", "camera", "smartCamera"];
  const chartIndex = chartTypeList.indexOf((chartType as any) ?? "dmeasure");
  const now = new Date();
  const maxDate = new Date();
  const minDate = new Date(now.setFullYear(now.getFullYear() - 5));

  // * State
  const [tabActive, setTabActive] = React.useState<number>(
    chartIndex >= 1 && chartIndex <= 4 ? chartIndex : 0
  );
  const [viewLarger, setViewLarger] = React.useState<boolean>(
    searchParams.get("veiwLarger") === "true" ? true : false
  );
  const [dmeasureDataParams, setDmeasureDataParams] =
    React.useState<RangeDeviceIdRequestParams | null>(null); // dmeasure, state 공용
  const [stateDataParams, setStateDataParams] =
    React.useState<RangeDeviceIdRequestParams | null>(null); // dmeasure, state 공용

  // Selected Select Option
  const [selectedRawSensor, setSelectedRawSensor] =
    React.useState<SelectOption>(initialSelectOption);
  const [selectedCMSensor, setSelectedCMSensor] =
    React.useState<SelectOption>(initialSelectOption);
  const [selectedSCSensor, setSelectedSCSensor] =
    React.useState<SelectOption>(initialSelectOption);

  const [paramsRaw, setParamsRaw] =
    React.useState<RangeSensorIdPagingRequestParams | null>(null);
  const [paramsCM, setParamsCM] =
    React.useState<RangeSensorIdPagingRequestParams | null>(null);
  const [paramsSC, setParamsSC] =
    React.useState<RangeSensorIdPagingRequestParams | null>(null);

  // * QUERIES
  const { isFetching: isFetchingDmeasure, data: dmeasureData } =
    useDmeasureDataQuery(dmeasureDataParams as RangeDeviceIdRequestParams);
  const { isFetching: isFetchingState, data: stateData } = useStateDataQuery(
    stateDataParams as RangeDeviceIdRequestParams
  );

  // * Functions
  const handleTabButton = (index: number) => {
    if (index !== tabActive) {
      setTabActive(index);

      switch (index) {
        case 1:
        case 3:
        case 4:
          // 로우일 때 selecetedRawSensor를 0번째 센서로 자동 선택 및 조회
          searchParams.set(
            "size",
            index === 1
              ? String(rawDataPaging.size)
              : index === 3
              ? String(cameraDataPaging.size)
              : String(smartCameraDataPaging.size)
          );
          searchParams.set("page", "1");
          break;
        case 0:
        case 2:
          searchParams.delete("size");
          searchParams.delete("page");
          searchParams.delete("sensorId");
          break;
      }

      searchParams.delete("contentUrl");

      navigate(
        {
          pathname: `/dataByDeviceId/${chartTypeList[index]}`,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };

  // 차트 크게보기 버튼
  const handleViewLargerButton = React.useCallback(() => {
    setViewLarger((prev) => !prev);
  }, []);

  // 서치바 검색 함수
  const getUrlParams = (data: SearchParameterValues | null) => {
    if (data) {
      switch (tabActive) {
        case 0:
          setDmeasureDataParams({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            deviceId: data.deviceId ?? "",
          });

          break;
        case 1:
          setParamsRaw({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            sensorId: data.sensorId ?? "",
            size: rawDataPaging.size,
            page: rawDataPaging.page,
          });

          break;
        case 2:
          setStateDataParams({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            deviceId: data.deviceId ?? "",
          });

          break;
        case 3:
          setParamsCM({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            sensorId: data.sensorId ?? "",
            size: cameraDataPaging.size,
            page: cameraDataPaging.page,
          });
          break;
        case 4:
          setParamsSC({
            startDate: data.startDate ?? "",
            endDate: data.endDate ?? "",
            sensorId: data.sensorId ?? "",
            size: smartCameraDataPaging.size,
            page: smartCameraDataPaging.page,
          });
          break;
      }

      if (!firstSearched) {
        setFirstSearched(true);
      }
    }
  };

  // * Effects

  // 페이징 바뀔 때 처리
  useUpdateParamsOnPageChange(rawDataPaging, setParamsRaw);
  useUpdateParamsOnPageChange(cameraDataPaging, setParamsCM);
  useUpdateParamsOnPageChange(smartCameraDataPaging, setParamsSC);

  useEffect(() => {
    setViewLarger(
      searchParams.get("viewLarger") === "true" && !viewLarger ? true : false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (firstSearched) {
      const newSearchParams = new URLSearchParams(searchParams);

      if (!searchParams.get("viewLarger") && viewLarger) {
        newSearchParams.set("viewLarger", "true");
      } else if (searchParams.get("viewLarger") && !viewLarger) {
        newSearchParams.delete("viewLarger");
      }

      navigate(
        {
          pathname: pathname,
          search: newSearchParams.toString(),
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewLarger]);

  // * SearchRow Optional Props
  const tabContents = [
    t("statistics_data"),
    t("raw_data"),
    t("device_status_data"),
    t("camera"),
    t("camera_ai"),
  ];

  const commonSearchRowProps = {
    searchedDate: true as true,
    dateType: DateTypeConst.Range,
    minDate,
    maxDate,
    inCard: true,
    cardSizeTheme: NormalCardStyle,
    getUrlParams,
  };

  const tabProps = {
    1: {
      selectedSensor: selectedRawSensor,
      setSelectedSensor: setSelectedRawSensor,
      sensorTypeFilter: ["AC", "GA", "GN", "TI", "TP", "WD", "WS", "GD"],
      paging: rawDataPaging,
      setPaging: setRawDataPaging,
    },
    3: {
      selectedSensor: selectedCMSensor,
      setSelectedSensor: setSelectedCMSensor,
      sensorTypeFilter: ["CM"],
      paging: cameraDataPaging,
      setPaging: setCameraDataPaging,
      hasViewLarger: true,
    },
    4: {
      selectedSensor: selectedSCSensor,
      setSelectedSensor: setSelectedSCSensor,
      sensorTypeFilter: ["SC"],
      paging: smartCameraDataPaging,
      setPaging: setSmartCameraDataPaging,
      hasViewLarger: true,
    },
  };

  const additionalProps =
    tabActive === 1 || tabActive === 3 || tabActive === 4
      ? {
          ...tabProps[tabActive],
          searchedSensor: true as true,
          hasContentUrl: true,
        }
      : { hasViewLarger: true };

  return (
    <StyledFlexWrap flexDirection="column">
      {/* SearchRow */}
      <OptionalDeviceSearchRow {...additionalProps} {...commonSearchRowProps} />

      {/* Tab Component */}
      <StyledTabButtonWrap marginBotttom={tabActive !== 1 ? 10 : 20}>
        <Tab
          contents={tabContents}
          tabActiveNumber={tabActive}
          tabActiveOnClick={(index) => handleTabButton(index)}
        />
        {tabActive !== 1 && (
          <IconButton
            sizeTheme={NormalIconButtonStyles}
            colorType="primary"
            iconName={viewLarger ? "viewCozy" : "viewFull"}
            onClick={handleViewLargerButton}
          />
        )}
      </StyledTabButtonWrap>
      {/* Chart Container */}
      <StyledRelativeFullWrap id="chart-wrap">
        {tabActive === 0 ? (
          <>
            {dmeasureData &&
            dmeasureData
              .flatMap((item) => item.chart)
              .find((chart) => chart !== null) ? (
              <StyledChartGridWrap viewLarger={viewLarger}>
                {dmeasureData
                  .sort((a, b) => {
                    const aLabel = getSensorLabel(a.sensorId) ?? "";
                    const bLabel = getSensorLabel(b.sensorId) ?? "";
                    return aLabel.localeCompare(bLabel);
                  })
                  .map((item, index) => {
                    if (item.chart) {
                      return (
                        <DefaultLineChart
                          key={index}
                          chartTitle={makeChartTitle(item.title)}
                          pngFileTitle={item.chart.pngFileTitle}
                          chartData={{
                            labels: item.chart.labels,
                            datasets: item.chart.datasets,
                          }}
                          viewLarger={viewLarger}
                          isInCard
                          showZoomButton
                          showPngDownloadButton
                          isXScaleTime
                          timeUnit={getTimeUnit(item.chart.labels)}
                          customOptions={customOptions}
                        />
                      );
                    }
                    return null;
                  })}
              </StyledChartGridWrap>
            ) : (
              <DataLoadingText text={t("no_data")} />
            )}
          </>
        ) : tabActive === 1 ? (
          <Raw params={paramsRaw} />
        ) : tabActive === 2 ? (
          <>
            {stateData &&
            stateData.chartList &&
            stateData.chartList.length > 0 ? (
              <div
                style={{
                  overflowX: "auto",
                  paddingBottom: 20,
                }}
              >
                <StyledStateChartGridWrap
                  viewLarger={viewLarger}
                  dataType="state"
                >
                  {stateData.chartList.map((item, index) => {
                    return (
                      <DefaultLineChart
                        key={index}
                        chartTitle={
                          stateData.titleList
                            ? makeChartTitle(stateData.titleList[index])
                            : ""
                        }
                        pngFileTitle={item.pngFileTitle}
                        chartData={{
                          labels: item.labels,
                          datasets: item.datasets,
                        }}
                        viewLarger={viewLarger}
                        isInCard
                        yScaleMin={
                          yScaleMinArray.find(
                            (scale) => scale.type === item.dataType
                          )?.val
                        }
                        yScaleMax={
                          yScaleMaxArray.find(
                            (scale) => scale.type === item.dataType
                          )?.val
                        }
                        showPngDownloadButton
                        showZoomButton
                        isXScaleTime
                        timeUnit={getTimeUnit(item.labels)}
                        customHeightRatio={130}
                      />
                    );
                  })}
                </StyledStateChartGridWrap>
              </div>
            ) : (
              <DataLoadingText text={t("no_data")} />
            )}
          </>
        ) : tabActive === 3 ? (
          selectedCMSensor.value !== "" ? (
            <Camera
              selectedSensor={selectedCMSensor.value}
              viewLarger={viewLarger}
              paging={cameraDataPaging}
              setPaging={setCameraDataPaging}
              params={paramsCM}
            />
          ) : (
            <DataLoadingText
              text={
                selectedCMSensor.value === ""
                  ? t("there_is_no_camera_sensor")
                  : t("please_click_search_button")
              }
            />
          )
        ) : tabActive === 4 ? (
          selectedSCSensor.value !== "" ? (
            <Camera
              selectedSensor={selectedSCSensor.value}
              viewLarger={viewLarger}
              paging={smartCameraDataPaging}
              setPaging={setSmartCameraDataPaging}
              params={paramsSC}
            />
          ) : (
            <DataLoadingText
              text={
                selectedSCSensor.value === ""
                  ? t("there_is_no_camera_sensor")
                  : t("please_click_search_button")
              }
            />
          )
        ) : null}
        {(!dmeasureData && isFetchingDmeasure) ||
        (!stateData && isFetchingState) ? (
          <LoadingOnPage parentId="chart-wrap" text={t("loading_data")} />
        ) : null}
      </StyledRelativeFullWrap>
    </StyledFlexWrap>
  );
}

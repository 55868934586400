import { makeMarkerClustering } from "@src/assets/js/marker-cluster";
import { useNavermaps } from "react-naver-maps";

interface UseNaverMapHooksProps {
  updateClickedMarker: (id: string, name: string) => void;
}

export default function useNaverMapHooks({
  updateClickedMarker,
}: UseNaverMapHooksProps) {
  const navermaps = useNavermaps();

  /**
   *
   * @param deviceId
   * @param deviceName
   * @param work: false일 경우 not-work 클래스를 추가해 마커 색상을 결정합니다.
   * @param size: 마커 icon의 size를 설정합니다.
   * @param anchor: 마커 icon의 anchor를 설정합니다.
   * @param cluster : true 만 가능하며 true로 들어올 경우 클러스터 아이콘을 리턴합니다.
   * @returns 마커 혹은 클러스터마커 아이콘을 리턴합니다.
   */

  const makeMarkerIcon = (
    deviceId: string,
    deviceName: string,
    work: boolean,
    dtrigger: "Y" | "N",
    size?: naver.maps.Size,
    anchor?: naver.maps.Point,
    cluster?: true
  ) => ({
    content: `
    <div class="marker-icon${
      dtrigger === "Y" ? " warning" : !work ? " not-work" : ""
    }" >
      <div class="marker-svg">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
          <g transform="translate(-526 -420)">
            <rect width="24" height="24" transform="translate(526 420)" fill="rgba(255,255,255,0)"/>
            <path  d="M17.981,15.865l.439-.442a9.182,9.182,0,0,0,1.929-2.882A8.927,8.927,0,0,0,21.052,9a8.936,8.936,0,0,0-.7-3.543,9.286,9.286,0,0,0-1.957-2.911L17.94,2.09,20.061.006l.433.443a12.618,12.618,0,0,1,2.559,3.843A11.776,11.776,0,0,1,24,9a11.763,11.763,0,0,1-.947,4.705,12.631,12.631,0,0,1-2.559,3.843L20.051,18ZM3.513,17.548A12.24,12.24,0,0,1,.95,13.731,11.855,11.855,0,0,1,0,9,11.848,11.848,0,0,1,.95,4.263,12.243,12.243,0,0,1,3.513.445L3.955,0,6.022,2.128l-.439.443A9.237,9.237,0,0,0,3.651,5.453,8.991,8.991,0,0,0,2.948,9a8.981,8.981,0,0,0,.7,3.544,9.348,9.348,0,0,0,1.96,2.911l.449.452-2.114,2.08ZM14.766,12.63l.439-.442a4.562,4.562,0,0,0,.947-1.422,4.663,4.663,0,0,0,0-3.537,4.657,4.657,0,0,0-.975-1.451l-.452-.452,2.117-2.077.433.433a7.628,7.628,0,0,1,1.581,2.357A7.408,7.408,0,0,1,19.449,9a7.338,7.338,0,0,1-.593,2.93,8.012,8.012,0,0,1-1.575,2.38l-.442.457ZM6.728,14.313a7.65,7.65,0,0,1-1.581-2.354A7.384,7.384,0,0,1,4.554,9a7.352,7.352,0,0,1,.593-2.958A7.628,7.628,0,0,1,6.728,3.681l.442-.446L9.237,5.367,8.8,5.806a4.619,4.619,0,0,0-.95,4.96,4.62,4.62,0,0,0,.979,1.451l.449.452-2.114,2.08Zm3.227-3.251a2.932,2.932,0,0,1,0-4.129,2.871,2.871,0,0,1,4.09,0,2.925,2.925,0,0,1,0,4.129,2.871,2.871,0,0,1-4.09,0Z" transform="translate(550 441) rotate(180)" />
          </g>
        </svg>
      </div>
      <div class="marker-text">
        <span class="marker-device-id${
          cluster ? " cluster-device-id" : ""
        } did">${deviceId}</span>
        <span class="marker-device-alias${
          cluster ? " cluster-device-alias" : ""
        }">${deviceName}</span>
        <span data-work="${
          work ? 1 : 0
        }" data-dtrigger="${dtrigger}" class="device-work-dtrigger" style="display: none;"></span>
      </div>
      <span class="marker-picker"></span>
      ${cluster ? '<span class="cluster-count"></span>' : ""}
    </div>`,
    size,
    anchor,
  });

  const handleClusterInfoBoxScroll = (
    event: WheelEvent,
    clusterInfoBox: HTMLElement
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const delta = event.deltaY || event.detail * -40 || 0; // deltaY 값 또는 detail 값을 변환
    const scrollSpeed = 10; // 스크롤 속도 조절
    clusterInfoBox.scrollTop += delta * scrollSpeed;
  };

  // * 클러스터 마커 스타일 함수에 사용할 함수
  // * 클러스터 마커의 클릭 이벤트 등 로직이 복잡합니다.
  const handleStyleFunction = (
    clusterMarker: any,
    count: number,
    member: naver.maps.Marker[]
  ) => {
    const clusterElement = clusterMarker.getElement(); // .marker-icon의 부모 태그

    // 클러스터 인포 박스가 열린 상태인지 확인하기 위한 .cluster-info-data-check 엘레먼트를 담습니다.
    const clusterInfoDataCheck = document.querySelector(
      ".cluster-info-data-check"
    ) as Element;

    // 클러스터 마커에 표시해줄 0번째 마커의 데이터입니다.
    // 추가로 현재 오픈되어 있는 클러스터 인포 박스가 본인의 것인지 확인하는 용도입니다.
    // (클러스터 마커에 포함된 마커들이 겹치지 않기 때문에 0번째 끼리 확인합니다.)
    let firstDeviceId = "";
    let firstDeviceAlias = "";
    let hasClusterWorkFalse = false; // true로 바뀌면 마커 클러스터 검정색으로 칠하는 변수
    let hasClusterDtrigger = false;

    // cluster-info-box에 표시할 엘레먼트를 생성합니다.
    const clusterInfoBoxParent = document.createElement("div");
    clusterInfoBoxParent.classList.add("cluster-info-box-parent");

    let el = `<div class="cluster-info-box">`;
    member.forEach((item: any, index: number) => {
      const itemEl = item.getElement();
      const deviceId = itemEl.querySelector(".marker-device-id")?.textContent;
      const deviceName = itemEl.querySelector(
        ".marker-device-alias"
      )?.textContent;
      const deviceWork = Number(
        itemEl.querySelector(".device-work-dtrigger").dataset.work
      );
      const deviceDtrigger = String(
        itemEl.querySelector(".device-work-dtrigger").dataset.dtrigger
      );

      if (index === 0) {
        firstDeviceId = deviceId ?? "";
        firstDeviceAlias = deviceName ?? "";
      }
      if (!hasClusterWorkFalse && !deviceWork) {
        // 최대 1회만 변경해주기 위해 hasClusterWorkFalse가 false 상태이고 dievceWork가 false면 true로 변경
        hasClusterWorkFalse = true;
      }
      if (!hasClusterDtrigger && deviceDtrigger === "Y") {
        hasClusterDtrigger = true;
      }

      el += `<div>
        ${
          deviceId
            ? `<div class="did${
                deviceDtrigger === "Y"
                  ? " warning"
                  : !deviceWork
                  ? " not-work"
                  : ""
              }">${deviceId}</div>`
            : ""
        }
        ${
          deviceName
            ? `<div class="dname${
                deviceDtrigger === "Y"
                  ? " warning"
                  : !deviceWork
                  ? " not-work"
                  : ""
              }">${deviceName}</div>`
            : ""
        }
        </div>`;
    });
    el += "</div>";

    // .marker-icon에 cluster-open 클래스를 삭제해 초기화합니다.
    const icon = clusterMarker.eventTarget.querySelector(".marker-icon");
    icon.classList.remove("cluster-open");

    // clusterInfoDataCheck에 데이터가 있으면 오픈되어있는 것으로 판단합니다.
    const infoBox = clusterInfoDataCheck?.querySelector(".cluster-info-box");
    if (infoBox) {
      const firstDidAll = infoBox.querySelectorAll(".did");

      // styleFunction이 실행될 때 마다 인포 박스가 사라지기 때문에
      // clusterInfoElParent에 0번째 디바이스 아이디가 styleFunction이 실행 중인 클러스터의
      // 0번째 마커 디바이스 아이디랑 동일한 경우 같은 클러스터로 판단합니다.
      // el을 돔에 추가하고 관련 이벤트도 추가합니다.
      if (firstDidAll[0].textContent === firstDeviceId) {
        // .marker-icon에 cluster-open 클래스를 추가합니다.
        const icon = clusterMarker.eventTarget.querySelector(".marker-icon");
        icon.classList.add("cluster-open");

        clusterInfoDataCheck.innerHTML = el;
        clusterInfoBoxParent.innerHTML = el;
        clusterElement.appendChild(clusterInfoBoxParent);

        // 인포 박스 영역에서 마우스 휠 움직이면 스크롤되는 이벤트를 추가합니다.
        const clustInfoBox = clusterElement.querySelector(".cluster-info-box");
        clustInfoBox.addEventListener("wheel", (e: WheelEvent) =>
          handleClusterInfoBoxScroll(e, clustInfoBox)
        );

        // 클러스터에 속한 마커 각각의 클릭 이벤트 추가합니다.
        const clusterInfoBoxEls = clusterMarker
          .getElement()
          .querySelectorAll(".cluster-info-box > div") as Element[];
        clusterInfoBoxEls?.forEach((item) => {
          item.addEventListener("click", (e: Event) => handleMarkerClick(e));
        });
      }
    }

    // 1개 뺀 카운터 표시
    clusterElement.querySelector(".cluster-count").innerText = `+${count - 1}`;

    // 클러스터 마커에 0번째 마커 디바이스 아이디, 별명 표시
    clusterElement.querySelector(".cluster-device-id").innerText =
      firstDeviceId ?? "";
    clusterMarker
      .getElement()
      .querySelector(".cluster-device-alias").innerText =
      firstDeviceAlias ?? "";

    // hasClusterWorkFalse가 true면 클러스터 마커에 속한 마커들 중 최소 1개 이상 work false입니다.
    // 검정으로 표시하기 위한 클래스를 추가합니다.
    if (hasClusterWorkFalse) {
      clusterMarker.eventTarget
        .querySelector(".marker-icon")
        .classList.add("not-work");
    }

    // hasClusterWorkFalse가 true면 클러스터 마커에 속한 마커들 중 최소 1개 이상 dtrigger가 'Y'입니다.
    // 빨강으로 표시하기 위한 클래스를 추가합니다.
    if (hasClusterDtrigger) {
      clusterMarker.eventTarget
        .querySelector(".marker-icon")
        .classList.add("warning");
    }

    // * 클러스터 이벤트 시작
    navermaps.Event.addListener(clusterMarker, "mousedown", () => {
      // 클러스터 마커를 마우스다운할 때 자신의 인포 박스가 노출되어 있는지 확인합니다.
      // 있으면 click했을 때 삭제시키기 위해 바로 return 합니다.
      const checkEl = clusterMarker
        .getElement()
        .querySelector(".cluster-info-box-parent");
      if (checkEl) {
        return;
      }

      // 클러스터 마커를 마우스다운할 때 다른 인포 박스가 노출되어 있는지 확인하고 삭제합니다.
      // 이때 clusterInfoDataCheck의 내부 돔도 비워줍니다.
      const otherInfoBox = document.querySelector(".cluster-info-box-parent");
      if (otherInfoBox) {
        otherInfoBox.remove();
        clusterInfoDataCheck.innerHTML = "";
      }

      // 이미 열려있는 clusterOpen이 있으면 클래스 제거합니다.
      const clusterOpen = document.querySelector(".cluster-open");
      if (clusterOpen) {
        clusterOpen.classList.remove("cluster-open");
      }
    });

    // 클러스터 마커를 클릭하면 클러스터 인포 박스를 노출시킵니다.
    navermaps.Event.addListener(clusterMarker, "click", () => {
      // checkEl이 있으면 지우고 리턴합니다.
      const checkEl = clusterMarker
        .getElement()
        .querySelector(".cluster-info-box-parent");
      if (checkEl) {
        checkEl.remove();
        clusterInfoDataCheck.innerHTML = "";
        const icon = clusterMarker.getElement().querySelector(".marker-icon");
        icon.classList.remove("cluster-open");
        return;
      }

      // .marker-icon에 .cluster-open 클래스를 추가합니다.
      const icon = clusterMarker.getElement().querySelector(".marker-icon");
      icon.classList.add("cluster-open");

      // 클러스터 마커에 인포 박스 박스를 띄웁니다.
      clusterInfoDataCheck.innerHTML = el;
      clusterInfoBoxParent.innerHTML = el;
      clusterElement.appendChild(clusterInfoBoxParent);

      // 인포 박스 영역에서 마우스 휠 움직이면 스크롤되는 이벤트를 추가합니다.
      const clustInfoBox = clusterElement.querySelector(".cluster-info-box");
      clustInfoBox.addEventListener("wheel", (e: WheelEvent) =>
        handleClusterInfoBoxScroll(e, clustInfoBox)
      );

      // 클러스터에 속한 마커 각각의 클릭 이벤트 추가합니다.
      const clusterInfoBoxEls = clusterMarker
        .getElement()
        .querySelectorAll(".cluster-info-box > div") as Element[];
      clusterInfoBoxEls?.forEach((item) => {
        item.addEventListener("click", (e: Event) => handleMarkerClick(e));
      });
    });

    // scale 스타일을 위한 cluster-eneter 클래스 추가/제거
    navermaps.Event.addListener(clusterMarker, "mouseover", () => {
      const icon = clusterMarker.getElement().querySelector(".marker-icon");
      icon.classList.add("cluster-enter");
    });
    navermaps.Event.addListener(clusterMarker, "mouseout", () => {
      const icon = clusterMarker.getElement().querySelector(".marker-icon");
      icon.classList.remove("cluster-enter");
    });
  };

  // 마커, 클러스터 마커 클릭 시 실행되는 함수
  const handleMarkerClick = (e: any) => {
    let deviceId = "";
    let deviceName = "";
    if (e.currentTarget) {
      e.stopPropagation();
      deviceId = e.currentTarget.querySelector(".did")?.textContent;
      deviceName = e.currentTarget.querySelector(".dname")?.textContent;
    } else {
      deviceId = e.overlay.eventTarget.querySelector(".did")?.textContent;
      deviceName = e.overlay.eventTarget.querySelector(".dname")?.textContent;
    }

    // handleDialogOpen(`${deviceId}-${deviceName}`);
    // clickedMarkerRef.current = deviceId;
    updateClickedMarker(deviceId, deviceName);
    // setClickedMarker(deviceId);
  };

  return {
    makeMarkerClustering,
    makeMarkerIcon,
    handleStyleFunction,
    handleMarkerClick,
    handleClusterInfoBoxScroll,
  };
}

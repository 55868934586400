import React from "react";
import { ButtonStyleProps } from "../sizeTheme";
import { StyledGroupButtonContainer, StyledLineButtonWrap } from "../styles";

export interface ButtonProps {
  sizeTheme: ButtonStyleProps;
  colorType: "primary" | "secondary";
  type?: "button" | "submit" | "reset";
  groupMaxWidth?: number;
  contents: {
    text: string;
    onClick: (index: number) => void;
    formId?: string;
    isDisabled?: boolean;
    maxWidth?: number;
    maxWidthStr?: string;
  }[];
  activeToggle: number;
  setActiveToggle?: React.Dispatch<React.SetStateAction<number>>;
  isDisabled?: boolean;
}

export default function GroupButton({
  sizeTheme,
  colorType,
  type,
  contents,
  activeToggle,
  setActiveToggle,
}: ButtonProps) {
  return (
    <StyledGroupButtonContainer>
      {contents.map((item, index) => (
        <StyledLineButtonWrap
          key={index}
          sizeTheme={sizeTheme}
          colorType={colorType}
          isDisabled={item.isDisabled}
          className={activeToggle === index ? "active" : undefined}
        >
          <button
            type={type}
            form={item.formId}
            onClick={() => {
              item.onClick(index);
              if (setActiveToggle) setActiveToggle(index);
            }}
            disabled={item.isDisabled}
          >
            {item.text}
          </button>
        </StyledLineButtonWrap>
      ))}
    </StyledGroupButtonContainer>
  );
}

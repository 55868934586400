import Icon from "@src/components/Icon";
import React, { useEffect } from "react";
import { StyledCheckbox, StyledCheckboxWrap } from "../styles";
import { CheckboxStyleProps, NoramlCheckboxStyle } from "../sizeTheme";

interface CommonCheckboxProps {
  sizeTheme?: CheckboxStyleProps;
  fixWidth?: number;
  name: string;
  colorType: "primary" | "secondary" | "table";
  isChecked: boolean;
  label?: string;
  isDisabled?: boolean;
}
type ConditionalProps =
  | {
      index: number;
      handler: (index: number, checked?: boolean) => void;
    }
  | {
      index?: never;
      handler: (checked?: boolean) => void;
    };

type CheckboxProps = ConditionalProps & CommonCheckboxProps;

function CheckboxComponent({
  sizeTheme = NoramlCheckboxStyle,
  fixWidth,
  name,
  colorType,
  isChecked,
  label,
  index,
  isDisabled,
  handler,
}: CheckboxProps) {
  const [checked, setChecked] = React.useState<boolean>(false);

  const onChange = () => {
    if (typeof index === "number") {
      handler(index, !isChecked);
    } else {
      handler(!isChecked);
    }
  };

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onChange();
    }
  };

  useEffect(() => {
    if (isChecked !== undefined) {
      setChecked(isChecked);
    }
  }, [isChecked]);

  return (
    <StyledCheckboxWrap
      textSize={sizeTheme.fontSize}
      textWeight={sizeTheme.fontWeight}
      fixWidth={fixWidth}
    >
      <label
        htmlFor={`checkbox-${name}-${index}`}
        style={{
          cursor: "pointer",
        }}
        tabIndex={0}
        onKeyDown={handleKeydown}
      >
        <StyledCheckbox
          isChecked={checked}
          isDisabled={isDisabled}
          colorType={colorType}
          checkboxWidth={sizeTheme.boxWidth}
          checkboxHeight={sizeTheme.boxHeight}
        >
          <Icon
            iconName="check"
            iconWidth={sizeTheme.iconWidth}
            iconHeight={sizeTheme.iconHeight}
            iconColor="transparent"
            aria-labelledby="myLabel"
          />
          <input
            id={`checkbox-${name}-${index}`}
            name={name}
            type="checkbox"
            checked={checked}
            disabled={isDisabled}
            onChange={onChange}
            style={{
              display: "none",
            }}
          />
        </StyledCheckbox>
        {label && <span>{label}</span>}
      </label>
    </StyledCheckboxWrap>
  );
}

/**
 * @Description
 * - React.memo: isChecked 이전과 같을 경우 재렌더링하지 않는다.
 * ------------------------------------------------
 * @Param
 * - colorType: "primary" | "secondary" | "table"
 * - isChecked: boolean;
 * - lable?: string (체크박스 우측에 표시될 문자열)
 * - isDisabled?: boolean (체크박스 disabled)
 * ------------------------------------------------
 * @Conditional-Pararm
 * - typeof index === 'number' --> handler: (index: number, checked?: boolean) => void;
 * - typeof index !== 'number' --> handler: (checked?: boolean) => void;
 * @returns Checkbox
 */
const Checkbox = React.memo(
  CheckboxComponent,
  (prevProps, nextProps) =>
    prevProps.isChecked === nextProps.isChecked &&
    prevProps.label === nextProps.label
);
export default Checkbox;

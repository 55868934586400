import Card from "@src/components/Card";
import { CardSizeProps } from "@src/components/Card/sizeTheme";
import Table from "@src/components/TableNew";
import { ColorTypeConst } from "@src/constructs/common";
import {
  StyledNormalBadge,
  StyledUnknownBadge,
  StyledWarnningBadge,
} from "../styles";
import IconButton from "@src/components/Buttons/IconButton";
import { SmallIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import React from "react";
import Pagination from "@src/components/Pagination";
import { useLanguageStore } from "@src/stores/useTranslationStore";
import { useTranslation } from "react-i18next";
import { DeviceStateShortResponse } from "@src/fetch/fetchCommon";

// * 테이블
const head = [
  {
    value: "deviceGroupName",
    label: "group",
  },
  {
    value: "deviceId",
    label: "device",
  },
  {
    value: "structureName",
    label: "install_location",
  },
  {
    value: "uptime",
    label: "uptime",
  },
  {
    value: "battery",
    label: "battery",
  },
  {
    value: "solarChargeVolt",
    label: "solarchargevolt",
  },
  {
    value: "work",
    label: "state",
  },
];

interface CommonDeviceStatusTableProps {
  cardSizeTheme: CardSizeProps;
  isLoading: boolean;
  data?: DeviceStateShortResponse[] | null;
  showAllButton?: boolean;
  showSizeSelect?: boolean;
  elementSize?: "small" | "normal";
  isSearchable?: boolean;
}

type OptionalPagingDeviceStatusTableProps =
  | {
      showPaging: true;
      isCustomPaging: true;
      paging?: never;
      setPaging?: never;
    }
  | {
      showPaging: true;
      isCustomPaging?: false;
      paging: PagingType;
      setPaging: (paging: PagingType) => void;
    }
  | {
      showPaging?: false;
      isCustomPaging?: false;
      paging?: never;
      setPaging?: never;
    };

type DeviceStatusTableProps = CommonDeviceStatusTableProps &
  OptionalPagingDeviceStatusTableProps;

export default function DeviceStatusTable({
  cardSizeTheme,
  isLoading,
  data,
  isCustomPaging,
  paging,
  setPaging,
  showSizeSelect,
  showAllButton,
  showPaging,
  elementSize,
  isSearchable,
}: DeviceStatusTableProps) {
  const { t } = useTranslation();
  const [customPaging, setCustomPaging] = React.useState<PagingType>({
    page: 1,
    size: 3,
  });
  const language = useLanguageStore();

  const customGridColumnsMinMaxArray = [
    {
      min: "120px",
      max: "1fr",
    },
    {
      min: "110px",
      max: "1fr",
    },
    {
      min: language === "en" ? "250px" : "120px",
      max: language === "en" ? "1.2fr" : "1.2fr",
    },
    {
      min: "200px",
      max: "2fr",
    },
    {
      min: "95px",
      max: "0.8fr",
    },
    {
      min: "95px",
      max: "0.8fr",
    },
    {
      min: language === "en" ? "105px" : "85px",
      max: "0.8fr",
    },
  ];

  return (
    <Card
      sizeTheme={cardSizeTheme}
      title={t("device_status")}
      headerButtons={
        showAllButton ? (
          <IconButton
            sizeTheme={SmallIconButtonStyles}
            text="전체 보기"
            colorType="primary"
            iconName="move"
            onClick={() => {}}
          />
        ) : null
      }
    >
      <Table
        colorType={ColorTypeConst.Primary}
        head={head}
        body={
          data?.map((item, index) => {
            return {
              trOptions: {
                dataRowIndex: index,
              },
              tdOptions: [
                {
                  dataKey: "deviceGroupName",
                  dataValue: item.deviceGroupName,
                  tdValue: item.deviceGroupName,
                },
                {
                  dataKey: "deviceId",
                  dataValue: item.deviceId,
                  tdValue: item.deviceId,
                },
                {
                  dataKey: "structureName",
                  dataValue: item.structureName,
                  tdValue: item.structureName,
                },
                {
                  dataKey: "uptime",
                  dataValue: item.uptime,
                  tdValue: item.uptime,
                },
                {
                  dataKey: "battery",
                  dataValue: item.battery,
                  tdValue: item.battery ? `${item.battery}%` : "N",
                },
                {
                  dataKey: "solarChargeVolt",
                  dataValue: item.solarChargeVolt,
                  tdValue: item.solarChargeVolt
                    ? `${item.solarChargeVolt}v`
                    : "N",
                },
                {
                  dataKey: "work",
                  dataValue: item.work,
                  tdValue: item.work ? (
                    <StyledNormalBadge>ON</StyledNormalBadge>
                  ) : item.work === false ? (
                    <StyledWarnningBadge>OFF</StyledWarnningBadge>
                  ) : (
                    <StyledUnknownBadge>N</StyledUnknownBadge>
                  ),
                },
              ],
            };
          }) ?? null
        }
        minWidth={400}
        customGridColumnsMinMaxArray={customGridColumnsMinMaxArray}
        page={
          isCustomPaging
            ? customPaging.page
            : showPaging
            ? paging.page
            : undefined
        }
        size={
          isCustomPaging
            ? customPaging.size
            : showPaging
            ? paging.size
            : undefined
        }
        isCustomPaging={isCustomPaging}
        isLoading={isLoading}
        isSortable
        isSearchable={isSearchable}
        elementSize={elementSize ?? "normal"}
      />
      {showPaging ? (
        <Pagination
          page={isCustomPaging ? customPaging.page : paging.page}
          size={isCustomPaging ? customPaging.size : paging.size}
          count={data?.length ?? 0}
          groupLength={3}
          setPaging={isCustomPaging ? setCustomPaging : setPaging}
          elementSize={elementSize ?? "normal"}
          showSizeSelect={showSizeSelect}
        />
      ) : null}
    </Card>
  );
}

import { ButtonStyleProps } from "../sizeTheme";
import { StyledBgButtonWrap } from "../styles";

export interface ButtonProps {
  className?: string;
  sizeTheme: ButtonStyleProps;
  colorType: "primary" | "secondary";
  type?: "button" | "submit" | "reset";
  text: string;
  formId?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  fixWidth?: string;
  maxWidth?: number;
  maxWidthStr?: string;
  onClick?: () => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

export default function Button({
  className,
  sizeTheme,
  colorType,
  type,
  text,
  formId,
  isDisabled,
  isActive,
  fixWidth,
  maxWidth,
  maxWidthStr,
  onClick,
  buttonRef,
}: ButtonProps) {
  return (
    <StyledBgButtonWrap
      sizeTheme={sizeTheme}
      colorType={colorType}
      isDisabled={isDisabled}
      fixWidth={fixWidth}
      maxWidth={maxWidth}
      maxWidthStr={maxWidthStr}
    >
      <button
        type={type}
        form={formId}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
        disabled={isDisabled}
        ref={buttonRef}
        className={
          className && isActive
            ? `${className} active`
            : isActive
            ? "active"
            : className ?? undefined
        }
      >
        {text}
      </button>
    </StyledBgButtonWrap>
  );
}

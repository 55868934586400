import Header from "@src/components/Header";
import Sidebar from "@src/components/Sidebar";
import {
  BrowserSizeConst,
  MENU_LIST,
  SIDEBAR_WIDTH,
  ThemeConst,
} from "@constructs/common";
import { mediaQuery } from "@src/styles/theme";
import { useIsFetching } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  useBrowserSizeStore,
  useCommonStoreActions,
  useEventDatasetStore,
  useIsSummarySidebarStore,
  useLoadingOnPageInitStore,
  useLoggedInStore,
  useThemeStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import useFirebaseHooks from "@src/hooks/useFirebaseHooks";
import FloatEvent from "@src/components/FloatEvent";
import { createPortal } from "react-dom";
import LoadingRandomIcon from "@src/components/Loadings/LoadingRandomIcon";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@src/firebase";
import { Div } from "@src/styles/commonStyles";

const StyledAppContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100svh; */
  overflow: hidden;
`;

const StyledMain = styled.main<{ loadingOnPageInit: boolean }>`
  flex: 1;
  display: grid;
  grid-template-rows: max-content 1fr;
  background-color: ${({ theme }) => theme.color.background};
  height: 100svh;

  position: relative;
  overflow: hidden;

  ${mediaQuery.laptop} {
    margin-top: 54px;
    height: calc((var(--vh, 1vh) * 100) - 54px);
  }

  ${mediaQuery.mobile} {
    margin-top: 48px;
    height: calc((var(--vh, 1vh) * 100) - 48px);
  }
`;

const StyledMainInner = styled(Div)<{ isFetching: boolean }>`
  grid-template-rows: 1fr;

  padding: 15px 30px 20px;
  width: 100%;
  overflow-x: hidden;
  /* overflow-y: ${({ isFetching }) => (isFetching ? "hidden" : "auto")}; */
  position: relative;

  ${mediaQuery.tablet} {
    padding: 15px;
  }

  & > div {
    grid-column: 1 / 13;
    /* overflow: ${({ isFetching }) => (isFetching ? "hidden" : "unset")}; */
  }

  div[id$="-wrap"] {
    /* overflow: ${({ isFetching }) => (isFetching ? "hidden" : "unset")}; */
  }
`;

const StyledFooter = styled.footer`
  background-color: #bc78e0;
`;

export interface AppLayoutProps {
  children: ReactNode;
}
function AppLayoutComponent({ children }: AppLayoutProps) {
  // hooks
  const dialogRoot = document.getElementById("dialog") as HTMLElement;
  const isFetching = useIsFetching();
  // const isMutating = useIsMutating();
  const userInfo = useUserInfoStore();
  const themeType = useThemeStore();
  const browserSize = useBrowserSizeStore();
  const isSummarySidebar = useIsSummarySidebarStore();
  const { setIsSummarySidebar } = useCommonStoreActions();
  const { pathname } = useLocation();
  const { getTokenHook, onMessageHook } = useFirebaseHooks();
  const eventDataset = useEventDatasetStore();
  const { setEventDataset } = useCommonStoreActions();
  const loggedIn = useLoggedInStore();
  const beforeLoggedInRef = React.useRef<boolean>(false);
  const loadingOnPageInit = useLoadingOnPageInitStore();

  // State
  const [activePath, setActivePath] = React.useState<string>("");
  const [activeTitle, setActiveTitle] = React.useState<string>("");
  const [activeNotice, setActiveNotice] =
    React.useState<{ title: string; message: string | string[] } | undefined>(
      undefined
    );
  const [beforeThemeType, setBeforeThemeType] = React.useState<ThemeType>(
    themeType === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
  );
  const [init, setInit] = React.useState<boolean>(false);
  const [isOpenMobileSidebar, setIsOpenMobileSidebar] =
    React.useState<boolean>(false); // only for under Desktop Size

  const sidebarMargin =
    browserSize && browserSize <= BrowserSizeConst.Laptop
      ? SIDEBAR_WIDTH.ZERO
      : isSummarySidebar
      ? SIDEBAR_WIDTH.SUMMARY
      : SIDEBAR_WIDTH.EXPANTION;

  // * Effects
  // pathname으로 active path, title, notice 설정
  useEffect(() => {
    setActivePath(pathname);

    MENU_LIST.flatMap((item) => item.menu).find((innerItem) => {
      if (pathname.includes(innerItem.activePathStr)) {
        setActiveTitle(innerItem.name);
        setActiveNotice(innerItem.notice);
        return true;
      }
      return false;
    });
  }, [pathname]);

  // Desktop 사이즈로 바뀔 때 mobile setIsOpenMobileSidebar = false
  useEffect(() => {
    if (browserSize && browserSize >= BrowserSizeConst.DesktopSmall) {
      setIsOpenMobileSidebar(false);
    } else {
      setIsSummarySidebar(false);
    }
  }, [browserSize, setIsSummarySidebar]);

  // themeType이 변경되면 테마 트렌지션 클래스 추가했다가 0.5초 뒤에 삭제
  useEffect(() => {
    if (themeType === beforeThemeType) {
      setTimeout(() => {
        setBeforeThemeType((prev) =>
          prev === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
        );
      }, 500);
    }
  }, [themeType, beforeThemeType]);

  // 메세지 받아서 우측 하단에 메세지 엘리먼트 추가
  useEffect(() => {
    onMessageHook(eventDataset, setEventDataset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDataset]);

  // FCM 기기 토큰 업데이트
  useEffect(() => {
    if (loggedIn && beforeLoggedInRef.current === false) {
      getTokenHook();
      beforeLoggedInRef.current = loggedIn;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // Firebase Auth 이메일 인증 사용자인지 확인 후 init true로 변경
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        // 이메일 인증된 사용자만
        setTimeout(() => {
          setInit(true);
        }, 500);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {!init ? (
        <LoadingRandomIcon />
      ) : (
        <StyledAppContainer
          initial={false}
          animate={{
            marginLeft: sidebarMargin,
          }}
          className={
            themeType === beforeThemeType ? "theme-changing" : undefined
          }
        >
          <Sidebar
            isOpenMobileSidebar={isOpenMobileSidebar}
            setIsOpenMobileSidebar={setIsOpenMobileSidebar}
            activePath={activePath}
          />
          <StyledMain loadingOnPageInit={loadingOnPageInit}>
            <Header activeTitle={activeTitle} activeNotice={activeNotice} />
            <StyledMainInner
              id="main-inner"
              layoutGridParent
              isFetching={Boolean(isFetching)}
            >
              {children}
              {userInfo.name.ko === "" ? <LoadingRandomIcon /> : null}

              {/* {<LoadingSpinner />} */}
              {/* {isPageLoading ? (
                <StyledRelativeFullWrap
                  id="loading-on-page-wrap"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <LoadingOnPage text={t("loading_data")} />
                </StyledRelativeFullWrap>
              ) : null} */}
            </StyledMainInner>
          </StyledMain>
          <StyledFooter />
        </StyledAppContainer>
      )}

      {/* //* 이벤트! */}
      {eventDataset !== null
        ? createPortal(<FloatEvent eventDataset={eventDataset} />, dialogRoot)
        : null}
      {/* //* 이벤트 끝 */}
    </>
  );
}

const AppLayout = React.memo(
  AppLayoutComponent,
  (prevProps, nextProps) => prevProps.children === nextProps.children
);
export default AppLayout;

import Card from "@src/components/Card";
import { StyledChartGridWrap } from "../styles";
import { SmallCardStyle } from "@src/components/Card/sizeTheme";
import IconButton from "@src/components/Buttons/IconButton";
import { SmallIconButtonStyles } from "@src/components/Buttons/sizeTheme";
import { StyledFlexWrap, StyledGridWrap } from "@src/styles/commonStyles";
import DefaultLineChart from "@src/components/Charts/DefaultLineChart";
import React, { ReactNode, useEffect } from "react";
import Slide from "@src/components/Slide";
import InterestSensorSettingDialog from "./InterestSensorSettingDialog";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import { BrowserSizeConst, FlexAlignTypeConst } from "@src/constructs/common";
import {
  useBrowserSizeStore,
  useInterestSensorListStore,
} from "@src/stores/useCommonStore";
import { format } from "date-fns";
import { useDialog } from "@src/contexts/DialogProvider";
import { theme } from "@src/styles/theme";
import { useTranslation } from "react-i18next";
import useCommonHooks from "@src/hooks/useCommonHooks";
import SensorChartData from "@src/models/SensorChartData";

const customOptions = {
  interaction: {
    mode: "index",
    intersect: false, // 모든 위치에서 클릭 가능하게
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "hour",
        displayFormats: {
          hour: "yyyy MM.dd HH:mm",
          minute: "yyyy MM.dd HH:mm:ss.SSS",
          second: "yyyy MM.dd HH:mm:ss.SSS",
        },
      },
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};

interface DmeasureChartSlideProps {
  isLoading: boolean;
  dmeasureData: SensorChartData[] | null | undefined;
}

export default function DmeasureChartSlide({
  isLoading,
  dmeasureData,
}: DmeasureChartSlideProps) {
  // hooks
  const { makeChartTitle } = useCommonHooks();
  const { handleDialogOpen } = useDialog();
  const browserSize = useBrowserSizeStore();
  const date = format(new Date(), "yyyy.MM.dd");
  const { t } = useTranslation();
  const interestSensorList = useInterestSensorListStore();

  // State
  const [interestSensorSettingDialogOpen, setInterestSensorSettingDialogOpen] =
    React.useState<boolean>(false);
  const [chartTitle, setChartTitle] =
    React.useState<NonNullable<ReactNode>>("");

  const hadleDialogOpen = () => {
    handleDialogOpen(t("set_up_interest_sensor"));
    setInterestSensorSettingDialogOpen(true);
  };

  const getChartTitle = (index: number) => {
    if (dmeasureData) {
      setChartTitle(makeChartTitle(dmeasureData[index].title));
    }
  };

  const slideElRef = React.useRef<HTMLDivElement>(null);
  const slideElHeight = React.useRef<number>(0);
  const [slideHeight, setSlideHeight] = React.useState<number>(0);

  const getSlideElHeight = () => {
    if (slideElRef && slideElRef.current) {
      slideElHeight.current = slideElRef.current.clientHeight;
      setSlideHeight(slideElRef.current.clientHeight);
    }
  };

  useEffect(() => {
    getSlideElHeight();
    window.addEventListener("resize", getSlideElHeight);

    return () => {
      window.removeEventListener("resize", getSlideElHeight);
    };
  }, []);

  return (
    <StyledChartGridWrap
      viewLarger={true}
      ref={slideElRef}
      data-height={slideHeight}
    >
      <Card
        title={chartTitle !== "" ? chartTitle : "관심 센서 통계 데이터"}
        sizeTheme={SmallCardStyle}
        headerButtons={
          <IconButton
            sizeTheme={SmallIconButtonStyles}
            text={t("set_up_interest_sensor")}
            colorType="primary"
            iconName="setting"
            onClick={hadleDialogOpen}
            isDisabled={isLoading}
          />
        }
        isLoading={isLoading}
      >
        <StyledFlexWrap
          flexDirection="column"
          vertical="center"
          style={{
            width: "100%",
            height: browserSize
              ? browserSize >= BrowserSizeConst.Desktop
                ? "100%"
                : browserSize <= BrowserSizeConst.Mobile
                ? 480
                : browserSize <= BrowserSizeConst.Tablet
                ? 300
                : 150
              : 150,
            overflow: "hidden",
          }}
        >
          {interestSensorList?.length === 0 ? (
            <DataLoadingText text={t("there_is_no_selected_interest_sensor")} />
          ) : !isLoading && !dmeasureData ? (
            <DataLoadingText text={t("no_data")} />
          ) : dmeasureData ? (
            <Slide getChartTitle={getChartTitle} autoSlide>
              {dmeasureData.map((item, index) => {
                return item.chart === null ? (
                  <StyledFlexWrap
                    key={index}
                    align={FlexAlignTypeConst.Center}
                    vertical={FlexAlignTypeConst.Center}
                    style={{
                      color: theme.color.primary,
                      fontSize: 15,
                      fontWeight: 500,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    센서의 {date} 통계 데이터를 불러올 수 없습니다.{" "}
                  </StyledFlexWrap>
                ) : (
                  <StyledGridWrap
                    templateColumns={
                      slideHeight >= 500
                        ? "repeat(2, 1fr)"
                        : slideHeight >= 290
                        ? "repeat(3, 1fr)"
                        : browserSize && browserSize <= BrowserSizeConst.Mobile
                        ? "repeat(2, 1fr)"
                        : browserSize && browserSize <= BrowserSizeConst.Tablet
                        ? "repeat(3, 1fr)"
                        : "repeat(5, 1fr)"
                    }
                    templateRows={
                      slideHeight >= 500
                        ? "calc(33.3% - 10px) calc(33.3% - 10px) calc(33.3% - 10px)"
                        : slideHeight >= 290
                        ? "calc(50% - 10px) calc(50% - 10px)"
                        : browserSize && browserSize <= BrowserSizeConst.Mobile
                        ? "calc(33.3% - 10px) calc(33.3% - 10px) calc(33.3% - 10px)"
                        : browserSize && browserSize <= BrowserSizeConst.Tablet
                        ? "calc(50% - 10px) calc(50% - 10px)"
                        : "100%"
                    }
                    gap={20}
                    key={index}
                  >
                    <DefaultLineChart
                      chartTitle="AC-X 센서 기본 데이터"
                      pngFileTitle="차트 이미지"
                      chartData={{
                        labels: item.chart.labels,
                        datasets: [item.chart.datasets[0]],
                      }}
                      fixHeight={
                        browserSize && browserSize >= BrowserSizeConst.Desktop
                          ? "100%"
                          : 130
                      }
                      customOptions={customOptions}
                      showLegendonMobile
                      isXScaleTime
                      timeUnit="hour"
                    />
                    <DefaultLineChart
                      chartTitle="AC-X 센서 기본 데이터"
                      pngFileTitle="차트 이미지"
                      chartData={{
                        labels: item.chart.labels,
                        datasets: [item.chart.datasets[1]],
                      }}
                      fixHeight={
                        browserSize && browserSize >= BrowserSizeConst.Desktop
                          ? "100%"
                          : 130
                      }
                      customOptions={customOptions}
                      showLegendonMobile
                      isXScaleTime
                      timeUnit="hour"
                    />
                    <DefaultLineChart
                      chartTitle="AC-X 센서 기본 데이터"
                      pngFileTitle="차트 이미지"
                      chartData={{
                        labels: item.chart.labels,
                        datasets: [item.chart.datasets[2]],
                      }}
                      fixHeight={
                        browserSize && browserSize >= BrowserSizeConst.Desktop
                          ? "100%"
                          : 130
                      }
                      customOptions={customOptions}
                      showLegendonMobile
                      isXScaleTime
                      timeUnit="hour"
                    />
                    <DefaultLineChart
                      chartTitle="AC-X 센서 기본 데이터"
                      pngFileTitle="차트 이미지"
                      chartData={{
                        labels: item.chart.labels,
                        datasets: [item.chart.datasets[3]],
                      }}
                      fixHeight={
                        browserSize && browserSize >= BrowserSizeConst.Desktop
                          ? "100%"
                          : 130
                      }
                      customOptions={customOptions}
                      showLegendonMobile
                      isXScaleTime
                      timeUnit="hour"
                    />
                    <DefaultLineChart
                      chartTitle="AC-X 센서 기본 데이터"
                      pngFileTitle="차트 이미지"
                      chartData={{
                        labels: item.chart.labels,
                        datasets: [item.chart.datasets[4]],
                      }}
                      fixHeight={
                        browserSize && browserSize >= BrowserSizeConst.Desktop
                          ? "100%"
                          : 130
                      }
                      customOptions={customOptions}
                      showLegendonMobile
                      isXScaleTime
                      timeUnit="hour"
                    />
                  </StyledGridWrap>
                );
              })}
            </Slide>
          ) : null}
        </StyledFlexWrap>
        <InterestSensorSettingDialog
          open={interestSensorSettingDialogOpen}
          setOpen={setInterestSensorSettingDialogOpen}
        />
      </Card>
    </StyledChartGridWrap>
  );
}

import Icon from "@components/Icon";
import { ButtonStyleProps } from "../sizeTheme";
import { StyledIconButtonWrap } from "../styles";
import React from "react";

type OptionalCustomColorType =
  | {
      colorType: "custom";
      iconColor: string;
      iconHoverColor: string;
    }
  | {
      colorType:
        | "primary"
        | "secondary"
        | "primaryNoBg"
        | "secondaryNoBg"
        | "grayToPrimaryNoBg";
      iconColor?: never;
      iconHoverColor?: never;
    };

interface ButtonCommonProps {
  sizeTheme: ButtonStyleProps;
  colorType:
    | "primary"
    | "secondary"
    | "primaryNoBg"
    | "secondaryNoBg"
    | "grayToPrimaryNoBg"
    | "custom";
  type?: "button" | "submit" | "reset";
  iconName: string;
  iconWidth?: number;
  iconHeight?: number;
  iconColor?: string;
  iconHoverColor?: string;
  text?: string;
  formId?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  isActiveWithScale?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
}

export type IconButtonProps = ButtonCommonProps & OptionalCustomColorType;

function IconButtonComponent({
  sizeTheme,
  colorType,
  type,
  iconName,
  iconWidth,
  iconHeight,
  iconColor,
  iconHoverColor,
  text,
  formId,
  isDisabled,
  isActive,
  isActiveWithScale,
  onClick,
  buttonRef,
}: IconButtonProps) {
  return (
    <StyledIconButtonWrap
      sizeTheme={sizeTheme}
      colorType={colorType}
      isDisabled={isDisabled}
      text={text}
      iconColor={iconColor}
      iconHoverColor={iconHoverColor}
    >
      <button
        ref={buttonRef}
        type={type}
        form={formId}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onClick();
          e.preventDefault();
        }}
        disabled={isDisabled}
        className={
          isActiveWithScale ? "active-scale" : isActive ? "active" : undefined
        }
      >
        <Icon
          iconName={iconName}
          iconWidth={iconWidth ? iconWidth : sizeTheme.iconWidth ?? 24}
          iconHeight={iconHeight ? iconHeight : sizeTheme.iconHeight ?? 24}
        />
        {text && <span>{text}</span>}
      </button>
    </StyledIconButtonWrap>
  );
}

const IconButton = React.memo(IconButtonComponent, (prevProps, nextProps) => {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.isActiveWithScale === nextProps.isActiveWithScale &&
    prevProps.isDisabled === nextProps.isDisabled &&
    prevProps.iconName === nextProps.iconName &&
    prevProps.iconWidth === nextProps.iconWidth &&
    prevProps.iconHeight === nextProps.iconHeight &&
    prevProps.text === nextProps.text &&
    prevProps.onClick === nextProps.onClick
  );
});

export default IconButton;

import React, { useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindowF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { DeviceLatLngResponse } from "@src/fetch/fetchCommon";
import Icon from "@src/components/Icon";
import { theme } from "@src/styles/theme";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import styled from "styled-components";

const StyledGoogleMapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.card.border};
  border-radius: 4px;
  overflow: hidden;
`;

type MarkerClustererOptionalType =
  | {
      type: "marker";
      size?: never;
      markers?: never;
    }
  | {
      type: "cluster";
      size: number;
      markers: {
        deviceId: string;
        deviceName: string;
        work: boolean;
        dtrigger: "Y" | "N";
      }[];
      clusterOpen: boolean;
    };

interface MarkerClustererInfoWindowProps {
  position: google.maps.LatLng;
  size: number;
  markers: {
    deviceId: string;
    deviceName: string;
    work: boolean;
    dtrigger: "Y" | "N";
  }[];
}

interface CommonMapProps {
  minZoom?: number;
  maxZoom?: number;
  center?: { lat: number; lng: number };
  markerData?: DeviceLatLngResponse[] | null;
  stopKineticPan?: boolean; // 관성 드래깅 멈추기
  stopDraggable?: boolean; // 지도 드래깅 멈추기
  showControls?: boolean;
  updateClickedMarker: (id: string, name: string) => void;
}
type MarkerTypeProps =
  | {
      showMarker: true;
      markerType: "marker" | "cluster";
    }
  | {
      showMarker?: never;
      markerType?: never;
    };
type MapProps = CommonMapProps & MarkerTypeProps;

const containerStyle = {
  display: "flex",
  flex: 1,
  width: "100%",
};

const defaultCenter = { lat: 37.5434128 + 0.001, lng: 126.9528962 - 0.0012 };
// const defaultCenter = { lat: 36.1313677, lng: -115.1647232 };

function GoogleMapComponent({
  center,
  markerData,
  updateClickedMarker,
}: MapProps) {
  // * Hooks
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
    version: "weekly",
  });

  // * States
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [clusterInfoWindow, setClusterInfoWindow] = React.useState<
    MarkerClustererInfoWindowProps[]
  >([]);
  const [clusterOpens, setClusterOpens] = React.useState<boolean[]>([]);

  // * Functions
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  // 클러스터링 완료됐을 때 호출되는 함수로
  // 화면에 그려지는 마커 클러스터의 데이터를 closeInfoWindows 스테이트에 담아준다.
  const handleOnClusteringEnd = React.useCallback((clusterer: Clusterer) => {
    clusterer.getClusters().forEach((item) => {
      // 마커 클러스터 안보이게하기
      const clusterStyles = item.getClusterer().getStyles();
      clusterStyles.forEach((style) => {
        style.url = ""; // 이미지 경로를 빈 문자열로 설정
        style.height = 0; // 스타일의 크기를 0으로 설정
        style.width = 0;
        style.textSize = 0;
        style.textColor = "transparent";
      });
      item.getClusterer().setStyles(clusterStyles);
    });

    const data = clusterer.getClusters().map((item) => ({
      position: item.getCenter() as google.maps.LatLng,
      size: item.getSize(),
      markers: item.getMarkers().map((marker) => {
        const label = marker.getLabel() as string;

        const segments = label.split("__");
        const resultObject = segments.reduce((acc, segment) => {
          const [key, value] = segment.split(":");

          acc = {
            ...acc,
            [key]: value,
          };
          return acc;
        }, {}) as { [key: string]: any };

        return {
          deviceId: resultObject["deviceId"],
          deviceName: resultObject["deviceName"],
          work: resultObject["work"] === "true" ? true : false,
          dtrigger: resultObject["dtrigger"],
        };
      }),
    }));
    setClusterInfoWindow(data);

    // data의 길이와 clusterOpens의 길이가 같지 않으면 clusterOpens 업데이트
    if (data.length !== clusterOpens.length) {
      setClusterOpens(() => new Array(data.length).fill(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDtriggerDialogOpen = React.useCallback(
    (deviceId: string, deviceName: string) => {
      updateClickedMarker(deviceId, deviceName);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // 디바이스 설치 위치 마커를 google.maps.InfoWindow로 커스텀한다.
  const makeGoogleCustomMarker = React.useCallback<
    (
      data: {
        position: google.maps.LatLng;
        deviceId: string;
        deviceName: string;
        dtrigger: "Y" | "N";
        work: boolean;
        clusterIndex: number;
      } & MarkerClustererOptionalType
    ) => JSX.Element
  >((data) => {
    const handleOnClick = (e: React.MouseEvent | React.KeyboardEvent) => {
      if (data.type === "cluster") {
        setClusterOpens((prev) => {
          const newData = new Array(prev.length).fill(false);

          if (prev[data.clusterIndex] === false) {
            newData[data.clusterIndex] = true;
          }

          return newData;
        });
      } else if (data.type === "marker") {
        // 팝업창 열기
        handleDtriggerDialogOpen(data.deviceId, data.deviceName);
      }
    };

    return (
      <InfoWindowF
        position={data.position}
        options={{
          ariaLabel: "google-info-window-custom",
          disableAutoPan: true,
        }}
        key={data.clusterIndex}
      >
        <>
          {data.type === "cluster" && data.clusterOpen === true ? (
            <div
              className="cluster-info-box-parent"
              style={{
                left: "calc(50% - 20px)",
              }}
            >
              <div className="cluster-info-box">
                {data.markers.map((item, index) => {
                  return (
                    <div
                      key={index}
                      tabIndex={0}
                      role="button"
                      aria-label="dtrigger-dialog-open-button"
                      onClick={() =>
                        handleDtriggerDialogOpen(item.deviceId, item.deviceName)
                      }
                      onKeyDown={(e: React.KeyboardEvent) =>
                        e.key.toLowerCase() === "enter"
                          ? handleDtriggerDialogOpen(
                              item.deviceId,
                              item.deviceName
                            )
                          : undefined
                      }
                    >
                      <div
                        className={`did${
                          item.dtrigger === "Y"
                            ? " warning"
                            : !item.work
                            ? " not-work"
                            : ""
                        }`}
                      >
                        {item.deviceId}
                      </div>
                      <div
                        className={`dname${
                          item.dtrigger === "Y"
                            ? " warning"
                            : !item.work
                            ? " not-work"
                            : ""
                        }`}
                      >
                        {item.deviceName}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div
            role="button"
            aria-label="device-marker-button"
            tabIndex={0}
            className={`marker-icon${
              data.dtrigger === "Y" ? " warning" : !data.work ? " not-work" : ""
            }${
              data.type === "cluster" && data.clusterOpen === true
                ? " cluster-open"
                : ""
            }`}
            style={{
              transform: "translateX(calc(50% - 8px - 12px))",
              cursor: "pointer",
            }}
            onClick={handleOnClick}
            onKeyDown={(e: React.KeyboardEvent) =>
              e.key.toLowerCase() === "enter" ? handleOnClick(e) : undefined
            }
            onMouseEnter={(e) => e.currentTarget.classList.add("cluster-enter")}
            onMouseLeave={(e) =>
              e.currentTarget.classList.remove("cluster-enter")
            }
          >
            <div className="marker-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <g transform="translate(-526 -420)">
                  <rect
                    width="24"
                    height="24"
                    transform="translate(526 420)"
                    fill="rgba(255,255,255,0)"
                  />
                  <path
                    d="M17.981,15.865l.439-.442a9.182,9.182,0,0,0,1.929-2.882A8.927,8.927,0,0,0,21.052,9a8.936,8.936,0,0,0-.7-3.543,9.286,9.286,0,0,0-1.957-2.911L17.94,2.09,20.061.006l.433.443a12.618,12.618,0,0,1,2.559,3.843A11.776,11.776,0,0,1,24,9a11.763,11.763,0,0,1-.947,4.705,12.631,12.631,0,0,1-2.559,3.843L20.051,18ZM3.513,17.548A12.24,12.24,0,0,1,.95,13.731,11.855,11.855,0,0,1,0,9,11.848,11.848,0,0,1,.95,4.263,12.243,12.243,0,0,1,3.513.445L3.955,0,6.022,2.128l-.439.443A9.237,9.237,0,0,0,3.651,5.453,8.991,8.991,0,0,0,2.948,9a8.981,8.981,0,0,0,.7,3.544,9.348,9.348,0,0,0,1.96,2.911l.449.452-2.114,2.08ZM14.766,12.63l.439-.442a4.562,4.562,0,0,0,.947-1.422,4.663,4.663,0,0,0,0-3.537,4.657,4.657,0,0,0-.975-1.451l-.452-.452,2.117-2.077.433.433a7.628,7.628,0,0,1,1.581,2.357A7.408,7.408,0,0,1,19.449,9a7.338,7.338,0,0,1-.593,2.93,8.012,8.012,0,0,1-1.575,2.38l-.442.457ZM6.728,14.313a7.65,7.65,0,0,1-1.581-2.354A7.384,7.384,0,0,1,4.554,9a7.352,7.352,0,0,1,.593-2.958A7.628,7.628,0,0,1,6.728,3.681l.442-.446L9.237,5.367,8.8,5.806a4.619,4.619,0,0,0-.95,4.96,4.62,4.62,0,0,0,.979,1.451l.449.452-2.114,2.08Zm3.227-3.251a2.932,2.932,0,0,1,0-4.129,2.871,2.871,0,0,1,4.09,0,2.925,2.925,0,0,1,0,4.129,2.871,2.871,0,0,1-4.09,0Z"
                    transform="translate(550 441) rotate(180)"
                  />
                </g>
              </svg>
            </div>
            <div className="marker-text">
              <span className="marker-device-id">{data.deviceId}</span>
              <span className="marker-device-alias">{data.deviceName}</span>
              <span
                className="device-work-dtrigger"
                style={{ display: "none" }}
              ></span>
            </div>
            <span className="marker-picker"></span>
            {data.type === "cluster" ? (
              <span className="cluster-count">{`+${data.size}`}</span>
            ) : null}
          </div>
        </>
      </InfoWindowF>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 마커 클러스터 인포 윈도우
  const MarkerClustererInfoWindowsEl = React.useMemo(() => {
    if (clusterInfoWindow.length > 0) {
      return clusterInfoWindow.map((clusterInfo, index) => {
        // clusterInfo.size가 1일 경우 type을 마커로
        // 아닐 경우 type을 클러스터로한다. 또한 클러스터일 경우 work, dtrigger를 마커스 중 하나라도 해당하면 그걸로 넣는다
        return clusterInfo.size === 1
          ? makeGoogleCustomMarker({
              position: clusterInfo.position,
              deviceId: clusterInfo.markers[0].deviceId,
              deviceName: clusterInfo.markers[0].deviceName,
              work: clusterInfo.markers[0].work,
              dtrigger: clusterInfo.markers[0].dtrigger,
              type: "marker",
              clusterIndex: index,
            })
          : makeGoogleCustomMarker({
              position: clusterInfo.position,
              deviceId: clusterInfo.markers[0].deviceId,
              deviceName: clusterInfo.markers[0].deviceName,
              work: clusterInfo.markers.some((item) => item.work === false)
                ? false
                : true,
              dtrigger: clusterInfo.markers.some(
                (item) => item.dtrigger === "Y"
              )
                ? "Y"
                : "N",
              type: "cluster",
              markers: clusterInfo.markers,
              size: clusterInfo.size - 1, // size를 1 +n으로 표시해주기 위해 -1한다.
              clusterOpen: clusterOpens[index],
              clusterIndex: index,
            });
      });
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterInfoWindow, clusterOpens]);

  useEffect(() => {
    if (map) {
      // 지도 내부에서 mousedown할 때 마커 클러스터의 상세 정보 창이 열려있을 경우 닫기
      const mousedownListener = map.addListener(
        "mousedown",
        (event: google.maps.MapMouseEvent) => {
          if (
            clusterOpens.length > 0 &&
            clusterOpens.findIndex((item) => item === true) !== -1
          ) {
            // 하나라도 열려있을 경우 닫기
            setClusterOpens((prev) => new Array(prev.length).fill(false));
          }
        }
      );

      // 모든 클릭 이벤트를 막음
      // 특정 zoom 이상에서 InfoWindow를 클릭하면 다른 InfoWindow 모두를 닫아버리기 때문에
      const clickListerner = map.addListener(
        "click",
        (event: google.maps.MapMouseEvent) => {
          event.stop();
        }
      );

      return () => {
        google.maps.event.removeListener(mousedownListener);
        google.maps.event.removeListener(clickListerner);
      };
    }
  }, [map, clusterOpens]);

  return isLoaded ? (
    <StyledGoogleMapDiv>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center ?? defaultCenter}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {markerData ? (
          <MarkerClustererF
            gridSize={60}
            minimumClusterSize={2}
            children={(clusterer) => {
              return (
                <>
                  {markerData.map((marker, index) => {
                    return (
                      <Marker
                        options={{
                          optimized: true,
                        }}
                        key={index}
                        position={
                          marker.latitude && marker.longitude
                            ? {
                                lat: Number(marker.latitude),
                                lng: Number(marker.longitude),
                              }
                            : defaultCenter
                        }
                        clusterer={clusterer}
                        visible={false}
                        icon=""
                        label={`deviceId:${marker.deviceId}__deviceName:${marker.deviceName}__work:${marker.work}__dtrigger:${marker.dtrigger}`}
                      />
                    );
                  })}
                </>
              );
            }}
            onClusteringEnd={handleOnClusteringEnd}
          ></MarkerClustererF>
        ) : null}
        {MarkerClustererInfoWindowsEl}
      </GoogleMap>
    </StyledGoogleMapDiv>
  ) : (
    <StyledGoogleMapDiv>
      <Icon iconName="loading" iconColor={theme.color.primary} />
    </StyledGoogleMapDiv>
  );
}

export default React.memo(GoogleMapComponent);

import useQueryHooks from "@src/hooks/useQueryHooks";
import useCommonHooks from "@src/hooks/useCommonHooks";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import { NormalCardStyle } from "@src/components/Card/sizeTheme";
import LoadingOnPage from "@src/components/Loadings/LoadingOnPage";
import {
  StyledGridWrap,
  StyledRelativeFullWrap,
} from "@src/styles/commonStyles";
import DeviceStatusTable from "../HomePage/DeviceStateTable";
import { useTranslation } from "react-i18next";

export default function InstallationManagementPage() {
  const { t } = useTranslation();
  const { useDeviceStateShortQuery } = useQueryHooks();
  const { useCustomLoading } = useCommonHooks();

  // * State

  // * QUERY
  const { isFetching, data: deviceStateData } = useDeviceStateShortQuery();

  useCustomLoading();

  return (
    <StyledGridWrap
      templateColumns="inherit"
      templateRows="minmax(100px, 1fr)"
      layoutGridInherit
    >
      <StyledRelativeFullWrap id="chart-wrap" layoutGridColumn="1 / 13">
        {!isFetching ? (
          <DeviceStatusTable
            cardSizeTheme={NormalCardStyle}
            isLoading={isFetching}
            data={deviceStateData ?? null}
            showSizeSelect
            isSearchable
          />
        ) : (
          <DataLoadingText text={t("no_data")} />
        )}
        {isFetching ? (
          <LoadingOnPage parentId="chart-wrap" text={t("loading_data")} />
        ) : null}
      </StyledRelativeFullWrap>
    </StyledGridWrap>
  );
}

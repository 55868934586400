/**
 * 사이즈만 설정하는 테마
 */

import { theme } from "@src/styles/theme";

export interface CheckboxStyleProps {
  boxWidth: number;
  boxHeight: number;
  iconWidth: number;
  iconHeight: number;
  fontSize: string;
  fontWeight: number;
}

export interface InputStyleProps {
  padding: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  fixWidth?: number | string;
  height?: number;
  borderRadius: number;
  fontSize: string;
  fontWeight: number;
  noticeFontSize: string;

  // addon
  addonWidth?: number;
  addonHeight?: number;

  // select
  optionHeight?: number;
  optionFontSize?: string;
  optionFontWeight?: number;

  // date
  rangeDateWidth?: number;
  singleDateWidth?: number;
}

export const NormalInputStyle: InputStyleProps = {
  padding: theme.size.normalInput.padding,
  height: theme.size.normalInput.height,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  borderRadius: theme.size.normalInput.borderRadius,
  noticeFontSize: theme.size.common.fontSize.sm,
  addonWidth: theme.size.normalInput.addonWidth ?? 0,
  addonHeight: theme.size.normalInput.addonHeight ?? 0,
  optionHeight: theme.size.normalInput.optionHeight,
  optionFontSize: theme.size.common.fontSize.base,
  optionFontWeight: theme.size.common.fontWeight.base,
  rangeDateWidth: theme.size.normalInput.rangeDateWidth,
  singleDateWidth: theme.size.normalInput.singleDateWidth,
};

export const BigSizeInputStyles: InputStyleProps = {
  padding: theme.size.bigSizeInput.padding,
  height: theme.size.bigSizeInput.height,
  fontSize: theme.size.common.fontSize.md,
  fontWeight: theme.size.common.fontWeight.lg,
  borderRadius: theme.size.bigSizeInput.borderRadius,
  noticeFontSize: theme.size.common.fontSize.base,
  addonWidth: theme.size.bigSizeInput.addonWidth ?? 0,
  addonHeight: theme.size.bigSizeInput.addonHeight ?? 0,
  optionHeight: theme.size.bigSizeInput.optionHeight,
  optionFontSize: theme.size.common.fontSize.base,
  optionFontWeight: theme.size.common.fontWeight.base,
  rangeDateWidth: theme.size.bigSizeInput.rangeDateWidth,
  singleDateWidth: theme.size.bigSizeInput.singleDateWidth,
};

export const SmallInputStyle: InputStyleProps = {
  padding: theme.size.smallSizeInput.padding,
  height: theme.size.smallSizeInput.height,
  fontSize: theme.size.common.fontSize.xbase,
  fontWeight: theme.size.common.fontWeight.base,
  borderRadius: theme.size.smallSizeInput.borderRadius,
  noticeFontSize: theme.size.common.fontSize.sm,
  addonWidth: theme.size.smallSizeInput.addonWidth ?? 0,
  addonHeight: theme.size.smallSizeInput.addonHeight ?? 0,
  optionHeight: theme.size.smallSizeInput.optionHeight,
  optionFontSize: theme.size.common.fontSize.xbase,
  optionFontWeight: theme.size.common.fontWeight.base,
  rangeDateWidth: theme.size.smallSizeInput.rangeDateWidth,
  singleDateWidth: theme.size.smallSizeInput.singleDateWidth,
};

export const CustomDateYearSelector: InputStyleProps = {
  padding: 4,
  height: 0,
  fontSize: theme.size.common.fontSize.lg,
  fontWeight: theme.size.common.fontWeight.lg,
  borderRadius: theme.size.normalInput.borderRadius,
  noticeFontSize: theme.size.common.fontSize.sm,
  addonWidth: theme.size.normalInput.addonWidth ?? 0,
  addonHeight: theme.size.normalInput.addonHeight ?? 0,
  optionHeight: theme.size.normalInput.optionHeight,
  optionFontSize: theme.size.common.fontSize.base,
  optionFontWeight: theme.size.common.fontWeight.base,
  maxWidth: 100,
};

export const NoramlCheckboxStyle: CheckboxStyleProps = {
  boxWidth: 20,
  boxHeight: 20,
  iconWidth: 12,
  iconHeight: 10,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
};

export const SmallCheckboxStyle: CheckboxStyleProps = {
  boxWidth: 16,
  boxHeight: 16,
  iconWidth: 10,
  iconHeight: 8,
  fontSize: theme.size.common.fontSize.xbase,
  fontWeight: theme.size.common.fontWeight.sm,
};

import React, { ReactNode, createContext, useContext, useState } from "react";
import { ProviderProps } from "./AllContextProvider";

const DialogContext = createContext({
  isNoticeDialogOpen: false,
  noticeTitle: "",
  noticeContent: null as ReactNode,
  handleNoticeDialogOpen: (title: string, content: ReactNode) =>
    new Promise<boolean>((res) => res),
  onNoticeInteractionEnd: (value: boolean) => {},
});

export type ResponseHanlder<T = boolean> = (value: T | PromiseLike<T>) => void;
export function NoticeDialogProvider({ children }: ProviderProps) {
  const [isNoticeDialogOpen, setIsNoticeDialogOpen] = useState(false);
  const [noticeTitle, setNoticeTitle] = useState<string>("");
  const [noticeContent, setNoticeContent] = useState<ReactNode>(null);
  const noticeResolveRef =
    React.useRef<((value: boolean | PromiseLike<boolean>) => void) | null>(
      null
    );

  /**
   * Notice Dialog 컴포넌트 오픈
   * ***
   * @param
   * - title: Notice Dialog 타이틀
   * - content: Notice Dialog 콘텐츠(string, string[])
   * @return
   * - Promise<boolean>
   */
  const handleNoticeDialogOpen = React.useCallback(
    (title: string, content: ReactNode) => {
      setNoticeContent(content);
      setNoticeTitle(title);
      setIsNoticeDialogOpen(true);

      noticeResolveRef.current = null;
      return new Promise<boolean>(async (resolve) => {
        noticeResolveRef.current = resolve;
      });
    },
    []
  );

  /**
   * Notice Dialog 컴포넌트 취소 / 확인 버튼 클릭 시 이벤트
   * ***
   * @param
   * - title: Notice Dialog 타이틀
   * - content: Notice Dialog 콘텐츠(string, string[])
   * @return
   * - Promise<boolean>
   */
  const onNoticeInteractionEnd = React.useCallback((value: boolean) => {
    if (noticeResolveRef.current !== null) {
      noticeResolveRef.current(value);
    }
    setIsNoticeDialogOpen(false);
    setNoticeContent("");
    setNoticeTitle("");
  }, []);

  const value = {
    isNoticeDialogOpen,
    noticeTitle,
    noticeContent,
    handleNoticeDialogOpen,
    onNoticeInteractionEnd,
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
}

export function useNoticeDialog() {
  return useContext(DialogContext);
}

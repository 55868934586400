import React, { ReactNode } from "react";
import FloatBox from "../FloatBox";
import Icon from "../Icon";
import { Div, StyledIconButton } from "@src/styles/commonStyles";
import styled, { css } from "styled-components";
import { theme } from "@src/styles/theme";
import { AnimatePresence, motion } from "framer-motion";
import { SmallFloatBoxStyles } from "../FloatBox/sizeTheme";
import { ELEMENT_Z_INDEX } from "@src/constructs/common";
import useCommonHooks from "@src/hooks/useCommonHooks";

const StyledKeyValueWrap = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;

  li {
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      color: ${({ theme }) => theme.color.font.default};
    }
  }
`;

const StyledKeyColorBox = styled(Div)`
  display: inline-flex;

  padding: 4px;
  color: ${({ theme }) => theme.color.font.default};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};
  background-color: ${({ theme }) =>
    theme.color.floatBox.keyColorBoxBackground};
  border-radius: 2px;
`;

const CSSAbsolute = css<{
  inset?: string;
}>`
  display: block;
  position: absolute;
  inset: ${({ inset }) => inset};
`;

const StyledInputBoxContainer = styled(Div)<{
  iconAlign: string;
  isAbsolute?: boolean;
  inset?: string;
}>`
  display: flex;
  justify-content: ${({ iconAlign }) =>
    iconAlign === "left" ? "flex-start" : "flex-end"};
  align-items: flex-start;
  margin: 2px;
  margin-bottom: 10px;

  ${({ isAbsolute }) => (isAbsolute ? CSSAbsolute : undefined)}
`;

const StyledInfoFloatBoxWrap = styled(Div)`
  display: inline-flex;
  position: relative;
`;
const StyledFloatBoxWrap = styled(motion.div)<{
  open: boolean;
  iconalign: string;
  floatboxalign: string;
  fixwidth?: number;
}>`
  min-width: ${({ fixwidth }) => (fixwidth ? `${fixwidth}px` : "240px")};

  position: absolute;
  top: ${({ floatboxalign }) =>
    floatboxalign === "bottom"
      ? "calc(18px + 15px)"
      : floatboxalign !== "top"
      ? "4px"
      : undefined};
  left: ${({ iconalign, floatboxalign }) =>
    floatboxalign === "right"
      ? "calc(18px + 15px)"
      : iconalign === "left"
      ? 0
      : undefined};
  right: ${({ iconalign, floatboxalign }) =>
    floatboxalign === "left"
      ? "calc(18px + 15px)"
      : iconalign === "right"
      ? 0
      : undefined};
  bottom: ${({ floatboxalign }) =>
    floatboxalign === "top" ? "calc(18px + 15px)" : undefined};
  z-index: ${ELEMENT_Z_INDEX.INFOFLOATBOX};

  transform-origin: ${({ iconalign, floatboxalign }) =>
    iconalign === "left" && floatboxalign === "top"
      ? "left bottom"
      : iconalign === "left"
      ? "left top"
      : floatboxalign === "top"
      ? "right bottom"
      : "right top"};
`;

interface CommonInfoFloatBoxProps {
  title?: string;
  isWarning?: boolean;
  fixWidth?: number;
}

type OptionalExplainChildren =
  | {
      children: ReactNode;

      explainKeyValue?: never;
      explainObject?: never;
      contentText?: never;
    }
  | {
      explainKeyValue: true;
      explainObject: { [key: string]: any };

      children?: never;
      contentText?: never;
    }
  | {
      contentText: string | string[];

      explainKeyValue?: never;
      explainObject?: never;
      children?: never;
    };

type OptionalAlignInfoFloatBoxProps =
  | {
      iconAlign: "left";
      floatBoxAlign: "top" | "bottom" | "right";
    }
  | {
      iconAlign: "right";
      floatBoxAlign: "top" | "bottom" | "left";
    };

type OptionalAbsoluteProps =
  | {
      isAbsolute: true;
      inset: string;
    }
  | {
      isAbsolute?: never;
      inset?: never;
    };

type InfoFloatBoxProps = CommonInfoFloatBoxProps &
  OptionalAlignInfoFloatBoxProps &
  OptionalExplainChildren &
  OptionalAbsoluteProps;

export default function InfoFloatBox({
  title,
  iconAlign,
  floatBoxAlign,
  isWarning,
  children,
  explainKeyValue,
  explainObject,
  contentText,
  fixWidth,
  isAbsolute,
  inset,
}: InfoFloatBoxProps) {
  const { useOutsideClickEffect } = useCommonHooks();
  const [open, setOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  // handle outside click
  useOutsideClickEffect(buttonRef, open, setOpen);

  return (
    <StyledInputBoxContainer
      iconAlign={iconAlign}
      isAbsolute={isAbsolute}
      inset={inset}
    >
      <StyledInfoFloatBoxWrap>
        <StyledIconButton
          type="button"
          onClick={() => setOpen((prev) => !prev)}
          ref={buttonRef}
        >
          <Icon
            iconName="info"
            iconWidth={18}
            iconHeight={18}
            iconColor={isWarning ? theme.color.invalid : theme.color.primary}
            iconHoverColor={
              isWarning ? theme.color.invalid : theme.color.primaryHover
            }
          />
        </StyledIconButton>
        <AnimatePresence mode="wait">
          {open && (
            <StyledFloatBoxWrap
              open={open}
              iconalign={iconAlign}
              floatboxalign={floatBoxAlign}
              fixwidth={fixWidth}
              initial={{
                scale: 0,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 25,
                  stiffness: 400,
                },
              }}
              exit={{
                scale: 0,
                opacity: 0,
                transition: {
                  type: "spring",
                  damping: 25,
                  stiffness: 400,
                },
              }}
            >
              <FloatBox
                sizeTheme={SmallFloatBoxStyles}
                title={title}
                isWarning={isWarning}
                contentText={contentText ? contentText : undefined}
              >
                {children
                  ? children
                  : explainKeyValue &&
                    explainObject && (
                      <StyledKeyValueWrap>
                        {Object.entries(explainObject).map(
                          (item: any, index: number) => (
                            <li key={index}>
                              <StyledKeyColorBox>{item[0]}</StyledKeyColorBox>
                              <span>: {item[1]}</span>
                            </li>
                          )
                        )}
                      </StyledKeyValueWrap>
                    )}
              </FloatBox>
            </StyledFloatBoxWrap>
          )}
        </AnimatePresence>
      </StyledInfoFloatBoxWrap>
    </StyledInputBoxContainer>
  );
}

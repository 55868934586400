import React, { ReactNode, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointStyle,
  ChartDataset,
  ChartOptions,
  LegendItem,
  ChartData,
  Color,
  ChartEvent,
  registerables,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { darkTheme, mediaQuery, theme } from "@src/styles/theme";
import Card from "@src/components/Card";
import { CardSizeProps, NormalCardStyle } from "@src/components/Card/sizeTheme";
import { Chart } from "react-chartjs-2";
import { pngImageDownload } from "@src/hooks/useCommonHooks";
import {
  FlexAlignTypeConst,
  BrowserSizeConst,
  ThemeConst,
} from "@constructs/common";
import Button from "@src/components/Buttons/Button";
import { SmallButtonStyles } from "@src/components/Buttons/sizeTheme";
import { Div, StyledFlexWrap } from "@src/styles/commonStyles";
import { format } from "date-fns";
import "chartjs-adapter-date-fns";
import InfoFloatBox from "@src/components/InfoFloatBox";
import { useBrowserSizeStore, useThemeStore } from "@src/stores/useCommonStore";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";

const StyledChartWrap = styled(Div)`
  display: grid;
  height: 100%;
`;

/**
 * customHeightRatio: width: 100%일때 height를 150%로 설정 하고 싶으면 150
 */
const StyledChartBox = styled(Div)<{
  themeType: ThemeType;
  customHeightRatio?: number; // width: 100%일때 기준으로 쓰기
  fixHeight?: string | number;
  isContentTableChart?: boolean;
  viewLarger?: boolean;
}>`
  flex: ${({ fixHeight, viewLarger }) =>
    fixHeight || viewLarger ? "0 0 auto" : "1"};
  height: ${({ fixHeight, viewLarger, isContentTableChart }) =>
    fixHeight
      ? typeof fixHeight == "string"
        ? fixHeight
        : `${fixHeight}px`
      : isContentTableChart
      ? "100%"
      : viewLarger
      ? "40svh"
      : "undefined"};
  min-height: ${({ viewLarger }) => (viewLarger ? "200px" : "300px")};

  background: ${({ themeType }) =>
    themeType === ThemeConst.Light ? "white" : "#21212C"};
  position: relative;
  overflow: hidden;

  .thumb {
    display: block;
    width: 100%;
    padding-top: ${({ customHeightRatio, isContentTableChart }) =>
      isContentTableChart
        ? "0"
        : customHeightRatio
        ? `${customHeightRatio}%`
        : "90%"};
  }

  .canvas-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${mediaQuery.largeLaptop} {
    /* height 정하는 값이 없을 때 강제로 300px 고정 */
    height: ${({ viewLarger, isContentTableChart }) =>
      !isContentTableChart && !viewLarger ? "300px" : undefined};
  }
`;

interface CommonDefaultLineChartProps {
  chartOptions?: {
    chartTitle: NonNullable<ReactNode>;
    pngFileTitle: string;
    datasets: ChartDataset[];
    labels: string[] | number[];
  };
  chartTitle: NonNullable<ReactNode>;
  pngFileTitle: string;
  chartData: {
    labels: string[] | number[];
    datasets: ChartDataset[];
  };
  viewLarger?: boolean;
  fixHeight?: number | string;
  customOptions?: any;
  isFetching?: boolean;
  fetchingText?: string;
  showPngDownloadButton?: boolean;
  showZoomButton?: boolean;
  showLegendonMobile?: boolean;
  yScaleMin?: number;
  yScaleMax?: number;
  yScalePoint?: number;
  isXScaleTime?: boolean;
  timeUnit?: "second" | "minute" | "hour" | "millisecond";
  customHeightRatio?: number;
  isContentTableChart?: boolean;
  autoScale?: boolean;
  peakFreqIndex?: number[];
  peakOrder?: number[];
}

type DefaultLineChartProps = CommonDefaultLineChartProps & ChartInCardProps;

type ChartInCardProps =
  | ({
      isInCard: true;
      cardSizeTheme?: CardSizeProps;
    } & CloseableComponentProps)
  | {
      isInCard?: false;
      cardSizeTheme?: never;
      showCloseBtn?: never;
      handleClose?: never;
    };

// !! 에러: 빌드 후 웹서버 배포했을 때 "Error: "line" is not a registered controller" 에러 발생
// ** 해결: add register(...registerables)
ChartJS.register(...registerables);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

function DefaultLineChart({
  chartOptions,
  chartTitle,
  pngFileTitle,
  chartData,
  viewLarger,
  isXScaleTime,
  timeUnit,
  isFetching,
  fetchingText,
  isInCard,
  cardSizeTheme,
  showCloseBtn,
  handleClose,
  fixHeight,
  customOptions,
  showZoomButton,
  showPngDownloadButton,
  showLegendonMobile,
  yScaleMin,
  yScaleMax,
  yScalePoint,
  customHeightRatio,
  isContentTableChart,
  autoScale,
  peakFreqIndex,
  peakOrder,
}: DefaultLineChartProps) {
  // Hook & Zustand
  const { t } = useTranslation();
  const themeType = useThemeStore();
  const browserSize = useBrowserSizeStore();
  // const { useThemeToggleChanged } = useCommonHooks();

  const chartRef = React.useRef<ChartJS>(null);
  // const [beforeThemeType, setBeforeThemeType] = React.useState<ThemeType>(
  //   themeType === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
  // );
  // const [isHiddenFalse, setIsHiddenFalse] = React.useState<boolean>(false); // TODO: 상/하한 추가되면 사용

  const data: ChartData = React.useMemo(() => {
    return {
      labels: [...chartData.labels],
      datasets: chartData.datasets.map((item: ChartDataset) => {
        const newData: ChartDataset = { ...item, label: t(item.label ?? "") };
        return newData;
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  // 차트 리사이즈 함수
  const handleWindowResize = () => {
    chartRef.current?.resize();
  };

  // 리셋 줌
  const handleResetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  // png 다운로드
  const handlePngDownload = () => {
    if (chartRef.current) {
      const el = chartRef.current.canvas;
      // const url = chartRef.current.toBase64Image();
      pngImageDownload(el, `${pngFileTitle}.png`);
    }
  };

  // TODO: 상/하한 추가되면 사용
  // const handleStandadMinMaxToggle = () => {
  //   const datasets = chartRef.current?.data.datasets;
  //   if (datasets) {
  //     const minMaxHiddenArray: boolean[] = [];
  //     datasets?.map(
  //       (dataset) =>
  //         dataset.label === "상한" ||
  //         (dataset.label === "하한" &&
  //           minMaxHiddenArray.push(dataset.hidden ?? false))
  //     );

  //     // 히든이 둘다 false일 때 isHiddenfalse = true
  //     // isHiddenFalse가 True면 아이템 둘다 true /아니면 false
  //     const isHiddenFalse = minMaxHiddenArray.every((item) => item === false);

  //     datasets?.map((dataset) => {
  //       if (dataset.label === "상한" || dataset.label === "하한") {
  //         if (isHiddenFalse) {
  //           dataset.hidden = true;
  //         } else {
  //           dataset.hidden = false;
  //         }
  //       }
  //     });

  //     chartRef.current?.update();
  //   }
  // };

  // useThemeToggleChanged(beforeThemeType, setBeforeThemeType, 240);

  // Custom Plugin for Canvas Bg Color
  const customCanvasBackgroundColor = {
    id: "customCanvasBackgroundColor",
    beforeDraw: (
      chart: ChartJS,
      _args: any,
      options: {
        color: string;
      }
    ) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = options.color || "#99ffff";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  // Custom Plugin for Canvas Bg Color
  const customPointLabels = {
    id: "customPointLabels",
    afterDatasetsDraw: function (
      chart: ChartJS,
      _args: any,
      options: { peakFreqIndex?: number[]; peakOrder?: number[] }
    ) {
      const { ctx, chartArea } = chart;
      if (!chartArea) return;

      const meta = chart.getDatasetMeta(0);

      if (options.peakFreqIndex && options.peakOrder) {
        options.peakFreqIndex.forEach((val, index) => {
          const point = meta.data[val];
          const { x, y } = point.getProps(["x", "y"], true);

          ctx.save();
          ctx.fillStyle = theme.color.font.default; // Text color
          ctx.font = "12px Pretendard";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(`${options.peakOrder?.[index]}`, x, y - 12);
          ctx.restore();
        });
      }
    },
  };

  const commonOptions: ChartOptions & {
    plugins: {
      customCanvasBackgroundColor: {
        color: string;
      };
      customPointLabels: { peakFreqIndex?: number[]; peakOrder?: number[] };
    };
  } = React.useMemo(() => {
    return {
      resizeDelay: 0,
      maintainAspectRatio: false,
      animation: false,
      // animation: {
      //   duration: 1000, // 애니메이션 지속 시간 (밀리초)
      //   easing: "linear", // 애니메이션 효과 (linear, easeInOutQuad 등)
      //   // loop: true, // 애니메이션 반복 여부
      // },
      responsive: true,
      layout: {
        padding: 0,
      },
      scales: {
        x: {
          type: isXScaleTime ? "time" : "category",
          ...(isXScaleTime
            ? {
                time: {
                  unit: timeUnit ?? "hour",
                  displayFormats: {
                    hour: "yyyy MM.dd HH:mm",
                    minute: "yyyy MM.dd HH:mm:ss.SSS",
                    second: "yyyy MM.dd HH:mm:ss.SSS",
                  },
                },
              }
            : null),
          ticks: {
            font: {
              size: 11,
            },
            color:
              themeType === ThemeConst.Light
                ? theme.color.chart.ticks.color
                : darkTheme.color.chart.ticks.color,
            callback: (value, index, _ticks) => {
              if (isXScaleTime) {
                if (timeUnit === "millisecond") {
                  value = format(new Date(value), "yyyy MM.dd HH:mm ss.sss");
                } else if (timeUnit === "second") {
                  value = format(new Date(value), "yyyy MM.dd HH:mm:ss");
                } else {
                  value = format(new Date(value), "yyyy MM.dd HH:mm");
                }
                return value.split(" ");
              } else {
                return Number(chartData.labels[index]).toFixed(3);
              }
            },
            maxRotation: 0,
            align: "center",
            autoSkip: true,
            autoSkipPadding: 15,
          },
          // grid: {
          //   color:
          //     themeType === ThemeConst.Light && themeType !== beforeThemeType
          //       ? theme.color.chart.grid.border
          //       : themeType === ThemeConst.Light
          //       ? "transparent"
          //       : themeType === ThemeConst.Dark && themeType !== beforeThemeType
          //       ? darkTheme.color.chart.grid.border
          //       : "transparent",
          // },
          grid: {
            color:
              themeType === ThemeConst.Light
                ? theme.color.chart.grid.border
                : darkTheme.color.chart.grid.border,
          },
        },
        y: {
          beginAtZero: false,
          ticks: {
            color:
              themeType === ThemeConst.Light
                ? theme.color.chart.ticks.color
                : darkTheme.color.chart.ticks.color,
          },
          // grid: {
          //   color:
          //     themeType === ThemeConst.Light && themeType !== beforeThemeType
          //       ? theme.color.chart.grid.border
          //       : themeType === ThemeConst.Light
          //       ? "transparent"
          //       : themeType === ThemeConst.Dark && themeType !== beforeThemeType
          //       ? darkTheme.color.chart.grid.border
          //       : "transparent",
          // },
          grid: {
            color:
              themeType === ThemeConst.Light
                ? theme.color.chart.grid.border
                : darkTheme.color.chart.grid.border,
          },
          min: yScaleMin ?? undefined, // 최소값을 원하는 값으로 설정
          max: yScaleMax ?? undefined, // 최대값을 원하는 값으로 설정
        },
      },
      interaction: {
        intersect: false,
        mode: "nearest",
      },
      plugins: {
        tooltip: {
          position: "nearest",
          callbacks: {
            title: (context) => {
              context[0].formattedValue = String(context[0].parsed.y);

              if (!isXScaleTime) {
                return;
              }

              context[0].label = format(
                new Date(context[0].parsed.x),
                timeUnit === "second"
                  ? "yyyy.MM.dd HH:mm:ss"
                  : timeUnit === "millisecond"
                  ? "yyyy.MM.dd HH:mm:ss.SSS"
                  : "yyyy.MM.dd HH:mm"
              );
            },
          },
        },
        customCanvasBackgroundColor: {
          color: "transparent",
        },
        customPointLabels: {
          peakFreqIndex: peakFreqIndex,
          peakOrder: peakOrder,
        },
        legend: {
          onHover: (e: ChartEvent) => {
            const target = e.native?.target as HTMLCanvasElement;
            target.style.cursor = "pointer";
          },
          onLeave: (e: ChartEvent) => {
            const target = e.native?.target as HTMLCanvasElement;
            target.style.cursor = "default";
          },
          onClick: (_e: ChartEvent, legendItem: LegendItem, legend) => {
            const index: number = legendItem.datasetIndex ?? 0;
            const dataset: ChartDataset = chartRef.current?.data.datasets[
              index
            ] as ChartDataset;

            const value = !dataset.hidden;
            dataset.hidden = value;
            chartRef.current?.update();
          },
          position: "top" as const,
          align: "end" as const,
          labels: {
            boxWidth: 6,
            boxHeight: 6,
            usePointStyle: true,
            padding: 14,
            generateLabels: (chart: ChartJS<"line">) => {
              return chart.data.datasets.map(
                (dataset: ChartDataset<"line">, index: number) => {
                  const meta = chart.getDatasetMeta(index);
                  let legendItem: LegendItem = {
                    text: "",
                  };

                  if (!meta.visible) {
                    legendItem = {
                      text: dataset.label as string,
                      fillStyle: "#aaa",
                      strokeStyle: "#aaa",
                      fontColor: "#aaa",
                      pointStyle: dataset.pointStyle as PointStyle,
                      datasetIndex: index,
                      hidden: !meta.visible,
                    };
                  }

                  legendItem = {
                    text: dataset.label as string,
                    fillStyle: dataset.backgroundColor as Color | undefined,
                    strokeStyle: dataset.borderColor as Color | undefined,
                    fontColor: dataset.borderColor as Color | undefined,
                    pointStyle: dataset.pointStyle as PointStyle,
                    datasetIndex: index,
                    hidden: !meta.visible,
                  };

                  if (
                    !showLegendonMobile &&
                    browserSize === BrowserSizeConst.Mobile
                  ) {
                    legendItem = {
                      ...legendItem,
                      text: "",
                    };
                  }

                  return legendItem;
                }
              );
            },
          },
        },
        zoom: {
          pan: {
            // 마우스로 잡아서 그래프 이동
            enabled: true,
            mode: "xy" as "x" | "xy" | "y",
            modifierKey: "shift",
          },
          // limits: {
          //   y: { min: -1, max: 1 },
          // },
          zoom: showZoomButton
            ? {
                // zoom options and/or events
                pinch: {
                  enabled: true,
                },
                wheel: {
                  enabled: false,
                },
                drag: {
                  enabled: true,
                  borderColor: "rgb(54, 162, 235)",
                  borderWidth: 1,
                  backgroundColor: "rgba(54, 162, 235, 0.3)",
                  // modifierKey: "ctrl",
                },
                mode: "xy" as "x" | "xy" | "y",
              }
            : undefined,
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, peakFreqIndex, peakOrder]);

  const options = React.useMemo(() => {
    return {
      ...commonOptions,
      ...customOptions,
    };
  }, [commonOptions, customOptions]);

  // window resize시 차트 리사이즈
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const commonProps = {
    sizeTheme: cardSizeTheme ?? NormalCardStyle,
    title: chartTitle,
    isLoading: isFetching,
    loadingText: fetchingText,
    headerButtons: showZoomButton ? (
      <InfoFloatBox
        title={t("guide_using_zoom")}
        iconAlign="right"
        floatBoxAlign="bottom"
        explainKeyValue
        explainObject={{
          "Left Mouse Click": t("zoom_in_on_pc"),
          "Shift + Left Mouse Click": t("move_on_pc"),
          Pinch: t("zoom_in_on_mobile"),
          Drag: t("move_on_mobile"),
        }}
      />
    ) : undefined,
  };

  const cardProps = showCloseBtn
    ? {
        ...commonProps,
        showCloseBtn,
        handleClose,
      }
    : {
        ...commonProps,
      };

  return (
    <>
      {isInCard ? (
        <Card {...cardProps}>
          <StyledChartWrap>
            <StyledChartBox
              themeType={themeType}
              customHeightRatio={customHeightRatio}
              isContentTableChart={isContentTableChart}
              viewLarger={viewLarger}
              fixHeight={fixHeight}
            >
              {data.datasets.length > 0 ? (
                <>
                  <div className="thumb" />
                  <div className="canvas-wrapper">
                    <Chart
                      type="line"
                      options={options}
                      data={data}
                      ref={chartRef}
                      plugins={[customCanvasBackgroundColor, customPointLabels]}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </>
              ) : (
                <DataLoadingText text={t("no_data")} />
              )}
            </StyledChartBox>
            {data.datasets.length > 0 &&
            (showPngDownloadButton || showZoomButton) ? (
              <StyledFlexWrap
                align={FlexAlignTypeConst.FlexEnd}
                gap={12}
                style={{ flexWrap: "wrap", marginTop: 20 }}
              >
                {/* <Button
                  sizeTheme={SmallButtonStyles}
                  colorType="primary"
                  type="button"
                  text={isHiddenFalse ? "상/하한 숨기기" : "상/하한 보이기"}
                  onClick={handleStandadMinMaxToggle}
                /> */}
                {showPngDownloadButton ? (
                  <Button
                    sizeTheme={SmallButtonStyles}
                    colorType="primary"
                    type="button"
                    text={t("save_png")}
                    onClick={handlePngDownload}
                  />
                ) : null}
                {showZoomButton ? (
                  <Button
                    sizeTheme={SmallButtonStyles}
                    colorType="primary"
                    type="button"
                    text={t("reset_zoom")}
                    onClick={handleResetZoom}
                  />
                ) : null}
              </StyledFlexWrap>
            ) : null}
          </StyledChartWrap>
        </Card>
      ) : (
        <StyledChartWrap>
          <StyledChartBox
            fixHeight={fixHeight}
            themeType={themeType}
            style={{
              flex: viewLarger || fixHeight ? "0 0 auto" : "1",
              height: viewLarger ? "24vw" : fixHeight ?? undefined,
              minHeight: viewLarger ? "300px" : fixHeight ?? undefined,
            }}
          >
            {data.datasets.length > 0 ? (
              <>
                <div className="thumb" />
                <div className="canvas-wrapper">
                  <Chart
                    type="line"
                    options={options}
                    data={data}
                    ref={chartRef}
                    plugins={[customCanvasBackgroundColor, customPointLabels]}
                    width="100%"
                    height={viewLarger ? "300px" : "100%"}
                  />
                </div>
              </>
            ) : (
              <DataLoadingText text={t("no_data")} />
            )}
          </StyledChartBox>
          {data.datasets.length > 0 &&
          (showPngDownloadButton || showZoomButton) ? (
            <StyledFlexWrap
              align={FlexAlignTypeConst.FlexEnd}
              gap={12}
              style={{ flexWrap: "wrap", marginTop: 30 }}
            >
              {/* <Button
                    sizeTheme={SmallButtonStyles}
                    colorType="primary"
                    type="button"
                    text={isHiddenFalse ? "상/하한 숨기기" : "상/하한 보이기"}
                    onClick={handleStandadMinMaxToggle}
                  /> */}
              {showPngDownloadButton ? (
                <Button
                  sizeTheme={SmallButtonStyles}
                  colorType="primary"
                  type="button"
                  text={t("save_png")}
                  onClick={handlePngDownload}
                />
              ) : null}
              {showZoomButton ? (
                <Button
                  sizeTheme={SmallButtonStyles}
                  colorType="primary"
                  type="button"
                  text={t("reset_zoom")}
                  onClick={handleResetZoom}
                />
              ) : null}
            </StyledFlexWrap>
          ) : null}
        </StyledChartWrap>
      )}
    </>
  );
}

const ChartMemoComponent = React.memo(
  DefaultLineChart,
  (prevProps, nextProps) =>
    // prevProps.chartOptions === nextProps.chartOptions &&
    prevProps.chartTitle === nextProps.chartTitle &&
    prevProps.chartData === nextProps.chartData &&
    prevProps.viewLarger === nextProps.viewLarger &&
    prevProps.isFetching === nextProps.isFetching &&
    prevProps.fetchingText === nextProps.fetchingText
);

export default ChartMemoComponent;

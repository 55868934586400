import Button from "../../Buttons/Button";
import { NormalButtonStyles } from "../../Buttons/sizeTheme";
import RangeDate from "../../Datepickers/RangeDate";
import SingleDate from "../../Datepickers/SingleDate";
import {
  NormalInputStyle,
  SmallCheckboxStyle,
} from "@src/components/Inputs/sizeTheme";
import Select from "@src/components/Inputs/Selects/Select";
import { DateTypeConst, FlexAlignTypeConst } from "@constructs/common";
import { CardSizeProps, NormalCardStyle } from "@src/components/Card/sizeTheme";
import {
  StlyedSearchItemsWrap,
  StyledSearchConditionWrap,
  StyledSensorTypesWrap,
  StyledTableSearchRowWrap,
} from "../styles";
import React from "react";
import Input from "@src/components/Inputs/Input";
import { StyledFlexWrap } from "@src/styles/commonStyles";
import Checkbox from "@src/components/Inputs/Checkbox";
import AllCheckbox from "@src/components/Inputs/AllCheckbox";
import { useTranslation } from "react-i18next";
import useSearchbarHooks, {
  CommonSearchHooksProps,
  OptionalDateType,
} from "@src/hooks/useSearchbarHooks";

const sensorTypes = [
  "AC_X",
  "AC_Y",
  "AC_Z",
  "TI_X",
  "TI_Y",
  "TI_Z",
  "GA_X",
  "GA_Y",
  "GA_Z",
  "GN_X",
  "GN_Y",
  "GN_Z",
  "WS_X",
  "WS_Y",
  "WS_Z",
  "DS_X",
  "DS_Y",
  "DS_Z",
  "DI_X",
  "DI_Y",
  "DI_Z",
];

export interface CommonTableSearchRowProps {
  inCard?: boolean;
  cardSizeTheme?: CardSizeProps;
  layoutGridColumn?: string;
  searchTypeOptions: SelectOption[];
}

export type TableSearchRowProps = CommonTableSearchRowProps &
  OptionalDateType &
  CommonSearchHooksProps;

export default function TableSearchRow({
  searchedDate,
  dateType,
  maxDate,
  minDate,
  inCard,
  cardSizeTheme,
  getUrlParams,
  searchTypeOptions,
  paging,
  setPaging,
  hasContentUrl,
  layoutGridColumn,
}: TableSearchRowProps) {
  // hooks
  const { t } = useTranslation();

  const dateParams = searchedDate
    ? {
        searchedDate,
        dateType,
        minDate,
        maxDate,
      }
    : {};
  const {
    handleSubmit,
    onSubmit,
    onError,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    errors,
    date,
    setDate,
    submitButtonRef,
    selectedSearchType,
    setSelectedSearchType,
    searchValue,
    setSearchValue,
    onSearchValueChange,
  } = useSearchbarHooks({
    ...dateParams,
    getUrlParams,
    paging,
    setPaging,
    hasContentUrl,
    searchedConditionSearch: true,
    searchTypeOptions,
  });

  // state
  const [isCheckedSensorTypes, setIsCheckedSensorTypes] = React.useState<
    boolean[]
  >(new Array(sensorTypes.length).fill(false));

  const handleisCheckedSensorTypesAll = (allChecked: boolean) =>
    setIsCheckedSensorTypes((prev) => {
      const newData = [...prev];
      newData.map((_, i) => (newData[i] = allChecked));
      return newData;
    });

  return (
    <StyledTableSearchRowWrap
      inCard={inCard}
      cardSizeTheme={cardSizeTheme ?? NormalCardStyle}
      align={FlexAlignTypeConst.SpaceBetween}
      columnGap={15}
      rowGap={15}
      marginBottom={inCard ? 24 : 0}
      layoutGridColumn={layoutGridColumn}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <StlyedSearchItemsWrap>
          {searchedDate && (
            <>
              {/* Range or Single */}
              {dateType === DateTypeConst.Range ? (
                <RangeDate
                  sizeTheme={NormalInputStyle}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  isInvalid={errors.endDate?.message !== undefined}
                  errorMessages={[errors.endDate?.message ?? ""]}
                  showChoseDateButtons
                  showLabel
                  labelText={t("period")}
                />
              ) : (
                <SingleDate
                  sizeTheme={NormalInputStyle}
                  date={date}
                  setDate={setDate}
                  isInvalid={errors.date?.message !== undefined}
                  errorMessages={[errors.date?.message ?? ""]}
                  minDate={minDate}
                  maxDate={maxDate}
                  showLabel
                  labelText={t("period")}
                />
              )}
            </>
          )}
          <StyledSearchConditionWrap searchType={selectedSearchType.value}>
            <Select
              sizeTheme={NormalInputStyle}
              name="deviceGroupSelect"
              options={searchTypeOptions}
              value={selectedSearchType}
              event={(value: SelectOption) => {
                setSelectedSearchType(value);

                if (value.value === "") {
                  setSearchValue("");
                }
              }}
              isInvalid={errors.searchType?.message !== undefined}
              errorMessages={[errors.searchType?.message ?? ""]}
              showLabel
              labelText="검색 조건"
              fixWidth={130}
              isClearable
            />
            {selectedSearchType.value === "sensorType" ? (
              <StyledSensorTypesWrap>
                <AllCheckbox
                  sizeTheme={SmallCheckboxStyle}
                  fixWidth={40}
                  name="sensorType"
                  label="All"
                  colorType="secondary"
                  isCheckedArray={isCheckedSensorTypes}
                  handler={(checked) => handleisCheckedSensorTypesAll(checked)}
                />
                <StyledFlexWrap
                  align={FlexAlignTypeConst.FlexStart}
                  gap={12}
                  rowGap={12}
                  style={{
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {sensorTypes.map((item, index) => (
                    <Checkbox
                      key={index}
                      sizeTheme={SmallCheckboxStyle}
                      fixWidth={55}
                      name="sensorType"
                      label={item}
                      colorType="primary"
                      isChecked={isCheckedSensorTypes[index]}
                      handler={() => {
                        setIsCheckedSensorTypes((prev: boolean[]) => {
                          const newData = [...prev];
                          newData[index] = !newData[index];
                          return newData;
                        });
                      }}
                    />
                  ))}
                </StyledFlexWrap>
              </StyledSensorTypesWrap>
            ) : (
              <Input
                inputName="search-value-ip"
                type="text"
                sizeTheme={NormalInputStyle}
                placeholder="검색어를 입력하세요."
                isInvalid={errors.searchValue?.message !== undefined}
                errorMessages={[errors.searchValue?.message ?? ""]}
                eventType="Custom"
                value={searchValue}
                onChange={onSearchValueChange}
              />
            )}
          </StyledSearchConditionWrap>
        </StlyedSearchItemsWrap>
        <Button
          type="submit"
          sizeTheme={NormalButtonStyles}
          colorType="primary"
          text={t("search")}
          buttonRef={submitButtonRef}
        />
      </form>
    </StyledTableSearchRowWrap>
  );
}

import React from "react";
import Icon from "../Icon";
import { theme } from "@src/styles/theme";
import IconButton from "../Buttons/IconButton";
import {
  SmallIconButtonStyles,
  XSmallIconButtonStyles,
} from "../Buttons/sizeTheme";
import { motion } from "framer-motion";
import { BrowserSizeConst, MENU_LIST, SIDEBAR_WIDTH } from "@constructs/common";
import Logo from "@components/Logo";
import UserInfoBox from "@components/UserInfoBox";
import {
  StyledBurgerButton,
  StyledMenu,
  StyledMenuCategory,
  StyledMenuList,
  StyledMenuSummary,
  StyledMotionIconSpan,
  StyledMotionNameSpan,
  StyledMotionSidebar,
  StyledNav,
  StyledSidebar,
  StyledSidebarExpansionButtonWrap,
  StyledSidebarOverflowWrap,
  StyledThemeSwitchWrap,
} from "./styles";
import ThemeTooggle from "../ThemeToggle";
import { StyledMotionSpan } from "@src/styles/commonStyles";
import useCommonHooks from "@src/hooks/useCommonHooks";
import {
  useBrowserSizeStore,
  useCommonStoreActions,
  useIsSummarySidebarStore,
  useUserInfoStore,
} from "@src/stores/useCommonStore";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  isOpenMobileSidebar: boolean;
  setIsOpenMobileSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  activePath: string;
}

const Sidebar = ({
  isOpenMobileSidebar,
  setIsOpenMobileSidebar,
  activePath,
}: SidebarProps) => {
  // zustand & hooks
  const { t } = useTranslation();
  const browserSize = useBrowserSizeStore();
  const userInfo = useUserInfoStore();
  const isSummarySidebar = useIsSummarySidebarStore();
  const { setIsSummarySidebar } = useCommonStoreActions();
  const { useOutsideClickEffect, initializeSearchParams } = useCommonHooks();

  const sidebarRef = React.useRef<HTMLDivElement>(null);

  // framer
  const variants = {
    menuActiveArrowIcon: {
      initial: { x: -20 },
      animate: {
        opacity: 1,
        x: 0,
        transition: {
          x: 0.24,
        },
      },
      exit: {
        opacity: 0,
        x: -20,
        transition: {
          duration: 0,
        },
      },
    },
    sidebar: {
      desktop: {
        width: isSummarySidebar
          ? SIDEBAR_WIDTH.SUMMARY
          : SIDEBAR_WIDTH.EXPANTION,
        left: 0,
      },
      hideInitial: {
        width: SIDEBAR_WIDTH.EXPANTION,
        left: -SIDEBAR_WIDTH.EXPANTION,
      },
      underDesktop: {
        width: SIDEBAR_WIDTH.EXPANTION,
        left: isOpenMobileSidebar
          ? SIDEBAR_WIDTH.ZERO
          : -SIDEBAR_WIDTH.EXPANTION,
      },
    },
    burger: {
      close_0: {
        y: browserSize && browserSize <= BrowserSizeConst.Mobile ? 7 : 9,
        x: 215,
        rotateZ: 45,
        transition: {
          x: {
            delay: 0.3,
          },
          rotateZ: {
            delay: 0.5,
          },
        },
      },
      close_1: {
        x: 215,
        opacity: 0,
        transition: {
          x: {
            delay: 0.3,
          },
          opacity: {
            delay: 0.3,
          },
        },
      },
      close_2: {
        y: browserSize && browserSize <= BrowserSizeConst.Mobile ? -7 : -9,
        x: 215,
        rotateZ: -45,
        transition: {
          y: {
            delay: 0.2,
          },
          x: {
            delay: 0.4,
          },
          rotateZ: {
            delay: 0.6,
          },
        },
      },
      back_0: {
        y: 0,
        x: 0,
        rotateZ: 0,
        transition: {
          x: {
            delay: 0.2,
          },
          y: {
            delay: 0.8,
          },
        },
      },
      back_1: {
        x: 0,
        opacity: 1,
        transition: {
          x: {
            delay: 0.2,
          },
          opacity: {
            delay: 0.2,
          },
        },
      },
      back_2: {
        y: 0,
        x: 0,
        rotateZ: 0,
        transition: {
          y: {
            delay: 0.6,
          },
          x: {
            delay: 0.2,
          },
          rotateZ: {
            delay: 0.1,
          },
        },
      },
    },
  };

  // Menu burger
  const burgerArray = Array.from(Array(3).keys());

  // handle outside click
  useOutsideClickEffect(
    sidebarRef,
    isOpenMobileSidebar,
    setIsOpenMobileSidebar,
    ["sidebar-toggle-btn"]
  );

  // Functions

  const isActivePath = React.useCallback(
    (path: string) => activePath.includes(path),
    [activePath]
  );

  const handleSidebarExpansionButton = React.useCallback(() => {
    setIsSummarySidebar();
  }, [setIsSummarySidebar]);

  const handleBurgerButton = () => setIsOpenMobileSidebar((prev) => !prev);

  return (
    <StyledMotionSidebar
      initial={false}
      animate={
        browserSize && browserSize >= BrowserSizeConst.DesktopSmall
          ? "desktop"
          : "underDesktop"
      }
      variants={variants.sidebar}
      ref={sidebarRef}
    >
      <StyledSidebar isSummarySidebar={isSummarySidebar}>
        <div>
          {browserSize && browserSize <= BrowserSizeConst.Laptop && (
            <StyledBurgerButton
              className="sidebar-toggle-btn"
              onClick={handleBurgerButton}
              isOpenMobileSidebar={isOpenMobileSidebar}
            >
              {burgerArray.map((_item, index) => (
                <motion.div
                  key={index}
                  animate={
                    isOpenMobileSidebar ? `close_${index}` : `back_${index}`
                  }
                  variants={variants.burger}
                />
              ))}
            </StyledBurgerButton>
          )}
          {browserSize && browserSize >= BrowserSizeConst.DesktopSmall && (
            <StyledSidebarExpansionButtonWrap>
              <IconButton
                sizeTheme={
                  isSummarySidebar
                    ? XSmallIconButtonStyles
                    : SmallIconButtonStyles
                }
                colorType="primary"
                iconName={isSummarySidebar ? "arrowRight" : "arrowLeft"}
                onClick={handleSidebarExpansionButton}
              />
            </StyledSidebarExpansionButtonWrap>
          )}
          <Logo type={isSummarySidebar ? "small" : "default"} />
          <div
            style={{
              marginTop: 24,
              marginBottom: isSummarySidebar ? 36 : 40,
            }}
          >
            {!isSummarySidebar && <UserInfoBox />}
          </div>
        </div>
        <StyledSidebarOverflowWrap className="hover-scrollbar">
          <StyledNav isSummarySidebar={isSummarySidebar}>
            <ul>
              {isSummarySidebar
                ? MENU_LIST.map((item, menuListIndex) =>
                    userInfo.menuPermission >= item.categoryPermission ? (
                      <StyledMenuList key={menuListIndex}>
                        <ul>
                          {item.menu.map((menu, menuIndex) => (
                            <motion.li
                              key={menuIndex}
                              initial={false}
                              animate={{
                                scale: isActivePath(menu.activePathStr)
                                  ? [0, 1]
                                  : 1,
                              }}
                            >
                              <StyledMenuSummary
                                to={initializeSearchParams(
                                  menu.path,
                                  menu.paramsKeys,
                                  menu.customParmas,
                                  menu.initDate,
                                  menu.sensorTypeFilter
                                )}
                                className={
                                  isActivePath(menu.activePathStr)
                                    ? "active"
                                    : undefined
                                }
                              >
                                <Icon
                                  iconName={menu.iconName}
                                  iconWidth={24}
                                  iconHeight={24}
                                  iconColor={theme.color.sidebar.menu}
                                />
                              </StyledMenuSummary>
                            </motion.li>
                          ))}
                        </ul>
                      </StyledMenuList>
                    ) : null
                  )
                : MENU_LIST.map((item, menuListIndex) =>
                    userInfo.menuPermission >= item.categoryPermission ? (
                      <StyledMenuList key={menuListIndex}>
                        {item.title && (
                          <StyledMenuCategory>{item.title}</StyledMenuCategory>
                        )}
                        <ul>
                          {item.menu.map((menu, menuIndex) => {
                            return userInfo.menuPermission >=
                              menu.menuPermission ? (
                              <li key={menuIndex}>
                                <StyledMenu
                                  onClick={handleBurgerButton}
                                  to={initializeSearchParams(
                                    menu.path,
                                    menu.paramsKeys,
                                    menu.customParmas,
                                    menu.initDate,
                                    menu.sensorTypeFilter
                                  )}
                                  className={
                                    isActivePath(menu.activePathStr)
                                      ? "active"
                                      : undefined
                                  }
                                >
                                  {
                                    <>
                                      {isActivePath(menu.activePathStr) ? (
                                        <StyledMotionSpan
                                          variants={
                                            variants.menuActiveArrowIcon
                                          }
                                          initial="initial"
                                          animate="animate"
                                          exit="exit"
                                        >
                                          <Icon
                                            iconName="longArrowRight"
                                            iconWidth={20}
                                            iconHeight={12}
                                            iconColor={theme.color.sidebar.menu}
                                          />
                                        </StyledMotionSpan>
                                      ) : null}
                                      <StyledMotionIconSpan
                                        className={
                                          isActivePath(menu.activePathStr)
                                            ? "active"
                                            : undefined
                                        }
                                      >
                                        <Icon
                                          iconName={menu.iconName}
                                          iconWidth={20}
                                          iconHeight={20}
                                          iconColor={theme.color.sidebar.menu}
                                        />
                                      </StyledMotionIconSpan>
                                      <StyledMotionNameSpan
                                        minWidth={
                                          isActivePath(menu.activePathStr)
                                            ? 220 - (14 + 20) * 2
                                            : 220 - (14 + 20)
                                        }
                                        className={
                                          isActivePath(menu.activePathStr)
                                            ? "active"
                                            : undefined
                                        }
                                      >
                                        {t(menu.name)}
                                      </StyledMotionNameSpan>
                                    </>
                                  }
                                </StyledMenu>
                              </li>
                            ) : null;
                          })}
                        </ul>
                      </StyledMenuList>
                    ) : null
                  )}
            </ul>
          </StyledNav>
        </StyledSidebarOverflowWrap>
        <StyledThemeSwitchWrap>
          <ThemeTooggle />
        </StyledThemeSwitchWrap>
      </StyledSidebar>
    </StyledMotionSidebar>
  );
};

const ComponentMemo = React.memo(Sidebar, (prevProps, nextProps) => {
  return (
    prevProps.isOpenMobileSidebar === nextProps.isOpenMobileSidebar &&
    prevProps.setIsOpenMobileSidebar === nextProps.setIsOpenMobileSidebar &&
    prevProps.activePath === nextProps.activePath
  );
});

export default ComponentMemo;

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { User, getIdToken } from "firebase/auth";
import useFirebaseHooks from "./useFirebaseHooks";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import { useTranslation } from "react-i18next";
import AxiosResponseData from "@src/models/AxiosResponseData";

// Config Default 설정
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const axiosCommonConfig: AxiosRequestConfig = {
  validateStatus: function (status: number) {
    // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
    return status < 500;
  },
  baseURL: `${process.env.REACT_APP_ENV === "local" ? "http" : "https"}://${
    process.env.REACT_APP_SERVER_URL
  }`,
  responseType: "json",
};

export type UseAxiosType = (
  method: "POST" | "GET" | "PUT" | "DELETE",
  url: string,
  params?: object,
  config?: object,
  data?: object,
  customContentType?: string
) => Promise<any>;

export type UseWebSocketType = (
  url: string,
  params?: object,
  onmessage?: (event: MessageEvent<any>) => void,
  onclose?: (event: CloseEvent) => void,
  onerror?: (event: Event) => void
) => Promise<WebSocket>;

export default function useNetworkHooks(): {
  useAxios: UseAxiosType;
  useWebSocket: UseWebSocketType;
} {
  const { t } = useTranslation();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { getAsyncFirebaseUser, handleForceLogout } = useFirebaseHooks();

  /**
   * 인증 토큰을 확인하고(이상한 경우 강제 로그아웃 등) HTTP 요청을 보내는 함수
   *
   * @param {"POST" | "GET"} method - HTTP 메소드 (GET, POST)
   * @param {string} url - 요청을 보낼 엔드포인트 URL
   * @param {object?} params - 요청 시 함께 보낼 데이터
   * @param {object?} config - 요청 시 수정/추가 하고싶은 axios config
   *
   * @returns {Promise<any>} - 서버 응답 데이터를 담은 Promise 객체
   * @throws {Error} - 요청에 실패하거나 응답이 오류 상태인 경우 발생하는 예외
   */
  const useAxios: UseAxiosType = async (
    method: "POST" | "GET" | "PUT" | "DELETE",
    url: string,
    params?: AxiosRequestConfig,
    config?: AxiosRequestConfig,
    data?: object,
    customContentType?: string
  ): Promise<any> => {
    try {
      // 비동기적으로 사용자 정보 가져오기
      const user: User = await getAsyncFirebaseUser();

      if (user) {
        const token = await getIdToken(user);

        if (token) {
          // * api 호출
          const response: AxiosResponse<
            ReturnResponseType,
            AxiosRequestConfig
          > = await axios({
            method,
            url,
            params: {
              uid: user.uid,
              ...params,
            },
            ...axiosCommonConfig,
            ...config,
            data,
            headers: {
              Authorization: `Bearer ${token}`,
              ...(customContentType
                ? { "Content-Type": customContentType }
                : null),
            },
          })
            .then((result) => result)
            .catch((e) => {
              // 통신 보내기 전 axios 에러, 뭔가 잘못 입력했을 수 있다.
              throw Error(e);
            });

          const result = new AxiosResponseData(response);

          if (response.status >= 200 && response.status < 300) {
            // * status가 200 이상, 300 미만으로 데이터를 성공적으로 받았을 때
            // response 에러 발생 X
            if (
              response &&
              response.config &&
              response.config.responseType &&
              response.config.responseType.toLowerCase() === "arraybuffer"
            ) {
              const disposition = response.headers["content-disposition"]; // Content-Disposition 헤더 가져오기
              const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
                disposition
              );
              let filename = "";
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, ""); // 파일명 추출
              }
              return {
                name: filename,
                file: response.data,
              };
            }
            return result.data;
          } else if (response.status >= 300 && response.status < 400) {
            // * status가 300 이상, 400 미만으로 리다이렉션이 되어야할 때?
            throw Error(result.errors?.message);
          } else if (response.status >= 400) {
            // * status가 400 이상으로 에러가 발생했을 때
            // 에러메세지가 search_failed일 경우 테스트 기기의 통계 데이터 차트가 DB에 없을 때 였다.
            // no_result는 디바이스 리스트 못불러올 때 인데 react-query 실패 에러 팝업 띄워주기 위해 무시한다.
            if (
              // result.errors.message.toLowerCase() === "no_result" ||
              result?.errors?.message.toLowerCase() === "search_failed"
            ) {
              return null;
            } else if (
              result?.errors?.message.toLowerCase().includes("token revoked")
            ) {
              // 해당 토큰이 취소된 에러 - 강제 로그아웃
              handleForceLogout();
            } else if (
              result?.errors?.message.toLowerCase().includes("disabled user")
            ) {
              // 제한된 유저 - 강제 로그아웃
              handleForceLogout(
                t("disabled_user_title"),
                t("disabled_user_message")
              );
            } else if (
              result?.errors?.message.toLowerCase().includes("token is invalid")
            ) {
              // 유효하지 않은 토큰으로 다시 시도하도록 유도
              // getIdToken의 토큰이 업데이트 될 거라 생각 중
              handleAlertDialogOpen(
                t("invalid_token_title"),
                t("invalid_token_message")
              );
            } else {
              throw Error(result.errors?.message);
            }
          }

          // 데이터 리턴
          return result.data;
        }
      }
    } catch (error: any) {
      // axios 라이브러리 오류 처리
      if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      }

      if (
        error.message.replaceAll(" ", "_").includes("has_no_right_to_accese")
      ) {
        // 데이터 없다고 리턴
        return null;
      }

      throw Error(error.message);
    }
  };

  /**
   * 인증 토큰을 확인하고(이상한 경우 강제 로그아웃 등) HTTP 요청을 보내는 함수
   *
   * @param {string} url - 요청을 보낼 엔드포인트 URL
   * @param {object?} params - 요청 시 함께 보낼 데이터
   * @param {void?} onmessage - 웹소켓 연결 후 onmessage 콜백 발생 시 실행하려는 함수
   * @param {void?} onclose - 웹소켓 연결 후 onclose 콜백 발생 시 실행하려는 함수
   * @param {void?} onerror - 웹소켓 연결 후 onerror 콜백 발생히 실행하려는 함수
   *
   * @returns {Promise<any>} - 서버 응답 데이터를 담은 Promise 객체
   * @throws {Error} - 요청에 실패하거나 응답이 오류 상태인 경우 발생하는 예외
   */
  const useWebSocket: UseWebSocketType = async (
    url,
    params,
    onmessage,
    onclose,
    onerror
  ): Promise<any> => {
    try {
      // 비동기적으로 사용자 정보 가져오기
      const user: User = await getAsyncFirebaseUser();

      if (user) {
        const token = await getIdToken(user);

        if (token) {
          // url_params 생성 - 토큰, uid, params 합성
          let url_params = `tk=${token}&uid=${user.uid}`;
          if (params) {
            url_params += Object.entries(params).reduce(
              (acc: string, [key, value]) => {
                if (value !== "") {
                  acc += `&${key}=${value}`;
                }
                return acc;
              },
              ""
            );
          }

          // * api 호출
          const ws = new WebSocket(
            `${process.env.REACT_APP_ENV === "local" ? "ws" : "wss"}://${
              process.env.REACT_APP_SERVER_URL
            }/${url}?${url_params}`
          );

          // 웹 소켓 메세지 설정
          ws.onmessage = (event) => {
            if (onmessage) {
              onmessage(event);
            } else {
              console.log(
                "There is no onmessage func. you have to add onmessage param on your useWebSocket hook"
              );
            }
          };

          // 웹 소켓 연결 에러 핸들러 설정
          ws.onerror = (error) => {
            console.error("WebSocket 연결 에러:", error);

            if (onerror) {
              console.error("custom oneerror가 존재합니다.");
              onerror(error);
            } else {
              throw Error("Failed to connect websocket");
            }
          };

          ws.onclose = (event) => {
            if (onclose) {
              onclose(event);
            }
          };

          // 데이터 리턴
          return ws;
        }
      }
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return {
    useAxios,
    useWebSocket,
  };
}

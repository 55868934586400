import OptionalDeviceSearchRow from "@components/Searchbar/OptionalDeviceSearchRow";
import { NormalCardStyle } from "@components/Card/sizeTheme";
import React, { useEffect } from "react";
import useQueryHooks from "@src/hooks/useQueryHooks";
import { useBrowserSizeStore } from "@src/stores/useCommonStore";
import {
  StyledFlexWrap,
  StyledGridWrap,
  StyledRelativeFullWrap,
  StyledTablePaginationWrap,
} from "@src/styles/commonStyles";
import LoadingOnPage from "@src/components/Loadings/LoadingOnPage";
import Card from "@src/components/Card";
import Table from "@src/components/Table";
import Pagination from "@src/components/Pagination";
import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import IconButton from "@src/components/Buttons/IconButton";
import {
  NormalIconButtonStyles,
  XSmallNoSpaceIconButtonStyles,
} from "@src/components/Buttons/sizeTheme";
import InfoFloatBox from "@src/components/InfoFloatBox";
import {
  BrowserSizeConst,
  FlexAlignTypeConst,
  initialPaging,
} from "@src/constructs/common";
import { useMutation } from "@tanstack/react-query";
import useFetchMenagementData, {
  CreateScoreReportRequestParams,
} from "@src/fetch/fetchManagement";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";
import Loading from "@src/components/Loadings/Loading";
import fileSaver from "file-saver";
import useFilesDownloadHooks from "@src/hooks/useFilesDownloadHooks";
import { useTranslation } from "react-i18next";

const TableHeadRaw = [
  { value: "device", label: "device" },
  { value: "file_name", label: "file_name" },
  { value: "date", label: "date" },
];

const TableHeadReport = [...TableHeadRaw, { value: "svae", label: "save" }];

const customGridColumnsMinMaxArrayRaw = [
  {
    min: "85px",
    max: "85px",
  },
  {
    min: "210px",
    max: "1fr",
  },
  {
    min: "160px",
    max: "240px",
  },
];

const customGridColumnsMinMaxArrayReport = [
  {
    min: "85px",
    max: "85px",
  },
  {
    min: "340px",
    max: "1fr",
  },
  {
    min: "160px",
    max: "240px",
  },
  {
    min: "60px",
    max: "100px",
  },
];

interface CommonCardTableProps {
  data: FileListResponse | null;
  isLoading: boolean;
  paging: PagingType;
  setPaging: (paging: PagingType) => void;
}
interface ScoreReportRawProps extends CommonCardTableProps {
  handleScoreReportRefetch: () => void;
}

function ScoreReportRawCardTable({
  data,
  isLoading,
  paging,
  setPaging,
  handleScoreReportRefetch,
}: ScoreReportRawProps) {
  // * State
  const { t } = useTranslation();
  const [isCheckedRow, setIsCheckedRow] = React.useState<boolean[]>([]);

  // * Hooks
  const { handleAlertDialogOpen } = useAlertDialog();
  const { handleNoticeDialogOpen } = useNoticeDialog();
  const browserSize = useBrowserSizeStore();
  const { handleCheckbox } = useCommonHooks();
  const { FetchInstallerCreateScoreReport } = useFetchMenagementData();

  // * Query
  const {
    mutate: createScoreReportMutate,
    isLoading: isLoadingCreateScoreReportMutate,
  } = useMutation<
    FileResponse | null,
    unknown,
    CreateScoreReportRequestParams,
    unknown
  >(async (params) => FetchInstallerCreateScoreReport(params), {
    onSuccess: (val) => {
      if (val) {
        const name = val.name.includes("__already_created")
          ? val.name.replace("__already_created", "")
          : val.name;
        const file = new Blob([val.file as ArrayBuffer], {
          type: "application/octet-stream",
        });
        fileSaver.saveAs(file, name);

        if (val.name.includes("__already_created")) {
          handleAlertDialogOpen(
            t("notification"),
            t("already_created_score_report")
          );
        } else {
          handleAlertDialogOpen(t("notification"), [t("score_report_created")]);
          handleScoreReportRefetch();
        }
      }
    },
    onError: (e) => {
      console.log("createScoreReportMutate error", e);
    },
  });

  // * Functions

  // 성적서 생성 버튼 핸들러
  const handleCreateScoreReportButton = React.useCallback(async () => {
    const agree = await handleNoticeDialogOpen(
      t("agree_installer_create_score_report_title"),
      t("agree_installer_create_score_report_message")
    );

    if (agree) {
      const indices = isCheckedRow.reduce(
        (acc: number[], item: boolean, index: number) => {
          if (item === true) {
            acc.push(index);
          }
          return acc;
        },
        []
      );

      const fileKeys = indices
        .map((i) => data?.fileList[i].fileKey)
        .reverse()
        .join(",");

      const params: CreateScoreReportRequestParams = {
        deviceId: data?.fileList[0].deviceId ?? "",
        fileKeys: fileKeys,
      };

      createScoreReportMutate(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isCheckedRow]);

  // * Effects
  // data가 변경되면 data 개수에 맞춰서 isCheckedRow 초기화
  useEffect(() => {
    if (data) {
      setIsCheckedRow(new Array(data.fileList.length).fill(false));
    } else {
      setIsCheckedRow([false]);
    }
  }, [data]);

  return (
    <>
      {isLoadingCreateScoreReportMutate ? <Loading /> : null}
      <Card
        sizeTheme={NormalCardStyle}
        layoutGridColumn={
          browserSize && browserSize >= BrowserSizeConst.DesktopSmall
            ? "1 / 7"
            : "1 / 13"
        }
        title={`${t("score_report_raw")} ${t("file_list")}`}
        headerButtons={
          <>
            <IconButton
              sizeTheme={NormalIconButtonStyles}
              colorType="primary"
              text={t("create_score_report_with_files")}
              iconName="saveAll"
              onClick={handleCreateScoreReportButton}
              isDisabled={
                isCheckedRow.filter((item) => item === true).length < 2
              }
            />
            <InfoFloatBox
              title={t("how_to_create_score_report_title")}
              iconAlign="left"
              floatBoxAlign="right"
              fixWidth={136}
              contentText={t("how_to_create_score_report_message")}
              isAbsolute
              inset="4px auto auto 4px"
            />
          </>
        }
      >
        <StyledTablePaginationWrap>
          <Table
            colorType="primary"
            head={TableHeadRaw}
            body={
              data
                ? data.fileList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.deviceId}</td>
                      <td>{item.fileKey}</td>
                      <td>{item.date}</td>
                    </tr>
                  ))
                : [<></>]
            }
            isLoading={isLoading}
            customGridColumnsMinMaxArray={customGridColumnsMinMaxArrayRaw}
            isCheckableRow
            checkboxName="score-report-raw"
            isCheckedRow={isCheckedRow}
            handleCheckedRow={(index: number) =>
              handleCheckbox(
                index,
                setIsCheckedRow,
                2,
                "creating_report_requires_use_of_two_files"
              )
            }
          />
          {data && data.count > 0 ? (
            <Pagination
              count={data?.count ?? 1}
              page={paging.page}
              size={paging.size}
              setPaging={setPaging}
              elementSize="normal"
              showSizeSelect
            />
          ) : null}
        </StyledTablePaginationWrap>
      </Card>
    </>
  );
}

function ScoreReportCardTable({
  data,
  isLoading,
  paging,
  setPaging,
}: CommonCardTableProps) {
  // State
  const { t } = useTranslation();
  const [isCheckedRowReport, setIsCheckedRowReport] = React.useState<boolean[]>(
    []
  );

  // Hooks
  const browserSize = useBrowserSizeStore();
  const { handleCheckbox } = useCommonHooks();
  const { handleFilesDownloadButton, isGetS3FilesMutateLoading } =
    useFilesDownloadHooks("scoreReport", data, isCheckedRowReport);

  useEffect(() => {
    if (data) {
      setIsCheckedRowReport(new Array(data.fileList.length).fill(false));
    } else {
      setIsCheckedRowReport([false]);
    }
  }, [data]);

  return (
    <>
      {isGetS3FilesMutateLoading ? <Loading /> : null}
      <Card
        sizeTheme={NormalCardStyle}
        layoutGridColumn={
          browserSize && browserSize >= BrowserSizeConst.DesktopSmall
            ? "7 / 13"
            : "1 / 13"
        }
        title={`${t("score_report")} ${t("file_list")}`}
        headerButtons={
          <IconButton
            sizeTheme={NormalIconButtonStyles}
            colorType="primary"
            text={t("save_selected_file")}
            iconName="saveAll"
            onClick={() => handleFilesDownloadButton()}
            isDisabled={
              isGetS3FilesMutateLoading ||
              isCheckedRowReport.every((item) => !item)
            }
          />
        }
      >
        <StyledTablePaginationWrap>
          <Table
            colorType="primary"
            head={TableHeadReport}
            body={
              data
                ? data.fileList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.deviceId}</td>
                      <td>{item.fileKey}</td>
                      <td>{item.date}</td>
                      <td>
                        <StyledFlexWrap align={FlexAlignTypeConst.FlexStart}>
                          <IconButton
                            sizeTheme={XSmallNoSpaceIconButtonStyles}
                            colorType="grayToPrimaryNoBg"
                            iconName="save"
                            onClick={() =>
                              handleFilesDownloadButton(item.fileKey)
                            }
                            isDisabled={isGetS3FilesMutateLoading}
                          />
                        </StyledFlexWrap>
                      </td>
                    </tr>
                  ))
                : [<></>]
            }
            isLoading={isLoading}
            customGridColumnsMinMaxArray={customGridColumnsMinMaxArrayReport}
            isCheckableRow
            checkboxName="score-report"
            isCheckedRow={isCheckedRowReport}
            handleCheckedAll={(bool: boolean) =>
              handleCheckbox(bool, setIsCheckedRowReport)
            }
            handleCheckedRow={(index: number) =>
              handleCheckbox(index, setIsCheckedRowReport)
            }
          />
          {data && data.count > 0 ? (
            <Pagination
              count={data?.count ?? 1}
              page={paging.page}
              size={paging.size}
              setPaging={setPaging}
              elementSize="normal"
              showSizeSelect
            />
          ) : null}
        </StyledTablePaginationWrap>
      </Card>
    </>
  );
}

export default function ScoreReportPage() {
  // * Hooks
  const { t } = useTranslation();
  const { useScoreReportQuery } = useQueryHooks();
  const { useCustomLoading } = useCommonHooks();
  useCustomLoading(); // use Custom Loading

  // * State
  const [isLoadingBySearch, setIsLoadingBySearch] =
    React.useState<boolean>(false);
  const [pagingRaw, setPagingRaw] = React.useState<PagingType>(initialPaging);
  const [pagingReport, setPagingReport] =
    React.useState<PagingType>(initialPaging);
  const [paramsRaw, setParamsRaw] =
    React.useState<PagingDeivceIdRequestParams | null>(null);
  const [paramsReport, setParamsReport] =
    React.useState<PagingDeivceIdRequestParams | null>(null);
  const [searchedDeviceId, setSearchedDeviceId] = React.useState<string>("");

  // * QUERIES
  const { isFetching: isFetchingScoreReportRaw, data: scoreReportRawData } =
    useScoreReportQuery(
      paramsRaw as PagingDeivceIdRequestParams,
      "scoreReportRaw"
    );
  const {
    isFetching: isFetchingScoreReport,
    data: scoreReportData,
    refetch: refetchScoreReport,
  } = useScoreReportQuery(
    paramsReport as PagingDeivceIdRequestParams,
    "scoreReport"
  );

  const handleScoreReportRefetch = React.useCallback(
    () => {
      refetchScoreReport();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // * Functions
  // 서치바 검색 함수
  const getUrlParams = React.useCallback(
    (data: SearchParameterValues | null) => {
      if (data && data.deviceId) {
        setSearchedDeviceId(data.deviceId);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // searchedDeviceId => 서치 파라미터 업데이트
  useEffect(() => {
    if (searchedDeviceId) {
      if (searchedDeviceId === paramsRaw?.deviceId) {
        return;
      }

      setIsLoadingBySearch(true);
      setParamsRaw({
        deviceId: searchedDeviceId ?? "",
        page: pagingRaw.page,
        size: pagingRaw.size,
      });

      setParamsReport({
        deviceId: searchedDeviceId ?? "",
        page: pagingReport.page,
        size: pagingReport.size,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedDeviceId]);

  // 페이징 => 서치 파라미터 업데이트
  useEffect(() => {
    if (paramsRaw !== null) {
      setParamsRaw((prev) => {
        const newData = { ...prev } as PagingDeivceIdRequestParams;
        newData.size = pagingRaw.size;
        newData.page = pagingRaw.page;
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingRaw]);

  // 페이징 => 서치 파라미터 업데이트
  useEffect(() => {
    if (paramsReport !== null) {
      setParamsReport((prev) => {
        const newData = { ...prev } as PagingDeivceIdRequestParams;
        newData.size = pagingReport.size;
        newData.page = pagingReport.page;
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingReport]);

  // 서치 로딩 false
  useEffect(() => {
    if (
      (!isFetchingScoreReport && scoreReportData) ||
      (!isFetchingScoreReportRaw && scoreReportRawData)
    ) {
      setIsLoadingBySearch(false);
    }
  }, [
    scoreReportData,
    scoreReportRawData,
    isFetchingScoreReportRaw,
    isFetchingScoreReport,
  ]);

  return (
    <StyledGridWrap
      templateColumns="inherit"
      templateRows="max-content minmax(400px, 1fr)"
      columnGap={36}
      layoutGridInherit
    >
      {/* SearchRow */}
      <OptionalDeviceSearchRow
        inCard
        cardSizeTheme={NormalCardStyle}
        getUrlParams={getUrlParams}
        layoutGridColumn="1 / 13"
      />
      <StyledRelativeFullWrap id="chart-wrap" layoutGridInherit>
        <ScoreReportRawCardTable
          data={scoreReportRawData ?? null}
          isLoading={isFetchingScoreReportRaw}
          paging={pagingRaw}
          setPaging={setPagingRaw}
          handleScoreReportRefetch={handleScoreReportRefetch}
        />
        <ScoreReportCardTable
          data={scoreReportData ?? null}
          isLoading={isFetchingScoreReport}
          paging={pagingReport}
          setPaging={setPagingReport}
        />
        {/* 로딩 */}
        {isLoadingBySearch ? (
          <LoadingOnPage parentId="chart-wrap" text={t("loading_data")} />
        ) : null}
      </StyledRelativeFullWrap>
    </StyledGridWrap>
  );
}

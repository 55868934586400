import { StyledMotionSpan } from "@src/styles/commonStyles";
import { motion } from "framer-motion";
import styled from "styled-components";
import Icon from "../Icon";
import React from "react";
import useCommonHooks from "@src/hooks/useCommonHooks";
import {
  useCommonStoreActions,
  useThemeStore,
} from "@src/stores/useCommonStore";
import { ThemeConst } from "@src/constructs/common";

export const StyledThemeSwitch = styled.button<{ themeType: ThemeType }>`
  display: flex;
  align-items: center;
  width: 46px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 12px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryHover};
  }

  & > * {
    position: absolute;
  }

  &[aria-disabled="true"] {
    pointer-events: none;
    user-select: none;
  }
`;

export const StyledCircle = styled(motion.span)`
  display: block;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
`;

export default function ThemeTooggle() {
  // zustand & hook
  const themeType = useThemeStore();
  const { setTheme } = useCommonStoreActions();
  const { useThemeToggleChanged } = useCommonHooks();

  const handleThemeButton = () =>
    setTheme(
      themeType === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
    );

  const [beforeThemeType, setBeforeThemeType] = React.useState<ThemeType>(
    themeType === ThemeConst.Light ? ThemeConst.Dark : ThemeConst.Light
  );

  useThemeToggleChanged(beforeThemeType, setBeforeThemeType, 500);

  return (
    <StyledThemeSwitch
      onClick={handleThemeButton}
      themeType={themeType}
      className="theme-toggle-btn"
      aria-disabled={themeType === beforeThemeType}
    >
      <StyledMotionSpan
        key="moving"
        initial={false}
        animate={{
          x: themeType === ThemeConst.Light ? 5 : 46 - 12 - 7,
        }}
        transition={{
          duration: 0.44,
        }}
      >
        {themeType === ThemeConst.Light ? (
          <StyledMotionSpan
            key="light"
            initial={false}
            animate={{
              opacity: [0, 1],
              transition: {
                ease: "easeIn",
                opacity: {
                  duration: 0.34,
                },
              },
            }}
          >
            <Icon
              iconName="light"
              iconWidth={16}
              iconHeight={16}
              iconColor="#FFF3B4"
            />
          </StyledMotionSpan>
        ) : (
          <StyledMotionSpan
            key="dark"
            initial={false}
            animate={{
              opacity: [0, 1],
              transition: {
                ease: "easeIn",
                opacity: {
                  duration: 0.34,
                },
              },
            }}
          >
            <Icon
              iconName="dark"
              iconWidth={14}
              iconHeight={14}
              iconColor="#FFF3B4"
            />
          </StyledMotionSpan>
        )}
      </StyledMotionSpan>
      <StyledCircle
        initial={false}
        animate={{ x: themeType === ThemeConst.Light ? 46 - 12 - 5 : 5 }}
        transition={{
          duration: 0.44,
        }}
      />
    </StyledThemeSwitch>
  );
}

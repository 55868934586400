import { ELEMENT_Z_INDEX } from "@src/constructs/common";
import { Div } from "@src/styles/commonStyles";
import styled from "styled-components";

export const StyledEventDimWrap = styled(Div)`
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 60px 20px;
  background: ${({ theme }) => theme.color.event.dimBackground};

  position: fixed;
  top: 0px;
  right: 0px;
  z-index: ${ELEMENT_Z_INDEX.FLOATEVENT};

  user-select: none;
  pointer-events: none;
`;

export const StyledEventFixWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 24px;
  bottom: 24px;
  user-select: auto;
  pointer-events: auto;
`;

export const EventParentWrap = styled(Div)<{ childLength: number }>`
  width: 100%;
  height: 100%;
  min-height: ${({ childLength }) =>
    childLength > 1 && childLength <= 2 ? "158px" : "166px"};
  position: relative;

  & > div {
    align-self: flex-end;
    width: 220px;
    display: flex;
    flex-direction: "column";
    gap: 10px;

    position: absolute;

    & > div {
      width: 100%;
    }
  }

  & > div:nth-child(1) {
    right: 10px;
    bottom: 10px;
  }

  & > div:nth-child(2) {
    right: 18px;
    bottom: 18px;
  }

  & > div:nth-child(3) {
    right: 26px;
    bottom: 26px;
  }
`;

export const StyledMessageTextWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: ${({ theme }) => theme.size.common.fontSize.xmd};
  line-height: 1.2;
`;
export const StyledDataTextWrap = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: ${({ theme }) => theme.size.common.fontSize.xbase};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.base};
  line-height: 1.1;
`;

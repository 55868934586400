import { StyledBgButtonWrap } from "@src/components/Buttons/styles";
import { CardSizeProps } from "@src/components/Card/sizeTheme";
import {
  StyledDateWithLabelWrap,
  StyledDateWrap,
  StyledRangeDateWithButtonsWrap,
} from "@src/components/Datepickers/styles";
import { StyledSelectWithLabelWrap } from "@src/components/Inputs/styles";
import { Div, StyledPrimaryLabel } from "@src/styles/commonStyles";
import { mediaQuery } from "@src/styles/theme";
import styled, { css } from "styled-components";

export const CssCard = css<{ cardSizeTheme: CardSizeProps }>`
  padding: ${({ cardSizeTheme }) => `16px ${cardSizeTheme.paddingLR}px`};
  width: 100%;
  background: ${({ theme }) => theme.color.card.background};
  border: 1px solid ${({ theme }) => theme.color.card.border};
  border-radius: 12px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.color.card.boxShadow};

  ${mediaQuery.mobile} {
    padding: 15px;
  }
`;

export const StlyedSearchItemsWrap = styled(Div)`
  display: flex;
  justify-content: flex-start;
  column-gap: 40px;
  row-gap: 15px;
  flex-wrap: wrap;
`;

export const StyledSelectsWrap = styled(Div)`
  display: flex;
  justify-content: flex-start;
  column-gap: 40px;
  row-gap: 15px;
  flex-wrap: wrap;
`;

export const StyledSearchRowCommonWrap = styled(Div)<{
  inCard?: boolean;
  cardSizeTheme: CardSizeProps;
  align: FlexAlignType;
  columnGap?: number;
  rowGap?: number;
  marginBottom?: number;
}>`
  & > form {
    display: flex;
    justify-content: ${({ align }) => align};
    align-items: flex-start;
    column-gap: ${({ columnGap }) => columnGap ?? 40}px;
    row-gap: ${({ rowGap }) => rowGap ?? 20}px;
  }

  ${({ inCard }) => inCard && CssCard};

  margin-bottom: ${({ marginBottom }) => `${marginBottom}px` ?? 0};
`;

export const StyledSearchConditionWrap = styled(Div)<{
  searchType: string;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: ${({ searchType }) => (searchType === "sensorType" ? 20 : 8)}px;
  row-gap: 10px;
  flex-wrap: wrap;
  max-width: 100%;

  @media screen and (max-width: 600px) {
    justify-content: flex-end;
    width: 100%;

    & > div:nth-child(2) {
      width: calc(100% - 60px - 12px);
    }
  }
`;

export const StyledSensorTypesWrap = styled(Div)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px 12px;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  min-width: 400px;
`;

export const StyledSearchRowWrap = styled(StyledSearchRowCommonWrap)`
  @media screen and (max-width: 700px) {
    & > form {
      flex-wrap: wrap;
    }

    /* button */
    ${StyledBgButtonWrap} {
      width: 100%;
      height: 48px;
      button {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    ${StyledSelectsWrap} {
      flex-direction: column;
    }
    ${StyledPrimaryLabel} {
      min-width: 76px;
    }

    /* Date */
    ${StyledDateWithLabelWrap} {
      width: 100%;
    }
    ${StyledRangeDateWithButtonsWrap} {
      flex-direction: column;
      flex: 1;
    }
    ${StyledDateWrap} {
      width: 100%;
    }

    /* Select */
    ${StyledSelectsWrap} {
      width: 100%;
    }
    ${StyledSelectWithLabelWrap} {
      width: 100%;

      & > div {
        width: 100%;
        .select__control {
          width: 100%;
        }
      }
    }

    /* button */
    ${StyledBgButtonWrap} {
      width: 100%;
      height: 48px;
      button {
        height: 100%;
      }
    }

    ${StyledSensorTypesWrap} {
      min-width: unset;
    }
  }

  @media screen and (max-width: 400px) {
    ${StyledSelectsWrap} {
      flex-direction: row;
      gap: 15px;
    }
    ${StyledDateWithLabelWrap}, ${StyledSelectWithLabelWrap} {
      flex-direction: column;
      gap: 4px;
    }
    ${StyledSelectWithLabelWrap}:nth-child(2) {
      flex: 1 0 0;
      width: auto;
    }
    ${StyledSelectWithLabelWrap}:nth-child(3) {
      flex: 0.5 0 0;
      width: auto;
    }
    ${StyledPrimaryLabel} {
      line-height: 20px;
    }
  }
`;

export const StyledTableSearchRowWrap = styled(StyledSearchRowCommonWrap)`
  @media screen and (max-width: 700px) {
    & > form {
      flex-wrap: wrap;
    }

    /* button */
    ${StyledBgButtonWrap} {
      width: 100%;
      height: 48px;
      button {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    ${StyledSelectsWrap} {
      flex-direction: column;
    }
    ${StyledPrimaryLabel} {
      min-width: 60px;
    }

    /* Date */
    ${StyledDateWithLabelWrap} {
      width: 100%;
    }
    ${StyledRangeDateWithButtonsWrap} {
      flex-direction: column;
      flex: 1;
    }
    ${StyledDateWrap} {
      width: 100%;
    }

    /* Select */
    ${StyledSelectsWrap} {
      width: 100%;
    }
    ${StyledSelectWithLabelWrap} {
      width: 100%;

      & > div {
        width: 100%;
        .select__control {
          width: 100%;
        }
      }
    }

    /* button */
    ${StyledBgButtonWrap} {
      width: 100%;
      height: 48px;
      button {
        height: 100%;
      }
    }

    ${StyledSensorTypesWrap} {
      min-width: unset;
    }
  }

  @media screen and (max-width: 400px) {
    ${StyledDateWithLabelWrap}, ${StyledSelectWithLabelWrap} {
      flex-direction: column;
      gap: 4px;
    }
    ${StyledPrimaryLabel} {
      line-height: 20px;
    }
  }
`;

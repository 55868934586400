import React, { ReactNode, useEffect } from "react";
import DatePicker, {
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "@src/components/Inputs/Input";
import { InputStyleProps } from "@src/components/Inputs/sizeTheme";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { theme } from "@src/styles/theme";
import CustomHeader from "../CustomHeader";
import { KEY_TYPE } from "@constructs/common";
import {
  StyledCalrendar,
  StyledDateWithLabelWrap,
  StyledDateWrap,
} from "../styles";
import { StyledPrimaryLabel } from "@src/styles/commonStyles";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

interface CommonSingleDateProps {
  sizeTheme: InputStyleProps;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  isInvalid?: boolean;
  errorMessages?: string[];
  maxDate?: Date;
  minDate?: Date;
  showLabel?: boolean;
  labelText?: string;
  isDisabled?: boolean;
}

// Label Optional Props
type OptionalLabelProps =
  | {
      showLabel: true;
      labelText: string;
      labelDirection?: "TOP" | "LEFT";
    }
  | {
      showLabel?: false;
      labelText?: never;
      labelDirection?: never;
    };

export type SingleDateProps = CommonSingleDateProps & OptionalLabelProps;

export default function SingleDate({
  sizeTheme,
  date,
  setDate,
  isInvalid,
  errorMessages,
  maxDate,
  minDate,
  showLabel,
  labelText,
  labelDirection,
  isDisabled,
}: SingleDateProps) {
  // zustand & hooks
  const { checkOutsideClick, handleKeyDown } = useCommonHooks();

  const [open, setOpen] = React.useState<boolean>(false);
  const [dateStr, setDateStr] = React.useState<string>("");

  const dateRef = React.useRef<DatePicker>(null);
  const inputWrapRef = React.useRef<HTMLDivElement>(null);

  // Custom Day Element
  const renderDayContents = (dayOfMonth: number, _date: Date) => {
    return (
      <>
        <div className="day-connect" />
        <div className="day-bg" />
        <span className="day-number">{dayOfMonth}</span>
      </>
    );
  };

  // Custom Header Element
  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    return <CustomHeader {...props} maxDate={maxDate} minDate={minDate} />;
  };

  // Custom Calendar Container
  const calendarContainer = (props: CalendarContainerProps) => (
    <StyledCalrendar {...props} />
  );

  const handleDateChange = (date: Date | null) => {
    setDate(date);
    setOpen(false);
  };

  const handleCalendarOpen = () => !open && setOpen(true);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) =>
    checkOutsideClick(e, inputWrapRef, open, setOpen);

  // Setting day name & sunday color change
  const setFormatWeekDay: (day: string) => ReactNode = (day: string) => {
    if (day === "일요일") {
      return (
        <span
          style={{
            color: theme.color.invalid,
          }}
        >
          {day.slice(0, 1)}
        </span>
      );
    }
    return <span>{day.slice(0, 1)}</span>;
  };

  // set Date string with date-fns format
  useEffect(() => {
    if (!date) {
      setDateStr("");
    } else {
      setDateStr(format(date, "yyyy.MM.dd"));
    }
  }, [date]);

  const Calrendar = () => {
    return (
      <DatePicker
        ref={dateRef}
        open={open}
        selected={date}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        onClickOutside={handleOutsideClick}
        formatWeekDay={setFormatWeekDay}
        customInput={<input type="hidden" />}
        calendarContainer={calendarContainer}
        renderCustomHeader={renderCustomHeader}
        renderDayContents={renderDayContents}
        monthsShown={1}
        focusSelectedMonth
        showMonthDropdown
        showYearDropdown
        portalId="float"
        locale={ko}
        disabled={isDisabled}
      />
    );
  };

  return (
    <StyledDateWithLabelWrap labelDirection={labelDirection}>
      {showLabel && (
        <StyledPrimaryLabel
          style={{ cursor: "pointer" }}
          onClick={handleCalendarOpen}
          labelLineHeight={
            labelDirection ? undefined : sizeTheme.height ?? undefined
          }
        >
          <span>{labelText}</span>
        </StyledPrimaryLabel>
      )}
      <StyledDateWrap fixWidth={sizeTheme.singleDateWidth ?? 0}>
        <div
          ref={inputWrapRef}
          style={{
            cursor: "pointer",
          }}
          onClick={handleCalendarOpen}
          onKeyDown={(e) => {
            e.stopPropagation();
            handleKeyDown(e, [KEY_TYPE.ENTER], handleCalendarOpen);
            e.preventDefault();
          }}
        >
          <Input
            inputName="single-date"
            type="text"
            sizeTheme={sizeTheme}
            eventType="Custom"
            placeholder="Select Date"
            value={dateStr ?? ""}
            isDate
            isFoucs={open}
            isInvalid={isInvalid}
            errorMessages={errorMessages}
            addonDirection="right"
            addonName="calendar"
            addonShow
            isDisabled={isDisabled}
          />
        </div>
        <Calrendar />
      </StyledDateWrap>
    </StyledDateWithLabelWrap>
  );
}

import { useEffect } from "react";
import Portal from "../../Portal";
import {
  StyledDimFullScreen,
  StyledPrimaryText,
} from "../../styles/commonStyles";
import Icon from "../Icon";
import { useThemeStore } from "../../stores/useCommonStore";
import { ThemeConst } from "../../constructs/common";
import { darkTheme, theme } from "../../styles/theme";
import React from "react";

/**
 *
 * @returns Portal을 사용해 #root 엘리먼트가 아닌 #hidden 엘리먼트에 Loading 컴포넌트를 띄웁니다.
 */
function LoadingRandomIcon() {
  const themeType = useThemeStore();
  const icons = ["/images/loading_1.svg", "/images/loading_2.svg"];
  const messages = ["더 나은 세상으로 향하는 다리", "안전을 잇는 다리"];

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <Portal rootId="loading_random_icon">
      <StyledDimFullScreen
        bgColor={
          themeType === ThemeConst.Light
            ? theme.color.background
            : darkTheme.color.background
        }
      >
        <div
          style={{
            position: "absolute",
            right: 20,
            top: 20,
          }}
        ></div>
        <img
          src={icons[0]}
          alt="로딩 일러스트"
          style={{
            // maxWidth: "170px",
            maxHeight: "250px",
          }}
        />
        <StyledPrimaryText size="lg" weight={400}>
          {messages[0]}
        </StyledPrimaryText>
        <Icon iconName="gbWebLogoLight" iconWidth={140} />
      </StyledDimFullScreen>
    </Portal>
  );
}

const LoadingRandomIconMemo = React.memo(LoadingRandomIcon);
export default LoadingRandomIconMemo;

import React, { ReactNode, createContext, useContext, useState } from "react";
import { ProviderProps } from "./AllContextProvider";

const DialogContext = createContext({
  isAlertDialogOpen: false,
  alertTitle: "",
  alertContent: null as ReactNode,
  handleAlertDialogOpen: (title: string, content: ReactNode) => {},
  cancelAlertDialogOpen: () => {},
});

export function AlertDialogProvider({ children }: ProviderProps) {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertContent, setAlertContent] = useState<ReactNode>(null);

  /**
   * Alert Dialog 컴포넌트 오픈
   * ***
   * @param
   * - title: Alert Dialog 타이틀
   * - content: Alert Dialog 콘텐츠(string, string[])
   */
  const handleAlertDialogOpen = React.useCallback(
    (title: string, content: ReactNode) => {
      setAlertTitle(title);
      setAlertContent(content);
      setIsAlertDialogOpen(true);
      return;
    },
    []
  );

  const cancelAlertDialogOpen = React.useCallback(() => {
    setIsAlertDialogOpen(false);
    setAlertTitle("");
    setAlertContent(null);
  }, []);

  const value = {
    isAlertDialogOpen,
    alertTitle,
    alertContent,
    handleAlertDialogOpen,
    cancelAlertDialogOpen,
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
}

export function useAlertDialog() {
  return useContext(DialogContext);
}

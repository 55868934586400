import { ELEMENT_Z_INDEX } from "@constructs/common";
import { mediaQuery } from "@src/styles/theme";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

// keyframes
const KeyframesMoveX = keyframes`
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
`;
const KeyframesMoveX2 = keyframes`
  0% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const StyledMotionSidebar = styled(motion.div)`
  grid-area: Sidebar;
  height: 100svh;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: ${({ theme }) => theme.color.sidebar.boxShadow};
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ELEMENT_Z_INDEX.SIDEBAR};
`;

export const StyledSidebar = styled.div<{ isSummarySidebar: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px;
  height: 100svh;

  & > * {
    padding: 0 ${({ isSummarySidebar }) => (isSummarySidebar ? 10 : 20)}px;
  }
`;

export const StyledSidebarOverflowWrap = styled.div`
  flex: 1;
  overflow-y: overlay;
`;

export const StyledSidebarExpansionButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button {
    border-radius: 2px 0 0 2px;
  }
`;

export const StyledMenuCategory = styled.span`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color.sidebar.menuTitle};
`;

export const StyledMenuList = styled.li`
  display: flex;
  flex-direction: column;

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
  }
`;

export const StyledMenu = styled(Link)`
  display: flex;
  align-items: center;

  width: 100%;

  font-size: ${({ theme }) => theme.size.common.fontSize.mdlg};
  font-weight: ${({ theme }) => theme.size.common.fontWeight.md};

  span {
    color: ${({ theme }) => theme.color.sidebar.menu};
  }

  & > *:last-child {
    margin-left: 14px;
  }
  & > * + *:not(:last-child) {
    margin-left: 14px;
  }

  &:hover,
  &.active {
    span {
      color: ${({ theme }) => theme.color.primary};
    }

    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`;

export const StyledMenuSummary = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background-color: transparent;
  border-radius: 4px;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.color.primary};
    svg {
      fill: #ffffff;
    }
  }
`;

export const StyledNav = styled.nav<{ isSummarySidebar: boolean }>`
  & > ul {
    display: flex;
    flex-direction: column;
    gap: ${({ isSummarySidebar }) => (isSummarySidebar ? 36 : 30)}px;

    & > li:not(:last-child):after {
      content: "";
      display: block;
      margin-top: ${({ isSummarySidebar }) => (isSummarySidebar ? 36 : 30)}px;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.color.sidebar.navLine};
    }
  }

  ${StyledMenuList} {
    align-items: ${({ isSummarySidebar }) =>
      isSummarySidebar ? "center" : "flex-start"};
  }

  ${StyledMenuList} {
    & > ul {
      gap: ${({ isSummarySidebar }) => (isSummarySidebar ? 24 : 20)}px;
    }
  }
`;

export const StyledMotionIconSpan = styled.span`
  display: inline-flex;
  align-items: center;
  transition: 0.24s;
  transform: translateX(0);

  &.active {
    animation: ${KeyframesMoveX} 0.2s forwards;
  }
`;

export const StyledMotionNameSpan = styled.span<{ minWidth: number }>`
  display: flex;
  align-items: center;

  width: 100%;

  transition: 0.24s;
  transform: translateX(0);

  overflow-wrap: break-word; /* 단어 단위로 줄바꿈 */
  word-break: break-all; /* 단어가 넘칠 경우 줄바꿈 */
  white-space: normal; /* 기본값으로 줄바꿈을 허용 */

  &.active {
    animation: ${KeyframesMoveX2} 0.2s forwards;
  }
  /* position: absolute; */
  min-width: ${({ minWidth }) => minWidth}px;
`;

// menu burger
export const StyledBurgerButton = styled.button<{
  isOpenMobileSidebar: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 6px;
  cursor: pointer;

  & > div {
    width: 24px;
    height: 4px;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 2px;
  }

  position: fixed !important;
  top: 10px;
  left: 10px;

  &:after {
    transition: transform 0.24s ease-in;
  }

  &:focus,
  &:focus-visible {
    &:after {
      transform: translateX(
        ${({ isOpenMobileSidebar }) => (isOpenMobileSidebar ? 250 : 0)}px
      );
    }
  }

  ${mediaQuery.mobile} {
    gap: 4px;
    padding: 4px;
    & > div {
      width: 20px;
      height: 3px;
    }
  }
`;

// Theme Button
export const StyledThemeSwitchWrap = styled.div`
  margin-top: 20px;
`;

import { theme } from "@styles/theme";

export interface ButtonStyleProps {
  paddingTB: number;
  paddingLR: number;
  maxWidth?: number;
  minWidth?: number;
  height: number;
  fontSize: string;
  fontWeight: number;
  iconWidth?: number;
  iconHeight?: number;
}

export const BigButtonStyles = {
  paddingTB: 8,
  paddingLR: 12,
  minWidth: 80,
  height: 50,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
};

export const NormalButtonStyles = {
  paddingTB: 8,
  paddingLR: 12,
  minWidth: 80,
  height: 40,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
};

export const SmallButtonStyles = {
  paddingTB: 4,
  paddingLR: 8,
  height: 30,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
};

export const DialogButtonStyles = {
  paddingTB: 8,
  paddingLR: 12,
  maxWidth: 70,
  height: 40,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
};

export const NormalIconButtonStyles = {
  paddingTB: 8,
  paddingLR: 8,
  minWidth: 40,
  height: 40,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 20,
  iconHeight: 20,
};

export const SmallIconButtonStyles = {
  paddingTB: 7.2,
  paddingLR: 7.2,
  minWidth: 32,
  height: 32,
  fontSize: theme.size.common.fontSize.sm,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 14,
  iconHeight: 14,
};

export const XSmallIconButtonStyles = {
  paddingTB: 6,
  paddingLR: 6,
  minWidth: 24,
  height: 24,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 12,
  iconHeight: 12,
};

export const BigNoSpaceIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 36,
  height: 36,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 36,
  iconHeight: 36,
};

export const NormalNoSpaceIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 24,
  height: 24,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 24,
  iconHeight: 24,
};

export const NormalPaginationIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 24,
  height: 24,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 16,
  iconHeight: 16,
};
export const SmallPaginationIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 16,
  height: 16,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 10,
  iconHeight: 10,
};

export const XSmallNoSpaceIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 20,
  height: 20,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 20,
  iconHeight: 20,
};

export const XXSmallNoSpaceIconButtonStyles = {
  paddingTB: 0,
  paddingLR: 0,
  minWidth: 14,
  height: 11,
  fontSize: theme.size.common.fontSize.base,
  fontWeight: theme.size.common.fontWeight.base,
  iconWidth: 11,
  iconHeight: 11,
};

import { useAlertDialog } from "@src/contexts/AlertDialogProvider";
import useFetchChartData, {
  IPSDFileData,
  IRawFileData,
  S3JsonDataResponse,
  S3JsonFileData,
} from "@src/fetch/fetchChart";
import { useUserInfoStore } from "@src/stores/useCommonStore";
import fileSaver from "file-saver";
import React, { useEffect } from "react";
import {
  areEqual,
  getDaysBetweenDates,
  isDataType,
  waitForCondition,
} from "./useCommonHooks";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import JSZip from "jszip";
import { psdFreqStep } from "@src/constructs/common";
import { useTranslation } from "react-i18next";

interface FileDownloadMutateParams extends readChartMutateParams {
  index: number;
  dataLength: number;
}
interface readChartMutateParams {
  params: {
    fileKey: string;
    deviceId: string;
  };
  dataType: string;
}

export default function useFilesDownloadHooks(
  dataType: string,
  data?: FileListResponse | null,
  isChekcedRow?: boolean[]
) {
  // * Hooks
  const { t } = useTranslation();
  const userInfo = useUserInfoStore();
  const { handleAlertDialogOpen } = useAlertDialog();
  const { FetchReadS3File } = useFetchChartData();

  // * Query
  const { mutate: getS3FilesMutate } = useMutation<
    S3JsonDataResponse | FileResponse | null,
    unknown,
    FileDownloadMutateParams,
    unknown
  >(async (params) => {
    return FetchReadS3File(params.params, params.dataType)
      .then((value) => {
        // 성공시 실행하는 이벤트
        if (isDataType<S3JsonDataResponse>(value, "fileData")) {
          getS3FilesResponseRef.current[params.index] = value.fileData;
        } else if (isDataType<FileResponse>(value, "file")) {
          getS3FilesResponseRef.current[params.index] = value;
        }
        return value;
        // return mutateSuccess(value);
      })
      .finally(() => {
        if (getS3FilesMutateFinishNumRef.current === params.dataLength) {
          // getS3FilesMutateFinishNumRef.current의 숫자와 라스트인덱스랑 일치하면 비동기 작업을 완료한다.
          // getS3FilesResponseRef의 데이터 개수랑 비교해도 되지않을까 생각했는데 특정 파일 1개라도 에러가 나면 ref에 데이터가 추가되지않아서 최종개수가 다를 것임
          // finally는 에러가 발생하든 아니든 +1을 하기 때문에 개수 비교할 수 있음
          isLastGetS3FilesMutateRef.current = true;
        }

        getS3FilesMutateFinishNumRef.current++;
      });
  });

  // * State
  const [fileDownloadCall, setFileDownloadCall] = React.useState<{
    fileUrl?: string;
    callNumber: number;
  }>({
    fileUrl: undefined,
    callNumber: 0,
  });
  const [isGetS3FilesMutateLoading, setIsGetS3FilesMutateLoading] =
    React.useState<boolean>(false);

  // * Ref
  const getS3FilesResponseRef = React.useRef<
    (S3JsonFileData<IRawFileData | IPSDFileData> | FileResponse)[]
  >([]); // getS3FilesMutate의 response를 담는 ref
  const getS3FilesMutateFinishNumRef = React.useRef<number>(1); // getS3FilesMutate 실행이 완료된 횟수 저장 getS3FilesResponseRef의 개수와 비교해서 isLastGetS3FilesMutateRef를 구한다.
  const isLastGetS3FilesMutateRef = React.useRef<boolean>(false); // 마지막 mutate가 끝났는지 boolean, 비동기 작업 완료시키는 용도

  // * Functions
  const handleFilesDownloadButton = (checkedUrl?: string) => {
    setFileDownloadCall((prev) => ({
      fileUrl: checkedUrl,
      callNumber: prev.callNumber + 1,
    }));
  };

  // 파일 저장하는 함수
  const saveFiles = React.useCallback(
    async (fileUrl?: string) => {
      if (data && isChekcedRow) {
        // 함수 시작 시 false로 변경
        setIsGetS3FilesMutateLoading(true);
        isLastGetS3FilesMutateRef.current = false;
        getS3FilesMutateFinishNumRef.current = 1;
        getS3FilesResponseRef.current = [];

        // 파일 다운로드 할 때 api 파라미터로 사용하고 파일명 생성할 변수
        let downloadFileInfoArray: {
          fileKey: string;
          deviceId: string;
          sensorId: string;
        }[] = [];

        // downloadFileInfoArray 채우기
        if (fileUrl !== undefined) {
          const sensorId = dataType.includes("scoreReport")
            ? ""
            : data?.fileList.find((item) => item.fileKey === fileUrl)
                ?.sensorId ?? `ae.${fileUrl.replace("/", "-").split("/")[0]}`;

          const deviceId =
            data?.fileList.find((item) => item.fileKey === fileUrl)?.deviceId ??
            userInfo.sensorList.find((item) => item.sensor_id === sensorId)
              ?.device_id ??
            "";

          downloadFileInfoArray = [
            {
              fileKey: fileUrl,
              deviceId,
              sensorId,
            },
          ];
        } else if (!fileUrl) {
          isChekcedRow.forEach((item, index) => {
            if (item) {
              downloadFileInfoArray.push({
                fileKey: data?.fileList[index].fileKey ?? "",
                deviceId: data?.fileList[index].deviceId ?? "",
                sensorId: data?.fileList[index].sensorId ?? "",
              });
            }
          });
        }

        // 1개도 선택 안했을 때 알림 팝업
        if (downloadFileInfoArray.length < 1) {
          handleAlertDialogOpen(t("check_file_title"), t("check_file_message"));
          setIsGetS3FilesMutateLoading(false);
          return;
        }

        // Mutation 실행
        downloadFileInfoArray.forEach((item, index) => {
          const params = {
            params: {
              fileKey: item.fileKey,
              deviceId: item.deviceId,
            },
            dataType,
            index,
            dataLength: downloadFileInfoArray.length,
          };
          getS3FilesMutate(params);
        });

        try {
          // 모든 뮤테이션의 Promise를 기다린 후 결과를 처리
          const result = await waitForCondition(isLastGetS3FilesMutateRef);

          if (result) {
            // Blob 배열 생성
            let blob: {
              file: Blob;
              name: string;
            }[] = [];

            // dataType에 따라서 Blob 파일/네임 데이터 가공 및 저장
            switch (dataType) {
              case "image":
                blob = getImageBlob(
                  getS3FilesResponseRef.current as FileResponse[]
                );
                break;
              case "deviceSpecialInfo":
                blob = getImageBlob(
                  getS3FilesResponseRef.current as FileResponse[]
                );
                break;
              case "psd":
                blob = getPSDBlob(
                  getS3FilesResponseRef.current as S3JsonFileData<IPSDFileData>[],
                  downloadFileInfoArray
                );
                break;
              case "scoreReport":
                blob = getScoreReportBlob(
                  getS3FilesResponseRef.current as FileResponse[]
                );
                break;
              default:
                blob = getRawBlob(
                  getS3FilesResponseRef.current as S3JsonFileData<IRawFileData>[],
                  downloadFileInfoArray
                );
                // raw, event
                break;
            }

            // 압축 시작
            const zip = new JSZip();
            if (blob.length === 1) {
              // 파일 1개 저장하는 경우 csv/jpg return
              fileSaver.saveAs(blob[0].file, blob[0].name);
            } else {
              // 여러개 저장하는 경우 zip return
              let name = "";

              if (dataType === "scoreReport") {
                // 성적서일 때
                name = `${blob[0].name.split("]")[0]}].zip`;
              } else {
                // 다른 파일일 때
                const fileNameSplit = blob[0].name.split("_");
                name = `${fileNameSplit[0]}_${fileNameSplit[1]}_${dataType}_files.zip`;
              }

              blob.map((item: { file: Blob; name: string }) => {
                const currDate = new Date();
                const dateWithOffset = new Date(
                  currDate.getTime() - currDate.getTimezoneOffset() * 60000
                );
                return zip.file(item.name, item.file, {
                  date: dateWithOffset,
                });
              });

              zip.generateAsync({ type: "blob" }).then((content) => {
                fileSaver.saveAs(content, name);
              });
            }
          }
          setIsGetS3FilesMutateLoading(false);

          if (
            getS3FilesResponseRef.current.length !==
            downloadFileInfoArray.length
          ) {
            // 일부 파일이 에러 발생해서 다운로드 되지 않았다
            handleAlertDialogOpen(
              t("notification"),
              t("some_file_downloads_failed")
            );
          }
        } catch (error) {
          setIsGetS3FilesMutateLoading(false);
          handleAlertDialogOpen(t("notification"), t("file_download_failed"));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isChekcedRow]
  );

  // dataType === 'image' / 이미지 파일 가공 => 리턴
  const getImageBlob = React.useCallback((filesResult: FileResponse[]) => {
    return filesResult.map((item: FileResponse, index) => {
      return {
        file: new Blob([item.file], {
          type: "application/octet-stream",
        }),
        name: item.name,
      };
    });
  }, []);

  // PSD 파일 가공
  const getPSDBlob = React.useCallback(
    (
      filesResult: S3JsonFileData<IPSDFileData>[],
      downloadFileInfoArray: {
        fileKey: string;
        deviceId: string;
        sensorId: string;
      }[]
    ) => {
      return filesResult.map((item: S3JsonFileData<IPSDFileData>, index) => {
        const headers = ["Frequency", "Data", "isPeak"];

        const freq = new Array(item.file.count)
          .fill(false)
          .map((_, index) => index * psdFreqStep);

        const freqIndex = item.file.peakFreq
          .map((pF) => {
            const foundIndex = freq.findIndex((f) => areEqual(f, pF, 7)); // 7자리까지 비교
            return foundIndex;
          })
          .filter((pF) => pF !== -1);

        // csv 형태의 파일 데이터 생성
        let psdFileData: (string | number)[][] | (string | number) = freq.map(
          (f, index) => {
            const data = [];
            data.push(f);
            data.push(item.file.data[index]);

            if (freqIndex.find((f) => f === index)) {
              data.push("PEAK");
            } else {
              data.push("");
            }
            return data;
          }
        );
        psdFileData.unshift(headers);
        psdFileData = psdFileData
          .map((row) => {
            return row.join(", ");
          })
          .join("\n");

        // 리턴 파일명 확장자 제거
        const fileName = item.name.includes(".")
          ? item.name.split(".")[0]
          : item.name;

        return {
          file: new Blob([psdFileData], {
            type: "application/octet-stream",
          }),
          name: `${fileName}.csv`,
        };
      });
    },
    []
  );

  // 성적서 파일 가공
  const getScoreReportBlob = React.useCallback(
    (filesResult: FileResponse[]) => {
      return filesResult.map((item: FileResponse) => {
        return {
          file: new Blob([item.file], {
            type: "application/octet-stream",
          }),
          name: item.name,
        };
      });
    },
    []
  );

  // 로우 파일 가공
  const getRawBlob = React.useCallback(
    (
      filesResult: S3JsonFileData<IRawFileData>[],
      downloadFileInfoArray: {
        fileKey: string;
        deviceId: string;
        sensorId: string;
      }[]
    ) => {
      return filesResult.map((item: S3JsonFileData<IRawFileData>, index) => {
        // * dataType이 그 외(raw로 가정)일 때 파일 가공
        const headers = ["Time", "Data"];

        // startDate, endDate로 사이 데이터 전부 구하기
        const startDate = new Date(
          format(new Date(item.file.startTime), "yyyy-MM-dd HH:mm:ss.SSS")
        );
        const endDate = new Date(
          format(new Date(item.file.endTime), "yyyy-MM-dd HH:mm:ss.SSS")
        );
        const startEndLabels: string[] = getDaysBetweenDates(
          startDate,
          endDate,
          Number(item.file.count),
          "yyyy.MM.dd HH:mm:ss.SSS"
        );

        // csv 형태로 파일 데이터 생성
        let rawFileData: (string | number)[][] | (string | number) =
          startEndLabels.map((date, index) => {
            const data = [];
            data.push(date);
            data.push(item.file.data[index]);
            return data;
          });
        rawFileData.unshift(headers);
        rawFileData = rawFileData
          .map((row) => {
            return row.join(", ");
          })
          .join("\n");

        // 리턴 파일명 확장자 제거
        const fileName = item.name.includes(".")
          ? item.name.split(".")[0]
          : item.name;

        return {
          file: new Blob([rawFileData], {
            type: "application/octet-stream",
          }),
          name: `${fileName}.csv`,
        };
      });
    },
    []
  );

  // fileDownloadCall이 업데이트 됐으면 파일 저장 함수를 실행한다.
  useEffect(() => {
    if (fileDownloadCall.callNumber > 0) {
      saveFiles(fileDownloadCall.fileUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDownloadCall]);

  return {
    fileDownloadCall,
    handleFilesDownloadButton,
    isGetS3FilesMutateLoading,
    getScoreReportBlob,
    getImageBlob,
  };
}

import React, { useEffect } from "react";
import DatePicker, {
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "@src/components/Inputs/Input";
import { InputStyleProps } from "@src/components/Inputs/sizeTheme";
import useCommonHooks from "@src/hooks/useCommonHooks";
import { theme } from "@src/styles/theme";
import CustomHeader from "../CustomHeader";
import { BrowserSizeConst, KEY_TYPE } from "@constructs/common";
import {
  StyledCalrendar,
  StyledDateWithLabelWrap,
  StyledDateWrap,
  StyledRangeDateWithButtonsWrap,
} from "../styles";
import { StyledI18nSpan, StyledPrimaryLabel } from "@src/styles/commonStyles";
import GroupButton from "@src/components/Buttons/GroupButton";
import { NormalButtonStyles } from "@src/components/Buttons/sizeTheme";
import {
  differenceInDays,
  format,
  isEqual,
  isToday,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { ko } from "date-fns/locale";
import { useBrowserSizeStore } from "@src/stores/useCommonStore";
import { useTranslation } from "react-i18next";

interface CommonRangeDateProps {
  sizeTheme: InputStyleProps;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  isInvalid?: boolean;
  errorMessages?: string[];
  maxDate?: Date;
  minDate?: Date;
  showChoseDateButtons?: boolean;
  searchedTotalPeriod?: boolean;
  showLabel?: boolean;
  labelText?: string;
  isDisabled?: boolean;
}

// Label Optional Props
type OptionalLabelProps =
  | {
      showLabel: true;
      labelText: string;
      labelDirection?: "TOP" | "LEFT";
    }
  | {
      showLabel?: false;
      labelText?: never;
      labelDirection?: never;
    };

export type RangeDateProps = CommonRangeDateProps & OptionalLabelProps;

function RangeDate({
  sizeTheme,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isInvalid,
  errorMessages,
  maxDate,
  minDate,
  showChoseDateButtons,
  searchedTotalPeriod,
  showLabel,
  labelText,
  labelDirection,
  isDisabled,
}: RangeDateProps) {
  // zustand & hooks
  const browserSize = useBrowserSizeStore();
  const { checkOutsideClick, handleKeyDown } = useCommonHooks();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [startDateStr, setStartDateStr] = React.useState<string>("");
  const [endDateStr, setEndDateStr] = React.useState<string>("");

  // 일간/주간/월간 버튼
  const dateButtonsData: {
    text: string;
    onClick: (index: number) => void;
    formId?: string | undefined;
    isDisabled?: boolean | undefined;
    maxWidth?: number | undefined;
    maxWidthStr?: string | undefined;
  }[] = [
    {
      text: t("daily"),
      onClick: (_: number) => {
        setStartDate(new Date());
        setEndDate(new Date());
      },
      isDisabled,
    },
    {
      text: t("weekly"),
      onClick: (_: number) => {
        const end = new Date();
        setStartDate(startOfWeek(end, { weekStartsOn: 1 }));
        setEndDate(end);
      },
      isDisabled,
    },
    {
      text: t("monthly"),
      onClick: (_: number) => {
        const end = new Date();
        setStartDate(startOfMonth(end));
        setEndDate(end);
      },
      isDisabled,
    },
  ];

  // for group button
  const [activeToggle, setActiveToggle] = React.useState<number>(-1);
  const beforeActiveToggle = React.useRef<number>(-1);

  const dateRef = React.useRef<DatePicker>(null);
  const inputWrapRef = React.useRef<HTMLDivElement>(null);

  // Custom Day Element
  const renderDayContents = React.useCallback(
    (dayOfMonth: number, _date: Date) => {
      return (
        <>
          <div className="day-connect" />
          <div className="day-bg" />
          <span className="day-number">{dayOfMonth}</span>
        </>
      );
    },
    []
  );

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    return <CustomHeader {...props} maxDate={maxDate} minDate={minDate} />;
  };

  // Custom Calendar Container
  const calendarContainer = React.useCallback(
    (props: CalendarContainerProps) => <StyledCalrendar {...props} />,
    []
  );

  const handleDateChange = React.useCallback(
    (date: [Date | null, Date | null]) => {
      setStartDate(date[0]);
      setEndDate(date[1]);

      if (date[1]) {
        setOpen(false);
      } else {
        // startDate를 클릭할 때 endDate는 일단 초기화 (for setError)
        setEndDate(null);
      }

      setActiveToggle(-1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCalendarOpen = React.useCallback(
    () => !open && setOpen(true),
    [open]
  );

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) =>
    checkOutsideClick(e, inputWrapRef, open, setOpen);

  const koreanToEnglishDayMap: { [key: string]: string } = {
    일요일: "sunday",
    월요일: "monday",
    화요일: "tuesday",
    수요일: "wednesday",
    목요일: "thursday",
    금요일: "friday",
    토요일: "saturday",
  };

  // Setting day name & sunday color change
  const setFormatWeekDay = React.useCallback((day: string) => {
    return (
      <StyledI18nSpan
        data-str={t(koreanToEnglishDayMap[day])}
        style={
          day === "일요일"
            ? {
                color: theme.color.invalid,
              }
            : undefined
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calrendar Component
  const Calrendar = React.useMemo(() => {
    return (
      <DatePicker
        ref={dateRef}
        open={open}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        onClickOutside={handleOutsideClick}
        formatWeekDay={setFormatWeekDay}
        customInput={<input type="hidden" />}
        calendarContainer={calendarContainer}
        renderCustomHeader={renderCustomHeader}
        renderDayContents={renderDayContents}
        monthsShown={
          browserSize && browserSize >= BrowserSizeConst.Laptop ? 2 : 1
        }
        focusSelectedMonth
        showMonthDropdown
        showYearDropdown
        portalId="float"
        locale={ko}
        disabled={isDisabled}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, startDate, endDate]);

  // set startDate string with date-fns format
  useEffect(() => {
    if (!startDate) {
      setStartDateStr("");
    } else {
      setStartDateStr(format(startDate, "yyyy.MM.dd"));
    }
  }, [startDate]);

  // set endDate string with date-fns format
  useEffect(() => {
    if (!endDate) {
      setEndDateStr("");
    } else {
      setEndDateStr(format(endDate, "yyyy.MM.dd"));
    }
  }, [endDate]);

  // 그룹 버튼을 바꾸지 않았지만 선택된 날짜가 특정 그룹에 해당할 때 버튼 액티브
  useEffect(() => {
    if (beforeActiveToggle.current === activeToggle) {
      if (searchedTotalPeriod && (!startDate || !endDate)) {
        setActiveToggle(0);
      } else if (isToday(endDate as Date) && startDate && endDate) {
        if (differenceInDays(endDate as Date, startDate as Date) === 0) {
          setActiveToggle(searchedTotalPeriod ? 1 : 0);
        } else if (
          isEqual(
            startOfDay(startDate),
            startOfWeek(endDate, { weekStartsOn: 1 })
          )
        ) {
          setActiveToggle(searchedTotalPeriod ? 2 : 1);
        } else if (
          isEqual(startOfDay(startDate), startOfDay(startOfMonth(endDate)))
        ) {
          setActiveToggle(searchedTotalPeriod ? 3 : 2);
        } else {
          setActiveToggle(-1);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, beforeActiveToggle.current]);

  // activeToggle이 변경됐다는 걸 알려주기 위해 beforeActiveToggle을 업데이트한다.
  useEffect(() => {
    if (activeToggle !== beforeActiveToggle.current) {
      beforeActiveToggle.current = activeToggle;
    }
  }, [activeToggle]);

  return (
    <StyledDateWithLabelWrap labelDirection={labelDirection}>
      {showLabel && (
        <StyledPrimaryLabel
          style={{ cursor: "pointer" }}
          onClick={handleCalendarOpen}
          labelLineHeight={
            labelDirection === "TOP" ? undefined : sizeTheme.height ?? undefined
          }
        >
          <span>{labelText}</span>
        </StyledPrimaryLabel>
      )}
      <StyledRangeDateWithButtonsWrap>
        <StyledDateWrap fixWidth={sizeTheme.rangeDateWidth ?? 0}>
          <div
            ref={inputWrapRef}
            style={{
              cursor: "pointer",
            }}
            onClick={handleCalendarOpen}
            onKeyDown={(e) => {
              e.stopPropagation();
              handleKeyDown(e, [KEY_TYPE.ENTER], handleCalendarOpen);
            }}
          >
            <Input
              inputName="range-date"
              type="text"
              sizeTheme={sizeTheme}
              eventType="Custom"
              placeholder="Select Date"
              value={
                (startDateStr || endDateStr) &&
                `${startDateStr} - ${endDateStr}`
              }
              isDate
              isFoucs={open}
              isInvalid={isInvalid}
              errorMessages={errorMessages}
              addonDirection="right"
              addonName="calendar"
              addonShow
              isDisabled={isDisabled}
            />
          </div>
          {Calrendar}
        </StyledDateWrap>
        {showChoseDateButtons && (
          <GroupButton
            type="button"
            sizeTheme={NormalButtonStyles}
            colorType="primary"
            contents={
              searchedTotalPeriod
                ? [
                    {
                      text: t("all"),
                      onClick: (_: number) => {
                        setStartDate(null);
                        setEndDate(null);
                      },
                      isDisabled,
                    },
                    ...dateButtonsData,
                  ]
                : dateButtonsData
            }
            activeToggle={activeToggle}
            setActiveToggle={setActiveToggle}
          />
        )}
      </StyledRangeDateWithButtonsWrap>
    </StyledDateWithLabelWrap>
  );
}

const RangeDateMemo = React.memo(
  RangeDate,
  (prevProps, nextProps) =>
    prevProps.startDate === nextProps.startDate &&
    prevProps.endDate === nextProps.endDate &&
    prevProps.labelText === nextProps.labelText &&
    prevProps.errorMessages === nextProps.errorMessages &&
    prevProps.isDisabled === nextProps.isDisabled
);
export default RangeDateMemo;

import React, { useEffect } from "react";
import {
  Container as MapDiv,
  NaverMap as NaverMapComponent,
  useNavermaps,
  Overlay,
  useMap,
  Marker,
} from "react-naver-maps";
import { DeviceLatLngResponse } from "@src/fetch/fetchCommon";
import { makeMarkerClustering } from "@src/assets/js/marker-cluster";
import useNaverMapHooks from "@src/hooks/useNaverMapHooks";
import DataLoadingText from "@src/components/Loadings/DataLoadingText";
import { useTranslation } from "react-i18next";
import { StyledFlexWrap } from "@src/styles/commonStyles";
import styled, { css } from "styled-components";

const DimBackgroundCss = css`
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: ${({ theme }) => theme.color.dimBackground};
  }
`;
const StyledMapWrap = styled.div<{ isLoading?: boolean }>`
  position: absolute;
  inset: 0;

  ${({ isLoading }) => isLoading && DimBackgroundCss}
`;

interface MarkerClusterProps {
  markerData: DeviceLatLngResponse[];
  updateClickedMarker: (id: string, name: string) => void;
}

function MarkerCluster({
  markerData,
  updateClickedMarker,
}: MarkerClusterProps) {
  // Hooks
  const navermaps = useNavermaps();
  const map = useMap();
  const MarkerClustering = makeMarkerClustering(window.naver);
  const { makeMarkerIcon, handleMarkerClick, handleStyleFunction } =
    useNaverMapHooks({
      updateClickedMarker,
    });

  // State
  const [cluster, setCluster] = React.useState<any>(null);

  useEffect(() => {
    if (map && markerData.length > 0 && !cluster) {
      const markers: naver.maps.Marker[] = [];

      markerData.forEach((item) => {
        const position = new navermaps.LatLng(
          item.latitude !== null ? Number(item.latitude) : 37.5434128,
          item.longitude !== null ? Number(item.longitude) : 126.9528962
        );
        const marker = new navermaps.Marker({
          position,
          icon: makeMarkerIcon(
            item.deviceId,
            item.deviceName,
            item.work ?? false,
            item.dtrigger
          ),
          draggable: true,
        });
        naver.maps.Event.addListener(marker, "click", (e) =>
          handleMarkerClick(e)
        );

        markers.push(marker);
      });

      // 클러스터 업데이트
      setCluster(() => {
        const cluster = new MarkerClustering({
          minClusterSize: 4,
          maxZoom: 18, // 조절하면 클러스터링이 되는 기준이 달라짐 (map zoom level)
          map: map,
          markers: markers,
          disableClickZoom: true,
          gridSize: 120,
          icons: [
            makeMarkerIcon(
              "",
              "",
              true,
              "N",
              new navermaps.Size(40, 40),
              new navermaps.Point(20, 20),
              true
            ),
          ],
          indexGenerator: [2],
          stylingFunction: function (
            clusterMarker: any,
            count: number,
            member: any
          ) {
            handleStyleFunction(clusterMarker, count, member);
          },
        });
        return cluster;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markerData, cluster]);

  return (
    <>
      {/* 클러스터 인포 박스가 Open 상태인지 확인하는 div  */}
      <div className="cluster-info-data-check" />
      {cluster ? (
        <Overlay
          element={{
            ...cluster,
            getMap: () => null,
            setMap: () => null,
          }}
        />
      ) : null}
    </>
  );
}
const MarkerClusterMemo = React.memo(
  MarkerCluster,
  (prevProps, nexProps) => prevProps.markerData === nexProps.markerData
);

/**
 * Naver Map Component
 */
interface CommonMapProps {
  minZoom?: number;
  maxZoom?: number;
  center?:
    | (naver.maps.Coord | naver.maps.CoordLiteral)
    | (naver.maps.Point | naver.maps.PointLiteral);
  markerData?: DeviceLatLngResponse[] | null;
  stopKineticPan?: boolean; // 관성 드래깅 멈추기
  stopDraggable?: boolean; // 지도 드래깅 멈추기
  showControls?: boolean;
  isLoading?: boolean;
  updateClickedMarker: (id: string, name: string) => void;
}
type MarkerTypeProps =
  | {
      showMarker: true;
      markerType: "marker" | "cluster";
    }
  | {
      showMarker?: never;
      markerType?: never;
    };
type MapProps = CommonMapProps & MarkerTypeProps;

export function NaverMap({
  center,
  minZoom,
  maxZoom,
  markerData,
  showMarker,
  markerType,
  stopKineticPan,
  stopDraggable,
  isLoading,
  updateClickedMarker,
}: MapProps) {
  // Hooks
  const { t } = useTranslation();
  const navermaps = useNavermaps();
  const { makeMarkerIcon, handleMarkerClick } = useNaverMapHooks({
    updateClickedMarker: updateClickedMarker,
  });

  // State
  const [allControl] = React.useState<boolean>(() => true);

  const MemorizedNaverMap = React.useMemo(() => {
    return (
      <NaverMapComponent
        center={center}
        centerPoint={center as naver.maps.Point | naver.maps.PointLiteral}
        defaultCenter={
          center ??
          new navermaps.LatLng(37.5434128 + 0.001, 126.9528962 - 0.0012)
        }
        defaultCenterPoint={
          (center as naver.maps.Point | naver.maps.PointLiteral) ??
          new navermaps.LatLng(37.5434128 + 0.001, 126.9528962 - 0.0012)
        }
        defaultZoom={15}
        // 지도 인터랙션 옵션 - 기본 가능하게한다.
        draggable={!stopDraggable}
        pinchZoom={!stopDraggable}
        scrollWheel={!stopDraggable}
        keyboardShortcuts={!stopDraggable}
        disableDoubleTapZoom={stopDraggable}
        disableDoubleClickZoom={stopDraggable}
        disableTwoFingerTapZoom={stopDraggable}
        // 관성 드래깅 옵션
        disableKineticPan={stopKineticPan ?? false}
        // min/max 줌 레벨
        minZoom={minZoom ?? 7}
        maxZoom={maxZoom ?? 20}
        // 지도 컨트롤
        scaleControl={allControl}
        mapTypeControl={allControl}
        zoomControl={allControl}
        zoomControlOptions={{
          position: naver.maps.Position.RIGHT_CENTER,
        }}
        logoControl
        logoControlOptions={{
          position: naver.maps.Position.BOTTOM_LEFT,
        }}
        mapDataControl={false}
      >
        {markerData ? (
          <>
            {showMarker && markerType === "marker" ? (
              <>
                {markerData.map((item, index) => {
                  const position = new navermaps.LatLng(
                    item.latitude !== null ? Number(item.latitude) : 37.5434128,
                    item.longitude !== null
                      ? Number(item.longitude)
                      : 126.9528962
                  );

                  return (
                    <>
                      <Marker
                        key={index}
                        position={position}
                        icon={makeMarkerIcon(
                          item.deviceId,
                          item.deviceName,
                          item.work ?? false,
                          item.dtrigger
                        )}
                        onClick={(e) => handleMarkerClick(e)}
                      ></Marker>
                    </>
                  );
                })}
              </>
            ) : showMarker && markerType === "cluster" ? (
              <MarkerClusterMemo
                markerData={markerData}
                updateClickedMarker={updateClickedMarker}
              />
            ) : null}
          </>
        ) : null}
      </NaverMapComponent>
    );
  }, [
    center,
    allControl,
    markerData,
    maxZoom,
    minZoom,
    showMarker,
    stopDraggable,
    stopKineticPan,
    handleMarkerClick,
    makeMarkerIcon,
    markerType,
    navermaps.LatLng,
    updateClickedMarker,
  ]);

  // useEffect(() => {}, [isLoading]);

  const handleMarkerOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const clusterBox = document.querySelector(".cluster-info-box-parent");
    const clusterBoxData = document.querySelector(".cluster-info-data-check");

    if (clusterBox && clusterBoxData) {
      const el = e.target as Element;

      const markers = document.querySelectorAll(".marker-icon");

      if (markers.length > 0) {
        let clickNum = 0;

        markers.forEach((marker) => {
          if (
            !clusterBox.contains(el) &&
            !marker.contains(el) &&
            !el.contains(clusterBox) &&
            !el.contains(marker)
          ) {
            clickNum++;
          }
        });

        // cluster info box 삭제
        if (clickNum >= markers.length) {
          clusterBox.remove();
          clusterBoxData.innerHTML = "";

          // marker-icon에 cluster-open 클래스 삭제
          const icon = document.querySelector(".cluster-open");
          if (icon) {
            icon.classList.remove("cluster-open");
          }
        }
      }
    }
  };

  return (
    <StyledFlexWrap
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <StyledMapWrap isLoading={isLoading}>
        <MapDiv
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: isLoading ? "0.6" : 1,
          }}
          onClick={handleMarkerOutsideClick}
        >
          {() => MemorizedNaverMap}
        </MapDiv>
      </StyledMapWrap>
      {isLoading && (
        <DataLoadingText text={t("loading_data")} showIcon zIndex={1} />
      )}
    </StyledFlexWrap>
  );
}

const NaverMapMemo = React.memo(
  NaverMap,
  (prevProps, nexProps) =>
    prevProps.center === nexProps.center &&
    prevProps.minZoom === nexProps.minZoom &&
    prevProps.maxZoom === nexProps.maxZoom &&
    prevProps.markerData === nexProps.markerData &&
    prevProps.showMarker === nexProps.showMarker &&
    prevProps.markerType === nexProps.markerType &&
    prevProps.stopKineticPan === nexProps.stopKineticPan &&
    prevProps.stopDraggable === nexProps.stopDraggable &&
    prevProps.isLoading === nexProps.isLoading
);
export default NaverMapMemo;

import { create } from "zustand";

interface StorageStoreItems {
  language: "ko" | "en";
}

interface CommonStoreActions {
  actions: {
    setLanguage: (language: "ko" | "en") => void;
  };
}

type CommonStoreTypes = StorageStoreItems & CommonStoreActions;

export const useCommonStore = create<CommonStoreTypes>((set) => ({
  language: (localStorage.getItem("i18nextLng") as "ko" | "en") || "ko",
  actions: {
    setLanguage: (language: "ko" | "en") => {
      set((prev) => ({ ...prev, language }));
    },
  },
}));

// * ITEMS
export const useLanguageStore = () => useCommonStore((state) => state.language);

// * ACTIONS
export const useLanguageStoreActions = () =>
  useCommonStore((state) => state.actions);

import { DataSearchTypeConst } from "@src/constructs/common";
import useFetchChartData, {
  RangeDeviceIdRequestParams,
  RangeSensorIdPagingRequestParams,
  RangeSensorIdRequestParams,
  RangeOptionalDeviceIdSensorIdRequestParams,
  S3PresignedUrlRequestParams,
  S3PresignedUrlResponse,
  S3JsonDataResponse,
} from "@src/fetch/fetchChart";
import useFetchData, {
  DeviceLatLngResponse,
  DeviceStateShortResponse,
  IDeviceList,
  RangeOptionalSearchPagingRequestParams,
  ReadFileS3RequestParams,
  UserInfoV2Response,
} from "@src/fetch/fetchCommon";
import useFetchMenagementData, {
  AdminScoreReportsResponse,
  DeviceSpecialInfoRequestParams,
  DeviceSpecialInfoResponse,
} from "@src/fetch/fetchManagement";
import DeviceChartData from "@src/models/DeviceChartData";
import SensorChartData from "@src/models/SensorChartData";
import { useQuery } from "@tanstack/react-query";

export default function useQueryHooks() {
  // * hooks
  const {
    FetchDeviceList,
    FetchUserInfo,
    FetchDeviceStateShort,
    FetchDeviceLatLng,
    FetchS3FileListRange,
    FetchS3FileList,
  } = useFetchData();

  const {
    FetchDmeasureData,
    FetchStateData,
    FetchReadS3File,
    FetchS3PresignedUrl,
    FetchPSDCableTension,
  } = useFetchChartData();

  const { FetchDeviceSpecialInfoGet, FetchAdminScoreReports } =
    useFetchMenagementData();

  // * ********************** COMMON QUERIES ********************** * //
  const useDeviceListQuery = () => {
    return useQuery<IDeviceList[] | null>({
      queryKey: ["useFetchDeviceList"],
      queryFn: () => FetchDeviceList(),
      cacheTime: 5 * 60 * 1000, // 5분에 한번씩 가져오기
      staleTime: 10000,
      meta: {
        errorMessage: "Failed to fetch Device List",
      },
    });
  };

  const useUserInfoQuery = () =>
    useQuery<UserInfoV2Response | null>({
      queryKey: ["useFetchUserInfo"],
      queryFn: () => FetchUserInfo(),
      staleTime: 60 * 60 * 1000, // 1시간 동안 캐시된 데이터는 stale 상태가 되지 않음
      cacheTime: 60 * 60 * 1000, // 1시간 동안 캐시 유지 (필요에 따라 설정)
      refetchOnWindowFocus: true, // 창 포커스시 리페치 비활성화
      // refetchInterval: 60000, // 1분마다 백그라운드에서 자동으로 데이터 리페치
      // refetchIntervalInBackground: true, // 백그라운드에서도 리페치
      // refetchInterval: 60 * 60 * 1000, // 12시간 마다 백그라운드에서 자동으로 데이터 리페치
      // refetchIntervalInBackground
      refetchOnMount: false,
      meta: {
        errorMessage: "Failed to fetch User Info",
      },
    });

  const useDeviceStateShortQuery = () =>
    useQuery<DeviceStateShortResponse[] | null>({
      queryKey: ["useFetchDeviceStateShort"],
      queryFn: () => FetchDeviceStateShort(),
      staleTime: 0, // 10분 (10분마다 재요청)
      cacheTime: 60 * 60 * 1000, // 1시간 (1시간까지 캐시 유지),
      meta: {
        errorMessage: "Failed to fetch Device State Short",
      },
    });

  // * ********************** CHART QUERIES ********************** * //
  const useDmeasureDataQuery = (
    dataParams: RangeOptionalDeviceIdSensorIdRequestParams,
    chartStyle?: "fill" | "gradient"
  ) =>
    useQuery<SensorChartData[] | null>({
      queryKey: ["dmeasureData", dataParams],
      queryFn: () =>
        FetchDmeasureData(
          dataParams as RangeOptionalDeviceIdSensorIdRequestParams,
          chartStyle
        ),
      enabled: dataParams !== null && dataParams.sensorId !== "",
      meta: {
        errorMessage: "Failed to fetch dmeasure data",
      },
    });

  const useRawDataQuery = (dataParams: RangeSensorIdPagingRequestParams) => {
    return useQuery<FileListResponse | null>({
      queryKey: ["rawDataV2", dataParams],
      queryFn: () =>
        FetchS3FileListRange("raw", {
          startDate: dataParams.startDate,
          endDate: dataParams.endDate,
          searchType: DataSearchTypeConst.SensorId,
          searchValue: dataParams.sensorId,
          size: dataParams.size,
          page: dataParams.page,
        } as RangeS3FileListRequestParams),
      enabled: dataParams !== null,
      meta: {
        errorMessage: "Failed to fetch s3 file list - [raw]",
      },
    });
  };

  const usePSDFileListQuery = (
    dataParams: RangeSensorIdPagingRequestParams
  ) => {
    return useQuery<FileListResponse | null>({
      queryKey: ["psdData", dataParams],
      queryFn: () =>
        FetchS3FileListRange("psd", {
          startDate: dataParams.startDate,
          endDate: dataParams.endDate,
          searchType: DataSearchTypeConst.SensorId,
          searchValue: dataParams.sensorId,
          size: dataParams.size,
          page: dataParams.page,
        } as RangeS3FileListRequestParams),
      enabled: dataParams !== null,
      meta: {
        errorMessage: "Failed to fetch s3 file list - [psd]",
      },
    });
  };

  const useScoreReportQuery = (
    dataParams: PagingDeivceIdRequestParams,
    type: S3BucketType
  ) => {
    return useQuery<FileListResponse | null>({
      queryKey: [`S3FileList-${type}`, dataParams],
      queryFn: () =>
        FetchS3FileList(type, {
          searchType: DataSearchTypeConst.DeviceId,
          searchValue: dataParams.deviceId,
          size: dataParams.size,
          page: dataParams.page,
        } as S3FileListRequestParams),
      enabled: dataParams !== null,
      meta: {
        errorMessage: `Failed to fetch s3 file list - [${type}]`,
      },
    });
  };

  const useStateDataQuery = (dataParams: RangeDeviceIdRequestParams) => {
    return useQuery<DeviceChartData | null>({
      queryKey: ["stateData", dataParams],
      queryFn: () => {
        return FetchStateData(dataParams as RangeDeviceIdRequestParams);
      },
      enabled: dataParams !== null,
      meta: {
        errorMessage: "Failed to fetch state chart data",
      },
    });
  };

  const useReadS3FileQuery = (
    dataParams: ReadFileS3RequestParams,
    dataType: string
  ) => {
    return useQuery<S3JsonDataResponse | FileResponse | null>({
      queryKey: ["downloadFile", `${dataParams.fileKey}_${dataType}`],
      queryFn: () => FetchReadS3File(dataParams, dataType),
      enabled: dataParams.fileKey !== "",
      meta: {
        errorMessage: "Failed to fetch raw chart file download",
      },
      staleTime: Infinity,
    });
  };

  const useDtriggerFileListQuery = (
    params: RangeOptionalSearchPagingRequestParams
  ) => {
    return useQuery<FileListResponse | null>({
      queryKey: ["dtriggerRawFileList", params],
      queryFn: () =>
        FetchS3FileListRange("event", {
          startDate: params.startDate,
          endDate: params.endDate,
          size: params.size,
          page: params.page,
          searchType: params.searchType,
          searchValue: params.searchValue,
        } as RangeS3FileListRequestParams),
      enabled: params !== null,
      meta: {
        errorMessage: "Failed to fetch dtrigger raw chart data",
      },
    });
  };

  // 유저가 소유한 모든 디바이스의 위/경도를 가져오는 쿼리
  const useDeviceLatLngQuery = () => {
    return useQuery<DeviceLatLngResponse[] | null>({
      queryKey: ["deviceLatLng"],
      queryFn: () => FetchDeviceLatLng(),
      staleTime: 60 * 1000,
      cacheTime: 60 * 1000,
      meta: {
        errorMessage: "Failed to fetch device lat lng data",
      },
    });
  };

  // 차트 페이지에서 카메라 데이터 가져오는 쿼리
  const useCamereDataQuery = (dataParams: RangeSensorIdPagingRequestParams) => {
    return useQuery<FileListResponse | null>({
      queryKey: ["useCamereDataQuery", dataParams],
      queryFn: () =>
        FetchS3FileListRange("image", {
          startDate: dataParams.startDate,
          endDate: dataParams.endDate,
          searchType: DataSearchTypeConst.SensorId,
          searchValue: dataParams.sensorId,
          page: dataParams.page,
          size: dataParams.size,
        } as RangeS3FileListRequestParams),
      enabled: dataParams !== null,
      meta: {
        errorMessage: "Failed to fetch image file list",
      },
    });
  };

  const useS3PresignedUrlQuery = (params: S3PresignedUrlRequestParams) => {
    return useQuery<S3PresignedUrlResponse | null>({
      queryKey: ["useS3PresignedUrlQuery", params],
      queryFn: () => FetchS3PresignedUrl(params as S3PresignedUrlRequestParams),
      enabled: params.fileKey !== "",
      meta: {
        errorMessage: "Failed to fetch presigned url",
      },
    });
  };

  // psd의 cableTension 데이터를 랜지로 날짜 선택, 센서 선택해서 가져와서 차트 그릴 때 사용하는 쿼리
  const usePSDCableTensionQuery = (params: RangeSensorIdRequestParams) => {
    return useQuery<SensorChartData | null>({
      queryKey: ["psdCableTension", params],
      queryFn: () => FetchPSDCableTension(params as RangeSensorIdRequestParams),
      enabled: params !== null,
      meta: {
        errorMessage: "Failed to fetch psd cable tension data",
      },
    });
  };

  const useDeviceSpecialInfoQuery = (
    params: DeviceSpecialInfoRequestParams | null
  ) => {
    return useQuery<DeviceSpecialInfoResponse[] | null>({
      queryKey: ["deviceSpecialInfo", params],
      queryFn: () =>
        FetchDeviceSpecialInfoGet(params as DeviceSpecialInfoRequestParams),
      enabled: params != null,
      meta: {
        errorMessage: "Failed to fetch device special info data",
      },
    });
  };

  const useAdminScoreReportsQuery = (params: { deviceIdList: string }) => {
    return useQuery<AdminScoreReportsResponse | null>({
      queryKey: ["admin score reports", params],
      queryFn: () => FetchAdminScoreReports(params),
      enabled: params != null && params.deviceIdList !== "",
      meta: {
        errorMessage: "Failed to fetch admin score report data",
      },
    });
  };

  return {
    useDeviceListQuery,
    useUserInfoQuery,
    useDeviceStateShortQuery,
    useDmeasureDataQuery,
    useRawDataQuery,
    useStateDataQuery,
    useReadS3FileQuery,
    useDtriggerFileListQuery,
    useDeviceLatLngQuery,
    useCamereDataQuery,
    useS3PresignedUrlQuery,
    usePSDFileListQuery,
    usePSDCableTensionQuery,
    useScoreReportQuery,
    useDeviceSpecialInfoQuery,
    useAdminScoreReportsQuery,
  };
}

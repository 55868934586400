import Button from "../../Buttons/Button";
import { NormalButtonStyles } from "../../Buttons/sizeTheme";
import RangeDate from "../../Datepickers/RangeDate";
import SingleDate from "../../Datepickers/SingleDate";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import { DateTypeConst, FlexAlignTypeConst } from "@constructs/common";
import { CardSizeProps, NormalCardStyle } from "@src/components/Card/sizeTheme";
import { StlyedSearchItemsWrap, StyledSearchRowWrap } from "../styles";
import React from "react";
import { useTranslation } from "react-i18next";
import useSearchbarHooks, {
  CommonSearchHooksProps,
  SearchDateProps,
} from "@src/hooks/useSearchbarHooks";

export interface CommonDateSearchRowProps {
  inCard?: boolean;
  cardSizeTheme?: CardSizeProps;
  layoutGridColumn?: string;
  isDisabled?: boolean;
  searchedTotalPeriod?: boolean;
}

type DateSearchRowProps = CommonSearchHooksProps &
  CommonDateSearchRowProps &
  SearchDateProps;

function DateSearchRowComponent({
  dateType,
  minDate,
  maxDate,
  searchedTotalPeriod,
  inCard,
  cardSizeTheme,
  getUrlParams,
  paging,
  setPaging,
  layoutGridColumn,
  isDisabled,
  customSearchParams,
}: DateSearchRowProps) {
  // hooks
  const { t } = useTranslation();

  const dateParams = {
    dateType,
    minDate,
    maxDate,
  };

  const {
    handleSubmit,
    onSubmit,
    onError,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    errors,
    date,
    setDate,
    submitButtonRef,
  } = useSearchbarHooks({
    searchedDate: true,
    ...dateParams,
    getUrlParams,
    paging,
    setPaging,
    customSearchParams,
    searchedTotalPeriod,
  });

  return (
    <>
      <StyledSearchRowWrap
        inCard={inCard}
        cardSizeTheme={cardSizeTheme ?? NormalCardStyle}
        align={FlexAlignTypeConst.SpaceBetween}
        marginBottom={24}
        layoutGridColumn={layoutGridColumn}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <StlyedSearchItemsWrap>
            <>
              {/* Range or Single */}
              {dateType === DateTypeConst.Range ? (
                <RangeDate
                  sizeTheme={NormalInputStyle}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  isInvalid={errors.endDate?.message !== undefined}
                  isDisabled={isDisabled}
                  errorMessages={[errors.endDate?.message ?? ""]}
                  showChoseDateButtons
                  searchedTotalPeriod
                  showLabel
                  labelText={t("period")}
                />
              ) : (
                <SingleDate
                  sizeTheme={NormalInputStyle}
                  date={date}
                  setDate={setDate}
                  isInvalid={errors.date?.message !== undefined}
                  errorMessages={[errors.date?.message ?? ""]}
                  minDate={minDate}
                  maxDate={maxDate}
                  showLabel
                  labelText={t("period")}
                  isDisabled={isDisabled}
                />
              )}
            </>
          </StlyedSearchItemsWrap>
          <Button
            type="submit"
            sizeTheme={NormalButtonStyles}
            colorType="primary"
            text={t("search")}
            buttonRef={submitButtonRef}
            isDisabled={isDisabled}
          />
        </form>
      </StyledSearchRowWrap>
    </>
  );
}

const DateSearchRow = React.memo(
  DateSearchRowComponent,
  (prevProps, nextProps) =>
    prevProps.customSearchParams === nextProps.customSearchParams
);
export default DateSearchRow;

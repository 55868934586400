import ColorBox from "@src/components/ColorBox";
import { StyledIndexWrap } from "../styles";
import { theme } from "@src/styles/theme";
import { useBrowserSizeStore } from "@src/stores/useCommonStore";
import { BrowserSizeConst } from "@src/constructs/common";

const CountBoxes = [
  {
    bgColor: "linear-gradient(111deg, #a579d9, #6a7cdd)",
    bgIconName: "connects",
    dataType: "deviceGroups",
    keyText: "Groups",
  },
  {
    bgColor: "linear-gradient(111deg, #6a7cdd, #7DB4D1)",
    bgIconName: "bridge",
    dataType: "structures",
    keyText: "Structures",
  },
  {
    bgColor: "linear-gradient(111deg, #7DB4D1, #71CC9E)",
    bgIconName: "gbDevice",
    dataType: "devices",
    keyText: "Devices",
  },
  {
    bgColor: "linear-gradient(111deg, #71CC9E, #A6C16A)",
    bgIconName: "sensors",
    dataType: "sensors",
    keyText: "Sensors",
  },
];

export interface ICounts {
  [key: string]: number;
}

interface CountBoxProps {
  counts: ICounts | null;
}

/* 컬러박스 4개 / 디바이스 정보 */
export default function CountBox({ counts }: CountBoxProps) {
  const browserSize = useBrowserSizeStore();

  return (
    <StyledIndexWrap>
      {CountBoxes.map((item, index) => (
        <ColorBox
          key={index}
          bgColor={item.bgColor}
          bgIconName={item.bgIconName}
          bgIconWidth={140}
          keyTextSize={theme.size.common.fontSize.lg}
          keyTextWeight={theme.size.common.fontWeight.md}
          valueTextSize={
            browserSize && browserSize <= BrowserSizeConst.Laptop
              ? "1.8rem"
              : "2.2rem"
          }
          valueTextWeight={
            browserSize && browserSize <= BrowserSizeConst.Laptop ? 600 : 800
          }
          keyText={item.keyText}
          valueText={counts ? counts[item.dataType].toLocaleString() : ""}
        />
      ))}
    </StyledIndexWrap>
  );
}

import { mediaQuery } from "@src/styles/theme";
import styled from "styled-components";
import { StyledCardBody } from "../Card";
import { Div } from "@src/styles/commonStyles";

export const StyledChartGridWrap = styled(Div)<{ viewLarger: boolean }>`
  display: grid;
  grid-template-columns: ${({ viewLarger }) =>
    viewLarger ? "1fr !important" : "repeat(3, 1fr)"};
  flex-wrap: wrap;
  flex: 0 0 auto;
  gap: 2.4vw;
  width: 100%;

  & > div {
    flex: 0 0 auto;
    min-width: 200px;
    min-height: 200px;
  }

  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const StyledTabButtonWrap = styled(Div)`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > *:first-child {
      width: 100%;
    }

    & > *:last-child {
      align-self: flex-end;
    }
  }
`;

export const StyledTableWrap = styled(Div)<{ fileUrl: string }>`
  width: ${({ fileUrl }) => (fileUrl !== "" ? "534px" : "100%")};
  height: clamp(300px, 100%, 100%);
  transition: width 0.24s ease-out;

  ${mediaQuery.largeLaptop} {
    order: 2;
  }
  ${mediaQuery.tablet} {
    min-width: auto;
  }
`;

export const StyledChartWrap = styled(Div)`
  flex: 1;

  ${mediaQuery.largeLaptop} {
    order: 1;
  }
`;

export const StyledImageContentWrap = styled(StyledChartWrap)`
  flex: 1;

  /* image content의 card div 높이 강제 제어 */
  & > div {
    height: auto !important;
    max-height: 100%;
  }

  .image-thumb {
    padding-top: 56%;
  }

  ${StyledCardBody} {
    overflow: auto !important;
  }

  @media screen and (max-height: 860px) {
    .image-thumb {
      padding-top: 46%;
    }
  }

  ${mediaQuery.largeLaptop} {
    order: 1;

    .image-thumb {
      padding-top: 56%;
    }
  }
`;

export const StyledTableSearchWithButtonWrap = styled(Div)`
  display: flex;
  column-gap: 40px;
  row-gap: 30px;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledTableContentContainer = styled(Div)<{
  isChildScroll?: boolean;
}>`
  display: flex;
  gap: 2vw;
  width: 100%;
  height: 100%;

  ${mediaQuery.largeLaptop} {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
`;

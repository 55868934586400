import Button from "../../Buttons/Button";
import { NormalButtonStyles } from "../../Buttons/sizeTheme";
import DeviceSearchSelect from "@src/components/Inputs/Selects/DeviceSearchSelect";
import { NormalInputStyle } from "@src/components/Inputs/sizeTheme";
import Select from "@src/components/Inputs/Selects/Select";
import { FlexAlignTypeConst } from "@constructs/common";
import { CardSizeProps, NormalCardStyle } from "@src/components/Card/sizeTheme";
import {
  StlyedSearchItemsWrap,
  StyledSearchRowWrap,
  StyledSelectsWrap,
} from "../styles";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSearchbarHooks, {
  CommonSearchHooksProps,
  OptionalDateType,
  OptionalSearchedSensorProps,
} from "@src/hooks/useSearchbarHooks";
import { StyledFlexWrap } from "@src/styles/commonStyles";

export interface CommonSearchRowProps {
  inCard?: boolean;
  cardSizeTheme?: CardSizeProps;
  layoutGridColumn?: string;
  isRunning: boolean;
  handleClose: () => void;
}

export type WebsocketDeviceSearchRowProps = CommonSearchRowProps &
  OptionalDateType &
  OptionalSearchedSensorProps &
  CommonSearchHooksProps;

export default function WebsocketDeviceSearchRow({
  searchedSensor,
  selectedSensor,
  setSelectedSensor,
  inCard,
  cardSizeTheme,
  getUrlParams,
  sensorTypeFilter,
  layoutGridColumn,
  isRunning,
  handleClose,
}: WebsocketDeviceSearchRowProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const sensorParams = searchedSensor
    ? {
        searchedSensor,
        sensorTypeFilter,
        selectedSensor,
        setSelectedSensor,
      }
    : {};

  const {
    handleSubmit,
    onSubmit,
    onError,
    errors,
    handleDeviceGroupSelect,
    handleDeviceSelect,
    handleSelectedSensorForSearch,
    submitButtonRef,
    selectedDeviceGroup,
    selectedDevice,
    memorizedDeviceGroupOptions,
    memorizedDeviceOptions,
    memorizedSensorOptions,
  } = useSearchbarHooks({
    searchedDevice: true,
    ...sensorParams,
    getUrlParams,
    disableAutoSearch: true,
  });

  return (
    <>
      <StyledSearchRowWrap
        inCard={inCard}
        cardSizeTheme={cardSizeTheme ?? NormalCardStyle}
        align={FlexAlignTypeConst.SpaceBetween}
        marginBottom={24}
        layoutGridColumn={layoutGridColumn}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <StlyedSearchItemsWrap>
            <StyledSelectsWrap>
              {/* 비즈니스 그룹 추가 */}
              <Select
                sizeTheme={NormalInputStyle}
                name="deviceGroupSelect"
                options={memorizedDeviceGroupOptions}
                value={selectedDeviceGroup}
                event={handleDeviceGroupSelect}
                isInvalid={errors.deviceGroup?.message !== undefined}
                errorMessages={[errors.deviceGroup?.message ?? ""]}
                showLabel
                labelText={t("group")}
                fixWidth={160}
              />
              <DeviceSearchSelect
                sizeTheme={NormalInputStyle}
                name="deviceSearchSelect"
                options={memorizedDeviceOptions}
                value={selectedDevice}
                event={handleDeviceSelect}
                isInvalid={errors.deviceId?.message !== undefined}
                errorMessages={[errors.deviceId?.message ?? ""]}
                showLabel
                labelText={t("device")}
              />
              {searchedSensor && (
                <Select
                  sizeTheme={NormalInputStyle}
                  name="sensorSearchSelect"
                  options={memorizedSensorOptions}
                  value={selectedSensor}
                  event={handleSelectedSensorForSearch}
                  fixWidth={150}
                  isInvalid={errors.sensorId?.message !== undefined}
                  errorMessages={[errors.sensorId?.message ?? ""]}
                  showLabel
                  labelText={t("sensor")}
                  placeholder="Select"
                />
              )}
            </StyledSelectsWrap>
          </StlyedSearchItemsWrap>
          <StyledFlexWrap>
            {isRunning &&
            searchParams.get("sensorId") === selectedSensor?.value ? (
              <Button
                type="button"
                sizeTheme={NormalButtonStyles}
                colorType="primary"
                text={t("stop")}
                onClick={handleClose}
                isActive={isRunning}
              />
            ) : (
              <Button
                buttonRef={submitButtonRef}
                type="submit"
                sizeTheme={NormalButtonStyles}
                colorType="primary"
                text={t("search")}
              />
            )}
          </StyledFlexWrap>
        </form>
      </StyledSearchRowWrap>
    </>
  );
}

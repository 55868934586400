import { useCommonStoreActions } from "@src/stores/useCommonStore";
import React from "react";
import IconButton from "../Buttons/IconButton";
import { SmallIconButtonStyles } from "../Buttons/sizeTheme";
import FloatBox, { FloatBoxProps } from "../FloatBox";
import { NormalFloatBoxStyles } from "../FloatBox/sizeTheme";
import {
  StyledDeviceIdText,
  StyledErrorText,
  StyledMoreBadge,
} from "@src/styles/commonStyles";
import { getSensorLabel } from "@src/hooks/useCommonHooks";
import {
  EventParentWrap,
  StyledDataTextWrap,
  StyledEventDimWrap,
  StyledEventFixWrap,
  StyledMessageTextWrap,
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import { useNoticeDialog } from "@src/contexts/NoticeDialogProvider";

interface FloatEventProps {
  eventDataset: EventData[];
}

const FloatEvent = React.memo(
  ({ eventDataset }: FloatEventProps) => {
    // hooks
    const { setEventDataset } = useCommonStoreActions();
    const setEventDatasetRef = React.useRef(setEventDataset);
    const { handleNoticeDialogOpen } = useNoticeDialog();

    // Functions
    const handleDeleteEventMsg = React.useCallback(
      async (keyword: string, index?: number) => {
        try {
          const result = await handleNoticeDialogOpen(
            `이벤트 알림 ${keyword === "ALL" ? "전체" : ""} 삭제`,
            <span>{`이벤트 알림 메세지를 ${keyword === "ALL" ? "전체" : ""}
        삭제하시겠습니까?`}</span>
          );

          if (result) {
            if (keyword === "ALL" || index === 0) {
              // 전부 삭제해야하거나 index가 0일 때 (1개만 존재할 때)
              setEventDatasetRef.current(null);
            } else if (index !== undefined) {
              // eventDataset이 2개 이상 존재하고 1개 씩 삭제할 때
              const newData = [...eventDataset];
              newData.splice(index, 1);
              setEventDatasetRef.current(newData);
            }
          }
        } catch (e) {
          console.log("handleDeleteEventMsg:", e);
        }
      },
      [eventDataset, handleNoticeDialogOpen]
    );

    const commonProps = {
      sizeTheme: NormalFloatBoxStyles,
      title: "이벤트 발생",
    };

    const eventFloatBoxVariatns = {
      initial: {
        scale: 0.5,
        opacity: 0,
      },
      animate: (index: number) => ({
        scale: 1,
        opacity: 1,
        transition: {
          type: index === eventDataset.length - 1 ? "spring" : "tween",
          damping: 25,
          stiffness: 400,
          opacity: {
            duration: index === eventDataset.length - 1 ? 0.2 : 0.4,
          },
        },
      }),
      exit: {
        scale: 0,
        opacity: 0,
        transition: {
          scale: {
            duration: 0.14,
          },
          opacity: {
            duration: 0.2,
          },
        },
      },
    };

    return (
      <StyledEventDimWrap>
        <StyledEventFixWrap>
          {eventDataset.length > 1 && (
            <IconButton
              colorType="secondary"
              sizeTheme={SmallIconButtonStyles}
              iconName="delete"
              onClick={() => handleDeleteEventMsg("ALL")}
              text="전체 삭제"
            />
          )}
          {eventDataset.length > 0 ? (
            <EventParentWrap childLength={eventDataset.length}>
              {eventDataset.map((item, index) => {
                // 최신 1개만 닫기 버튼 프로퍼티 추가
                const eventProps: FloatBoxProps =
                  index === eventDataset.length - 1
                    ? {
                        ...commonProps,
                        showCloseBtn: true,
                        handleClose: () => handleDeleteEventMsg("ONE", index),
                      }
                    : {
                        ...commonProps,
                      };

                // 최신 3개만 그리기
                if (index >= eventDataset.length - 1 - 2) {
                  return (
                    <AnimatePresence mode="wait" key={index}>
                      <motion.div
                        style={{
                          transformOrigin: "left top",
                        }}
                        layout
                        layoutId={`item-${index}`}
                        custom={index}
                        variants={eventFloatBoxVariatns}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                      >
                        <FloatBox {...eventProps}>
                          <StyledMessageTextWrap>
                            <span>
                              <StyledDeviceIdText boxSize="SMALL">
                                {item.deviceId}{" "}
                              </StyledDeviceIdText>
                              {` ${getSensorLabel(item.sensorId)}`} 센서에서
                            </span>
                            <span>이벤트가 발생되었습니다.</span>
                          </StyledMessageTextWrap>
                          <StyledDataTextWrap>
                            {item.val ? (
                              <span>
                                {item.val
                                  ? `기준값 초과: ${item.val}`
                                  : `객체 검출: ${
                                      item.detail === ""
                                        ? "검출된 객체 X"
                                        : item.detail
                                    }`}
                              </span>
                            ) : (
                              <StyledErrorText>
                                {`객체 검출: ${
                                  item.detail === ""
                                    ? "검출된 객체 X"
                                    : `person ${item.detail}`
                                }`}
                              </StyledErrorText>
                            )}
                            <span>발생 시각: {item.start ?? item.time}</span>
                          </StyledDataTextWrap>
                        </FloatBox>
                      </motion.div>
                    </AnimatePresence>
                  );
                }
                return null;
              })}
              {eventDataset.length - 3 > 0 && (
                <StyledMoreBadge
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                  }}
                  key={eventDataset.length}
                  initial={{ opacity: 0, scale: 0.4 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      type: "spring",
                      damping: 40,
                      stiffness: 600,
                      opacity: {
                        duration: 0.24,
                      },
                      scale: {
                        duration: 0.2,
                      },
                    },
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0,
                  }}
                >
                  + {eventDataset.length - 3}
                </StyledMoreBadge>
              )}
            </EventParentWrap>
          ) : null}
        </StyledEventFixWrap>
      </StyledEventDimWrap>
    );
  },
  (prevProps, nextProps) => prevProps.eventDataset === nextProps.eventDataset
);

export default FloatEvent;
